/* istanbul ignore file */
import React from "react";
import ReactDOM from "react-dom";
import ReactXinyaUi from "./XinyaUi/index";
import "./XinyaUi/xinya_ui.scss";
import "./Toast.scss";

const ToastSuccess = ({ text }) => {
  return (
    <div className="modal_mask">
      <div className="modal">
        <div className="icon_status success">
          <div className="modal_text no_offset">{text}</div>
        </div>
      </div>
    </div>
  );
};
const ToastError = ({ text }) => {
  return (
    <div className="modal_mask">
      <div className="modal">
        <div className="icon_status error">
          <div className="modal_text no_offset">{text}</div>
        </div>
      </div>
    </div>
  );
};
const ToastText = ({ text, classes }) => (
  <div className="modal_mask">
    <div className={`modal_text ${classes}`}  style={{width: 'fit-content'}}>
      {typeof text == "function" ? text() : text}
    </div>
  </div>
);

class Toast extends React.Component {
  render() {
    return <ReactXinyaUi.Toast show={true} {...this.props} />;
  }
}

class ToastProxy {
  constructor(component) {
    this.node = document.createElement("div");
    document.body.appendChild(this.node);
    ReactDOM.render(component, this.node);
  }

  cancel() {
    ReactDOM.unmountComponentAtNode(this.node);
  }
}

const ToastManager = {
  show(component) {
    const proxy = new ToastProxy(component);
    return proxy;
  },
  showToastSuccess(content) {
    const toast = ToastManager.show(<ToastSuccess text={content} />);
    setTimeout(() => toast.cancel(), 3000);
    return toast;
  },
  showToastError(content) {
    const toast = ToastManager.show(<ToastError text={content} />);
    setTimeout(() => toast.cancel(), 3000);
    return toast;
  },
  showLoading(content, textStyle = {}) {
    return ToastManager.show(<Toast icon="loading" textstyle={textStyle}>{content}</Toast>);
  },
  showToastText(content, delay = 3000, classes = "") {
    const toast = ToastManager.show(<ToastText text={content} classes={classes} />);
    setTimeout(() => toast.cancel(), delay);
    return toast;
  },
  showSuccess(content) {
    const proxy = ToastManager.show(
      <Toast icon="success-no-circle">{content}</Toast>
    );
    setTimeout(() => proxy.cancel(), 2000);
    return proxy;
  },

  showError(content) {
    const proxy = ToastManager.show(<Toast icon="cancel">{content}</Toast>);
    setTimeout(() => proxy.cancel(), 2000);
    return proxy;
  },
};

export { Toast, ToastManager };
