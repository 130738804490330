/* eslint-disable react/prop-types */
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useCallback,
  useRef,
  memo,
} from "react";
import ErrorBoundary from "../utils/ErrorBoundary";
import { ToastManager } from "../utils/Toast";
import { onNavigate } from "../utils/helpers";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { request, csrfHeaders } from "../utils/request";
import RightFloatIcons from "./RightFloatIcons";
import CountDownTime from "../utils/CountDown";
import { Tabs, TabPane } from "../utils/Tabs/Tabs.js";
import { onDirectBuy } from "./ShoppingBar";
import { WechatFollow } from "../study_package_activities/WechatFollow";
import {
  CustomizationCourseContentPieChart,
  CustomizationSubDescribe,
  CustomizationCoursePrice,
  showBoughtSuccessDialog,
} from "./ThinkingCustomize";
import { ActivityBanner } from "./activity_components";
import ThinkingCoupons from "./ThinkingCoupons";
import {
  StyledCustomizationThinkDetails,
  StyledThinkingDetailList,
  StyledRightList,
  StyledLeftListIndex,
  StyleDetailCourseContent,
  StyledThinkingBottomButton,
  StyledLimitDiscountsCountdown,
} from "./ThinkingCustomize.styled";
import "./SubActivityTabs.scss";

const detailColors = [
  {
    background: "#ebf3ff",
    frame_background: "#e1eeff",
    frame_border: "#c8dffe",
    frame_inset_boder: "#d6e7ff",
    font_background: "#82b2f8",
  },
  {
    background: "#fff2e4",
    frame_background: "#ffebd4",
    frame_border: "#ffdbaf",
    frame_inset_boder: "#ffdcb0",
    font_background: "#fea948",
  },
  {
    background: "#e9fffa",
    frame_background: "#d6faf2",
    frame_border: "#a9f0df",
    frame_inset_boder: "#b3f2e4",
    font_background: "#2fcfa7",
  },
];

const SubThinkingDetailBrief = ({
  customizeCategory,
  studyModules,
  actIndex,
}) => {
  return (
    <div className="sub_activity_brief customized_activeity_brief">
      <ThinkingDetailList
        customizeCategory={customizeCategory}
        studyModules={studyModules}
        actIndex={actIndex}
      />
    </div>
  );
};

export const calcSubMediaLessonsCountTotal = (studyModules) => {
  let all_media_lessons_total = 0;
  for (let i = 0; i < studyModules.length; i++) {
    for (let j = 0; j < studyModules[i].media_courses.length; j++) {
      all_media_lessons_total +=
        studyModules[i].media_courses[j].media_lessons_count;
    }
  }
  return all_media_lessons_total;
};

const ThinkingDetailList = memo(
  ({ customizeCategory, studyModules, actIndex }) => {
    const singleDetailColor = detailColors[actIndex % detailColors.length];

    let total = 0;
    const knowledgeCount = studyModules.reduce((pre, cur, index) => {
      return (total += cur.media_courses.length);
    }, 0);

    const allMediaLessonsTotal = calcSubMediaLessonsCountTotal(studyModules);

    return (
      <StyledThinkingDetailList>
        <div className="main_title">
          {customizeCategory}
          <span>{`（${studyModules.length}个单元、${knowledgeCount}个知识点、${allMediaLessonsTotal}节课）`}</span>
        </div>
        <div className="detail_lists_box">
          {studyModules.map((study_module, idx) => {
            let media_lessons_total = 0;
            const mediaLessonsCount = study_module.media_courses.reduce(
              (pre, cur, index) => {
                return (media_lessons_total += cur.media_lessons_count);
              },
              0
            );
            return (
              <div
                className="detail_lists"
                key={idx}
                style={{ background: singleDetailColor.background }}
              >
                <div className="sub_title">
                  <span>{idx + 1}</span>
                  <div className="package_name">{study_module.name}</div>
                </div>
                <React.Fragment>
                  <div
                    className="single_list_box"
                    style={{
                      border: `2px solid ${singleDetailColor.frame_border}`,
                    }}
                  >
                    <div className="knowledge_text">{`知识点（${mediaLessonsCount}节课）`}</div>
                    {study_module.media_courses.map((media_course, index) => (
                      <div
                        className="list_box"
                        key={index}
                        style={{
                          borderTop: `1px solid ${singleDetailColor.frame_inset_boder}`,
                        }}
                      >
                        <div className="left_list">
                          <StyledLeftListIndex
                            frameInsetColor={
                              singleDetailColor.frame_inset_boder
                            }
                            fontBackground={singleDetailColor.font_background}
                          >
                            {index + 1}
                          </StyledLeftListIndex>
                          <div className="left_list_name">
                            {media_course.name}
                          </div>
                        </div>
                        <StyledRightList
                          frameInsetColor={singleDetailColor.frame_inset_boder}
                        >
                          {`${media_course.media_lessons_count}节课`}
                        </StyledRightList>
                      </div>
                    ))}
                  </div>
                  {study_module.photo_url && (
                    <div className="detail_photo_url">
                      <img src={study_module.photo_url} alt="" />
                    </div>
                  )}
                </React.Fragment>
              </div>
            );
          })}
        </div>
      </StyledThinkingDetailList>
    );
  }
);

const ActivityThinkingDetailsTabs = memo(({ sub_study_packages }) => {
  const fakeTriggerTabClick = useRef(false);
  const pageMounted = useRef();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const tabClick = (e, activeTabIndex) => {
    setSelectedTabIndex(activeTabIndex * 1);
  };

  useLayoutEffect(() => {
    if (!pageMounted.current) {
      pageMounted.current = true;
      return;
    }
    if (!fakeTriggerTabClick.current) {
      // eslint-disable-next-line no-unused-expressions
      document
        .getElementsByClassName("sub_activity_brief")
        [selectedTabIndex]?.scrollIntoView(true);
      window.scrollBy(0, -50);
    }
  }, [selectedTabIndex]);

  useEffect(() => {
    $(".sub_tabs").on("click", function (event) {
      if (event.originalEvent === undefined) {
        fakeTriggerTabClick.current = true;
      } else {
        fakeTriggerTabClick.current = false;
      }
    });
    const toScrollEles = document.getElementsByClassName("sub_activity_brief");
    const eleArr = Array.from(toScrollEles);
    const scroll = () => {
      eleArr.forEach((ele, index) => {
        const { top, height } = ele.getBoundingClientRect();
        // 下滑和上滑
        if (
          (top >= 0 && top <= 50) ||
          (top < 0 && height - Math.abs(top) >= 50)
        ) {
          $(".sub_tab_wrapper").eq(index).trigger("click");
        }
      });
    };
    document.addEventListener("scroll", scroll);
    return () => {
      document.removeEventListener("scroll", scroll);
    };
  }, []);

  return (
    <div className="comp_sub_activity_tabs">
      <Tabs
        defaultAction={tabClick}
        styles="sub_tabs thinking_customized_tabs"
        defaultActiveIndex={0}
        animatedTabTitle={true}
        noAnimatedScroll={true}
      >
        {sub_study_packages
          .map((act) => act.customize_category)
          .map((item, index) => {
            return (
              <TabPane
                tab={<div className="sub_tab">{item}</div>}
                styles="sub_tab_wrapper"
                activeStyle="active"
                key={index}
              >
                {""}
              </TabPane>
            );
          })}
      </Tabs>

      <div className="mark_down_brief" id="tab_briefs">
        {sub_study_packages.map((act, index) => (
          <SubThinkingDetailBrief
            key={index}
            customizeCategory={act.customize_category}
            studyModules={act.study_modules}
            actIndex={index}
          />
        ))}
      </div>
    </div>
  );
});

const DetailCourseContent = ({ ...props }) => {
  return (
    <StyleDetailCourseContent>
      <CustomizationCourseContentPieChart {...props} />
    </StyleDetailCourseContent>
  );
};

export const LimitDiscountsCountdown = ({
  isThinkingUser,
  originalPrice,
  buyCountdownEndtime,
}) => {
  const ExpiredWarnText = ({ d, h, m, s, ms }) => {
    return (
      <span>
        <span className="expired_warn_text">剩余</span>
        {d == "00" ? (
          ""
        ) : (
          <>
            <span className="day">{d}</span>天
          </>
        )}
        {h == "00" ? (
          ""
        ) : (
          <>
            <span className="hour">{h}</span>:
          </>
        )}
        <span className="minus">{m}</span>:<span className="seconds">{s}</span>:
        <span className="ms">{ms}</span>
      </span>
    );
  };
  return (
    <StyledLimitDiscountsCountdown className="discount_count_down">
      {!isThinkingUser ? (
        <div className="left_discount_text">
          原价<del>￥{originalPrice}</del>限时0元学
        </div>
      ) : (
        <div className="left_discount_text">限时特惠</div>
      )}
      <div className="right_discount_countdown">
        <CountDownTime
          endTime={new Date(buyCountdownEndtime)}
          overText={<ExpiredWarnText d={0} h={0} m={0} s={0} ms={0} />}
          onEnd={() => {}}
        >
          {({ d, h, m, s, ms }) => {
            return <ExpiredWarnText d={d} h={h} m={m} s={s} ms={ms} />;
          }}
        </CountDownTime>
      </div>
    </StyledLimitDiscountsCountdown>
  );
};

export const ThinkingBottomButton = ({ ...props }) => {
  // 底部购买浮动按钮
  console.log("ThinkingBottomButton-props: ", props);
  const { collectLastPayParams, free_url, wechatRef } = props;
  const withoutCharge = (params) => {
    if (free_url) {
      const toast = ToastManager.showLoading("正在免费领取中 请耐心等待");

      if (params["target_id"]) {
        params["id"] = params["target_id"];
      }
      if (props?.customizedActivityId) {
        params["id"] = props?.customizedActivityId;
      }
      console.log("after_free_params: ", params);
      console.log("after_free_props: ", props);
      /**
       *  group_state: params['group_state'],
          ecoupon_id: params['ecouponId'],
          target_id: params['target_id'],
          id: params['target_id'],
          source: params['source'],
          virtual_account_money: params['virtualAccountBalance']
       *  */
      request({
        url: free_url,
        method: "POST",
        headers: csrfHeaders,
        data: { ...params },
      })
        .then((resp) => {
          toast.cancel();
          console.log(resp.data);
          if (resp.data.err_code) {
            window.location.href = resp.data.redirect_url;
          } else {
            // if (!props.is_thinking_user && props.no_customized) {
            if (false) {
              showBoughtSuccessDialog(props);
            } else {
              ToastManager.showToastText("领取成功", 2000);
              if (props.has_teaching_aid_package) {
                // 如果教具包存在 就跳到地址填写页
                window.location.href = props.gift_commodities_url;
                return;
              }

              // if (resp.data.redirect_url) {
              //   window.location.href = resp.data.redirect_url;
              //   return;
              // }

              if (props.note_url) {
                window.location.href = props.note_url;
                return;
              }

              window.location.reload(true);
            }
          }
        })
        .catch((err) => {
          toast.cancel();
          console.log(err);
        });
    } else {
      showBoughtSuccessDialog(props);
    }
  };

  const handlePayCick = () => {
    if (collectLastPayParams?.lastNeedPayMoney == 0) {
      const object = {
        source: "free",
        ecoupon_id: collectLastPayParams?.payEcouponId,
        group_state: "direct",
        target_id: props.study_package_activity_id,
        virtual_account_money: collectLastPayParams?.lastVirtualMoney,
      };
      console.log("免费领取 object: ", object);
      withoutCharge(object);
    } else {
      const objet = {
        amount: collectLastPayParams?.lastNeedPayMoney,
        group_state: "direct",
        target_id: props.study_package_activity_id,
        ecoupon_id: collectLastPayParams?.payEcouponId, // ecouponId,
        virtual_account_money: collectLastPayParams?.lastVirtualMoney,
        buy_url: props.study_package_activity_buy_url,
        trial: false,
        props,
      };

      console.log("正价课直接购买-535-objet: ", objet);
      onDirectBuy(
        collectLastPayParams?.lastNeedPayMoney,
        "direct",
        props.study_package_activity_id,
        collectLastPayParams?.payEcouponId,
        collectLastPayParams?.lastVirtualMoney,
        props.study_package_activity_buy_url,
        false,
        props
      );
    }
  };
  const handleOneYuanStudyWeek = () => {
    console.log("一元学一周");
    // const objet = {
    //   amount: 1,
    //   group_state: "direct",
    //   target_id: props.study_package_activity_id,
    //   ecoupon_id: -1,
    //   virtual_account_money: 0,
    //   buy_url: props.study_package_activity_buy_url,
    //   trial: true,
    //   props,
    // };
    // console.log("一元学一周-支付时需要提交的参数配置: ", objet);
    // onDirectBuy(
    //   1,
    //   "direct",
    //   props.study_package_activity_id,
    //   -1,
    //   0,
    //   props.study_package_activity_buy_url,
    //   true,
    //   props
    // );
    const object = {
      source: "free",
      ecoupon_id: -1,
      group_state: "direct",
      target_id: props.study_package_activity_id,
      virtual_account_money: 0,
      trial: true,
      props,
    };
    console.log("免费领取 object: ", object);
    withoutCharge(object);
  };

  const handleRecommendFriend = () => {
    console.log("handleRecommendFriend props: ", props);
    const {
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      bonus,
      bonus_type,
      avatar_location,
    } = props;
    const free = "is_customize_course";
    new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus,
      bonus_type,
      avatar_location,
    }).open();
  };

  const handleOneYuanClick = () => {
    if (props.user_interaction_tracks_url) {
      const params = {
        operate: "trial",
        tag:
          props.customized_study_package_activity?.id ||
          props?.customizedActivityId,
      };
      console.log("params: ", params);
      request({
        url: props.user_interaction_tracks_url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    }

    props.qrcode_url
      ? wechatRef.current.showWechatFollow()
      : handleOneYuanStudyWeek();
  };

  const handlePay = () => {
    if (props.user_interaction_tracks_url) {
      const params = {
        operate: "pay",
        tag: props.customized_study_package_activity?.id,
      };
      console.log("params: ", params);
      request({
        url: props.user_interaction_tracks_url,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    }
    props.qrcode_url ? wechatRef.current.showWechatFollow() : handlePayCick();
  };

  const beforeBuy = () => {
    console.log(
      "----427 collectLastPayParams props: ",
      collectLastPayParams?.originalPrice
    );
    return (
      <div className="think_bottom_button_box">
        {
          // 倒计时提醒 限时特惠
          props.buy_countdown_endtime && (
            <LimitDiscountsCountdown
              isThinkingUser={props.is_thinking_user}
              originalPrice={collectLastPayParams?.originalPrice}
              buyCountdownEndtime={props.buy_countdown_endtime}
            />
            // <div className="limit_discounts_countdown">
            //   {!props.is_thinking_user ? (
            //     <div className="left_discount_text">
            //       原价<del>￥{collectLastPayParams?.originalPrice}</del>
            //       限时0元学
            //     </div>
            //   ) : (
            //     <div className="left_discount_text">限时特惠</div>
            //   )}
            //   <div className="right_discount_countdown">
            //     <CountDownTime
            //       endTime={new Date(props.buy_countdown_endtime)}
            //       overText={<ExpiredWarnText d={0} h={0} m={0} s={0} />}
            //       onEnd={() => {}}
            //     >
            //       {({ d, h, m, s }) => {
            //         return <ExpiredWarnText d={d} h={h} m={m} s={s} />;
            //       }}
            //     </CountDownTime>
            //   </div>
            // </div>
          )
        }
        {
          // 1元学一周的状态 <i>￥</i>0.01
          !props.is_thinking_user ? (
            <div
              className="btn_right"
              id="thinking_series_trial"
              onClick={() => handleOneYuanClick()}
            >
              {/*
              <p className="top">
                <span style={{ fontSize: 20 }}>免费</span>
                学一周
                <span style={{ fontSize: "12px", fontWeight: "300" }}>
                  （正式课内容）
                </span>
              </p>

              <p className="bottom">
                还可得
                <span className="strong_num">￥100</span>
                课程优惠券
              </p>
              */}

              <span>立即0元学一周</span>
            </div>
          ) : (
            <div
              className="btn_right"
              id="think_series_course"
              onClick={() => handlePay()}
            >
              <p className="top">
                <span className="strong_num" style={{ fontSize: 20 }}>
                  <i>￥</i>
                  {Number(collectLastPayParams?.lastNeedPayMoney).toFixed(2)}
                </span>
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "300",
                    paddingLeft: "6px",
                  }}
                >
                  <del>￥{collectLastPayParams?.originalPrice}</del>
                </span>
              </p>
              <p className="bottom" style={{ fontSize: 14 }}>
                立享优惠报名
              </p>
            </div>
          )
        }
      </div>
    );
  };

  const afterBuy = () => {
    return (
      <div className="bought_after_buy">
        <div
          className="btn_left"
          onClick={(e) => onNavigate(e, props.study_url)}
        >
          <div className="top">进入课表</div>
        </div>
        <div className="btn_right" onClick={() => handleRecommendFriend()}>
          <p className="top">推荐好友</p>
        </div>
      </div>
    );
  };
  return (
    <StyledThinkingBottomButton className="thinking_bottom_button">
      {props.bought ? afterBuy() : beforeBuy()}
    </StyledThinkingBottomButton>
  );
};

// 计算总课程课时数
export const calcAllMediaLessonsCount = (sub_study_packages) => {
  console.log(
    "计算总课程课时数 calcAllMediaLessonsCount: ",
    sub_study_packages
  );
  let counts = 0;
  const new_arr = [];
  sub_study_packages.forEach((sub_study_package) => {
    for (let i = 0; i < sub_study_package.study_modules.length; i++) {
      new_arr.push(sub_study_package.study_modules[i].media_courses);
    }
  });
  const totalMediaLessonsCollections = new_arr.flat(Infinity);
  totalMediaLessonsCollections.reduce((pre, cur, index) => {
    return (counts += cur.media_lessons_count);
  }, 0);
  return counts;
};

// 默认选中的优惠券
export const findDefaultSelectedTicket = (props) => {
  console.log("473-选中默认券props: ", props);
  let defa_ticket;
  if (props.activity_ecoupons) {
    if (props.ecoupons_available) {
      defa_ticket = props.activity_ecoupons.find((ticket, index) => {
        ticket["id"] = ticket.id;
        ticket["index"] = index;
        return ticket;
      });
      console.log(
        "默认选中的优惠券: ",
        defa_ticket,
        "金额: ",
        defa_ticket && defa_ticket.price,
        "券的id: ",
        defa_ticket && defa_ticket.id
      );
      console.log("start-time: ", defa_ticket && defa_ticket.start_time);
    }
  }
  return {
    selectIndex: props.ecoupons_available
      ? defa_ticket && defa_ticket.index
      : -1, // 默认选中
    defaultSelectCouponId:
      props.ecoupons_available && defa_ticket ? defa_ticket.id : -1,
    defaultSelectPrice: props.ecoupons_available
      ? defa_ticket
        ? defa_ticket.price
        : 0
      : 0,
    defaultMinProductPrice: props.ecoupons_available
      ? defa_ticket
        ? defa_ticket.min_product_price
        : 0
      : 0,
  };
};

export default ({ ...props }) => {
  console.log("ThinkingDetails_props: ", props);
  const wechatRef = useRef();
  const [closeCouponDialog, setCloseCouponDialog] = useState(false);
  const [collectCouponData, setCollectCouponData] = useState({});
  const [collectLastPayParams, setCollectLastPayParams] = useState({});
  console.log("详情页-collectLastPayParams: ", collectLastPayParams);

  useEffect(() => {
    const {
      share_title,
      share_url,
      share_description,
      share_image_url,
    } = props;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
    if (props.group_qrcode_url) {
      showBoughtSuccessDialog(props);
    }
  }, []);

  const onInvitationIconClick = () => {
    const {
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      bonus,
      bonus_type,
      avatar_location,
    } = props;
    const free = "is_customize_course";
    new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus,
      bonus_type,
      avatar_location,
    }).open();
  };

  return (
    <ErrorBoundary>
      <StyledCustomizationThinkDetails
        style={
          props.buy_countdown_endtime && !props.bought
            ? { paddingBottom: 110 }
            : {}
        }
      >
        <ActivityBanner
          mainVideo={props.main_video_url}
          mainPhotoUrl={props.detail_photo_url}
        />
        <DetailCourseContent {...props} />
        <ActivityThinkingDetailsTabs
          sub_study_packages={props.sub_study_packages}
        />
        {
          // props.is_thinking_user && (
          <CustomizationSubDescribe
            title="课程价格"
            renderContent={() => {
              return (
                <CustomizationCoursePrice
                  collectCouponData={collectCouponData}
                  setCollectLastPayParams={setCollectLastPayParams}
                  setCloseCouponDialog={setCloseCouponDialog}
                  {...props}
                />
              );
            }}
          />
          // )
        }
        <ThinkingBottomButton
          setCloseCouponDialog={setCloseCouponDialog}
          collectCouponData={collectCouponData}
          collectLastPayParams={collectLastPayParams}
          wechatRef={wechatRef}
          {...props}
        />

        {closeCouponDialog && (
          <ThinkingCoupons
            eCouponData={props?.activity_ecoupons}
            closeCouponDialog={closeCouponDialog}
            setCloseCouponDialog={setCloseCouponDialog}
            setCollectCouponData={setCollectCouponData}
            collectCouponData={collectCouponData}
            defaultSelectedTicket={findDefaultSelectedTicket(props)}
            coursePayFinalPrice={
              collectLastPayParams?.surplusActivityDiscountPrice
            }
            {...props}
          />
        )}
        <RightFloatIcons
          isShowRedMoney={!!props.bonus}
          onRedMoneyClick={onInvitationIconClick}
          bottomPosition={
            props.buy_countdown_endtime && !props.bought ? 140 : 100
          }
        />
        <WechatFollow ref={wechatRef} qrcodeUrl={props.qrcode_url} />
      </StyledCustomizationThinkDetails>
    </ErrorBoundary>
  );
};
