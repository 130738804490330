import React from "react";
import PropTypes from "prop-types";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import "./CouponTicket.scss";
import Wuhan from "./Wuhan";

// import { throttle } from "../utils/helpers.js"
export default class CouponTicket extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dynamicTicketContentHeight: 200,
      ticketDrawed: props.ticketDrawed,
      redirectUrl: props.redirectUrl,
      couponTicketActionBottom: 0,
    };
    this.linkToCourse = this.linkToCourse.bind(this);
    this.drawCouponTicket = this.drawCouponTicket.bind(this);
    this.drawCouponTicketFromFriends = this.drawCouponTicketFromFriends.bind(
      this
    );
    console.log("CouponTicket", props);
  }

  componentDidMount() {
    if (this.props.actionState == "CollectNow") {
      wx.ready(function () {
        wx.hideAllNonBaseMenuItem();
      });
    }

    // 动态处理ticket适用课程高度（可领取状态下）
    if (this.centainCourseContainerRef) {
      let vaildCourseContainerHeight = this.centainCourseContainerRef.getBoundingClientRect()
        .height;
      let originvaildCourse = vaildCourseContainerHeight;
      if (vaildCourseContainerHeight) {
        let clientWidth = document.body.clientWidth;
        if (clientWidth > 320) {
          vaildCourseContainerHeight += 63;
          console.log(vaildCourseContainerHeight);
          let ticketTopInfoHei = vaildCourseContainerHeight;
          let ticketHei = vaildCourseContainerHeight - 91 + 200.5;
          let ticketInfoHei = ticketTopInfoHei;
          let ticketInfoMargin = 30;
          let ticketMargin = ticketHei - 200.5 + 5 - 30;
          if (originvaildCourse > 57) {
            this.setState({
              ticketInfoHei,
              ticketTopInfoHei,
              ticketHei,
              ticketMargin,
              ticketInfoMargin,
              ticketInfoPadTop: 10,
            });
          }
        } else {
          let ticketInfoMargin = 30;
          this.setState({
            ticketInfoMargin,
          });
          if (originvaildCourse > 57) {
            let ticketHei = vaildCourseContainerHeight + 30 + 30 + 30 + 8;
            let ticketInfoHei = vaildCourseContainerHeight + 30;
            let ticketMargin = ticketHei - 140 + 5 - 30;
            ticketInfoMargin = 42;
            this.setState({
              ticketInfoMargin,
              ticketHei,
              ticketInfoHei,
              ticketMargin,
            });
          }
        }
      }
    }

    //动态处理点击按钮下面适用课程高度（无领取权限下）
    if (this.textAttention) {
      let textAttentionHeight = this.textAttention.offsetHeight;
      if (textAttentionHeight) {
        console.log(textAttentionHeight);
        let offSet = textAttentionHeight - 13 + 26;
        let clientWidth = document.body.clientWidth;
        let couponTicketActionWidth = 321;
        let couponTicketActionHeight = offSet + 100;
        if (clientWidth <= 320) {
          couponTicketActionWidth = 256;
          couponTicketActionHeight = couponTicketActionHeight - 5;
        }
        if (textAttentionHeight === 13) {
          this.setState({
            couponTicketActionWidth,
          });
          return;
        }
        this.setState({
          offSet: offSet,
          couponTicketActionWidth: `${couponTicketActionWidth}px`,
          couponTicketActionBottom: `${0 - offSet}px`,
          couponTicketActionHeight: `${couponTicketActionHeight}px`,
        });
      }
    }
  }

  drawTicketRequest(url, QRCode) {
    if (QRCode) {
      this.props.alertQRCode();
      return;
    }
    this.toast = ToastManager.showLoading("优惠券领取中...");
    return request({
      url: url,
      method: "PATCH",
      headers: csrfHeaders,
    })
      .then((res) => {
        console.log(res);
        this.toast.cancel();
        if (res.data.err_code === 0) {
          this.setState({
            ticketDrawed: true,
            redirectUrl: res.data.redirect_url,
          });
          ToastManager.showToastSuccess("领取成功，请尽快使用。");
        } else {
          const redirect_url = res.data.redirect_url;
          if (redirect_url) {
            window.location.href = redirect_url;
            return;
          }
          let attention = res.data.err_msg || "领取失败，请刷新页面重试。";
          ToastManager.showToastError(attention);
        }
      })
      .catch((err) => {
        this.toast.cancel();
        ToastManager.showToastError("领取失败，请刷新页面重试。");
      });
  }

  linkToCourse() {
    if (this.state.ticketDrawed && this.state.redirectUrl) {
      window.location.href = this.state.redirectUrl;
    }
  }

  // 用户领取券
  drawCouponTicket() {
    console.log(
      "drawCouponTicket",
      this.props.getEcouponUrl,
      this.props.QRCodeUrl
    );
    this.drawTicketRequest.call(
      this,
      this.props.getEcouponUrl,
      this.props.QRCodeUrl
    );
  }

  // 用户领取朋友赠送券
  drawCouponTicketFromFriends() {
    const { canPresent, collectFromFriendState } = this.props;
    if (collectFromFriendState && collectFromFriendState === "Self") {
      ToastManager.showToastError("自己不能领取赠送出的优惠券");
      return;
    }
    if (canPresent) {
      console.log("drawCouponTicketFromFriends");
      this.drawTicketRequest.call(
        this,
        this.props.getEcouponUrl,
        this.props.QRCodeUrl
      );
    }
  }

  getCouponTicketAction(
    startSellTime,
    userName,
    collectFromFriendState,
    ticketAmount,
    limitOwnerNames,
    fromUser,
    canPresent
  ) {
    // {/* 未到领取时间 */ }
    const NotTimeToCollect = () => {
      if (!startSellTime) {
        console.log("no startSellTime");
        return;
      }
      return (
        <div className="coupon_ticket_action">
          <div className="action_botton_common inactive">立即领取</div>
          <div className="text_attention">{startSellTime} 开始领取</div>
        </div>
      );
    };

    // {/* 无领取权限 */}
    const NoAuthorityToCollect = () => {
      if (limitOwnerNames === undefined) {
        console.log("no limitOwnerNames");
        return;
      }

      return (
        <div
          className="coupon_ticket_action"
          style={{
            bottom: this.state.couponTicketActionBottom,
            height: this.state.couponTicketActionHeight,
            backgroundColor: "#ff4659",
            borderRadius: "10px",
            width: this.state.couponTicketActionWidth,
          }}
        >
          <div className="action_botton_common inactive">立即领取</div>
          <div
            className="text_attention"
            ref={(re) => (this.textAttention = re)}
          >
            仅限
            {limitOwnerNames && limitOwnerNames.length > 0
              ? limitOwnerNames.join("、")
              : "指定课程"}
            用户领取
          </div>
        </div>
      );
    };

    // {/* 仅限武汉地区用户领取 */ }
    const OnlyWuhanTickets = () => (
      <div className="coupon_ticket_action">
        <div className="action_botton_common inactive">立即领取</div>
        <div className="text_attention">很抱歉，优惠券仅限武汉地区用户领取</div>
      </div>
    );

    // {/* 已领完 */ }
    const NoMoreTickets = () => (
      <div className="coupon_ticket_action">
        <div className="action_botton_common inactive">已领完</div>
        <div className="text_attention">很抱歉，优惠券已被领完</div>
      </div>
    );

    //  {/* 超过优惠券使用时间 */}
    const ExcessValidity = () => (
      <div className="coupon_ticket_action">
        <div className="action_botton_common inactive">已过期</div>
        <div className="text_attention">很抱歉，优惠券已超过使用有效期</div>
      </div>
    );

    //  {/* 优惠券已经被使用 */}
    const TicketUsed = () => (
      <div className="coupon_ticket_action">
        <div className="action_botton_common inactive">已使用</div>
        <div className="text_attention">优惠券已经被使用</div>
      </div>
    );
    //  {/* 优惠券已赠送给他人 */}
    const TicketSent = () => (
      <div className="coupon_ticket_action">
        <div className="action_botton_common inactive">已赠送给他人</div>
        <div className="text_attention">优惠券已赠送给他人</div>
      </div>
    );
    //  {/* 优惠券领取 */}
    const CollectNow = () => (
      <div className="coupon_ticket_action">
        <div
          className="action_botton_common active"
          onClick={
            !this.state.ticketDrawed ? this.drawCouponTicket : this.linkToCourse
          }
        >
          {this.state.ticketDrawed ? "已领取，立即使用" : "立即领取"}
        </div>
        {/* <div className="text_attention">限{ticketAmount}张，领完即止</div> */}
      </div>
    );

    //  {/* 优惠券赠送领取 */}
    const CollectFromFriend = () => {
      let getStatedButton = () => {
        if (collectFromFriendState === "OutOfUse") {
          return <div className="action_botton_common inactive">已领完</div>;
        }
        if (collectFromFriendState === "OutOfDate") {
          return <div className="action_botton_common inactive">已过期</div>;
        }
        if (collectFromFriendState === "Used") {
          return <div className="action_botton_common inactive">已使用</div>;
        }
        if (collectFromFriendState === "Self") {
          return (
            <div
              className="action_botton_common active"
              onClick={this.drawCouponTicketFromFriends}
            >
              立即领取
            </div>
          );
        }
        if (collectFromFriendState === "Success") {
          return (
            <div
              className="action_botton_common active"
              onClick={
                !this.state.ticketDrawed
                  ? this.drawCouponTicketFromFriends
                  : this.linkToCourse
              }
            >
              {this.state.ticketDrawed ? "已领取，立即使用" : "立即领取"}
            </div>
          );
        }
        return null;
      };
      return (
        <div
          className="coupon_ticket_collect_action"
          style={{
            bottom: this.state.couponTicketActionBottom,
            height: this.state.couponTicketActionHeight,
            backgroundColor: "#ff4659",
            borderRadius: "10px",
            width: this.state.couponTicketActionWidth,
          }}
        >
          <img src={fromUser.photo_url} alt="" />
          <div
            className="text_attention_collect"
            ref={(re) => (this.textAttention = re)}
            style={{ width: "100%", height: "60px" }}
          >{`${fromUser.name}赠送给你1张优惠券`}</div>
          {getStatedButton()}
        </div>
      );
    };

    const stateStrategy = {
      NotTimeToCollect,
      NoAuthorityToCollect,
      NoMoreTickets,
      ExcessValidity,
      CollectNow,
      CollectFromFriend,
      TicketSent,
      TicketUsed,
      OnlyWuhanTickets,
    };
    return stateStrategy;
  }

  getSuitableCourse(validCourses, title) {
    if (validCourses) {
      return (
        <div
          className="centain_suitable"
          ref={(refs) => (this.centainCourseContainerRef = refs)}
        >
          <div className="suitable_title">可用于报名以下课程：</div>
          {validCourses.map((course, index) => (
            <div className="suitable_courses" key={index}>
              {course}
            </div>
          ))}
        </div>
      );
    } else {
      return <div className="all_suitable">{title}</div>;
    }
  }

  render() {
    const {
      ticketTopInfoHei,
      ticketHei,
      ticketInfoHei,
      ticketMargin,
      ticketInfoMargin,
      offSet,
      ticketInfoPadTop,
    } = this.state;
    const {
      actionState,
      sum,
      validCourses,
      vaildAll,
      vaildExperiences,
      title,
      endDate,
      startDate,
      startSellTime,
      userName,
      collectFromFriendState,
      ticketAmount,
      limitOwnerNames,
      fromUser,
      canPresent,
      mode,
      periodInterval,
      minProductPrice,
    } = this.props;
    return (
      <div
        className={
          this.props.isWuhanPromotion
            ? "comp_coupon_ticket wuhan"
            : "comp_coupon_ticket"
        }
        style={{ marginTop: ticketMargin, marginBottom: offSet }}
      >
        <div className="coupon_ticket_content" style={{ height: ticketHei }}>
          <div
            className="ticke_info"
            style={{
              height: ticketInfoHei,
              marginTop: ticketInfoMargin,
              paddingTop: ticketInfoPadTop,
            }}
          >
            <div className="top_info" style={{ height: ticketTopInfoHei }}>
              <div className="sum">
                {this.props.half_price ? <div className="half-price">5<span className="half-price-text">折</span></div>
                  :
                  <div className="unit_money">
                    <span className="unit">¥</span>
                    <span className="money">{sum}</span>
                  </div>
                }
                {/* 这里使用visibility： 1是为了避免回流；2是为了避免高度塌陷 */}
                <div
                  className="use_condition"
                  style={{
                    visibility: minProductPrice > 0 ? "visible" : "hidden",
                  }}
                >
                  {`满${minProductPrice}元可用`}
                </div>
              </div>
              <div className="vaild_course" id="vaild_course">
                {this.getSuitableCourse(validCourses, title)}
              </div>
            </div>
            <div className="bottom_info">
              <div className="start_end_date">
                {mode == 2
                  ? `领取后${periodInterval}小时有效`
                  : `${startDate} - ${endDate}`}
              </div>
            </div>
          </div>
        </div>
        <div className="coupon_ticket_cover">
          {this.getCouponTicketAction(
            startSellTime,
            userName,
            collectFromFriendState,
            ticketAmount,
            limitOwnerNames,
            fromUser,
            canPresent
          )[actionState]()}
        </div>
        {this.props.isWuhanPromotion && <Wuhan content={""} />}
      </div>
    );
  }
}

CouponTicket.propTypes = {
  actionState: PropTypes.oneOf([
    "NotTimeToCollect",
    "NoAuthorityToCollect",
    "NoMoreTickets",
    "ExcessValidity",
    "CollectNow",
    "CollectFromFriend",
    "TicketUsed",
    "TicketSent",
    "OnlyWuhanTickets",
  ]).isRequired,
  vaildAll: PropTypes.bool.isRequired,
  sum: PropTypes.number.isRequired,
  endDate: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
};
