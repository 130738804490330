import React, { useEffect, useState } from "react";
import CountDownTime from "../utils/CountDown";
import styled from "styled-components";
import step_index from "study_package/step_index.png";
import step_next from "study_package/step_next.png";
import icon_cancle_upper from "../../../assets/images/icon_cancle_upper.png";
import safe_icon from "study_package/safe_icon.png";
import "./BottomFromOut.scss";
import icon_lock_img from "c4/icon_lock_img.png";
import { GA } from "../utils/Env";
import propTypes from "prop-types";
import { buy } from "./ShoppingBar";
// import { pay } from "../utils/pay";
const time12 = 43200000;
const step_title = ["截图", "微信扫一扫", "入咨询群"];
const StepIndex = styled.div`
  height: 12px;
  font-size: 17px;
  font-family: Resource;
  font-weight: bold;
  color: #ffffff;
  line-height: 19px;
  text-align: center;
  width: 19px;
  height: 19px;
  background: url(${step_index}) 0 0/100% 100% no-repeat;
  position:relative;
  &::before {
    content:"${({ index }) => step_title[index]}";
    position:absolute;
    bottom:-18px;
    left:50%;
    font-size: 12px;
    font-family: SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 15px;
    white-space:nowrap;
    transform:translateX(-50%);
  }
`;

export const UnlockAll = (props) => {
  const {
    originPrice,
    finalPrice,
    activity,
    study_package_activity_buy_url,
    study_package_activity_id,
  } = props;
  return (
    <div className="study-lock-back">
      <div className="study-lock-model">
        <img src={icon_lock_img}></img>
        <div className="study-lock-model-tips">
          {/* {this.state.LockModelText || "请先完成前面的内容哦"} */}
          {"免费体验已结束,点击解锁全部课程"}
        </div>
        <div
          className="render-bottom"
          onClick={() => {
            GA("Courses", "promotion_trial_buy", study_package_activity_id);
            props.cancelClick && props.cancelClick();
            const parms = {
              amount: finalPrice,
              group_state: "direct",
              target_id: activity.id,
              ecoupon_id: -1,
              virtual_account_money: 0,
              // trial: false,
            };
            buy(study_package_activity_buy_url, parms);
            // const payEle = document.getElementsByClassName(
            //   "group_sign_price"
            // )[0];
            // console.log(payEle, "----------------");
            // if (payEle) {
            //   const bottomELe = document.getElementsByClassName(
            //     "bottom-from-out"
            //   )[0];

            //   bottomELe.style.display = "none";
            //   $(payEle).trigger("click");
            // }
          }}
        >
          <div className="now-price-style">
            <span>新人特惠价 </span>
            <span>{`¥${finalPrice}`}</span>
          </div>
          <div className="origin-price-style">
            <span>原价 </span>
            <span>{`¥${originPrice}`}</span>
          </div>
        </div>
        <div
          className="cancle"
          onClick={() => {
            props.cancelClick && props.cancelClick();
          }}
        >
          <img src={icon_cancle_upper}></img>
        </div>
      </div>
    </div>
  );
};

UnlockAll.propTypes = {
  originPrice: propTypes.number,
  finalPrice: propTypes.number,
  cancelClick: propTypes.func,
  activity: propTypes.object,
  study_package_activity_buy_url: propTypes.string,
  study_package_activity_id: propTypes.number,
};
UnlockAll.defaultProps = {
  originPrice: 0,
  finalPrice: 0,
  cancelClick: null,
  activity: null,
  study_package_activity_buy_url: "",
  study_package_activity_id: -1,
};

// import { DateCountDown } from "../utils/formatAudioTime";
const QrcodeDiag = (props) => {
  const {
    promotion_group_qrcode_url,
    study_package_activity_id,
    bottom_type,
    originPrice,
    finalPrice,
    activity,
    promotion_trial,
    study_package_activity_buy_url,
  } = props;
  const [showUnlock, setShowUnlock] = useState(false);
  const [endTime, setEndTime] = useState(
    window.localStorage.getItem("end_time") || new Date().getTime() + time12
  );
  const [showEntry, setShowEntry] = useState(false);
  useEffect(() => {
    console.log(props, promotion_trial, "buy------------");
    if (promotion_trial && promotion_trial.buy) {
      entryCourse();
      setShowUnlock(true);
    }
    if (
      !window?.localStorage?.getItem("end_time") ||
      new Date() - new Date(window.localStorage.getItem("end_time")) >=
        -60 * 60 * 1000
    ) {
      console.log(
        window.localStorage.getItem("end_time"),
        "结束时间----------------"
      );
      window.localStorage.setItem(
        "end_time",
        new Date(new Date().getTime() + time12)
      );
      setEndTime(new Date(new Date().getTime() + time12));
    }
    console.log(
      window.localStorage.getItem("end_time"),
      "结束时间----------------存在"
    );

    if (promotion_group_qrcode_url) {
      setTimeout(() => {
        GA("Courses", "promotion_qrcode_timeout", study_package_activity_id);
        setShowEntry(true);
      }, 30000);
    }
  }, []);
  const entryCourse = () => {
    const tabContainer = document.getElementsByClassName("switchTitle")[0];
    const liEle = tabContainer.getElementsByTagName("li");
    if (liEle && liEle.length > 0) {
      $(liEle[liEle.length - 1]).trigger("click");
    }
  };
  return (
    <div className="bottom-from-out">
      {showEntry && (
        <div className="erweima-diag">
          <div className="erweima-centent">
            <div className="steps">
              <StepIndex index={0}>1</StepIndex>

              <img src={step_next} className="step-next"></img>
              <StepIndex index={1}>2</StepIndex>

              <img src={step_next} className="step-next"></img>
              <StepIndex index={2}>3</StepIndex>
            </div>
            <div className="erweima-center">
              <img
                src={promotion_group_qrcode_url}
                className="qrcode-img"
              ></img>
              <div className="group-name">超低价咨询群</div>
              <div className="safe-tips">
                <img src={safe_icon}></img>此二维码已通过审核，请放心扫码
              </div>
            </div>
            <div
              className="cancel-button"
              onClick={() => {
                setShowEntry(false);
              }}
            >
              <img src={icon_cancle_upper} className="cancel-icon"></img>
            </div>
          </div>
        </div>
      )}
      {showUnlock && (
        <UnlockAll
          originPrice={originPrice}
          finalPrice={finalPrice}
          activity={activity}
          study_package_activity_buy_url={study_package_activity_buy_url}
          cancelClick={() => {
            setShowUnlock(false);
          }}
          study_package_activity_id={study_package_activity_id}

          // virtual_account={props.virtual_account}
        ></UnlockAll>
      )}
      {/* <UnlockAll originPrice={"33"} finalPrice={"22"} /> */}
      <div className="bottom-out">
        <CountDownTime
          endTime={new Date(endTime)}
          overText="新人限时超低价"
          onEnd={() => {
            window.localStorage.setItem(
              "end_time",
              new Date(new Date().getTime() + time12)
            );
            setEndTime(new Date(new Date().getTime() + time12));
          }}
        >
          {({ d, h, m, s }) => {
            return (
              <div className="count-time">
                {bottom_type === "study-all" ? (
                  <div className="count-time-tit">
                    限时特惠¥<span className="final-price">{finalPrice}</span>
                    <span className="origin-price">{`原价¥${originPrice}`}</span>
                  </div>
                ) : bottom_type === "first_fire" ? (
                  <div className="count-time-tit">首节课免费听</div>
                ) : (
                  <div className="count-time-tit">新人限时超低价</div>
                )}

                <span className="timer">
                  <span className="timer-lit">剩余</span>
                  <span>{d}</span>:<span>{h}</span>:<span>{m}</span>:
                  <span>{s}</span>
                </span>
              </div>
            );
          }}
        </CountDownTime>
        <div
          className="entry-group-button"
          onClick={() => {
            if (bottom_type === "study-all") {
              GA(
                "Courses",
                "promotion_trial_bottom_unlock_click",
                study_package_activity_id
              );
              setShowUnlock(true);
            } else if (bottom_type === "first_fire") {
              GA(
                "Courses",
                "promotion_trial_bottom_click",
                study_package_activity_id
              );
              entryCourse();
              // window.location.href = props.promotion_trial.trial_url;
            } else {
              GA(
                "Courses",
                "promotion_qrcode_click",
                study_package_activity_id
              );
              setShowEntry(true);
            }
          }}
        >
          {bottom_type === "study-all"
            ? "解锁全部课程"
            : bottom_type === "first_fire"
            ? "立即免费听"
            : "立即入群咨询"}
          {/* 立即入群咨询 */}
          {/* 立即免费听 */}
        </div>
      </div>
    </div>
  );
};
QrcodeDiag.propTypes = {
  promotion_group_qrcode_url: propTypes.string,
  study_package_activity_id: propTypes.number,
  bottom_type: propTypes.string,
  originPrice: propTypes.number,
  finalPrice: propTypes.number,
  activity: propTypes.object,
  study_package_activity_buy_url: propTypes.string,
  promotion_trial: propTypes.object,
};
QrcodeDiag.defaultProps = {
  promotion_group_qrcode_url: "",
  study_package_activity_id: 0,
  bottom_type: "",
  originPrice: 0,
  finalPrice: 0,
  activity: null,
  study_package_activity_buy_url: "",
  promotion_trial: null,
};
export default QrcodeDiag;
