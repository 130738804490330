/* eslint-disable react/prop-types */
import React, { useRef, useState, useMemo, useCallback } from "react";
import formatTime from "date-fns/format";
import ShoppingGroup from "./ShoppingGroup.jsx";
import CountDownTime from "../utils/CountDown";
import { calcDiscountPercentPrice } from "./StudyPackageActivityTemplate";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { showDialog } from "../utils/helpers";

import avatar from "place_avatar.png";
import {
  StyledInvitationTip,
  StyledInvitationBroad,
  StyledSingleInvitationPerson,
  StyledInvitationProgress,
  StyledTimePad,
  StyledRefoundButton,
  StyledToClass,
  StyledBuyNow,
  StyledInviteFriends,
  StyledTimeExtendModal,
  StyledFailedInvitation,
} from "./InvitationFission.styled";

const InvitationTip = ({ mainTip, subTip }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <StyledInvitationTip dangerouslySetInnerHTML={{ __html: mainTip }} />
      {subTip && <StyledInvitationTip isSub>{subTip}</StyledInvitationTip>}
    </div>
  );
};

const SingleInvitationPersons = ({ userAvatar, index, price, isLastPrice }) => {
  const isActive = userAvatar != null;
  return (
    <StyledSingleInvitationPerson
      isActive={isActive}
      isFinallyActive={isActive && isLastPrice}
    >
      <div className="avatar">
        <img src={userAvatar || avatar} alt="" />
      </div>
      <span className="index_person">第{index}人</span>
      <span className="price">￥{price}</span>
    </StyledSingleInvitationPerson>
  );
};

const InvitationProgress = ({ persons }) => {
  const lastPriceIndex = persons?.findIndex?.(
    (person) => person.userAvatar == null
  );
  return (
    <StyledInvitationProgress>
      {persons.map((p, index) => (
        <SingleInvitationPersons
          index={index}
          key={index}
          {...p}
          isLastPrice={index === lastPriceIndex - 1}
        />
      ))}
    </StyledInvitationProgress>
  );
};

const CountDownTimePads = ({ d, h, m, s }) => {
  return (
    <div
      style={{
        fontSize: 15,
        display: "flex",
        justifyContent: "center",
        marginTop: 19,
      }}
    >
      报名有效期
      <StyledTimePad style={{ marginLeft: 9 }}>
        {Number(d * 24) + Number(h)}
      </StyledTimePad>
      时<StyledTimePad>{m}</StyledTimePad>分<StyledTimePad>{s}</StyledTimePad>秒
    </div>
  );
};

const ValidProgressBts = ({
  canExtendTime,
  onInvite,
  gapPrice,
  onBuy,
  onExtendTime,
}) => {
  const [isShowModal, setShowModal] = useState(false);
  return (
    <>
      {isShowModal && (
        <InnerModal>
          <ModalMask>
            <StyledTimeExtendModal>
              <p>系统将为您延长12小时赠课有效期，</p>
              <p>12小时后未报名成功，将自动为您退款。</p>
              <div className="bnt">
                <div
                  className="cancel"
                  onClick={() => {
                    setShowModal(false);
                    onExtendTime({ extend: false });
                  }}
                >
                  取消
                </div>
                <div
                  className="confirm"
                  onClick={() => {
                    setShowModal(false);
                    onExtendTime({ extend: true });
                  }}
                >
                  确定
                </div>
              </div>
            </StyledTimeExtendModal>
          </ModalMask>
        </InnerModal>
      )}
      <div style={{ display: "flex" }}>
        {canExtendTime ? (
          <>
            <StyledBuyNow onClick={() => setShowModal(true)}>
              继续赠课
            </StyledBuyNow>
            <StyledInviteFriends onClick={onBuy}>
              <span>补差价</span>￥{gapPrice}
            </StyledInviteFriends>
          </>
        ) : (
          <>
            <StyledBuyNow onClick={onBuy}>
              <span>补差价</span>￥{gapPrice}
            </StyledBuyNow>{" "}
            <StyledInviteFriends onClick={onInvite}>
              赠课给好友
            </StyledInviteFriends>
          </>
        )}
        {/* {canExtendTime ? (
         
        ) : (
          <StyledBuyNow onClick={onBuy}>
            <span>补差价</span>￥{gapPrice}
          </StyledBuyNow>
        )}
        <StyledInviteFriends onClick={onInvite}>赠课给好友</StyledInviteFriends> */}
      </div>
    </>
  );
};

const DISCOUNT = [100, 95, 90, 85, 80];

// 邀请裂变是基于ShoppingGroup
const InvitationFission = (props) => {
  const [isNoShowChoosePhase, setShowChoosePhase] = useState(
    !(props.absentee_activity && props.bought)
  );
  const shoppingGroupModeRef = useRef();
  const userShoppingGroup = props.shopping_group?.user_shopping_groups ?? [];

  // 有效期内，报名成功前
  const isValidProgress =
    props.current_user_shopping_group?.state === "已支付押金" &&
    new Date(props.shopping_group?.end_time) > new Date(props.system_time);

  // 有效期外， 报名失败
  const isFailedInvitation =
    props.current_user_shopping_group?.state === "已支付押金" &&
    new Date(props.shopping_group?.end_time) <= new Date(props.system_time);

  // 报名成功
  const isSuccessfulBought =
    props.current_user_shopping_group?.state === "已完成";

  // 已退款
  const isRefound = props.current_user_shopping_group?.state === "已退款";

  const curActivityIndex = useMemo(
    () =>
      props.sub_study_package_activities.findIndex(
        (act) => act.id === props.current_user_shopping_group?.target_id
      ),
    [props.current_user_shopping_group, props.sub_study_package_activities]
  );

  const curActivity = props.sub_study_package_activities[curActivityIndex];

  const groupPersons = useMemo(() => {
    if (userShoppingGroup.length === 0 || !curActivity) return [];
    return userShoppingGroup
      .concat(
        new Array(5 - userShoppingGroup.length)
          .fill(0)
          .map(() => ({ user: { user: { photo_url: avatar } } }))
      )
      .map((user, index) => ({
        userAvatar: user.user.photo_url,
        price: parseInt(
          (calcDiscountPercentPrice(
            props.sub_study_package_activities,
            curActivity?.max_price,
            curActivity?.base_price,
            curActivityIndex
          ) *
            DISCOUNT[index]) /
            100
        ),
      }));
  }, [
    curActivity,
    curActivityIndex,
    props.sub_study_package_activities,
    userShoppingGroup,
  ]);

  const gapPrice = parseInt(
    groupPersons[userShoppingGroup.length - 1]?.price -
      groupPersons[DISCOUNT.length - 1]?.price
  );

  const invitationTip = useMemo(
    () =>
      isValidProgress
        ? `还需赠送<span class="strong">${
            5 - userShoppingGroup.length
          }</span>人，即可报名成功《${curActivity?.name}》`
        : isSuccessfulBought
        ? `您已报名成功《${curActivity?.name}》`
        : isRefound || isFailedInvitation
        ? `《${curActivity?.name}》报名失败`
        : "",
    [
      curActivity,
      isRefound,
      isSuccessfulBought,
      isValidProgress,
      userShoppingGroup.length,
      isFailedInvitation,
    ]
  );

  const onBuy = useCallback(() => {
    shoppingGroupModeRef.current.payRemaining(
      gapPrice,
      curActivity?.id,
      curActivity?.name
    );
  }, [curActivity, gapPrice]);

  const onInvite = useCallback(
    () => shoppingGroupModeRef.current.onAlertPoster(props),
    [props]
  );

  const timeExtendUrl = props.time_extend_url;

  const onExtendTime = useCallback(
    async ({ extend }) => {
      if (!extend) return;
      const toast = ToastManager.showLoading(
        // extend ? "有效期延长中..." : "取消中..."
        "有效期延长中..."
      );
      try {
        await request({
          method: "POST",
          url: timeExtendUrl,
          headers: csrfHeaders,
          data: {
            extend,
          },
        });
        window.location.reload();
      } catch (e) {
        toast.cancel();
        showDialog({
          text: "请求出错，请重试",
          mainLabel: "确定",
        });
      }
    },
    [timeExtendUrl]
  );

  return (
    <div className="comp_invitation_fission">
      {curActivity && (
        <StyledInvitationBroad>
          <InvitationTip
            mainTip={invitationTip}
            subTip={
              isRefound || isFailedInvitation
                ? "您未在有效期内赠送指定人数"
                : ""
            }
          />

          {(isValidProgress || isFailedInvitation) && (
            <CountDownTime
              endTime={new Date(props.shopping_group.end_time)}
              overText={<CountDownTimePads d={0} m={0} s={0} h={0} />}
              onEnd={() => {}}
            >
              {(props) => {
                return <CountDownTimePads {...props} />;
              }}
            </CountDownTime>
          )}

          <InvitationProgress persons={groupPersons} />

          {isRefound && <StyledRefoundButton>已退款</StyledRefoundButton>}

          {isSuccessfulBought && (
            <StyledToClass onClick={() => setShowChoosePhase((pre) => !pre)}>
              去上课
            </StyledToClass>
          )}

          {(isValidProgress || isFailedInvitation) && (
            <ValidProgressBts
              canExtendTime={!!timeExtendUrl}
              onExtendTime={onExtendTime}
              gapPrice={gapPrice}
              onInvite={onInvite}
              onBuy={onBuy}
            />
          )}
          {isFailedInvitation && (
            <StyledFailedInvitation>
              <p>1、您可以选择补相应差价，完成报名；</p>
              <p>2、或者点击“继续赠课”，延长12小时赠课有效期；</p>
              <p>
                3、不做任何操作，系统将在
                {formatTime(
                  +new Date(props.shopping_group?.end_time) +
                    12 * 60 * 60 * 1000,
                  "YYYY.MM.DD HH:mm"
                )}
                自动为您退款。
              </p>
            </StyledFailedInvitation>
          )}
        </StyledInvitationBroad>
      )}
      <ShoppingGroup
        {...props}
        isNoShowChoosePhase={isNoShowChoosePhase}
        isNoneRenderBottomBar={
          isValidProgress ||
          isSuccessfulBought ||
          isRefound ||
          isFailedInvitation
        }
        ref={shoppingGroupModeRef}
      />
    </div>
  );
};

export default InvitationFission;
