/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import styled from "styled-components";
import {
  BaseStudyPackageActivity,
  beforeSale,
  beforeSaleHeader,
  handleBeforeSale,
  showCouponDialog,
  trialShow,
} from "./BaseStudyPackageActivityNew";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import CourseLists from "./CourseListsNew";
import { DateMinus } from "../utils/formatAudioTime";
import {
  inviteFriend,
  showJoinGrouponUserNew,
  showPreviousUserBuyState,
} from "./ShoppingBar";
import DiscountCountDown from "./new_shopping/DiscountCountDown";
import "./StudyPackageActivity.scss";
import "./ShoppingGroupNew.scss";
import Uri from "jsuri";
import { Dialog, DialogManager } from "../utils/Dialog";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { DoubleElevenModal } from "../utils/DialogTemplate";
import { GA, GA_new } from "../utils/Env";
import UserAgent from "../utils/UserAgent";
import XinYaNativeBridge from "../../packs/native_bridges/native_bridge";
import ShoppingGroupDialog from "./ShoppingGroupDialog";
import icon_group_bg from "../../../assets/images/icon_group.png";
import CountDownTime from "../utils/CountDown";
import { onNavigate } from "../utils/helpers";
import {
  DialogCourseBuy,
  DialogCourseBuyManager,
} from "../utils/DialogCourseBuy";

import ActivityCouponTicket from "./ActivityCouponTicket.jsx";
import CouponTicketCouponDown from "./coupon_ticket_count_down/CouponTicketCouponDown.jsx";
import StudyPackageActivity, {
  ActivitySignIn,
  PriceTextShow,
  ActivityExperienceButton,
} from "./StudyPackageActivityNew.jsx";

import btn_close from "study_package/btn_close.png";

import {
  calcActivityLowestPrice,
  calcDiscountPercentPrice,
  filterSubActivityOweMediaCount,
  courseDiscountDialog,
  showFissionWelfareModal,
  showJoinGroupQrcodeModal,
  showOpenSpeedTrainingCampModal,
  findExperienceCourse,
} from "./StudyPackageActivityTemplate.jsx";

import useRemoveShoppingGroupDialog from "./RemoveShoppingGroupDialog";
import QrcodeDiag from "./BottomFromOut";
const ShoppingBottomBar = (props) => {
  console.log("ShoppingBottomBar_props: ", props);
  const {
    bought,
    study_package_activity,
    pre_has_bought,
    study_package_group_state,
    current_user_shopping_group,
    shopping_group,
    overall_bought,
    current_user,
    remaining_shopping_group_count,
    sub_study_package_activities,
    study_package_category_id,
  } = props;
  const { state } = props;

  console.log("ShoppingBottomBar_state: ", state);
  const {
    nextBuyInviteFriend,
    preBuyShopGroupState,
    onJoinShopGroup,
    payRemainingPrice,
    showUserBuyState,
    completeGrouponState,
    expiredShopGroupState,
  } = props;

  var dev_env = window.location.hash;
  if (props.sub_activities_limit_users_count_all_exceed) {
    return preBuyShopGroupState(props);
  }
  if (!state.isNotExpirdOfGroupon) {
    if (dev_env === "#dev_env") {
      alert("isNotExpirdOfGroupon");
    }
    // 已过期
    return expiredShopGroupState(props);
  }

  if (
    sub_study_package_activities &&
    sub_study_package_activities.length >= 1
  ) {
    if (overall_bought) {
      if (dev_env === "#dev_env") {
        alert("overall_bought");
      }
      // 全部已购买
      return showUserBuyState(props);
    }
  } else {
    if (bought) {
      if (dev_env === "#dev_env") {
        alert("bought");
      }
      // 已购买
      // return hasBought(props, state, setState)
      return showUserBuyState(props);
    }
  }

  if (!overall_bought || !bought) {
    // 可以再次购买
    if (!shopping_group) {
      // 可囤课系列课已购买还可以再次购买,团购
      if (dev_env === "#dev_env") {
        alert("!overall_bought || !bought -> !shopping_group");
      }
      return preBuyShopGroupState();
    } else if (shopping_group && "user_shopping_groups" in shopping_group) {
      // 有团，显示团信息
      const isNotComplete = shopping_group.user_shopping_groups.find((usg) => {
        return usg.state !== 1;
      });
      if (!isNotComplete && remaining_shopping_group_count === 0) {
        if (dev_env === "#dev_env") {
          alert(
            "!overall_bought || !bought -> shopping_group:" + !isNotComplete
          );
        }
        return completeGrouponState();
      }
      // 在别人的团里，自己已完成订单
      const isComplete = shopping_group.user_shopping_groups.find((usg) => {
        if (usg.user && current_user) {
          return usg.state === 1 && usg.user.id === current_user.id;
        }
      });
      if (isComplete && !current_user_shopping_group) {
        if (dev_env === "#dev_env") {
          alert(
            "!overall_bought || !bought -> shopping_group isComplete !current_user_shopping_group"
          );
        }
        return preBuyShopGroupState();
      }
    }
  }

  if (
    pre_has_bought &&
    !StudyPackageActivity.specialCategory.techAidPackages.includes(
      study_package_category_id
    )
  ) {
    if (dev_env === "#dev_env") {
      alert("pre_has_bought");
    }
    // 当属于进位计算训练分类时底部显示立即报名按钮，点击立即报名，弹框提示直接购买教具包（preBoughtCourseDialog 方法）
    // 往期已购买用户进入报名页后的页面状态
    // return showPreviousUserBuyState(props)
    return showUserBuyState(props);
  } else if (
    study_package_group_state === "partial" ||
    study_package_group_state === "superset"
  ) {
    if (dev_env === "#dev_env") {
      alert("pre_has_bought/partial or superset");
    }
    // the user already bought partial of this category
    // return showPreviousUserBuyState(props)
    return showUserBuyState(props);
  } else if (state.canPayRemaining && current_user_shopping_group) {
    if (dev_env === "#dev_env") {
      alert("payRemainingPrice");
    }
    // 未拼团成功 补差价
    return payRemainingPrice();
  } else if (current_user_shopping_group) {
    if (dev_env === "#dev_env") {
      alert("nextBuyInviteFriend");
    }
    // 邀请好友拼团 + 补差价，自己在团中

    return nextBuyInviteFriend(props);
  }

  // json.can_join_this_groupon  (@shopping_group.user_shopping_groups_count < max_count) && !(@shopping_group.user_shopping_groups.pluck(:user_id).include?(current_user.id))
  if (current_user && shopping_group && shopping_group.user_shopping_groups) {
    // hadJoinGroupon判断有没有加入拼团
    const hadJoinGroupon = shopping_group.user_shopping_groups
      .map((usg) => {
        if (usg.user) {
          return usg.user.id;
        }
      })
      .filter((id) => {
        return id;
      })
      .includes(current_user.id);
    if (!hadJoinGroupon && remaining_shopping_group_count > 0) {
      // 加入拼团，自己不在团中
      if (dev_env === "#dev_env") {
        alert("joinShoppingGroup");
      }
      return preBuyShopGroupState();
      // return onJoinShopGroup();
    }
    // 别人访问，进来的人不在团中
    if (!hadJoinGroupon && remaining_shopping_group_count === 0) {
      // 拼团已满
      if (dev_env === "#dev_env") {
        alert("completeGrouponState");
      }
      return completeGrouponState();
    }
  } else {
    // 没有拼团信息
    return preBuyShopGroupState();
  }
};

const ShoppingGroup = forwardRef((props, ref) => {
  console.log("ShoppingGroup: ", props);
  console.log(
    "ShoppingGroup: ",
    props.sub_activities_limit_users_count_all_exceed
  );
  const [showCourseCouponTicket, setShowCourseCouponTicket] = useState(false);
  const [isExistOweMediaCountObject, setIsExistOweMediaCountObject] = useState(
    undefined
  );

  const {
    time_line_share_url,
    app_message_share_url,
    share_title,
    share_description,
    share_image_url,
  } = props;
  wx.ready(function () {
    function on_share_success() {}
    var sharedJsonTimeLine = {
      link: time_line_share_url,
      title: share_title,
      desc: share_description,
      imgUrl: share_image_url,
      success: on_share_success,
    };
    var sharedJsonAppMessage = {
      link: app_message_share_url,
      title: share_title,
      desc: share_description,
      imgUrl: share_image_url,
      success: on_share_success,
    };
    wx.onMenuShareTimeline(sharedJsonTimeLine);
    wx.onMenuShareAppMessage(sharedJsonAppMessage);
  });

  const posterEndTime = props.bargain?.end_time;
  const [shoppingBargainEndTime, setShoppingBargainEndTime] = useState(
    posterEndTime
  );

  const { study_package_activity } = props;

  if (props.sub_study_package_activities) {
    var x = [...props.sub_study_package_activities];
    var y = [...props.sub_study_package_activities];
    var z = [...props.sub_study_package_activities];
    x = x
      .sort(function (a, b) {
        return a.min_price - b.min_price;
      })
      .filter((p) => p.min_price > 0);

    y.sort(function (a, b) {
      return b.max_price - a.max_price;
    });

    z.sort(function (a, b) {
      return b.list_price - a.list_price;
    });
    var trial_activities = props.sub_study_package_activities
      .filter((e) => e.trial_activity)
      .map((e) => e.trial_activity);
    if (
      !trial_activities ||
      (trial_activities && trial_activities.length <= 0)
    ) {
      if (props.trial_activity) {
        trial_activities = [props.trial_activity];
      }
    }
  }
  console.log("sort_min_price", x[0]?.min_price);

  const [state, setState] = useState({
    sort_min_price: props.sub_study_package_activities ? x[0]?.min_price : "",
    sort_min_price_last: props.sub_study_package_activities
      ? x[x.length - 1]?.min_price
      : "",
    sort_max_price: props.sub_study_package_activities ? y[0]?.max_price : "",
    sort_max_price_last: y ? y[y.length - 1]?.max_price : "",

    sort_list_price: props.sub_study_package_activities ? z[0]?.list_price : "",
    sort_list_price_last: props.sub_study_package_activities
      ? z[z.length - 1]?.list_price
      : "",
    isAlertSoleCoupon: false,
    absenteeActivity: props.absenteeActivity,
    isInMiniProgram: false,
    trialActivities: trial_activities,
    canPayRemaining: props.can_pay_remaining,
    isNotExpirdOfGroupon: props.isNotExpirdOfGroupon,
    // isDisplayCourseList: 'none'
  });

  const afterLoadAction = useCallback(() => {}, []);
  const alertPoster = (props) => {
    if (props.poster_url) {
      const sharePosterProxy = new SharePosterProxy({
        posterTemplate: props.poster_photo_template_url,
        posterCreation: props.poster_creation,
        mode: "front_end",
        posterUrl: props.poster_url,
        posterImgSrc: props.poster_img_src,
        free: props.free,
        bonus: props.bonus,
        bonus_type: props.bonus_type,
        sale_mode: props.sale_mode,
        end_time: props.buy_endtime,
        remaining_shopping_group_count: props.remaining_shopping_group_count,
        singleCourse: props.sub_study_package_activities.length <= 1,
        avatar_location: props.avatar_location,
        remaingInviteNumber:
          5 - props.shopping_group?.user_shopping_groups?.length ?? 0,
      });
      sharePosterProxy.open();
    }
  };

  const onAlertPoster = useCallback(() => {
    // 直接拼团，购买成功，尚未补差价的时候，以下条件符合（自动弹海报）
    if (
      props.current_user_shopping_group &&
      props.current_user_shopping_group.state.includes("已支付押金") &&
      !state.absenteeActivity &&
      props.remaining_shopping_group_count > 0
    ) {
      const buyEndTime = props.buy_endtime;
      const buyEndTimeDate = new Date(buyEndTime);
      const timeNow = new Date();
      if (
        buyEndTimeDate > timeNow &&
        props.sub_activities_limit_users_count_all_exceed !== true
      ) {
        const params = Object.assign({}, props);
        params["free"] = "in_or_open_shopping_group";
        alertPoster(params);
      }
    }
  }, []);

  // 页面加载后，先检查是否可以移团，然后跳海报
  if (!props.group_qrcode_url) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useRemoveShoppingGroupDialog({
      joinOtherGroupUrl: props.join_other_shopping_group_url,
      onAlertPoster,
      bonus: props.shopping_group_bonus,
    });
  }
  const [floorPriceDirect, setFloorPriceDirect] = useState(999999);
  const [ceilingPriceDirect, setCeilingPriceDirect] = useState(999999);
  const [floorPriceGroup, setFloorPriceGroup] = useState(999999);
  const [ceilingPriceGroup, setCeilingPriceGroup] = useState(999999);
  const [shoppingGroupInfo, setShoppingGroupInfo] = useState([]);
  const [firstGroup, setFirstGroup] = useState(null);
  const [showGroupWindow, setShowGroupWindow] = useState(false);
  // 进入后将优惠课时加入课程列表中
  // 进入后将优惠课时加入课程列表中;
  useEffect(() => {
    let joinGroup = {};
    let newGroupList = [];
    let maxCount = props.group_max_count;
    let isBeforeSale = beforeSale(props);
    if (
      props.shopping_group_info &&
      props.shopping_group_info.shopping_groups &&
      !props.sub_activities_limit_users_count_all_exceed &&
      !isBeforeSale &&
      props.isNotExpirdOfGroupon
    ) {
      // props.sub_study_package_activities
      props.shopping_group_info.shopping_groups.map((item, index) => {
        let userInfos = [...item.user_infos];
        if (item.user_infos.length < maxCount) {
          item.is_full = false;
          item.add_people = maxCount - item.user_infos.length;
          for (let i = maxCount - item.user_infos.length; i > 0; i--) {
            userInfos.push(null);
          }
        } else {
          item.is_full = true;
          item.add_people = 0;
        }
        item.userInfos = userInfos;
        if (item.is_top) {
          if (
            props.current_user &&
            props.current_user.id &&
            item.launcher.user_id == props.current_user.id
          ) {
            return;
          }

          setShowGroupWindow(true);
          joinGroup = { ...item, minCount: maxCount - item.user_infos.length };

          return;
        }
        newGroupList.push({
          ...item,
          minCount: maxCount - item.user_infos.length,
        });
      });
    }

    if (joinGroup.user_infos) {
      setFirstGroup({ ...joinGroup });
      newGroupList.unshift(joinGroup);
      baseRef.current.setState({ firstGroup: { ...joinGroup } });
    } else if (newGroupList.length) {
      setFirstGroup({ ...newGroupList[0] });
      baseRef.current.setState({ firstGroup: { ...newGroupList[0] } });
    }

    setShoppingGroupInfo(newGroupList);
    baseRef.current.setState({ shoppingGroupInfo: [...newGroupList] });
  }, []);

  useEffect(() => {
    let floorPriceDirect = 999999;
    let ceilingPriceDirect = 999999;
    let floorPriceGroup = 999999;
    let ceilingPriceGroup = 999999;
    let virtual_account = 0;
    if (props.virtual_account && props.virtual_account.money) {
      virtual_account = props.virtual_account.money;
    }
    if (props.sub_study_package_activities) {
      // props.sub_study_package_activities
      props.sub_study_package_activities.map((item, index) => {
        if (item.stage == 4) {
          return;
        }
        let maxCount = 0;
        let minCount = 0;
        if (ceilingPriceDirect > item.max_price) {
          ceilingPriceDirect = item.max_price;
        }
        if (ceilingPriceGroup > item.min_price) {
          ceilingPriceGroup = item.min_price;
        }

        let subtractOwnedMediaDirect =
          ((item.max_price - item.base_price) / item.media_lessons_count) *
          item.owned_media_lesson_count + item.low_price_media_lesson_paid_amount;
        let subtractOwnedMediaGroup =
          ((item.min_price - item.base_price) / item.media_lessons_count) *
          item.owned_media_lesson_count + item.low_price_media_lesson_paid_amount;
        console.log(
          item.max_price,
          "------11111111111111------",
          maxCount,
          subtractOwnedMediaDirect
        );
        if (
          item &&
          item.activity_ecoupons &&
          item.activity_ecoupons.length !== 0
        ) {
          item.activity_ecoupons.map((it, idx) => {
            if (
              item.max_price - subtractOwnedMediaDirect >=
              it.min_product_price
            ) {
              if (maxCount < it.price) {
                maxCount = it.price;
              }
            }
            if (
              item.min_price - subtractOwnedMediaGroup >=
              it.min_product_price
            ) {
              if (minCount < it.price) {
                minCount = it.price;
              }
            }
          });
        }
        let directPriceFloor =
          item.max_price -
          maxCount -
          subtractOwnedMediaDirect -
          virtual_account;
        let groupPriceFloor =
          item.min_price - minCount - subtractOwnedMediaGroup - virtual_account;
        item.subtractOwnedMediaDirect = Number(
          subtractOwnedMediaDirect
        ).toFixed();
        item.subtractOwnedMediaGroup = Number(
          subtractOwnedMediaGroup
        ).toFixed();
        item.directPriceFloor =
          directPriceFloor < 0 ? 0 : Number(directPriceFloor).toFixed();
        item.groupPriceFloor =
          groupPriceFloor < 0 ? 0 : Number(groupPriceFloor).toFixed();
        if (groupPriceFloor < 0) {
          floorPriceGroup = 0;
        } else {
          if (floorPriceGroup > groupPriceFloor) {
            floorPriceGroup = groupPriceFloor;
          }
        }
        if (directPriceFloor < 0) {
          floorPriceDirect = 0;
        } else {
          if (floorPriceDirect > directPriceFloor) {
            floorPriceDirect = directPriceFloor;
          }
        }
      });
    }
    setFloorPriceDirect(Number(floorPriceDirect).toFixed());
    setFloorPriceGroup(Number(floorPriceGroup).toFixed());
    setCeilingPriceDirect(Number(ceilingPriceDirect).toFixed());
    setCeilingPriceGroup(Number(ceilingPriceGroup).toFixed());
  }, []);

  // 可以移团时 跳海报
  useEffect(() => {
    if (props.group_qrcode_url) {
      showJoinGroupQrcodeModal(
        props.group_qrcode_url,
        props?.qrcode_reviewed_url,
        onAlertPoster
      );
    }
  }, []);
  // 小程序判断可以舍弃
  useEffect(() => {
    wx.miniProgram.getEnv((res) => {
      if (res.miniprogram) {
        setState((preState) => {
          return { ...preState, isInMiniProgram: true };
        });
      }
    });
  }, []);

  // 初始化拼团模式下，页面渲染完之后，弹优惠券领取弹框
  useEffect(() => {
    if (props.batch_ecoupon) {
      setShowCourseCouponTicket(true);
    }
  }, []);
  // 思维课和阅读课 任务对换弹窗
  useEffect(() => {
    if (props.promotion_20201111) {
      if (props.sub_course_type == "reading") {
        DoubleElevenModal("reading");
      }
      if (
        props.sub_course_type != "reading" &&
        props.sub_course_type != "thinking"
      ) {
        DoubleElevenModal("all");
      }
    }
  }, [props.promotion_20201111, props.sub_course_type]);

  // 回调获取关闭优惠券弹框的状态
  const getActivityCouponTicketCloseStatus = (boolState) => {
    setShowCourseCouponTicket(boolState);
  };

  // 过滤拥有已购买课时的课程
  useEffect(() => {
    const { sub_study_package_activities, sale_mode } = props;
    const cacheOwnedMediaCount = filterSubActivityOweMediaCount(
      sub_study_package_activities
    );
    setIsExistOweMediaCountObject();

    if (!sub_study_package_activities) return;

    const get_owned_media_lessons_url = props.get_owned_media_lessons_url;

    const showCourseDiscountDialog =
      typeof cacheOwnedMediaCount == "object" &&
      !props.had_joined_groupon &&
      cacheOwnedMediaCount.filterOwnedCountArray.length > 0;
    console.log(
      "showCourseDiscountDialog 是否显示折价弹框: ",
      showCourseDiscountDialog
    );
    if (showCourseDiscountDialog && !props.bought_legacy_c4) {
      courseDiscountDialog(
        cacheOwnedMediaCount.getLoopList,
        cacheOwnedMediaCount.calcSortLastDerateValue,
        get_owned_media_lessons_url,
        sub_study_package_activities,
        sale_mode
      );
    }

    // 拼团裂变：没有折价弹框的时候 && 用户未开团（自己成为团长）
    if (
      props.sale_mode == "shopping_group_new_member_only" &&
      !showCourseDiscountDialog &&
      !props.current_user_shopping_group
    ) {
      showFissionWelfareModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // 针对 加减计算提速营课程开通流程
  useEffect(() => {
    const {
      training_camp_promotion,
      study_package_activity,
      training_promotion_url,
    } = props;
    // 针对 加减计算提速营课程开通流程
    if (training_camp_promotion) {
      showOpenSpeedTrainingCampModal(
        training_camp_promotion,
        study_package_activity.name,
        training_promotion_url
      );
    }
  }, []);

  const baseRef = useRef();
  const courseCouponRef = useRef();
  const subCourseDilogRef = useRef();
  const groupRelativeMode =
    props.sale_mode === "direct_shopping_group" ||
    props.sale_mode === "shopping_group" ||
    props.sale_mode === "shopping_group_bargain" ||
    props.sale_mode === "shopping_group_new_member_only" ||
    props.sale_mode === "invitation_fission";
  const isBeforeSale = beforeSale(props);
  console.log(
    props.trial_activity,
    "11111111111111",
    trial_activities,
    isBeforeSale
  );
  const noShowExperienceButton =
    props.trial_activity || trial_activities.length > 0 || isBeforeSale;

  // alert choose direct buy or groupon
  const alertBuy = (groupon_amount, state) => {
    var directPrice = props.price;
    var amount = groupon_amount;
    if (
      new Uri(window.location.href).hasQueryParam("from_user") &&
      !props.isCurrentUser
    ) {
      directPrice = directPrice - discount;
      amount = amount - discount;
    }
    const buttons = [
      {
        label: <div className="close_dialog_group_amount">{"X"}</div>,
        onClick: () => confirmDialog.close(),
      },
      {
        label: props.groupon_direct_buy === true ? "免拼团购买" : `开始拼团`,
        onClick: () => {
          confirmDialog.close();
          baseRef.current.showCourseCouponPay(
            groupon_amount,
            state,
            props.id,
            props.name,
            "group"
          );
        },
      },

      !props.groupon_direct_buy && {
        label: "不拼团，直接报名 ¥" + directPrice,
        onClick: () => {
          confirmDialog.close();
          baseRef.current.showCourseCouponPay(
            directPrice,
            "",
            props.id,
            props.name,
            "direct"
          );
          console.log("价格： ", directPrice);
        },
      },
    ];
    // 拼团提示
    const confirmDialog = DialogCourseBuyManager.open(
      <DialogCourseBuy buttons={buttons}>
        <div className="shop_group_price">{`${props.group_max_count}人拼团价：¥${amount}`}</div>
        <span>
          原价：<del>¥{props.list_price}</del>
        </span>
      </DialogCourseBuy>
    );
    // 双十二逻辑, groupon_direct_buy 注释掉了
    if (props.groupon_direct_buy === true) {
      const buttonTwo = document.getElementsByClassName(
        "xinyaui-dialog__btn"
      )[1];
      buttonTwo.style.cssText = "margin-bottom: 25px;";

      const buttonLast = document.getElementsByClassName(
        "xinyaui-dialog__btn"
      )[2];
      buttonLast.style.cssText = "display: none;";
    }
  };

  // 课程列表展示
  const courseListDisplay = (isDisplayCourseList) => {
    // eslint-disable-next-line no-unused-expressions
    subCourseDilogRef.current?.setState({
      isChooseDisplay: isDisplayCourseList,
    });
  };
  // 是团购
  const isShopGroup = (shopGroup) => {
    subCourseDilogRef.current?.setState({
      coursePayMode: shopGroup ? "group" : "direct",
    });
  };
  //   // eslint-disable-next-line no-unused-expressions

  // };

  // click function新开团
  const newGroupBuy = (e) => {
    console.log("newGroupBuy");
    const sub_study_package_activities = props.sub_study_package_activities;
    if (!sub_study_package_activities) return;

    if (isBeforeSale) {
      handleBeforeSale(props);
      return;
    }
    console.log("===new group start===", props, "===new group end===");

    // （团购模式下模式下）Doing
    // 异常情况：当期已购买其他课程，准备购买第二份课程（bought_other）
    if (
      props.sale_mode != "shopping_group_new_member_only" &&
      props.study_package_group_state === "bought_other"
    ) {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => confirmDialog.close(),
        },
      ];
      const confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          为了孩子的学习效果，建议您不要同时报名多个课程
        </Dialog>
      );
      return;
    }

    if (!props.sub_study_package_activities) {
      GA("Courses", "newGroupBuy", props.study_package_activity_id);
    } else {
      GA("Courses", "chooseCourses", study_package_activity.id);
    }
    // 未登陆的重定向
    if (props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        const login_url = decodeURIComponent(props.login_url);
        XinYaNativeBridge.navigate({ href: login_url });
      } else {
        window.location.href = props.login_url;
      }
    } else if (props.qrcode_url != null) {
      baseRef.current.onWechatFollow();
    } else if (!props.grade_exam_finished) {
      console.log("enter else if");
      // 重定向
      if (props.need_redirect_by_ecoupon === true) {
        showCouponDialog(props.ecoupon_redirect_url);
      } else {
        if (props.sub_study_package_activities) {
          courseListDisplay("block");
        } else {
          alertBuy(props.group_price, "join_groupon");
        }
      }
    } else {
      console.log("enter else");
      if (props.need_redirect_by_ecoupon === true) {
        showCouponDialog(props.ecoupon_redirect_url);
      } else {
        if (props.sub_study_package_activities) {
          courseListDisplay("block");
        } else {
          alertBuy(props.group_price, "join_groupon");
        }
      }
    }
  };

  // 开课之前 最开始状态, 报名初始状态, 团购模式
  const preBuyShopGroupState = () => {
    let showPrice;
    const price = `¥${state.sort_min_price}起`;
    console.log("preBuyShopGroupState_price: ", price, props);

    // eslint-disable-next-line prefer-const
    showPrice = (
      <div className="activitiy_price">
        <PriceTextShow
          // price={state.sort_min_price}
          price={`${
            calcActivityLowestPrice(props.sub_study_package_activities)[0]
              .discountPrice
          }`}
          isExist={isExistOweMediaCountObject}
          subActivities={props.sub_study_package_activities}
          saleMode={props.sale_mode}
        />
        <div className="price_subscribe">{props.refund_policy}</div>
      </div>
    );
    // 过期 售完状态（头部）
    const header = () => {
      if (isBeforeSale) {
        return beforeSaleHeader(props);
      } else {
        if (
          props.group_price > 0 &&
          (props.sale_mode === "shopping_group" ||
            props.sale_mode === "shopping_group_bargain" ||
            props.sale_mode === "shopping_group_new_member_only")
        ) {
          return showJoinGrouponUserNew(props, state, setState);
        }
        return "";
      }
    };

    // 邀请砍价进行中 - 入口提示界面
    const isShoppingGroupBargain =
      props.sale_mode == "shopping_group_bargain" && props.bargain;
    let discountCountDownText;
    let discountCountDownTime;
    if (isShoppingGroupBargain) {
      const { invited_users_count, money_per_user, activity } = props.bargain;

      // 砍价时间已结束
      const isEndTimeOutOver =
        new Date(shoppingBargainEndTime) < new Date(props.system_time);

      const shoppingBargainDiscountPrice =
        parseInt(invited_users_count) * parseInt(money_per_user);

      const calcRemaingBargainPrice =
        activity.price - activity.lowest_price - shoppingBargainDiscountPrice;
      const lastRemaingBargainPrice =
        calcRemaingBargainPrice > 0 ? calcRemaingBargainPrice : 0;

      console.log("lastRemaingBargainPrice: ", lastRemaingBargainPrice);

      if (lastRemaingBargainPrice != 0 && !isEndTimeOutOver) {
        // 砍价中
        discountCountDownText = `已砍${shoppingBargainDiscountPrice}元，还可继续砍${lastRemaingBargainPrice}元`;
        discountCountDownTime = shoppingBargainEndTime;
      } else if (lastRemaingBargainPrice == 0) {
        // 砍价已达最大值( 已砍至0元 )
        discountCountDownText = `成功邀请${invited_users_count}人，已砍到最高${
          activity.price - activity.lowest_price
        }元`;
        discountCountDownTime = "";
      } else if (isEndTimeOutOver) {
        // 砍价时间结束
        discountCountDownText = `成功邀请${invited_users_count}人，已砍${shoppingBargainDiscountPrice}元`;
        discountCountDownTime = shoppingBargainEndTime;
      }
    }

    const shoppingGroupExit =
      (props.shopping_group &&
        "user_shopping_groups" in props.shopping_group) ||
      (firstGroup && "user_infos" in firstGroup);

    // const isTempThinkingSeriesTrial = props.sub_course_type === "thinking";
    const isTempThinkingSeriesTrial = false;
    // return (
    //   <div className="activity_box_foot base_shopping_foot">
    //     <div className="limit_users_null">本期名额已满</div>
    //     <div className="activity_box_bg clearfix">12345</div>
    //   </div>
    // );

    return (
      <div className="activity_box_foot base_shopping_foot">
        {!shoppingGroupExit &&
        isShoppingGroupBargain &&
        shoppingBargainEndTime ? (
          props.sub_activities_limit_users_count_all_exceed ? (
            ""
          ) : (
            <DiscountCountDown
              text={discountCountDownText}
              bargainEndTime={discountCountDownTime}
              warnText="砍价"
            />
          )
        ) : props.sub_activities_limit_users_count_all_exceed ? (
          ""
        ) : (
          header()
        )}
        {props.sub_activities_limit_users_count_all_exceed ? (
          <div className="limit_users_count">本期名额已满</div>
        ) : (
          ""
        )}

        <div className="activity_box_bg clearfix">
          {isTempThinkingSeriesTrial && !isBeforeSale ? (
            !props.absentee_activity && (
              <>
                <div
                  className="btn_left"
                  id="thinking_series_trial"
                  onClick={() => {
                    // if (props.setting_study_packages_url) {
                    //   window.location.href = props.setting_study_packages_url;
                    // } else {
                    subCourseDilogRef.current.setOneCoinLearningOneWeek(true);
                    courseListDisplay("block");
                    subCourseDilogRef.current.setSelectedActivity(true);
                    // }
                  }}
                >
                  <p className="top">
                    <span className="strong_num" style={{ fontSize: 24 }}>
                      免费
                    </span>
                    学一周
                    <span style={{ fontSize: "12px", fontWeight: "normal" }}>
                      （正式课内容）
                    </span>
                  </p>
                  {!!props.batch_ecoupons_amount && (
                    <p className="bottom">
                      还可得
                      <span className="strong_num">
                        ￥{props.batch_ecoupons_amount}
                      </span>
                      课程优惠券
                    </p>
                  )}
                </div>
                <div
                  className="btn_right"
                  onClick={() => {
                    subCourseDilogRef.current.setOneCoinLearningOneWeek(false);
                    courseListDisplay("block");
                    subCourseDilogRef.current.setSelectedActivity(false);
                  }}
                >
                  直接报名
                </div>
              </>
            )
          ) : (
            <>
              {isBeforeSale && showPrice}
              {props.sub_activities_limit_users_count_all_exceed ? (
                <div
                  className="activitiy_sign_in"
                  style={{ backgroundColor: "#999" }}
                >
                  等待下期
                </div>
              ) : (
                <>
                  <div
                    data-key="newGroupBuy"
                    className={`activitiy_sign_in  ${
                      isBeforeSale
                        ? "activity-sign-before-sale"
                        : groupRelativeMode &&
                          !noShowExperienceButton &&
                          findExperienceCourse(
                            props.sub_study_package_activities
                          ).length > 0
                        ? "activitiy_sign_in1 "
                        : "activitiy_sign_in1 activitiy_sign_in0"
                    }`}
                    // onClick={}
                  >
                    {isBeforeSale ? (
                      "提前预约"
                    ) : (
                      <div className="new_shopping_bottom">
                        <div className="new_shopping_bottom1">
                          <div
                            className="new_shopping_bottom_direct"
                            onClick={() => {
                              console.log("++++++++++++");
                              subCourseDilogRef.current?.setState({
                                coursePayMode: "direct",
                              });
                              baseRef.current.setState({
                                firstGroup: null,
                              });
                              newGroupBuy();
                              GA_new(
                                "show",
                                "direct_buy",
                                1,
                                props.target_type,
                                props.target_id
                              );
                            }}
                          >
                            <div className="new_shopping_bottom-price">
                              <span>{"￥" + floorPriceDirect + "起"}</span>
                              {floorPriceDirect != ceilingPriceDirect && (
                                <del>{"￥" + ceilingPriceDirect}</del>
                              )}
                            </div>
                            <div>直接购买</div>
                          </div>
                          <div
                            className="new_shopping_bottom_group"
                            onClick={() => {
                              window.scrollTo(
                                0,
                                document.getElementById("group-peoples")
                                  .scrollHeight
                              );
                              // isShopGroup(true);
                              GA_new(
                                "show",
                                "join_groupon",
                                1,
                                props.target_type,
                                props.target_id
                              );
                            }}
                          >
                            <div className="new_shopping_bottom-price">
                              <span>{"￥" + floorPriceGroup + "起"}</span>
                              {floorPriceGroup != ceilingPriceGroup && (
                                <del>{"￥" + ceilingPriceGroup}</del>
                              )}
                            </div>
                            <div>{props.group_max_count + "人拼团"}</div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {trialShow(trial_activities, study_package_activity.id)}
                  {console.log(
                    "222222222222222--------------------",
                    groupRelativeMode,
                    !noShowExperienceButton,
                    props.sub_study_package_activities
                  )}
                  {groupRelativeMode &&
                    !noShowExperienceButton &&
                    findExperienceCourse(props.sub_study_package_activities)
                      .length > 0 && (
                      <ActivityExperienceButton
                        activities={props.sub_study_package_activities}
                        handleExperienceCourse={handleExperienceCourse}
                        sub_course_type={props.sub_course_type}
                      />
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  };
  // 高价课
  const handleExperienceCourse = (e) => {
    e.preventDefault();
    const dataState = e.currentTarget.getAttribute("data-state");
    GA_new("show", "trial_coupon", 1, props.target_type, props.target_id);
    if (props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        const login_url = decodeURIComponent(props.login_url);
        XinYaNativeBridge.navigate({ href: login_url });
      } else {
        window.location.href = props.login_url;
      }
    } else if (props.qrcode_url != null) {
      baseRef.current.onWechatFollow();
    } else {
      if (dataState == "ok") {
        courseListDisplay("none");

        isShopGroup(false);
        $($("#course_list_container_ul li[data-index]")[0]).trigger("click");
        setTimeout(() => {
          $($(".alone_sign_price")[0]).trigger("click");
        }, 100);
      } else {
        onNavigate(
          e,
          `${props.trial_study_package_activity.url}&ref=trial_ecoupon`
        );
      }
    }
  };
  // 过期
  const expiredShopGroupState = () => {
    var showPrice;
    let price = `¥${state.sort_min_price}起`;
    showPrice = (
      <div className="activitiy_price">
        <PriceTextShow
          // price={state.sort_min_price}
          price={`${
            calcActivityLowestPrice(props.sub_study_package_activities)[0]
              .discountPrice
          }`}
          isExist={isExistOweMediaCountObject}
          subActivities={props.sub_study_package_activities}
          saleMode={props.sale_mode}
        />
        <div className="price_subscribe">{props.refund_policy}</div>
      </div>
    );
    return (
      <div className="activity_box_foot">
        {beforeSaleHeader(props)}
        <div className="activity_box_bg clearfix">
          {showPrice}
          <div
            data-key="handleBeforeSale"
            className={"activitiy_sign_in activity-sign-before-sale"}
            onClick={handleBeforeSale.bind(null, props)}
          >
            提前预约
          </div>
          {trialShow(trial_activities, study_package_activity.id)}
        </div>
      </div>
    );
  };

  // 已购买用户进入报名页后的页面状态
  const showUserBuyState = (props) => {
    var showPrice;
    let price = `¥${state.sort_min_price}起`;
    showPrice = (
      <div className="activitiy_price">
        <PriceTextShow
          // price={state.sort_min_price}
          price={`${
            calcActivityLowestPrice(props.sub_study_package_activities)[0]
              .discountPrice
          }`}
          isExist={isExistOweMediaCountObject}
          subActivities={props.sub_study_package_activities}
          saleMode={props.sale_mode}
        />
        <div className="price_subscribe">{props.refund_policy}</div>
      </div>
    );

    const params = Object.assign({}, props);
    params["free"] = "none_shopping_group";
    params["eventOperate"] = "shareCourse";
    params["eventCategory"] = "Courses";
    return (
      <div className="activity_box_foot">
        <div className="activitiy_amount tips_text">
          <span>
            {props.sync_practice_state == "basic"
              ? "你已购买基础版内容，可前往课表升级为拓展版"
              : "你已拥有当前所有课程，邀请朋友一起来学习吧～"}
          </span>
        </div>
        <div className="activity_box_bg clearfix">
          {showPrice}
          <div
            className="activitiy_sign_in"
            onClick={inviteFriend.bind(
              null,
              props.sale_mode === "shopping_group_new_member_only",
              params
            )}
          >
            邀请朋友
          </div>
          {trialShow(trial_activities, study_package_activity.id)}
        </div>
      </div>
    );
  };

  // 已购买用户进入报名页后的页面状态
  const completeGrouponState = () => {
    const { shopping_group, group_max_count } = props;

    var showPrice;
    const price = `¥${state.sort_min_price}起`;
    console.log("completeGrouponState_price: ", price);

    showPrice = (
      <div className="activitiy_price">
        <PriceTextShow
          // price={state.sort_min_price}
          price={`${
            calcActivityLowestPrice(props.sub_study_package_activities)[0]
              .discountPrice
          }`}
          isExist={isExistOweMediaCountObject}
          subActivities={props.sub_study_package_activities}
          saleMode={props.sale_mode}
        />
        <div className="price_subscribe">{props.refund_policy}</div>
      </div>
    );

    const users = () => {
      var users = [];
      if (
        shopping_group &&
        "user_shopping_groups" in shopping_group &&
        shopping_group.user_shopping_groups.length
      ) {
        for (let i = 0; i < group_max_count; i++) {
          const user_shopping_group = shopping_group.user_shopping_groups[i];
          if (user_shopping_group) {
            users.push(user_shopping_group.user.photo_url);
          } else {
            users.push(null);
          }
        }
      }
      return users;
    };

    return (
      <div className="activity_box_foot">
        <div className="group_head_amount tips_text">
          {users().map((photo_url, index) => {
            return photo_url ? (
              <img
                className="lazyload"
                data-src={photo_url}
                data-sizes="auto"
                key={index}
              />
            ) : (
              <div className="null-group-people">+</div>
            );
          })}
          <span>当前团已满，请新开团</span>
        </div>
        <div className="activity_box_bg clearfix">
          {/* {showPrice} */}
          <div
            className={`activitiy_sign_in activitiy_sign_in1  ${
              groupRelativeMode &&
              !noShowExperienceButton &&
              findExperienceCourse(props.sub_study_package_activities).length >
                0
                ? ""
                : "activitiy_sign_in0"
            }`}
            onClick={newGroupBuy}
          >
            {props.sale_mode == "shopping_group_bargain" ? (
              "查看课程"
            ) : (
              <div className="new_shopping_bottom">
                <div className="new_shopping_bottom1">
                  <div
                    className="new_shopping_bottom_direct"
                    onClick={() => {
                      console.log("++++++++++++");
                      subCourseDilogRef.current?.setState({
                        coursePayMode: "direct",
                      });
                      baseRef.current.setState({
                        firstGroup: null,
                      });
                      newGroupBuy();
                    }}
                  >
                    <div className="new_shopping_bottom-price">
                      <span>{"￥" + floorPriceDirect + "起"}</span>
                      <del>{"￥" + ceilingPriceDirect}</del>
                    </div>
                    <div>直接购买</div>
                  </div>
                  <div
                    className="new_shopping_bottom_group"
                    onClick={() => {
                      window.scrollTo(
                        0,
                        document.getElementById("group-peoples").scrollHeight
                      );
                      // isShopGroup(true);
                    }}
                  >
                    <div className="new_shopping_bottom-price">
                      <span>{"￥" + floorPriceGroup + "起"}</span>
                      <del>{"￥" + ceilingPriceGroup}</del>
                    </div>
                    <div>{props.group_max_count + "人拼团"}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {trialShow(trial_activities, study_package_activity.id)}
          {groupRelativeMode &&
            !noShowExperienceButton &&
            findExperienceCourse(props.sub_study_package_activities).length >
              0 && (
              <ActivityExperienceButton
                activities={props.sub_study_package_activities}
                handleExperienceCourse={handleExperienceCourse}
                sub_course_type={props.sub_course_type}
              />
            )}
        </div>
      </div>
    );
  };

  // 过滤找到符合当前补差价是否是折扣后购买的项 activity
  const findRemaingPayActivity = () => {
    const { id } = props;
    const targetActivity = [
      ...props.sub_study_package_activities.filter((item) =>
        item.id == id ? item : ""
      ),
    ];
    return targetActivity;
  };
  // 针对折价场景下的拼团，补差价:
  // 补差价的金额 = 折价后直接购买的价格 - 已支付的拼团价格
  const remaiingPayPrice = () => {
    const {
      sub_study_package_activities,
      current_user,
      shopping_group,
      price,
      base_price,
      launcher_price,
      group_price,
    } = props;

    const currentActivity = findRemaingPayActivity();

    // 折扣价
    const calc_original_rice = calcDiscountPercentPrice(
      currentActivity,
      price,
      base_price,
      0
    );
    const calc_launcher_price = calcDiscountPercentPrice(
      currentActivity,
      launcher_price,
      base_price,
      0
    );
    const calc_group_price = calcDiscountPercentPrice(
      currentActivity,
      group_price,
      base_price,
      0
    );

    console.log(
      "currentActivity, calc_original_rice, calc_launcher_price, calc_group_price: ",
      currentActivity,
      calc_original_rice,
      calc_launcher_price,
      calc_group_price
    );

    if (
      "launcher" in shopping_group &&
      "id" in current_user &&
      current_user.id === shopping_group.launcher.id
    ) {
      return calc_original_rice - calc_launcher_price;
    } else {
      return calc_original_rice - calc_group_price;
    }
  };
  // continue pay click function
  const remainPay = (e) => {
    GA("Courses", "remainPay", study_package_activity.id);
    setFirstGroup({ ...shoppingGroupInfo[0] });
    if (props.logged_in == null) {
      if (UserAgent.isNativeClient()) {
        XinYaNativeBridge.navigate({
          href: decodeURIComponent(this.props.login_url),
        });
      } else {
        window.location.href = this.props.login_url;
      }
    } else if (props.qrcode_url != null) {
      baseRef.current.onWechatFollow();
    } else {
      baseRef.current.showCourseCouponPay(
        remaiingPayPrice(),
        "pay_remaining",
        props.id,
        props.name,
        "group"
      );
    }
  };

  // 邀请好友拼团 + 补差价
  const nextBuyInviteFriend = (props) => {
    // bonus_type【0: cash， 1: virtual】
    const params = Object.assign({}, props);
    params["free"] = "in_or_open_shopping_group";
    params["eventOperate"] = "shareGroup";
    params["eventCategory"] = "Courses";
    let flexBotFt = "";
    if (
      !trial_activities ||
      (trial_activities && trial_activities.length == 0)
    ) {
      flexBotFt = " flex_price";
    }
    return (
      <div className="activity_box_ft">
        {showJoinGrouponUserNew(props, state, setState)}
        {trialShow(trial_activities, study_package_activity.id)}
        <div className={"activitiy_price" + flexBotFt} onClick={remainPay}>
          <div className="activitiy_direct_buy">
            <span className="buy_price">¥{remaiingPayPrice().toFixed(2)}</span>
            <br />
            <span>补差价报名</span>
          </div>
        </div>
        <div
          className={"study_group_price" + flexBotFt}
          onClick={inviteFriend.bind(null, true, params)}
        >
          <div className="invite_friends_group">
            <span>邀请拼团</span>
          </div>
        </div>
      </div>
    );
  };

  // 未拼团成功 补差价
  const payRemainingPrice = () => {
    const { pay_remaining_endtime, can_pay_remaining } = props;

    const handleCountdownEnd = (e) => {
      if (can_pay_remaining) {
        setState((preState) => {
          return {
            ...preState,
            canPayRemaining: can_pay_remaining,
          };
        });
        alert("handleCountdownEnd");
        console.log(state);
      } else {
        setState((preState) => {
          return {
            ...preState,
            isNotExpirdOfGroupon: false,
          };
        });
      }
    };
    const showPayRemainingTime = () => {
      return (
        <div className="group_pay_remaining tips_text">
          <CountDownTime
            endTime={new Date(pay_remaining_endtime)}
            overText="活动已结束"
            // onEnd={handleCountdownEnd}
          >
            {({ d, h, m, s }) => {
              return (
                <span>
                  补差价倒计时：{d}天 {h}:{m}:{s}
                </span>
              );
            }}
          </CountDownTime>
        </div>
      );
    };
    return (
      <div className="activity_pay_remaining_price">
        {showPayRemainingTime()}
        <div className="pay_remaining_buy" onClick={remainPay}>
          <span className="remaining_price">
            ¥{remaiingPayPrice().toFixed(2)}
          </span>
          <br />
          <span>补差价购买</span>
        </div>
      </div>
    );
  };

  // 加入拼团
  const onJoinShopGroup = () => {
    let price = `¥${state.sort_min_price}起`;
    var showPrice = (
      <div className="activitiy_price">
        <PriceTextShow
          // price={state.sort_min_price}
          price={`${
            calcActivityLowestPrice(props.sub_study_package_activities)[0]
              .discountPrice
          }`}
          isExist={isExistOweMediaCountObject}
          subActivities={props.sub_study_package_activities}
          saleMode={props.sale_mode}
        />
        <div className="price_subscribe">{props.refund_policy}</div>
      </div>
    );
    // if (price == "") {
    //   showPrice = (
    //     <div className="activitiy_price">
    //       <p>
    //         ¥{props.group_price}
    //         <span className="origin_price">
    //           &nbsp;&nbsp;原价：<del>¥{props.list_price}</del>
    //         </span>
    //       </p>
    //       <div className="price_subscribe">{props.refund_policy}</div>
    //     </div>
    //   );
    // }
    const header = () => {
      if (isBeforeSale) {
        return beforeSaleHeader(props);
      } else {
        if (
          props.group_price > 0 &&
          (props.sale_mode === "shopping_group" ||
            props.sale_mode === "shopping_group_bargain" ||
            props.sale_mode === "shopping_group_new_member_only")
        ) {
          return showJoinGrouponUserNew(props, state, setState);
        }
        // if (this.props.sale_mode === 'normal') {
        //   return this.showLimitedUser()
        // }
        return "";
      }
    };

    // 选择优惠券，加入拼团模块
    const joinGroupModule = (group_price, join_groupon) => {
      baseRef.current.showCourseCouponPay(
        group_price,
        join_groupon,
        "",
        "join_groupon"
      );
      // courseCouponRef.current.setState({
      //   isCouseCouponDisplay: 'block',
      //   orderGuideDisplay: 'block',
      //   coursePayFinalPrice: group_price,
      //   coursePayFinalState: join_groupon
      // })
      // setState({
      //   ...state,
      //   coursePayMode: 'join_groupon'
      // })
    };

    const joinGroupBuy = (e) => {
      if (isBeforeSale) {
        handleBeforeSale(props);
        return;
      }
      GA("Courses", "chooseCourses", study_package_activity.id);

      if (props.logged_in === null) {
        if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.navigate({
            href: decodeURIComponent(props.login_url),
          });
        } else {
          window.location.href = props.login_url;
        }
      } else if (props.qrcode_url != null) {
        baseRef.current.onWechatFollow();
        // this.alertBuy(this.props.group_price, 'join_groupon')
      }
      // else if (!this.props.grade_exam_finished) {
      //   this.alertBuy(this.props.group_price, 'join_groupon')
      // }
      else {
        if (props.remaining_shopping_group_count <= 0) {
          if (props.need_redirect_by_ecoupon === true) {
            showCouponDialog(props.ecoupon_redirect_url);
          } else {
            if (props.sub_study_package_activities) {
              // state['isDisplayCourseList'] = 'block'
              // setState({
              //   ...state,
              // })
              console.log("-----------------------");
              courseListDisplay("block");
            } else {
              joinGroupModule(props.group_price, "join_groupon");
            }
            // props.sub_study_package_activities
            //   ?
            //   setState({
            //     ...state,
            //     isDisplayCourseList: 'block'
            //   })
            //   :
            //   joinGroupModule(props.group_price, 'join_groupon')
          }
        } else {
          if (props.need_redirect_by_ecoupon === true) {
            showCouponDialog(props.ecoupon_redirect_url);
          } else {
            if (props.sub_study_package_activities) {
              // state['isDisplayCourseList'] = 'block'
              courseListDisplay("block");
              // setState({
              //   ...state,
              // })
            } else {
              alertBuy(props.group_price, "join_groupon");
            }
          }
        }
      }
    };

    return (
      <div className="activity_box_foot">
        {!props.sub_activities_limit_users_count_all_exceed && header()}
        {props.sub_activities_limit_users_count_all_exceed ? (
          <div className="limit_users_count">本期名额已满</div>
        ) : (
          ""
        )}
        <div className="activity_box_bg clearfix">
          {/* {showPrice} */}
          {props.sub_activities_limit_users_count_all_exceed ? (
            <div
              className="activitiy_sign_in"
              style={{ backgroundColor: "#999" }}
            >
              等待下期
            </div>
          ) : (
            <>
              <div
                className={`study_group_price study_group_price1 ${
                  groupRelativeMode &&
                  !noShowExperienceButton &&
                  findExperienceCourse(props.sub_study_package_activities)
                    .length > 0
                    ? ""
                    : "study_group_price0 "
                } ${isBeforeSale ? "activity-sign-before-sale" : ""}`}
                style={{ width: "fit-content" }}
                // onClick={joinGroupBuy}
              >
                {isBeforeSale ? (
                  "提前预约"
                ) : (
                  <div className="new_shopping_bottom">
                    <div className="new_shopping_bottom1">
                      <div
                        className="new_shopping_bottom_direct"
                        onClick={() => {
                          isShopGroup(false);
                          baseRef.current.setState({
                            firstGroup: null,
                          });
                          joinGroupBuy();
                        }}
                      >
                        <div className="new_shopping_bottom-price">
                          <span>{"￥" + floorPriceDirect + "起"}</span>
                          <del>{"￥" + ceilingPriceDirect}</del>
                        </div>
                        <div>直接购买</div>
                      </div>
                      <div
                        className="new_shopping_bottom_group"
                        onClick={(e) => {
                          setShowGroupWindow(true);
                          // isShopGroup(true);
                        }}
                      >
                        <div className="new_shopping_bottom-price">
                          <span>{"￥" + floorPriceGroup + "起"}</span>
                          <del>{"￥" + ceilingPriceGroup}</del>
                        </div>
                        <div>
                          {props.is_course_user ? "立即报名" : "加入拼团"}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* {isBeforeSale
                  ? "提前预约"
                  : props.is_course_user
                  ? "立即报名"
                  : "加入拼团"} */}
              </div>
              {console.log(
                groupRelativeMode,
                !noShowExperienceButton,
                "-------------------------0000"
              )}
              {trialShow(trial_activities, study_package_activity.id)}
              {groupRelativeMode &&
                !noShowExperienceButton &&
                findExperienceCourse(props.sub_study_package_activities)
                  .length > 0 && (
                  <ActivityExperienceButton
                    activities={props.sub_study_package_activities}
                    handleExperienceCourse={handleExperienceCourse}
                    sub_course_type={props.sub_course_type}
                  />
                )}
            </>
          )}
        </div>
      </div>
    );
  };
  // 直接购买
  const directBuyCourse = (
    selectMinMoney,
    selectMaxMoney,
    activityTargetId,
    selectName
  ) => {
    baseRef.current.directBuyCourse(
      selectMinMoney,
      selectMaxMoney,
      activityTargetId,
      selectName
    );
  };

  const invitingUser = () => {
    throw new Error("Sale Mode is Group");
  };

  // 选课弹窗
  const selectActivity = (index, activity) => {
    if (!activity || activity.redirect_url) {
      return;
    }
    if (activity !== null) {
      setState((preState) => {
        return {
          ...preState,
          selectIndex: index,
          selectActivityId: activity.id || 0,
          selectMaxMoney: activity.max_price || 0,
          selectMinMoney: activity.min_price || 0,
          selectLauncherMoney: activity.launcher_price || 0,
        };
      });
      courseListDisplay("block");
    } else {
      setState((preState) => {
        return {
          ...preState,
          selectIndex: index,
          selectActivityId: null,
          selectMaxMoney: null,
          selectMinMoney: null,
          selectLauncherMoney: null,
        };
      });
    }
  };

  // 折扣价
  const calcDiscountPercentPrices = (price, selectActivityIndex) => {
    if (selectActivityIndex < 0) return "";
    const current_owned_lesson_count =
      props.sub_study_package_activities[selectActivityIndex]
        .owned_media_lesson_count;

    const current_lessons_count =
      props.sub_study_package_activities[selectActivityIndex]
        .media_lessons_count;

    const base_price =
        props.sub_study_package_activities[selectActivityIndex]
          .base_price;

    const low_price_media_lesson_paid_amount =
        props.sub_study_package_activities[selectActivityIndex]
          .low_price_media_lesson_paid_amount;

    let discountPercentPrice;
    if (current_owned_lesson_count > 0) {
      discountPercentPrice = Math.round(
        (1 - current_owned_lesson_count / current_lessons_count) * (price - base_price) + base_price
      );
    } else {
      discountPercentPrice = price;
    }
    console.log("Dialog_discountPercentPrice: ", discountPercentPrice - low_price_media_lesson_paid_amount);
    return discountPercentPrice - low_price_media_lesson_paid_amount;
  };

  // 分级拼团购买
  const preGroupBuy = (selectIndex, groupState, activity, shoppingRule) => {
    console.log("pre_on_direct_buy 分级拼团购买: ", selectIndex);
    console.log("pre_on_direct_buy groupState: ", groupState);
    console.log("pre_on_direct_buy activity: ", activity);
    console.log("pre_on_direct_buy shoppingRule: ", shoppingRule);
    GA_new(
      "show",
      "shopping_group",
      activity.id,
      props.target_type,
      props.target_id
    );

    if (props.need_redirect_by_ecoupon === true) {
      showCouponDialog(props.ecoupon_redirect_url);
    } else {
      setState((preState) => {
        return {
          ...preState,
          isShowShoppingGroupAlert: true,
          shoppingRuleStatus: shoppingRule,
          selectMinMoney: activity.min_price,
          selectLauncherMoney: activity.launcher_price,
          groupPayState: groupState,
          selectActivityId: activity.id,
          selectName: activity.name,
          selectIndex,
        };
      });
      afterChoosePayMode(
        calcDiscountPercentPrices(
          groupState == "new_groupon"
            ? activity.launcher_price
            : activity.min_price,
          selectIndex
        ),
        groupState,
        activity.id,
        activity.name,
        "group",
        selectIndex
      );
      // cancelClickAction();
      GA(
        "Courses",
        groupState == "new_groupon" ? "newGroupBuy" : "joinGroupBuy",
        activity.id
      );
    }
  };
  // 分级直接购买
  const preOnDirectBuy = (
    groupon_amount,
    selectActivityId,
    selectName,
    selectIndex,
    trial
  ) => {
    console.log("pre_on_direct_buy 分级直接购买");
    if (selectIndex != 0) {
      GA_new(
        "show",
        "direct_buy",
        selectActivityId,
        props.target_type,
        props.target_id
      );
    }
    if (props.need_redirect_by_ecoupon === true) {
      showCouponDialog(props.ecoupon_redirect_url);
    } else {
      baseRef.current.showCourseCouponPay(
        groupon_amount,
        "direct",
        selectActivityId,
        selectName,
        "direct",
        selectIndex,
        trial
      );
      courseListDisplay("none");
    }
    // this.onDirectBuy(directPrice, selectActivityId)
  };

  // 弹出支付弹窗
  const afterChoosePayMode = (
    finalPrice,
    finalState,
    selectActivityId,
    selectName,
    coursePayMode,
    selectIndex,
    trial
  ) => {
    baseRef.current.showCourseCouponPay(
      finalPrice,
      finalState,
      selectActivityId,
      selectName,
      coursePayMode,
      selectIndex,
      trial
    );
    courseListDisplay("none");
  };

  const cancelClick = () => {
    setState((preState) => {
      return { ...preState, isShowShoppingGroupAlert: false };
    });
  };

  console.log("isShowShoppingGroupAlert:", state.isShowShoppingGroupAlert);

  // 邀请海报
  const inviteFriendPoster = () => {
    const product_code_arr = props.sub_study_package_activities
      .filter((sub_activity) => sub_activity.product_code)
      .map((item) => item.product_code)
      .join("、");
    const { poster_url, poster_creation } = props.bargain;
    const sharePosterProxy = new SharePosterProxy({
      posterTemplate: props.poster_photo_template_url,
      posterCreation: poster_creation,
      mode: "front_end",
      posterUrl: poster_url,
      // posterImgSrc:
      //   "https://upload.fireflybaby.cn/study_package_activity/3f085407dc0549ceef4a9517643ebb8c.jpeg",
      free: "new_shopping_invite_friend_poster",
      shopping_bargain: props.bargain,
      sale_mode: props.sale_mode,
      callback: posterCallback(),
      buy_endtime: props.buy_endtime,
      system_time: props.system_time,
      product_code_arr: product_code_arr,
      avatar_location: props.avatar_location,
    });
    sharePosterProxy.open();
  };

  // 判断用户通过海报邀请
  const posterCallback = () => {
    if (shoppingBargainEndTime) {
      return;
    }
    request({
      url: props.bargain.inviting_user_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        id: props.bargain.activity.id,
      },
    })
      .then((resp) => {
        console.log("resp: ", resp.data);
        setShoppingBargainEndTime(resp.data.end_time);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // 一元学一周
  useEffect(() => {
    if (props.trial) {
      // eslint-disable-next-line no-unused-expressions
      $("#thinking_series_trial")?.trigger?.("click");
    }
  }, [props.trial]);

  const isThinkingSeries = props.sub_course_type === "thinking";
  // 用户命令处理（生命周期函数）
  useImperativeHandle(ref, () => ({
    onAlertPoster,
    payRemaining: (price, id, name) => {
      baseRef.current.showCourseCouponPay(
        price,
        "pay_remaining",
        id,
        name,
        "group"
      );
    },
  }));
  return (
    <React.Fragment>
      {props.promotion_group_qrcode_url && (
        <QrcodeDiag
          promotion_group_qrcode_url={props.promotion_group_qrcode_url}
          study_package_activity_id={props.study_package_activity_id}
        ></QrcodeDiag>
      )}
      {props.promotion_method === "trial" ? (
        props.promotion_trial.trial_studied ? (
          <QrcodeDiag
            activity={props.sub_study_package_activities[0]}
            study_package_activity_id={props.study_package_activity_id}
            originPrice={props.sub_study_package_activities[0].max_price}
            finalPrice={props.sub_study_package_activities[0].min_price}
            promotion_trial={props.promotion_trial}
            study_package_activity_buy_url={
              props.study_package_activity_buy_url
            }
            bottom_type="study-all"
          ></QrcodeDiag>
        ) : (
          <QrcodeDiag
            study_package_activity_id={props.study_package_activity_id}
            promotion_trial={props.promotion_trial}
            bottom_type="first_fire"
          ></QrcodeDiag>
        )
      ) : (
        ""
      )}
      <div className="comp-shopping-group">
        {
          <BaseStudyPackageActivity
            {...props}
            ref={baseRef}
            shoppingGroupInfo={shoppingGroupInfo}
            firstGroup={firstGroup}
            // isDisplayCourseList={state.isDisplayCourseList}
            afterLoadAction={afterLoadAction}
            alertPoster={alertPoster}
            directBuyCourse={directBuyCourse}
            groupRender={() => {
              if (shoppingGroupInfo.length > 0) {
                return (
                  <div className="group-peoples" id="group-peoples">
                    <div
                      className="group-peoples-tips"
                      onClick={() => {
                        setShowGroupWindow(false);
                        subCourseDilogRef.current?.setState({
                          coursePayMode: "group",
                        });
                        newGroupBuy();
                        GA_new(
                          "show",
                          "new_groupon",
                          1,
                          props.target_type,
                          props.target_id
                        );
                      }}
                    >
                      以下家长正在拼团，你可直接加入，或
                      <span style={{ color: "#FB636F", fontSize: 14 }}>
                        {"新开拼团>>"}
                      </span>
                    </div>
                    <div className="group-peoples-circle">
                      {shoppingGroupInfo.map((item, index) => {
                        // console.log("shoppingGroupInfo---------------", item);
                        return (
                          item.minCount > 0 && (
                            <div
                              className="group-peoples-circle-item"
                              key={"shoppingGroupInfo" + index}
                            >
                              <div className="group-peoples-circle-item-left">
                                <img src={item.launcher.photo_url}></img>
                                <span>{item.launcher.name}</span>
                              </div>
                              <div className="group-peoples-circle-item-right">
                                <div className="group-info">
                                  <div>
                                    还差
                                    <span style={{ color: "#FB636F" }}>
                                      {item.minCount + "人"}
                                    </span>
                                    成团
                                  </div>
                                  {item.end_time &&
                                    new Date(item.end_time) - new Date() > 0 &&
                                    new Date(item.end_time) - new Date() <
                                      604800000 && (
                                      <div>
                                        <CountDownTime
                                          endTime={new Date(item.end_time)}
                                          overText=""
                                          // onEnd={handleCountdownEnd}
                                        >
                                          {({ d, h, m, s }) => {
                                            return d > 0 ? (
                                              <span>
                                                剩余:{d}天 {h}:{m}:{s}
                                              </span>
                                            ) : (
                                              <span>
                                                剩余: {h}:{m}:{s}
                                              </span>
                                            );
                                          }}
                                        </CountDownTime>
                                      </div>
                                    )}
                                </div>
                                <div
                                  className="jion-group"
                                  onClick={() => {
                                    setFirstGroup({ ...item });
                                    baseRef.current.setState({
                                      firstGroup: { ...item },
                                    });
                                    setShowGroupWindow(true);
                                    GA_new(
                                      "show",
                                      "join_groupon",
                                      2,
                                      props.target_type,
                                      props.target_id
                                    );
                                  }}
                                >
                                  加入拼团
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            }}
            bottomRender={(props) => {
              if (props.isNoneRenderBottomBar) {
                return "";
              }
              return props.hide_buy ? (
                ""
              ) : (
                <div className="activity_pay">
                  <ShoppingBottomBar
                    {...props}
                    state={state}
                    settingStudyPackagesUrl={props.setting_study_packages_url}
                    preBuyShopGroupState={preBuyShopGroupState}
                    showUserBuyState={showUserBuyState}
                    onJoinShopGroup={onJoinShopGroup}
                    nextBuyInviteFriend={nextBuyInviteFriend}
                    payRemainingPrice={payRemainingPrice}
                    completeGrouponState={completeGrouponState}
                    expiredShopGroupState={expiredShopGroupState}
                    shoppingGroupInfo={shoppingGroupInfo}
                    firstGroup={firstGroup}
                  />
                </div>
              );
            }}
          />
        }
        {showGroupWindow && firstGroup && (
          <div className="fixed-Window">
            <div className="center-window">
              <div className="group-people-header">
                {firstGroup.is_full ? (
                  <div className="group-people-title">{`该拼团人数已满`}</div>
                ) : (
                  <>
                    <div className="group-people-title">{`参与${firstGroup.launcher.name}的拼团`}</div>
                    <div className="group-people-desc">
                      {`还差`}
                      <span style={{ color: "#FB636F" }}>
                        {firstGroup.add_people}
                      </span>
                      {"人成团"}
                      {firstGroup.end_time &&
                        new Date(firstGroup.end_time) - new Date() > 0 &&
                        new Date(firstGroup.end_time) - new Date() <
                          604800000 && (
                          <span>
                            <CountDownTime
                              endTime={new Date(firstGroup.end_time)}
                              overText=""
                              // onEnd={handleCountdownEnd}
                            >
                              {({ d, h, m, s }) => {
                                return d > 0 ? (
                                  <span>
                                    ，剩余{d}天 {h}:{m}:{s}
                                  </span>
                                ) : (
                                  <span>
                                    ，剩余 {h}:{m}:{s}
                                  </span>
                                );
                              }}
                            </CountDownTime>
                          </span>
                        )}
                      {"结束"}
                    </div>
                  </>
                )}
              </div>
              <div className="group-people">
                {firstGroup.userInfos?.map((itm, idx) => {
                  return itm !== null ? (
                    idx == 0 ? (
                      <div className="group-people-item" key={"a" + idx}>
                        <div
                          className={`${
                            itm.is_launcher ? "group-people-lancher" : ""
                          }`}
                        >
                          <img
                            src={itm.photo_url}
                            className={`group-people-img ${
                              props.group_max_count <= 3
                                ? "group-people-img3"
                                : "group-people-img5"
                            } `}
                          ></img>
                        </div>
                      </div>
                    ) : (
                      <div className="group-people-item" key={"a" + idx}>
                        <div className="group-people-item-add">+</div>
                        <div
                          className={`${
                            itm.is_launcher ? "group-people-lancher" : ""
                          }`}
                        >
                          <img
                            src={itm.photo_url}
                            className={`group-people-img ${
                              props.group_max_count <= 3
                                ? "group-people-img3"
                                : "group-people-img5"
                            } `}
                          ></img>
                        </div>
                      </div>
                    )
                  ) : (
                    <div className="group-people-item" key={"a" + idx}>
                      <div className="group-people-item-add">+</div>
                      <div
                        className={`group-people-item-null ${
                          props.group_max_count <= 3
                            ? "group-people-img3"
                            : "group-people-img5"
                        }`}
                      ></div>
                    </div>
                  );
                })}
              </div>
              <div
                className="group-people-tips"
                onClick={() => {
                  if (firstGroup.is_full) {
                    setShowGroupWindow(false);
                    window.scrollTo(0, 0);
                  } else {
                    console.log("++++++++++++");
                    setShowGroupWindow(false);
                    baseRef.current.showCourseCouponPay(
                      props.group_price || 0,
                      "join_groupon",
                      "",
                      "join_groupon"
                    );
                    // joinGroupModule(props.group_price, "join_groupon");
                    subCourseDilogRef.current?.setState({
                      coursePayMode: "join_group",
                    });
                    newGroupBuy();
                    GA_new(
                      "show",
                      "join_groupon",
                      3,
                      props.target_type,
                      props.target_id
                    );
                  }
                }}
              >
                {firstGroup.is_full ? "加入其它家长的拼团" : "参与拼团"}
              </div>
              <div
                className="btn_close"
                onClick={() => {
                  setShowGroupWindow(false);
                  setFirstGroup({ ...shoppingGroupInfo[0] });
                  baseRef.current.setState({
                    firstGroup: { ...shoppingGroupInfo[0] },
                  });
                }}
              >
                <img src={btn_close}></img>
              </div>
            </div>
          </div>
        )}
        {
          // 多课程体系（子课程包分级购买）
          "sub_study_package_activities" in props && (
            <CourseLists
              subCourseType={props.sub_course_type}
              batchEcouponsAmount={props.batch_ecoupons_amount}
              ref={subCourseDilogRef}
              isThinkingSeries={isThinkingSeries}
              directBuyCourse={directBuyCourse}
              preGroupBuy={preGroupBuy}
              preOnDirectBuy={preOnDirectBuy}
              invitingUser={invitingUser}
              selectActivity={selectActivity}
              selectIndexCourse={state.selectIndex}
              selectActivityId={state.selectActivityId}
              selectMaxMoney={state.selectMaxMoney}
              selectMinMoney={state.selectMinMoney}
              selectLauncherMoney={state.selectLauncherMoney}
              subStudyPackageActivities={props.sub_study_package_activities}
              saleMode={props.sale_mode}
              // shopGroup={state.shopGroup || false}
              groupMaxCount={props.group_max_count}
              bargainDurationHours={props.bargain_duration_hours}
              buyEndtime={props.buy_endtime}
              systemTime={props.system_time}
              endTime={state.end_time}
              invitedUsersCount={props.invited_users_count}
              overallBought={props.overall_bought}
              bought={props.bought}
              shoppingGroup={props.shopping_group}
              currentUser={props.current_user}
              remainingShoppingGroupCount={props.remaining_shopping_group_count}
              // grouponDirectBuy={props.groupon_direct_buy}
              isShowStartTime={state.isShowStartTime}
              hasBoughtAny={state.hasBoughtAny}
              isPoetry={props.is_poetry}
              studyPackageActivityId={props.study_package_activity_id}
              inviteFriendPoster={inviteFriendPoster}
              shoppingBargainEndTime={shoppingBargainEndTime}
              shoppingBargain={props.bargain}
              baby={props.baby}
              qrcodeUrl={props.qrcode_url}
              trialStartDate={props.trial_start_date}
              afterChoosePayMode={afterChoosePayMode}
              showGroupWindow={showGroupWindow}
              setShowGroupWindow={setShowGroupWindow}
            />
          )
        }
      </div>
      {showCourseCouponTicket && (
        <ActivityCouponTicket
          batchEcoupon={props.batch_ecoupon}
          getActivityCouponTicketCloseStatus={
            getActivityCouponTicketCloseStatus
          }
        />
      )}
      {/* // 优惠券为当前报名页可用
        // 最近即将过期的优惠券时间距离当前时间在3天内 */}
      {props.recent_overdue_ecoupon &&
        DateMinus(props.system_time, props.recent_overdue_ecoupon.end_time) <=
          12 && (
          <CouponTicketCouponDown
            half_price={props.recent_overdue_ecoupon.half_price}
            price={props.recent_overdue_ecoupon.price}
            endTime={props.recent_overdue_ecoupon.end_time}
          />
        )}
    </React.Fragment>
  );
});

export default ShoppingGroup;
