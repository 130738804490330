/* eslint-disable */
import TrialChoose from "./TrialChoose";
import React, { useEffect, useState, useCallback } from "react";
import { GA } from "../utils/Env";

const TrialActivity = (props) => {
  const [isClickTrial, setState] = useState(false);

  const clickTrialChoose = useCallback((e) => {
    GA("Courses", "Trial", props.activityId);
    setState(!isClickTrial);
  });

  if (props.trialActivities && props.trialActivities.length) {
    console.log("多个试听链接");
    return (
      <React.Fragment>
        <div className="free_experience" onClick={clickTrialChoose}>
          免费试听
        </div>
        {isClickTrial && (
          <TrialChoose
            trialActivities={props.trialActivities}
            cancelChoose={clickTrialChoose}
          />
        )}
      </React.Fragment>
    );
  } else {
    return "";
  }
};

export default TrialActivity;
