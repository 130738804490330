/* eslint-disable react/prop-types */
import React, { memo } from "react";
import {
  chineseMonthDayDate,
  chineseYearMonthDayDate,
} from "../../utils/formatAudioTime";
import ErrorBoundary from "../../utils/ErrorBoundary";

// 分级课程开课时间·课时
const subSignPeriodStutus = (props) => {
  const { phase, subStudyPackageActivities } = props;
  return (
    <div className="sub_periods_time_box">
      <div className="navigate_time_box">
        <div className="box_left">{phase}</div>
        <div className="box_right">
          {[...subStudyPackageActivities].reverse().map((sub, idx) => (
            <div key={idx} className="sub_count_box">
              <h3>{sub.navigate_name}：</h3>
              <p>
                {chineseMonthDayDate(sub.start_time)} -{" "}
                {chineseMonthDayDate(sub.end_time)}
              </p>
              <span>{sub.media_lessons_count}节课</span>
            </div>
          ))}
          <div className="sub_count_box navigate_bottom_text">
            <p>课程进度可根据学习情况自行调整</p>
          </div>
        </div>
      </div>
    </div>
  );
};

// 课程开课日期·天数·课时
const signPeriodStatus = (props) => {
  const {
    phase,
    startDate,
    courseWeekCount,
    courseDayCount,
    studyPackageActivity,
    brief,
    isReadingActivity,
  } = props;
  const instanceIsFalse =
    studyPackageActivity && !studyPackageActivity.instance;
  return (
    <div className="periods_time_box">
      <div className="periods_time">{phase}</div>
      {instanceIsFalse ? (
        <div className="course_time_count_box">
          <span>{startDate}开课</span>
          {isReadingActivity ? (
            <span>{courseWeekCount}周</span>
          ) : courseDayCount ? (
            <span>{courseDayCount}天</span>
          ) : (
            ""
          )}

          {/* {media_lessons_count
                ? !is_spell && <span>{media_lessons_count}节课</span>
                : ""} */}
        </div>
      ) : (
        <div
          className="course_time_count_box"
          style={{
            paddingLeft: "95px",
          }}
        >
          {brief}
        </div>
      )}
    </div>
  );
};

const absenteePeriodStatus = (props) => {
  const { phases, studyPackageActivity, isUserStudyPackage } = props;
  if (phases == null) {
    return "";
  }
  if (studyPackageActivity && studyPackageActivity.instance) {
    return "";
  }
  return (
    <div className="absentee_periods_time_box">
      {isUserStudyPackage ? (
        <div className="absentee_time_text">
          开课日期：可选半年内任意一天开课
        </div>
      ) : (
        <>
          {
            <div className="absentee_time_tip">
              <h3>
                可选择&nbsp;“半年内”&nbsp;
                <br />
                任何一期开课
              </h3>
            </div>
          }
          {phases.map((phase, idx) => {
            const startTime = new Date(phase.start_time).getFullYear();
            const endTime = new Date(phase.end_time).getFullYear();
            const sameYear = startTime == endTime;
            return (
              <div key={idx} className="sub_count_box">
                <h3>{phase.name}</h3>
                <p>
                  {sameYear
                    ? chineseMonthDayDate(phase.start_time)
                    : chineseYearMonthDayDate(phase.start_time)}
                  开课
                </p>
                <p>
                  {sameYear
                    ? chineseMonthDayDate(phase.end_time)
                    : chineseYearMonthDayDate(phase.end_time)}
                  结课
                </p>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

const PeriodStatus = memo((props) => {
  const {
    startTime,
    isExperience,
    isParentClass,
    studyPackageActivityType,
    studyPackageActivity,
    phases,
    phase,
    startDate,
    courseWeekCount,
    courseDayCount,
    brief,
    subStudyPackageActivities,
    isUserStudyPackage,
    isReadingActivity,
  } = props;
  if (!startTime || isExperience || isParentClass) {
    return "";
  }
  const studyPackageActivityTypeEnum = {
    series: 0,
    normal: 1,
    absenteeSeries: 2,
    absenteeNormal: 3,
    seriesThinking: 4,
  };
  switch (studyPackageActivityType) {
    case studyPackageActivityTypeEnum.seriesThinking:
      return subSignPeriodStutus({
        subStudyPackageActivities,
        phase,
        isReadingActivity,
      }); // 系列课
    case studyPackageActivityTypeEnum.normal:
    case studyPackageActivityTypeEnum.series:
      return signPeriodStatus({
        phase,
        startDate,
        courseWeekCount,
        courseDayCount,
        brief,
        studyPackageActivity,
        isReadingActivity,
      }); // 正价课/体验课/试听课
    case studyPackageActivityTypeEnum.absenteeSeries:
    case studyPackageActivityTypeEnum.absenteeNormal:
      return absenteePeriodStatus({
        studyPackageActivity,
        phases,
        isUserStudyPackage,
        isReadingActivity,
      });
    default:
      break;
  }
});

export const ActivityPeriodStatus = (props) => {
  return (
    <ErrorBoundary>
      <PeriodStatus {...props} />
    </ErrorBoundary>
  );
};
