/* eslint-disable react/prop-types */
import React, { memo } from "react";
import CountDownTime from "../utils/CountDown";
import ErrorBoundary from "../utils/ErrorBoundary";
import styled from "styled-components";
import icon_double_arrow from "think_customized/icon_double_arrow.png";
import icon_right from "invite_fission/icon_right.png";
import icon_radius from "invite_fission/icon_radius.png";
import icon_right_triangle from "think_customized/icon_right_triangle.png";
import icon_logo from "icon_logo_mini.png";
import course_placeholder from "think_customized/course_placeholder.png";
import letter_cover from "think_customized/letter_cover.png";
import button_light from "promotion/button_light.gif";

const StyledFriendPresentCoureContainer = styled.div`
  min-height: 100vh;
  background-color: #eef5ff;
  padding-top: 30px;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .friend_present_contents {
    margin: 0 25px 10px;
    box-shadow: 0px 6px 10px -6px rgba(123, 174, 248, 0.2);
    border-radius: 15px;
    background-color: #fff;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background: #7badf7;
      left: -10px;
      right: 0;
      bottom: 25px;
      height: 260px;
      border-radius: 20px 20px 0 0;
      width: calc(100% + 20px);
      z-index: 1;
      z-index: 0;
    }
    &::after {
      content: "";
      position: absolute;
      background: url(${letter_cover}) no-repeat bottom center;
      background-size: 100% 100%;
      height: 197px;
      left: -10px;
      right: 0;
      bottom: 0;
      width: calc(100% + 20px);
      z-index: 3;
    }
    .top_course_content {
      .title_content {
        position: relative;
        text-align: center;
        border-bottom: 1px dashed #cee2ff;
        margin: 0 10px;
        padding: 15px 0;
        &::before {
          content: "";
          width: 18px;
          height: 18px;
          border-radius: 12px;
          position: absolute;
          bottom: -9px;
          display: block;
          background: #eef5ff;
          left: -19px;
          box-shadow: inset -1px 0px 0px 0px rgba(123, 174, 248, 0.1);
          z-index: 1;
        }
        &::after {
          content: "";
          display: block;
          width: 18px;
          height: 18px;
          border-radius: 12px;
          position: absolute;
          bottom: -9px;
          background: #eef5ff;
          right: -20px;
          box-shadow: inset 1px 0px 0px 0px rgba(123, 174, 248, 0.1);
          z-index: 1;
        }
        .left_xinya_logo {
          position: absolute;
          left: -10px;
          font-size: 12px;
          border-radius: 0 50px 50px 0;
          background: #e2eeff url(${icon_logo}) no-repeat left 2px center / 14px;
          padding: 3px 8px;
          height: 22px;
          span {
            padding-left: 12px;
          }
        }
        .course_title {
          color: #7baef8;
          font-size: 24px;
          font-weight: 700;
        }
      }
      .normal_course_content {
        text-align: center;
        padding-top: 22px;
        padding-bottom: 50px;
        position: relative;
        background: #fff;
        .invite_user {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 15px;
          position: relative;
          z-index: 1;
          .user {
            display: flex;
            align-items: center;
            height: 22px;
            img {
              width: 26px;
              height: 26px;
              border-radius: 50%;
              border: 2px solid #cee2ff;
              z-index: 1;
              position: relative;
            }
            p {
              padding: 0 5px 0 15px;
              border-radius: 0 50px 50px 0;
              white-space: nowrap;
              background-color: #e6f0ff;
              z-index: 0;
              margin-left: -10px;
              font-size: 14px;
            }
          }
          span {
            font-size: 14px;
            padding-left: 5px;
          }
        }
        .present_course_title {
          padding-bottom: 30px;
          text-align: center;
          position: relative;
          .main_title {
            font-weight: 700;
            font-size: 22px;
            color: #fda634;
            span {
              font-size: 16px;
            }
          }
        }
        .present_course_count_down {
          padding-bottom: 15px;
          display: flex;
          justify-content: center;
          white-space: nowrap;
          position: relative;
          .count_down_time {
            span.expired_warn_text {
              padding-right: 10px;
            }
            span.day,
            span.hour,
            span.minus,
            span.seconds {
              display: inline-block;
              background: #4e4e4e;
              border-radius: 4px;
              font-size: 16px;
              min-width: 22px;
              margin: 0 2px;
              padding: 0 3px;
              color: #fff;
            }
          }
        }
      }
    }
    .bottom_course_content {
      .present_course_button {
        background: linear-gradient(to bottom, #fda634, #ee7e06);
        box-shadow: 0 0 2px 2px rgba(253, 166, 52, 0.3);
        display: block;
        border-radius: 50px;
        min-height: 60px;
        padding: 5px 0;
        margin: 20px 20px 15px;
        text-align: center;
        position: relative;
        z-index: 5;
        &::after {
          content: "";
          position: absolute;
          background: url(${icon_double_arrow}) no-repeat right 30px center/24px;
          width: 24px;
          height: 24px;
          right: 0;
          top: 50%;
          margin-top: -12px;
          padding-right: 30px;
        }
        /* animation: scaleDrew 5s ease-in-out infinite; */
        /* @keyframes scaleDrew { */
        /* 定义关键帧、scaleDrew是需要绑定到选择器的关键帧名称 */
        /* 0% {
            transform: scale(0.7);
          }

          25% {
            transform: scale(1);
          }

          50% {
            transform: scale(0.7);
          }

          75% {
            transform: scale(1);
          }
          100% {
            transform: scale(0.7);
          } */
        /* } */
        .price_and_text {
          color: #fff;
          font-weight: 700;
          font-size: 18px;
          span {
            color: #ffec40;
            font-size: 35px;
            line-height: 35px;
            padding-right: 10px;
            i {
              font-size: 16px;
            }
          }
        }
        .ticket_text {
          color: #fff;
          font-size: 13px;
        }
      }
      .fit_grade_box {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 15px;
        position: relative;
        z-index: 5;
        .grade {
          background: url(${icon_right_triangle}) no-repeat left center / 13px;
          padding-left: 20px;
          font-size: 14px;
          color: #fff;
        }
      }
    }
  }
  .present_course_feature {
    margin: 0 10px 20px;
    padding: 0 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.1);
    .course_lists {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px dashed #ff9b05;
      padding: 10px 0;
      .left_content {
        padding-top: 5px;
        img {
          min-width: 69px;
          width: 69px;
          border-radius: 6px;
        }
      }
      .right_content {
        margin-left: 15px;
        .right_top_text {
          display: flex;
          align-items: center;
          .course_type {
            color: #ff9b05;
            border: 1px solid #ff9b05;
            border-radius: 50px;
            height: 18px;
            font-size: 12px;
            line-height: 18px;
            padding: 0 5px;
          }
          span {
            font-weight: 700;
            padding-left: 10px;
            font-size: 16px;
          }
        }
        .right_center_text {
          color: #666;
          font-size: 13px;
          padding: 5px 0;
        }
        .right_bottom_guide {
          background: url(${icon_right}) no-repeat right 4px/9px;
          color: #ff4d2a;
          font-size: 12px;
          display: inline-block;
          padding-right: 12px;
        }
      }
    }
    .course_feature {
      padding: 10px 0;
      .feature_title {
        background-color: #ffe9c9;
        border-radius: 50px 50px 50px 0;
        margin: 10px 0;
        display: inline-block;
        padding: 2px 10px;
      }
      .feature_list_box {
        .feature_list {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding-bottom: 5px;
          .key_word {
            color: #ff9b05;
            font-weight: 700;
            position: relative;
            white-space: nowrap;
            &::after {
              content: "";
              position: absolute;
              height: 12px;
              width: 2px;
              background: #dcdcdc;
              right: -10px;
              top: 50%;
              margin-top: -6px;
            }
          }
          p {
            padding-left: 20px;
            span {
              font-weight: 700;
            }
          }
        }
      }
    }
  }
`;
export const StyledCoursePlaceholder = styled.a`
  img {
    width: 100%;
    padding: 20px 15px;
  }
`;

export const StyledBottomCourseLight = styled.a`
  position: relative;
  z-index: 5;
  padding: 10px 0 11px;
  display: block;
  text-align: center;
  img {
    width: 100%;
  }
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    padding: 0 0 5px;
    img {
      width: 90%;
    }
  }
`;

const ExpiredWarnText = ({ d, h, m, s }) => {
  return (
    <span>
      <span className="expired_warn_text">报名有效期</span>
      {d == "00" ? (
        ""
      ) : (
        <>
          <span className="day">{d}</span>天
        </>
      )}
      <span className="hour">{h}</span>时<span className="minus">{m}</span>分
      <span className="seconds">{s}</span>秒
    </span>
  );
};

const FriendPresentContents = ({
  study_package_activity_customize_url,
  share_user,
  buy_endtime,
  batch_ecoupons_amount,
}) => {
  return (
    <div className="friend_present_contents">
      <div className="top_course_content">
        <div className="title_content">
          <div className="left_xinya_logo">
            <span>心芽学堂</span>
          </div>
          <div className="course_title">数学培优系列</div>
        </div>
        <div className="normal_course_content">
          {share_user && (
            <div className="invite_user">
              <div className="user">
                <img
                  src={`${share_user?.photo_url}?imageView2/1/w/96/h/96`}
                  alt=""
                />
                <p>{share_user?.name}</p>
              </div>
              <span>送您</span>
            </div>
          )}
          <div className="present_course_title">
            <div className="main_title">
              1元学一周
              <span>(正式课内容)</span>
            </div>
          </div>

          <div className="present_course_count_down">
            <CountDownTime
              endTime={new Date(buy_endtime)}
              overText={<ExpiredWarnText d={0} h={0} m={0} s={0} />}
              onEnd={() => {}}
            >
              {({ d, h, m, s }) => {
                return <ExpiredWarnText d={d} h={h} m={m} s={s} />;
              }}
            </CountDownTime>
          </div>
        </div>
      </div>
      <div className="bottom_course_content">
        {/* <a
          className="present_course_button"
          href={study_package_activity_customize_url}
        >
          <div className="price_and_text">开始定制，并体验</div>
          <div className="ticket_text">大约需1分钟</div>
        </a> */}
        <StyledBottomCourseLight href={study_package_activity_customize_url}>
          <img src={button_light} alt="" />
        </StyledBottomCourseLight>
        <div className="fit_grade_box">
          <div className="grade">适合：大班 ~ 二年级</div>
        </div>
      </div>
    </div>
  );
};

const PresentCourseFeature = memo(({ activity }) => {
  return (
    <div className="present_course_feature">
      <a className="course_lists" href={activity?.url}>
        <div className="left_content">
          <img src={`${activity?.recommend_thumb_photo_url}`} alt="" />
        </div>
        <div className="right_content">
          <div className="right_top_text">
            <div className="course_type">{activity?.category_type}</div>
            <span>{activity?.name}</span>
          </div>
          <div className="right_center_text">{activity?.recommend_brief}</div>
          <div className="right_bottom_guide">查看详情</div>
        </div>
      </a>
      <div className="course_feature">
        <div className="feature_title">课程特色</div>
        <div className="feature_list_box">
          <div className="feature_list">
            <div className="key_word">系统完整</div>
            <p>
              每个年龄阶段<span>思维重点</span>，尽在其中
            </p>
          </div>
          <div className="feature_list">
            <div className="key_word">学练结合</div>
            <p>
              <span>在线练习+PDF补充练习</span>，即时巩固
            </p>
          </div>
          <div className="feature_list">
            <div className="key_word">短时高效</div>
            <p>
              <span>每天15-30分钟</span>，讲解细致不拖沓
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ({ ...props }) => {
  console.log("props: ", props);
  return (
    <ErrorBoundary>
      <StyledFriendPresentCoureContainer>
        <FriendPresentContents {...props} />
        {/* <PresentCourseFeature {...props} /> */}
        <StyledCoursePlaceholder href={props.activity?.url}>
          <img src={course_placeholder} alt="" />
        </StyledCoursePlaceholder>
      </StyledFriendPresentCoureContainer>
    </ErrorBoundary>
  );
};
