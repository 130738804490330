import React from "react";
import PropTypes from "prop-types";

const maskStyle = {
  background: "rgba(0, 0, 0, 0.65)",
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 990,
  padding: "0px 0px",
  overflowY: "scroll",
};

const ModalMask = ({ children, className }) => {
  // 注：children.props?.className....这个处理是为了解决弹框层级被遮盖的问题
  return (
    <div
      className={`modal_mask ${
        children.props?.className == "discount_course_list_dialog"
          ? "high_z_index"
          : ""
      } ${className}`}
      style={maskStyle}
    >
      {children}
    </div>
  );
};

ModalMask.propType = {
  children: PropTypes.node,
};

export default ModalMask;
