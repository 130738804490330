import React from "react";
import ReactDOM from "react-dom";
import QRCode from "qrcode";
import PropTypes from "prop-types";
import $ from "jquery";
import Raven from "raven-js";
import ComponentProxy from "../core/ComponentProxy";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
// import CountDownTime from "../utils/CountDown";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { promisifiedLoadImage } from "../../components/utils/helpers.js";
import CountDownTime from "../utils/CountDown";
import { format as formatTime } from "../utils/formatAudioTime.js";

import "./SharePoster.scss";
import UserAgent from "../utils/UserAgent";

export class SharePoster extends React.Component {
  constructor(props) {
    super(props);
    console.log("1234567SharePoster", props);
    this.state = {
      rewardReventCheatStatus: false,
    };
  }

  handleCountdownEnd = (e) => {};

  componentDidMount() {
    const reward_revent_cheat = document.getElementById("reward_revent_cheat");
    if (reward_revent_cheat) {
      this.setState({
        rewardReventCheatStatus: true,
      });
    }
  }

  handleImgTransition = () => {
    let imgTransionElement = document.getElementById("_img_transition");
    if (imgTransionElement) {
      this.setState({
        imgTransionHeight: imgTransionElement.offsetHeight,
      });
    }
  };

  renderHalfPricePromotion() {
    if (this.props.half_price_ecoupon_promotion) {
      return <div className="half_price_ecoupon_promotion">限时活动：邀请新用户报名，双方各得<span className="big-text">半价券</span>一张</div>;
    } else {
      return "";
    }
  };

  render() {
    const { rewardReventCheatStatus, imgTransionHeight } = this.state;
    const end_time = this.props.shopping_bargain?.end_time;
    const bargain_duration_hours = this.props.shopping_bargain
      ?.bargain_duration_hours;
    const money_per_user = this.props.shopping_bargain?.money_per_user;
    const activity = this.props.shopping_bargain?.activity;

    return (
      <div className="comp-share-poster" id="comp-share-poster">
        <div className="share-poster-box">
          {this.props.free === "complete_free" ? (
            <div className="free_enter">
              <p>长按识别二维码报名</p>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free === "assist_free" ? (
            <div className="assist-free">
              <p>
                邀请
                <strong>2位好友</strong>
                扫描你的海报（无需关注公众号）
              </p>
              <p>即可免费领取</p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，或发送给朋友
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free === "virtual_count_not_enough" ? (
            <div className="assist-free">
              <p>当前奖学金余额不足</p>
              <p>
                每邀请
                <strong>1位</strong>
                新用户关注公众号, 可得<strong>5元</strong>奖学金
              </p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，或发送给朋友
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free === "inviting_friend_for_money" ? (
            <div className="assist-free">
              <p>
                每邀请
                <strong>1位好友</strong>
                关注公众号，可得5元奖学金
              </p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，或发送给朋友
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free === "invited_for_free_exam" ? (
            <div className="assist-free">
              <p>
                只需邀请
                <strong>{this.props.maxInviteUsersCount}位新用户</strong>
                扫描海报 + 关注公众号
              </p>
              <p>即可获得足够奖学金，免费评测</p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，或发送给朋友
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free === "shared_for_analysis" ? (
            <div className="assist-free">
              <p>
                测评做完感觉还不错？
                <strong>分享</strong>
                给朋友吧~
              </p>
              <p>
                每邀请<strong>{this.props.maxInviteUsersCount}</strong>
                位新用户关注公众号，可得<strong>5元</strong>奖学金
              </p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，或发送给朋友
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          ) : (
            ""
          )}

          {this.props.free == "trial_coupon_free" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p>长按上图保存图片，或发送给朋友</p>
            </div>
          ) : (
            ""
          )}

          {this.props.free == "normal" && (
            <div>
              <div className="text_container" id="_text_container">
                {this.props.textDescribe ? (
                  <div className="shopping_group_stroke">
                    <div className="group_common remaining_count promotion_invite">
                      每邀请1位新用户关注公众号，可得<strong>5元</strong>奖学金
                    </div>
                  </div>
                ) : (
                  <div className="shopping_group_stroke">
                    {this.props.targetType == "PromotionActivity" ||
                    this.props.targetType == "Experience" ? (
                      <div className="group_common remaining_count promotion_invite">
                        最多邀请
                        <strong>{this.props.maxInviteUsersCount}位</strong>
                        新用户扫描你的海报，关注公众号
                        <br />
                        即可获得足够奖学金，<strong>0元报名</strong>
                      </div>
                    ) : (
                      <div className="group_common remaining_count promotion_invite">
                        每邀请【3】人关注公众号，奖励1点
                        <br />
                        1点 = 1个讲座/圆桌（少数例外）
                      </div>
                    )}
                  </div>
                )}
                <div className="save_poster">
                  <strong>长按</strong>保存海报，或发送给朋友
                </div>
              </div>
              <ImgRealetive
                rewardReventCheatStatus={rewardReventCheatStatus}
                transition={true}
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
                handleImgTransition={this.handleImgTransition}
              />
              <div
                className="reward_revent_cheat"
                id="reward_revent_cheat"
                style={{
                  paddingTop: `${imgTransionHeight + 10}px`,
                }}
              >
                <p style={{ textAlign: "center" }}>
                  奖学金获取诚信为主，<span>不可作弊：</span>
                </p>
                <p>
                  1. 若邀请的朋友关注后取关，将<span>扣除</span>相应的奖学金
                </p>
                <p>
                  2. 若取关的朋友过多，您将<span>无权</span>再参与其他活动{" "}
                </p>
              </div>
            </div>
          )}

          {this.props.free == "study_package" &&
            // 拼团补差价的情况下, 邀请好友按钮生成的海报
            (this.props.sale_mode == "shopping_group" ? (
              <div>
                {this.props.bonus > 0 && (
                  <div
                    className="shopping_group_stroke"
                    style={{
                      paddingTop:
                        (this.props.remaining_shopping_group_count === 0 ||
                          !this.props.remaining_shopping_group_count) &&
                        "50px",
                    }}
                  >
                    {this.props.remaining_shopping_group_count > 0 && [
                      <div
                        className="group_common remaining_count"
                        key={"remaining_count"}
                      >
                        仅差
                        <strong>{`${this.props.remaining_shopping_group_count}人`}</strong>
                        成团！
                      </div>,
                      <div
                        className="group_common anyone_group"
                        key={"anyone_group"}
                      >
                        本课程<strong>任一级别</strong>，都可拼团
                      </div>,
                    ]}

                    <span className="group_common">
                      每邀一位“非心芽课程用户”报名，可得
                      <strong>{this.props.bonus}元</strong>
                      {this.props.bonus_type === 0 ? "现金奖励！" : "奖学金！"}
                    </span>
                  </div>
                )}
                <div className="save_poster">
                  长按保存海报，邀朋友组团学习吧
                </div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
              </div>
            ) : (
              <div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
                <p>长按上图发送给朋友</p>
                {this.props.bonus > 0 && (
                  <p>
                    {this.props.has_teaching_aid_package
                      ? `每邀请一位新朋友报名成功且不退款，可得￥${this.props.bonus}奖学金`
                      : `每邀请一位新朋友报名成功且不退款，奖励${this.props.bonus}元！`}
                    <br />
                    (新朋友：未购买过任何心芽课程的用户)
                  </p>
                )}
              </div>
            ))}
          {this.props.free == "invite_draw_cash_or_share_friend" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p>
                {this.props.bonus > 0 ? (
                  ""
                ) : (
                  <span style={{ textAlign: "center" }}>
                    长按图片直接发送给朋友，或保存图片再发到朋友圈
                  </span>
                )}
                {this.props.bonus > 0 ? (
                  <span style={{ textAlign: "left" }}>
                    <i>1.</i>
                    <span>
                      长按图片直接发送给朋友，或保存图片再发到朋友圈；
                    </span>
                    <i>2.</i>
                    <span>
                      每邀请1位“非心芽课程用户“成功报名且不退款，可获得
                      {this.props.bonus}元现金奖励；
                    </span>
                    <i>3.</i>
                    <span>奖励金将于开课7天后通过『心芽学堂』公众号发送。</span>
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>
          ) : (
            ""
          )}

          {
            // 拼团砍价海报处理
            this.props.sale_mode == "shopping_group_bargain" &&
            this.props.free == "new_shopping_invite_friend_poster" ? (
              <div>
                <div
                  className="save_poster"
                  style={{
                    color: "#f7d519",
                  }}
                >
                  长按保存海报， 邀请朋友来砍价吧
                </div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
                <div
                  className="count_down_box"
                  style={{ textAlign: "left", whiteSpace: "initial" }}
                >
                  <CountDownTime
                    endTime={
                      new Date(end_time ? end_time : this.props.buy_endtime)
                    }
                    overText="邀请已结束"
                    onEnd={this.handleCountdownEnd}
                  >
                    {({ d, h, m, s }) => {
                      return (
                        <>
                          <span style={{ display: "flex" }}>
                            <i>1.&nbsp;</i>
                            <span>
                              {(new Date(
                                end_time ? end_time : this.props.buy_endtime
                              ).getTime() -
                                new Date(this.props.system_time).getTime()) /
                                1000 /
                                60 /
                                60 >
                              bargain_duration_hours
                                ? bargain_duration_hours
                                : ~~h}
                              小时内，每邀请1位新朋友关注『心芽学堂』公众号即可减
                              {money_per_user}
                              元，最高可减
                              {activity.price - activity.lowest_price}
                              元；
                            </span>
                          </span>
                          <span
                            style={{
                              display: "flex",
                            }}
                          >
                            <i>2.&nbsp;</i>
                            <span style={{ fontSize: "12px" }}>
                              获得的优惠金额仅可用于报名
                              {this.props.product_code_arr}
                              中的任意1个课程，有效期截止
                              {formatTime(
                                this.props.buy_endtime,
                                "YYYY.MM.DD HH:mm"
                              )}
                              ，超出时间未使用将被清零。
                            </span>
                          </span>
                        </>
                      );
                    }}
                  </CountDownTime>
                </div>
              </div>
            ) : (
              ""
            )
          }

          {this.props.free == "invite_draw_cash" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              {
                <p>
                  长按图片直接发送给朋友，或保存图片再发到朋友圈；
                  <br />
                  邀请{this.props.invite_count_required}
                  位新朋友关注『心芽学堂』公众号即可免费报名
                </p>
              }
            </div>
          ) : (
            ""
          )}

          {this.props.free == "bargain_activity" ||
          this.props.free == "training_camp_activity" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p>长按上图保存图片，或发送给朋友</p>
            </div>
          ) : (
            ""
          )}

          {this.props.free == "more_than_bargain_activity_hour" ||
          this.props.free == "less_than_bargain_activity_hour" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p>长按上图保存图片，或发送给朋友</p>
              <div className="count_down_box">
                <CountDownTime
                  endTime={new Date(this.props.buy_endtime)}
                  overText="邀请已结束"
                  onEnd={this.handleCountdownEnd}
                >
                  {({ d, h, m, s }) => {
                    return (new Date(this.props.buy_endtime).getTime() -
                      new Date(this.props.system_time).getTime()) /
                      1000 /
                      60 /
                      60 >
                      this.props.bargain_duration_hours ? (
                      <div>
                        {this.props.bargain_duration_hours}
                        小时内，每邀请1位
                        <span style={{ color: "#f1d64c" }}>新朋友</span>
                        关注『心芽学堂』减
                        {this.props.inviteRewardPrice}元，
                        <div>
                          最高可减至
                          <span style={{ color: "#f1d64c" }}>
                            {this.props.activity?.lowest_price}元
                          </span>
                          购买！
                        </div>
                      </div>
                    ) : (
                      <div>
                        {~~h}小时内，每邀请1位
                        <span style={{ color: "#f1d64c" }}>新朋友</span>
                        关注『心芽学堂』减
                        {this.props.inviteRewardPrice}元，
                        <div>
                          最高可减至
                          <span style={{ color: "#f1d64c" }}>
                            {this.props.activity?.lowest_price}元
                          </span>
                          购买！
                        </div>
                      </div>
                    );
                  }}
                </CountDownTime>
              </div>
            </div>
          ) : (
            ""
          )}

          {this.props.free == "buy_bargain_text" && (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p>长按上图保存图片，或发送给朋友</p>
              <div className="count_down_box">
                <span>
                  {`每邀请1位朋友成功报名《${this.props.buyBargainActivityName}》即减${this.props.moneyPerUser}元，最高可0元领！`}
                </span>
              </div>
            </div>
          )}

          {this.props.free == "invite_collect_star" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p style={{ padding: "0 10px" }}>长按保存图片</p>
              <p style={{ padding: "0 10px" }}>
                发到朋友圈或分享给好友/群，邀请朋友为{this.props.nickName}
                送小星星
              </p>
              <a
                style={{
                  color: "#fff",
                  fontSize: "12px",
                  fontWeight: "300",
                  textDecoration: "underline",
                  display: "block",
                }}
                href={this.props.ruleUrl}
                target="_blank"
              >
                集星规则 »
              </a>
            </div>
          ) : (
            ""
          )}
          {
            // 每日学习报告，打卡海报及文案
            this.props.free == "invite_reward_money" && (
              <div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
                <p style={{ padding: "0" }}>保存图片并发到朋友圈完成打卡</p>
                <p style={{ padding: "0" }}>连续打卡33天，奖励现金330元！</p>
                <a
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    fontWeight: "300",
                    textDecoration: "underline",
                    display: "block",
                  }}
                  href={this.props.reportCardRuleUrl}
                  target="_blank"
                >
                  详细规则 »
                </a>
              </div>
            )
          }

          {this.props.free == "invite_card_collect_star" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p style={{ padding: "0 10px" }}>发到朋友圈或分享给好友/群</p>
              <p style={{ padding: "0 10px" }}>
                邀请朋友为{this.props.nickName}送上鼓励小星星
              </p>
            </div>
          ) : (
            ""
          )}
          {this.props.free == "poetry_share" ? (
            <div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
              <p style={{ padding: "0 10px" }}>长按保存图片</p>
              <p style={{ padding: "0 10px" }}>发到朋友圈或分享给好友/群</p>
            </div>
          ) : (
            ""
          )}

          {
            // 集赞免费领
            this.props.free == "collect_zan_free" && (
              <div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
                <p
                  style={{
                    padding: "5px 0px",
                    position: "absolute",
                    transform: "translateX(-50%)",
                    left: "50%",
                    minWidth: "210px",
                  }}
                >
                  {
                    <span style={{ textAlign: "left" }}>
                      <i>1.</i>
                      <span>长按保存海报图片，分享到朋友圈</span>
                      <i>2.</i>
                      <span>集满30个赞，截图发送给老师审核</span>
                      <i>3.</i>
                      <span>免费领取</span>
                    </span>
                  }
                </p>
              </div>
            )
          }

          {this.props.free === "in_or_open_shopping_group" && (
            <div className="in_or_open_shopping_group">
              {this.props.sale_mode == "invitation_fission" ? (
                // 邀请裂变下 补差价模式下的海报文案
                <p>
                  再<strong>{`赠送${this.props.remaingInviteNumber}`}人</strong>
                  ，即无需补差价
                </p>
              ) : (
                <>
                  <p>
                    仅差
                    <strong>
                      {this.props.remaining_shopping_group_count}人
                    </strong>
                    成团
                  </p>
                  {!this.props.singleCourse && (
                    <p>
                      朋友可选该系列<strong>任何级别</strong>课程拼团
                    </p>
                  )}
                </>
              )}
              {this.props.sale_mode == "shopping_group_new_member_only" &&
                this.props.bonus > 0 && (
                  <p className="shopping_rule_guide">
                    每邀请一位新用户报名可得
                    <strong>{this.props.bonus}元</strong>
                    {this.props.bonus_type === 0 ? "现金" : "奖学金"}奖励
                  </p>
                )}
              {new Date(this.props.end_time) > new Date() && (
                <div
                  style={{ paddingTop: 18, marginBottom: -12, fontSize: 13 }}
                >
                  <CountDownTime
                    endTime={new Date(this.props.end_time)}
                    overText="活动已结束"
                    onEnd={this.handleCountdownEnd}
                  >
                    {({ d, h, m, s }) => {
                      return (
                        <span style={{ color: "#fff" }}>
                          {/* 距离报名截止：{`${d == "00" ? "" : `${d}天`}`} {h}:{m}:{s} */}
                          拼团<strong>有效期</strong>：{`${d}天`} {h}:{m}:{s}
                        </span>
                      );
                    }}
                  </CountDownTime>
                </div>
              )}
              {this.renderHalfPricePromotion()}
              <div className="save_poster">
                <strong>长按</strong>保存海报，邀朋友组团学习吧
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          )}

          {this.props.free === "none_shopping_group" && (
            <div className="none_shopping_group">
              {this.props.half_price_ecoupon_promotion ? this.renderHalfPricePromotion()
                :
                <>
                  <p>
                    每邀请1位新用户报名，可得
                    <strong>{`${this.props.bonus}元`}</strong>
                    {this.props.bonus_type === 0 ? "现金" : "奖学金"}
                  </p>
                  {this.props.sale_mode != "shopping_group_new_member_only" && (
                    <div className="shopping_rule_guide">
                      拼团过程中的邀请报名不计入奖励
                    </div>
                  )}
                </>
              }
              <div className="save_poster">
                <strong>长按</strong>保存海报，邀朋友组团学习吧
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          )}
          {
            // TODO: 数学培优课海报弹框
            this.props.free === "is_customize_course" && (
              <div className="none_shopping_group">
                <p>
                  每邀请1位新用户报名正价课，可得
                  <strong>{`${this.props.bonus}元`}</strong>
                  {this.props.bonus_type === 0 ? "现金" : "奖学金"}
                </p>
                <div className="save_poster">
                  <strong>长按</strong>保存海报，邀朋友组团学习吧
                </div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
              </div>
            )
          }
          {
            // "邀请裂变"模式下浮动按钮"邀请有奖"
            this.props.free === "invitation_fission_group" && (
              <div className="none_shopping_group">
                <p>
                  每邀请1位新用户报名正价课，可得
                  <strong>{`${this.props.bonus}元`}</strong>
                  {this.props.bonus_type === 0 ? "现金" : "奖学金"}
                </p>
                <div className="save_poster">
                  <strong>长按</strong>保存海报，邀朋友组团学习吧
                </div>
                <ImgRealetive
                  imgSrc={this.props.imgSrc}
                  render={() => <BtnClose onClose={this.props.onClose} />}
                />
              </div>
            )
          }
          {this.props.free === "invite_mode_invite_friend" && (
            <div className="none_shopping_group">
              <p>
                邀请<strong>{this.props.invite_count_required}位</strong>
                新朋友扫描海报+关注公众号
              </p>
              <p>即可免费报名</p>
              <div className="save_poster">
                <strong>长按</strong>保存海报，邀朋友组团学习吧
              </div>
              <ImgRealetive
                imgSrc={this.props.imgSrc}
                render={() => <BtnClose onClose={this.props.onClose} />}
              />
            </div>
          )}
        </div>
        {/* <div className="btn-close" onClick={this.props.onClose}></div> */}
      </div>
    );
  }
}

const ImgRealetive = ({
  transition,
  imgSrc,
  render,
  rewardReventCheatStatus,
  handleImgTransition = () => {},
}) => {
  // 需要海报动画退出的, 传递 transition: true 过来, 否则不传
  console.log("transition: ", transition);
  return (
    <div className="img_realetive" id="_img_realetive">
      <img
        onLoad={handleImgTransition}
        src={imgSrc}
        id={transition ? "_img_transition" : null}
        className={rewardReventCheatStatus ? "revent_cheat_img_style" : ""}
      />
      {render()}
    </div>
  );
};

const BtnClose = ({ onClose }) => {
  return <div className="btn-close" id="_btn_close" onClick={onClose}></div>;
};

SharePoster.propTypes = {
  free: PropTypes.string.isRequired,
};

export class SharePosterProxy extends ComponentProxy {
  constructor(options) {
    super();
    console.log("1234567SharePosterProxy", options);
    this.isNewPoetry = options.isNewPoetry;
    this.posterTemplate = options.posterTemplate;
    this.posterQRCode = options.posterQRCode;
    this.posterCreation = options.posterCreation;
    this.mode = options.mode;
    this.posterUrl = options.posterUrl;
    this.posterImgSrc = options.posterImgSrc;
    this.free = options.free;
    this.invite_count_required = options.invite_count_required;
    this.bonus = options.bonus;
    this.sale_mode = options.sale_mode;
    this.promotionMode = options.promotionMode;
    this.textDescribe = options.textDescribe;
    this.bonus_type = options.bonus_type;
    this.avatar_location = options.avatar_location;
    this.targetType = options.targetType;
    this.groupMsg = options.groupMsg;
    this.maxInviteUsersCount = options.maxInviteUsersCount;
    this.remaining_shopping_group_count =
      options.remaining_shopping_group_count;
    this.singleCourse = options.singleCourse;
    this.remaingInviteNumber = options.remaingInviteNumber;
    this.has_teaching_aid_package = options.has_teaching_aid_package;
    this.bargain_duration_hours = options.bargain_duration_hours;
    this.inviteRewardPrice = options.inviteRewardPrice;
    this.activity = options.activity;
    this.end_time = options.end_time;
    this.buy_endtime = options.buy_endtime;
    this.system_time = options.system_time;
    this.moneyPerUser = options.moneyPerUser;
    this.buyBargainActivityName = options.buyBargainActivityName;
    this.nickName = options.nickName;
    this.ruleUrl = options.ruleUrl;
    this.reportCardRuleUrl = options.reportCardRuleUrl;
    this.callback = options.callback;
    this.shopping_bargain = options.shopping_bargain;
    this.product_code_arr = options.product_code_arr;
    this.half_price_ecoupon_promotion = options.half_price_ecoupon_promotion;
  }

  load() {
    // console.log("share_poster groupMsg: ", this.groupMsg)
    // console.log("share_poster maxInviteUsersCount: ", this.maxInviteUsersCount)
    // console.log("share_poster targetType: ", this.targetType)
    // console.log("share_poster free: ", this.free)
    this.toast = ToastManager.showLoading("加载中...");
    $.ajax({
      url: this.posterUrl,
      // TODO change to GET
      method: "POST",
      headers: csrfHeaders,
      complete: () => this.toast.cancel(),
      success: (data) => {
        if (data.err_code) {
          switch (data.err_code) {
            case "err_unauthenticated":
              new AuthenticationDialogProxy(data.redirect_url).open();
              break;
          }
          return;
        }

        if (data.errno) {
          alert("产生图片失败，请重新尝试");
          return;
        }

        this.posterImgSrc = data.url;
        super.open();
        if (this.callback) {
          this.callback();
        }
      },
    });
    // request({
    //   url: this.posterUrl,
    //   method: 'POST',
    //   headers: csrfHeaders
    // }).then({

    // })
  }

  loadUserPhoto = () => {
    return new Promise((resolve, reject) => {
      request({
        url: this.posterCreation,
        method: "GET",
        headers: csrfHeaders,
      })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err));
    });
  };

  putb64 = (picBase, token, openPoster, failToOpenPoster) => {
    picBase = picBase.substring(22);
    function fileSize(str) {
      var fileSize;
      if (str.indexOf("=") > 0) {
        var indexOf = str.indexOf("=");
        str = str.substring(0, indexOf);
      }

      fileSize = parseInt(str.length - (str.length / 8) * 2);
      return fileSize;
    }

    let url = "https://upload.qiniup.com/putb64/" + fileSize(picBase);
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
      if (xhr.readyState == 4) {
        if ((xhr.status >= 200 && xhr.status < 300) || xhr.status == 304) {
          let res = xhr.responseText;
          console.log(JSON.parse(res).url + "?imageView2/0/format/png");
          openPoster(JSON.parse(res).url + "?imageView2/0/format/png");
        } else {
          console.log(xhr.status, xhr.statusText);
          failToOpenPoster(xhr.status);
        }
      }
    };

    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "image/png");
    xhr.setRequestHeader("Authorization", "UpToken " + token);
    xhr.send(picBase);
  };

  uploadPoster = (b64, openPoster, failToOpenPoster, qiuNiuToken) => {
    return this.putb64(b64, qiuNiuToken, openPoster, failToOpenPoster);
  };

  makePoster = (images, qiuNiuToken) => {
    return new Promise((resolve, reject) => {
      this.drawCanvas(images)
        .then((canvas) => this.convertCanvasToImage(canvas, qiuNiuToken))
        .then(() => resolve())
        .catch((err) => reject(err));
    });
  };

  drawCanvas = (images) => {
    let template = images[0];
    let userPhoto = images[1];
    let posterQRCode = images[2];
    return new Promise((resolve, reject) => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      ctx.globalCompositeOperation = "source-atop";

      canvas.width = template.width;
      canvas.height = template.height;
      ctx.drawImage(template, 0, 0, canvas.width, canvas.height);
      ctx.save();
      console.log("sharePoster avatar_location : ", this.avatar_location);
      if (this.avatar_location == "left") {
        if (userPhoto) {
          let circleR = 53; //半径
          let circleX = 105 / 2 + 36; //圆心X坐标
          let circleY = 105 / 2 + 21; //圆心Y坐标
          let imgX = circleX - circleR; //图片X开始坐标
          let imgY = circleY - circleR; //图片Y开始坐标
          let imgWidth = 2 * circleR; //图片按圆形大小
          ctx.arc(circleX, circleY, circleR, 0, 2 * Math.PI);
          ctx.clip();
          ctx.drawImage(userPhoto, imgX, imgY, imgWidth, imgWidth);
          ctx.restore();
          ctx.save();
        }
      } else {
        if (userPhoto) {
          let circleR = 64; //半径
          let circleX = canvas.width / 2; //圆心X坐标
          let circleY = 150; //圆心Y坐标
          let imgX = circleX - circleR; //图片X开始坐标
          let imgY = circleY - circleR; //图片Y开始坐标
          let imgWidth = 2 * circleR; //图片按圆形大小
          ctx.arc(circleX, circleY, circleR, 0, 2 * Math.PI);
          ctx.clip();
          ctx.drawImage(userPhoto, imgX, imgY, imgWidth, imgWidth);
          ctx.restore();
          ctx.save();
        }
      }

      if (posterQRCode) {
        if (this.isNewPoetry) {
          ctx.fillStyle = "black";
          ctx.font = "bold 36px Arial";
          ctx.fillText(this.nickName, 106, 1540);
          ctx.clearRect(572, 1530, 180, 180);
          ctx.drawImage(posterQRCode, 572, 1530, 180, 180);
        } else {
          ctx.clearRect(510, 1110, 180, 180);
          ctx.drawImage(posterQRCode, 510, 1110, 180, 180);
        }
        ctx.restore();
      }

      resolve(canvas);
    });
  };

  convertCanvasToImage(canvas, qiuNiuToken) {
    return new Promise((resolve, reject) => {
      let imageBase64 = canvas.toDataURL("image/png");
      let openPoster = (src) => {
        if (UserAgent.isAndroid()) {
          this.posterImgSrc = src;
          super.open();
          resolve();
        }
      };
      let failToOpenPoster = (err) => {
        // 如果是安卓app 需要reject
        if (UserAgent.isAndroid()) {
          reject(err);
        }
      };

      // 非安卓APP 直接用base64打开poster，并行上传海报
      if (!UserAgent.isAndroid()) {
        this.posterImgSrc = imageBase64;
        super.open();
        resolve();
      }

      this.uploadPoster(imageBase64, openPoster, failToOpenPoster, qiuNiuToken);
    });
  }

  loadImages = (imagesSrcArr) => {
    if (!Array.isArray(imagesSrcArr)) {
      throw new Error("promisifiedLoadImage: must pass Array instance!");
    }
    function promiseFactory(imageSrc, index) {
      let loadedImage = new Image();
      loadedImage.setAttribute("crossOrigin", "Anonymous");
      return new Promise((resolve, reject) => {
        loadedImage.onload = () => {
          // console.log(index)
          resolve(loadedImage);
        };
        loadedImage.onerror = () => {
          reject(loadedImage);
        };
        loadedImage.src = imageSrc;
      });
    }
    return Promise.all(
      imagesSrcArr.map((src, index) => {
        if (src) {
          return promiseFactory(src, index);
        } else {
          return null;
        }
      })
    );
  };

  open(
    ops = {
      isShowToast: true,
    }
  ) {
    if (
      typeof this.posterImgSrc === "string" &&
      this.posterImgSrc.trim().length > 0
    ) {
      super.open();
      if (this.callback) {
        this.callback();
      }
    } else {
      if (this.mode && this.mode === "front_end") {
        if (ops.isShowToast) {
          this.toast = ToastManager.showLoading("加载中...");
        }
        console.log("front_end avatar_location", this.avatar_location);
        console.time("poster");
        return (
          this.loadUserPhoto()
            // .catch(() => ({}))
            .then((res) => {
              console.log(res);
              // 已经有海报数据
              if (res.url) {
                this.posterImgSrc = res.url + "?imageView2/0/format/png";
                super.open();
              } else {
                const {
                  front_end_generate_qrcode: isFrontEndMakeQR,
                  user_photo_url: userPhotoUrl,
                  qiuniu_token: qiuNiuToken,
                  user_qrcode_photo: userQRCode,
                  share_url: shareUrl,
                } = res;
                let generateQRImage;

                if (isFrontEndMakeQR) {
                  generateQRImage = QRCode.toDataURL(shareUrl, {
                    width: 180,
                  });
                } else {
                  generateQRImage = Promise.resolve(userQRCode);
                }

                // let posterQRCode =
                //   "https://upload.fireflybaby.cn/cc_info/87159a454454f1eb624522d5a5b49bf7.jpeg";
                // let template =
                //   "https://upload.fireflybaby.cn/study_package_activity/d2a0dd7de8cda83b8114d946129e5c19.png";
                return generateQRImage.then(
                  (qrCode) =>
                    new Promise((resolve, reject) => {
                      console.log("qrCode", qrCode);
                      this.loadImages([
                        this.posterTemplate,
                        userPhotoUrl,
                        qrCode,
                      ])
                        .then((res) => this.makePoster(res, qiuNiuToken))
                        .then(resolve, reject);
                    })
                );
              }
            })
            .then(() => {
              console.timeEnd("poster");
              this.toast && this.toast.cancel();
            })
            .catch((err) => {
              // alert(err)
              console.log(err);
              Raven.captureException(err);
              this.toast && this.toast.cancel();
              console.timeEnd("poster");
              alert("产生图片失败，请重新尝试");
            })
        );
      } else {
        this.load();
      }
    }
  }

  createComponent() {
    return (
      <SharePoster
        mode={this.mode}
        getUserPhotoUrl={this.getUserPhotoUrl}
        imgSrc={this.posterImgSrc}
        bonus={this.bonus}
        free={this.free}
        sale_mode={this.sale_mode}
        promotionMode={this.promotionMode}
        textDescribe={this.textDescribe}
        bonus_type={this.bonus_type}
        avatar_location={this.avatar_location}
        targetType={this.targetType}
        groupMsg={this.groupMsg}
        maxInviteUsersCount={this.maxInviteUsersCount}
        remaining_shopping_group_count={this.remaining_shopping_group_count}
        singleCourse={this.singleCourse}
        remaingInviteNumber={this.remaingInviteNumber}
        has_teaching_aid_package={this.has_teaching_aid_package}
        invite_count_required={this.invite_count_required}
        bargain_duration_hours={this.bargain_duration_hours}
        inviteRewardPrice={this.inviteRewardPrice}
        activity={this.activity}
        end_time={this.end_time}
        buy_endtime={this.buy_endtime}
        system_time={this.system_time}
        moneyPerUser={this.moneyPerUser}
        buyBargainActivityName={this.buyBargainActivityName}
        nickName={this.nickName}
        ruleUrl={this.ruleUrl}
        reportCardRuleUrl={this.reportCardRuleUrl}
        shopping_bargain={this.shopping_bargain}
        product_code_arr={this.product_code_arr}
        half_price_ecoupon_promotion={this.half_price_ecoupon_promotion}
        onClose={() => {
          this.cancel();
        }}
      />
    );
  }
}
