import React from "react";
import Raven from "raven-js";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { debounce } from "../utils/helpers.js";
import "./PoetryAbsenteeInputTimeDialog.scss";

const PoetryAbsenteeInputTimeDialog = ({ activity, onDialogClose }) => {
  const ajxPost = () => {
    if (activity.poetry_start_time_select_url) {
      const toast = ToastManager.showLoading("提交中...");
      request({
        url: activity.poetry_start_time_select_url,
        method: "POST",
        headers: csrfHeaders,
      })
        .then(() => {
          toast.cancel();
          window.location.reload(true);
        })
        .catch((e) => {
          toast.cancel();
          Raven.captureException(e);
          window.location.reload(true);
        });
    }
  };
  return (
    <InnerModal>
      <ModalMask>
        <div className="comp_absentee_input_time_dialog">
          <div className="on_close" onClick={onDialogClose}>
            &times;
          </div>
          <p className="title">选择上课时间</p>
          <p className="title_desc">确定选择之后，课程将于明天开课</p>
          <ul className="poetry_courses">
            <li className="poetry_course_item hight_light">
              <p>{activity.name}</p>
              <p>明天开课</p>
            </li>
          </ul>
          <div className="input_btn_group">
            <div className="input_cancel" onClick={onDialogClose}>
              取消
            </div>
            <div className="input_confirm" onClick={debounce(ajxPost, 200)}>
              确定
            </div>
          </div>
        </div>
      </ModalMask>
    </InnerModal>
  );
};

export default PoetryAbsenteeInputTimeDialog;
