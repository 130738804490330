import React from "react";
import styled from "styled-components";
import ModalManager from "../utils/Modal/ModalManage";
import ModalMask from "../utils/Modal/ModalMask";
import CountDownTime from "../utils/CountDown";
import { media } from "../utils/helpers";
import discountSuc from "banner_pass.png";
import discountFail from "banner_fail.png";
import modalClose from "new_experience/icon_close.png";
import tipsBg from "tips_bg.png";
// import qrCode from "qrcode_xinyaxuetang.jpg";

const ModalClose = styled.div`
  position: absolute;
  right: -15px;
  width: 36px;
  height: 36px;
  text-align: center;
  top: -15px;
  background: url(${modalClose}) no-repeat 100% 0/36px;
  border-radius: 50px;
  z-index: 10000;
`;

const QRCodeImage = styled.img`
  width: 170px;
  height: 170px;
  margin: 0 auto;
  margin-top: 15px;
  display: block;
  ${media.miniPhone`width: 128px;height: 128px;`}
`;

const ModalContentContainer = styled.div`
  position: absolute;
  top: 20vh;
  margin: 0 20px;
  background: white;
  width: calc(100% - 40px);
  border-radius: 6px;
  /* background-image: url(${discountSuc});
  background-size: 100% auto;
  background-position: 0 0;
  background-repeat: no-repeat; */
  /* overflow: hidden; */
  font-size: 0;
  ${media.aboveTablet`
      max-width: 400px; 
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
    `}

`;

const Image = styled.img`
  width: 100%;
  border-radius: 6px 6px 0 0;
`;

const HitWrapper = styled.div`
  padding: 12px;
  min-height: 66px;
  display: flex;
  align-items: center;
  flex-wrap: ${({ isWrap }) => (isWrap ? "wrap" : "nowrap")};
`;

const UserAvatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  min-width: 50px;
  min-height: 50px;
  margin-right: 12px;
`;

const HitTexts = styled.div`
  display: inline-block;
`;

const Text = styled.div`
  font-size: 14px;
  ${({ isCenter }) =>
    isCenter && {
      textAlign: "center",
      width: "100%",
    }}
  strong {
    color: red;
  }
`;

const BolderText = styled(Text)`
  font-size: 16px;
  font-weight: bold;
  ${media.miniPhone`font-size: 14px;`}
`;

const TipText = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

const FollowModalContent = styled.div`
  padding: 15px;
  position: absolute;
  top: 0;
  left: 0;
`;

const UserSayTip = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TextBlock = styled.div`
  position: relative;
  padding: 8px;
  font-size: 14px;
  background: white;
  line-height: 1.3;
  border-radius: 4px;
  box-shadow: 0 0 1.5px 1px rgba(0, 0, 0, 0.1);
  ${media.miniPhone`font-size: 13px;`}
  &::before {
    content: "";
    position: absolute;
    top: 15px;
    left: -5px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-top: 12px solid white;
    z-index: 999;
  }
  strong {
    color: red;
  }
`;

const Timer = styled.div`
  margin-top: 10px;
  text-align: center;
  color: black;
  p {
    color: black;
    font-size: 14px;
  }
  span {
    color: red;
    font-size: 14px;
    padding: 0 4px;
  }
`;

const DiscountSuccessOrError = ({ state, close, userName, amount, avatar }) => {
  const isSuccess = state === "success";
  const isHaveAvatar = state === "success" || state === "timeoutError";
  let errorTip = "";
  if (state === "hasFollowedError") {
    errorTip = (
      <>
        <Text isCenter>你已经是【心芽学堂】的老朋友了</Text>
        <TipText isCenter>
          从未关注过【心芽学堂】公众号的新朋友才能砍价成功
        </TipText>
      </>
    );
  }
  if (state === "timeoutError") {
    errorTip = (
      <>
        <UserAvatar src={avatar} />
        <HitTexts>
          <BolderText>{userName}</BolderText>
          <Text>本次砍价时间已截止，谢谢你的帮忙！一起来报名学习吧！</Text>
        </HitTexts>
      </>
    );
  }
  return (
    <ModalContentContainer>
      <ModalClose onClick={close} />
      <Image src={isSuccess ? discountSuc : discountFail} />
      <HitWrapper isWrap={!isHaveAvatar}>
        {isSuccess ? (
          <>
            <UserAvatar src={avatar} />
            <HitTexts>
              <BolderText>{userName}</BolderText>
              <Text>
                你已成功帮我砍了<strong>{`${amount}元`}</strong>
                ，非常感谢！一起来报名学习吧！
              </Text>
            </HitTexts>
          </>
        ) : (
          errorTip
        )}
      </HitWrapper>
    </ModalContentContainer>
  );
};

const NeedFollow = ({ close, endTime, qrcode, userName, avatar }) => {
  return (
    <ModalContentContainer>
      <ModalClose onClick={close} />
      <Image src={tipsBg} style={{ borderRadius: 6 }} />
      <FollowModalContent>
        <UserSayTip>
          <UserAvatar src={avatar} style={{ marginTop: 4 }} />
          <HitTexts>
            <BolderText>{userName}</BolderText>
            <TextBlock>
              你还需要
              <strong>扫码关注</strong>
              【心芽学堂】公众号，才能帮我砍价成功哦，谢谢！
            </TextBlock>
          </HitTexts>
        </UserSayTip>
        <QRCodeImage src={qrcode} alt="" />
        <Timer>
          <CountDownTime
            endTime={new Date(endTime)}
            overText={<p>本次砍价时间已结束</p>}
            onEnd={() => {}}
          >
            {({ d, h, m, s }) => {
              return (
                <p>
                  还剩<span>{`${h}:${m}:${s}`}</span>砍价结束
                </p>
              );
            }}
          </CountDownTime>
        </Timer>
      </FollowModalContent>
    </ModalContentContainer>
  );
};

const modal = (state, extraProps) => {
  let modalContent = () => {};

  if (
    state === "success" ||
    state === "hasFollowedError" ||
    state === "timeoutError"
  ) {
    modalContent = (close) => (
      <DiscountSuccessOrError state={state} close={close} {...extraProps} />
    );
  } else if (state === "needFollow") {
    modalContent = (close) => <NeedFollow close={close} {...extraProps} />;
  }

  return new ModalManager({
    isCreateModalDynamic: true,
    render: (_, modalClose) => {
      return <ModalMask>{modalContent(modalClose)}</ModalMask>;
    },
  });
};

export const discountModal = (state, extraProps) => {
  if (state === 0) {
    return modal("needFollow", extraProps);
  } else if (state === 1) {
    return modal("success", extraProps);
  } else if (state === 2) {
    return modal("hasFollowedError", extraProps);
  } else if (state === 3) {
    return modal("timeoutError", extraProps);
  }
};
