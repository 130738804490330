import React, { Component } from "react";
import { request, csrfHeaders } from "../utils/request";
import { reloadPage } from "../utils/helpers";
import { ToptipsManager } from "../utils/Toptips";

import "./city_area/CityArea.scss";

export default class CitySelectList extends Component {
  constructor(props) {
    super(props);
    console.log(props);

    let defa_pro = -1;
    let defa_city = -1;

    let province = [];
    let cities = [];
    for (let item of this.props.provinces) {
      province.push({ id: item.id, name: item.name });
      cities.push(item.cities);
    }
    if (props.area) {
      defa_pro = province.findIndex(function (element) {
        return element.name === props.area.province.name;
      });

      defa_city = cities[defa_pro].findIndex(function (ele) {
        return ele.name === props.area.city.name;
      });
      console.log("默认省/市", defa_pro);
      console.log("默认城区", defa_city);
    }

    this.state = {
      areaCityAddressDisplay: "none",
      selectIndex: defa_pro, // 如果默认选择左列的省市 则显示-1
      selectSubIndex: defa_city, //如果默认选择右列中的城市区域 则显示-1
      selectProvinceId: this.props.area ? this.props.area.province.id : -1,
      selectSubCityId: this.props.area ? this.props.area.city.id : -1,
      subCityDisplay: "none",
      province: province,
      cities: cities,
      selectCity: cities[defa_pro],
    };

    this.selectAreaClick = this.selectAreaClick.bind(this);
  }
  // 弹框显示城市列表
  selectAreaClick = () => {
    this.setState(
      {
        areaCityAddressDisplay: "block",
      },
      () => {
        let scrollEleTop = document.getElementById("province_list");
        let scrollSubEleTop = document.getElementById("sub_city_list");
        scrollEleTop.scrollTop = 40 * this.state.selectIndex;
        scrollSubEleTop.scrollTop = 40 * this.state.selectSubIndex;
        console.log(
          "top距离：" + scrollEleTop.scrollTop + "," + scrollSubEleTop.scrollTop
        );
      }
    );
  };

  // 关闭城市列表
  btnCloseClick = () => {
    if (this.state.selectIndex === -1) {
      ToptipsManager.warn("请选择左列中的省市");
      return;
    } else if (this.state.selectSubIndex === -1) {
      ToptipsManager.warn("请选择右列中的城区");
      return;
    } else {
      const url = this.props.user_url;
      let params = {
        province_id: parseInt(this.state.selectProvinceId),
        city_id: parseInt(this.state.selectSubCityId),
      };
      if (this.props.updateUserAddress) {
        this.props.updateUserAddress(
          this.state.selectSubValue || this.props.area.city.name
        );
      }
      this.setState({
        areaCityAddressDisplay: "none",
      });
      request({
        url: url,
        method: "PATCH",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {
          const data = resp.data;
          console.log(data);
          console.log("正确返回");
          // reloadPage()
        })
        .catch((err) => {
          console.log("错误返回");
          console.log(err);
        });
    }
  };

  // 点击相应的 省/市
  cityListClick = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const index = e.currentTarget.getAttribute("data-index");
    let value = e.currentTarget.getAttribute("data-value");
    const selectCity = this.state.cities[index];

    // if (this.state.selectCity.length <= 0) {
    //   value = ''
    // }

    this.setState(
      {
        selectProvinceId: id,
        selectIndex: index,
        selectValue: value,
        selectCity: selectCity,
        selectSubIndex: -1, // 如果想默认选中第一个，则改为数字 0
        selectSubCityId: -1,
        // selectSubCityId: this.state.cities[index][0].id  // 默认选中当前省市下的第一个市
      },
      () => {
        console.log("--index--:" + this.state.selectIndex);
        console.log("selectValue", this.state.selectValue);
        console.log("selectCity", this.state.selectCity);
      }
    );
  };

  // 显示 区/市
  subCityClick = (e) => {
    const id = e.currentTarget.getAttribute("sub-data-id");
    const index = e.currentTarget.getAttribute("sub-data-index");
    const value = e.currentTarget.getAttribute("sub-data-value");
    this.setState(
      {
        selectSubCityId: id,
        selectSubIndex: index,
        selectSubValue: value,
      },
      () => {
        console.log("onSelected");
        if (typeof this.props.onSelected == "function") {
          this.props.onSelected();
        }
        console.log(this.state.selectSubValue);
      }
    );
  };

  render() {
    console.log(
      "selectValue, selectSubValue",
      this.state.selectValue,
      this.state.selectSubValue
    );
    return (
      <div className="comp_city_area">
        <div
          id="select_city_address"
          className="select_city_addrss"
          onClick={this.selectAreaClick}
        >
          {
            this.state.selectValue || this.state.selectSubValue
              ? this.state.selectSubValue
              : this.props.area
              ? this.props.area.city.name
              : "请选择所在城市"

            // this.props.area
            // ?
            // (this.props.area.city ? this.props.area.city.name : this.props.area.province.name)
            // :
            // (
            //   this.state.selectValue
            //   ?
            //   this.state.selectSubValue
            //   :
            //   "选择居住地"
            // )
          }
        </div>
        <div
          id="picker"
          className="picker_select"
          style={{ display: this.state.areaCityAddressDisplay }}
        >
          <div className="overlay"></div>
          <div id="pick_mask" className="pick_mask">
            <h3>选择居住地</h3>
            <div className="close_button_right" onClick={this.btnCloseClick}>
              确定
            </div>
            <ul style={{ float: "left" }} id="province_list">
              {this.state.province &&
                this.state.province.map((province, index) => (
                  <li
                    key={index}
                    data-id={province.id}
                    data-index={index}
                    data-value={province.name}
                    onClick={this.cityListClick}
                    className={
                      this.state.selectIndex == index ? "highlight" : null
                    }
                  >
                    {province.name}
                  </li>
                ))}
            </ul>
            <ul
              id="sub_city_list"
              className="sub_city_value"
              style={{ float: "right" }}
            >
              {this.state.selectCity &&
                this.state.selectCity.map((city, idx) => (
                  <li
                    key={city.id}
                    sub-data-index={idx}
                    sub-data-id={city.id}
                    sub-data-value={city.name}
                    onClick={this.subCityClick}
                    className={
                      this.state.selectSubIndex == idx ? "highlight" : null
                    }
                  >
                    {city.name}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
