import styled from "styled-components";
import icon_close from "promotion/icon_close.png";
import iocn_top_modal from "promotion/iocn_top_modal.png";

export const StyledFixedBody = styled.div`
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
`;

export const StyledAbsoluteContent = styled.div`
  background: ${({ bg }) => (bg ? "#ffcb39" : "#fff")};
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : "10px"};
  width: ${({ width }) => (width ? "317px" : "90%")};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const StyledDoubleElevenModal = styled.div`
  .content_container {
    position: relative;
    border-radius: 15px;
    background-color: #ffcb39;
    padding: 5px 0 0;
    img {
      width: 100%;
      position: absolute;
      top: -110px;
      left: 0;
      right: 0;
      z-index: -1;
    }
    .course_discount_content {
      background: linear-gradient(#f23f1e, #fe6319, #f23f1e);
      min-height: 123px;
      position: relative;
      text-align: center;
      border-radius: 16px;
      margin: 0 5px;
      padding-bottom: 20px;
      .fit_course_name {
        color: #ffe5a1;
        padding: 20px 0 10px;
        font-size: 18px;
      }
      .discount_text {
        background-color: #fff4d6;
        width: calc(100% - 100px);
        margin-left: 50px;
        padding: 5px 0;
        transform: skew(-10deg);
        border-radius: 5px 10px 5px 10px;
        margin-bottom: 10px;
        .parallelogram_text {
          transform: skew(10deg);
          font-size: 22px;
          font-weight: 700;
          color: #fc6616;
        }
      }
      .all_discount_text,
      .peiyou_discount_text {
        font-size: 24px;
        p {
          color: #fc6616;
          transform: skew(10deg);
          font-weight: 700;
        }
      }
      .reading_discount_text {
        line-height: 40px;
        padding: 0;
        .discount_price {
          background-color: #ff9a16;
          width: 100px;
          height: 30px;
          line-height: 30px;
          border-radius: 5px 0;
          span {
            color: #fff;
            transform: skew(10deg);
            display: block;
            font-size: 17px;
          }
        }
        .parallelogram_text {
          font-size: 18px;
          font-weight: normal;
          span {
            font-size: 32px;
            line-height: 32px;
            font-weight: 700;
          }
        }
      }
      .discount_brief {
        color: #ffe5a1;
        padding-bottom: 20px;
        font-size: 18px;
        span {
          color: #fff;
          font-size: 20px;
        }
      }
    }
    .course_rule_content {
      color: #fff;
      font-size: 13px;
      text-align: left;
      padding: 10px 10px 10px 20px;
      .course_describe {
        ul {
          li {
            display: flex;
            align-items: center;
            span {
              border-radius: 50%;
              width: 14px;
              height: 14px;
              line-height: 14px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: #fff;
              border: 1px solid #fff;
              margin-right: 5px;
            }
          }
        }
      }
    }
    .peiyou_rule_content {
      font-size: 13px;
    }
  }
`;
export const StyledBuyButton = styled.div`
  width: 233px;
  height: 49px;
  line-height: 49px;
  background: linear-gradient(to bottom, #ffe889, #ffd64d);
  text-align: center;
  border-radius: 50px;
  position: relative;
  color: #fc6616;
  font-size: 20px;
  font-weight: 700;
  margin: 10px auto 25px;
  cursor: pointer;
  &::after {
    content: "";
    background-color: #fc5b16;
    right: 0;
    top: 5px;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    border-radius: 50px;
  }
`;
export const StyledCloseModal = styled.div`
  background: url(${icon_close}) no-repeat right center/28px;
  padding: 0 20px;
  height: 35px;
  line-height: 35px;
  color: #fff;
  margin: 20px auto;
  text-align: center;
  position: absolute;
  right: -10px;
  top: -120px;
  cursor: pointer;
  z-index: 1;
`;

// 恭喜兑换成功
export const StyledSuccessRedemptionModal = styled.div`
  img {
    width: 50px;
    height: 50px;
    margin: 15px auto;
    display: block;
  }
  p {
    text-align: center;
    font-size: 17px;
  }
`;

export const StyledConfirmButton = styled.div`
  width: 141px;
  height: 32px;
  line-height: 32px;
  background: linear-gradient(to top, #ff6d23, #ffbf3f);
  border-radius: 50px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  margin: 25px auto;
  cursor: pointer;
`;

export const StyledGoFinishExchangeModal = styled.div`
  p {
    text-align: center;
    font-size: 17px;
    padding: 30px 0 5px;
  }
`;

export const StyledUploadScreenShotModal = styled.div`
  position: relative;
  &::before {
    content: "温馨提醒";
    background: url(${iocn_top_modal}) no-repeat center center;
    width: 180px;
    height: 34px;
    background-size: 100% 100%;
    position: absolute;
    left: 50%;
    margin-left: -90px;
    top: -8px;
    text-align: center;
    line-height: 34px;
    color: #fff;
    font-size: 18px;
  }
  p {
    text-align: center;
    font-size: 17px;
    padding: 50px 25px 5px;
  }
`;
