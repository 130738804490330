/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from "react";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { debounce } from "../utils/helpers";
import icon_success from "icon_success.png";
import "../batch_ecoupons/CouponTicket.scss";
import "./ActivityCouponTicket.scss";
import coupon_bg from "@assets/images/coupon_bg.png";
import icon_close from "@assets/images/icon_close_red.png";

const ActivityCouponTicket = (props) => {
  const {
    start_date,
    end_date,
    price,
    can_buies,
    get_ecoupon_url,
    hint,
    mode,
    period_interval,
    min_product_price,
  } = props.batchEcoupon;
  const validCourses = can_buies;
  const title = "可用于报名心芽学堂所有课程";
  const sum = price;
  const startDate = start_date.replace(/-/g, ".");
  const endDate = end_date.replace(/-/g, ".");

  const centainCourseContainerRef = useRef();
  const [ticketDrawed, setTicketDrawed] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");

  const closeClick = () => {
    props.getActivityCouponTicketCloseStatus(false);

    // 如果当前券领取成功了，点击关闭按钮需刷新当前页更新券的状态
    if (ticketDrawed) {
      location.reload(true);
    }
  };

  const getSuitableCourse = (validCourses, title) => {
    if (validCourses) {
      if (props.styleType === 'old') {
        return (
          <div className="centain_suitable" ref={centainCourseContainerRef}>
            <div className="suitable_title">可用于报名以下课程：</div>
            {validCourses.map((course, index) => (
              <div className="suitable_courses" key={index}>
                {course}
              </div>
            ))}
          </div>
        );
      } else {
        return (
          <div className="centain_suitable" ref={centainCourseContainerRef}>
            <div className="suitable_title">可用于报名：{validCourses.join('、')}</div>
          </div>
        );
      }
    } else {
      return <div className="all_suitable">{title}</div>;
    }
  };

  const CollectNow = () => {
    const linkToCourse = () => {
      if (ticketDrawed && redirectUrl) {
        window.location.href = redirectUrl;
      }
    };

    // 用户领取券
    const drawCouponTicket = () => {
      console.log("drawCouponTicket");
      drawTicketRequest.call(this, get_ecoupon_url);
    };

    const drawTicketRequest = (url) => {
      const toast = ToastManager.showLoading("优惠券领取中...");
      return request({
        url: url,
        method: "PATCH",
        headers: csrfHeaders,
      })
        .then((res) => {
          console.log("res_result: ", res);
          toast.cancel();
          if (res.data.err_code === 0) {
            setTicketDrawed(true);
            setRedirectUrl(res.data.redirect_url);
            if (props.styleType === 'old') {
              ToastManager.showToastSuccess("领取成功，请尽快使用。");
            }
          } else {
            const redirect_url = res.data.redirect_url;
            if (redirect_url) {
              window.location.href = redirect_url;
              return;
            }
            const attention = res.data.err_msg || "领取失败，请刷新页面重试。";
            ToastManager.showToastError(attention);
          }
        })
        .catch(() => {
          toast.cancel();
          ToastManager.showToastError("领取失败，请刷新页面重试。");
        });
    };

    const drawSuccessText = () => {
      if (props.styleType === 'old') {
        return (
          <div className="draw_success_text">
            <img src={icon_success} alt="" />
            <div className="text">领取成功，请尽快使用。</div>
          </div>
        );
      } else {
        return '领取成功，请尽快使用';
      }
    };
    return (
      <div className="coupon_ticket_action">
        <div
          className={`action_botton_common ${!ticketDrawed ? "active" : ""}`}
          onClick={
            !ticketDrawed ? debounce(drawCouponTicket) : debounce(linkToCourse)
          }
        >
          {!ticketDrawed ? "立即领取" : drawSuccessText()}
        </div>
      </div>
    );
  };

  if (props.styleType === 'old') {
    return (
      <div className="comp_activity_coupon_ticket">
        <div className="activity_coupon_ticket_box">
          <div className="coupon_text_describe">
            {hint || "天降神券，请立即领取使用！"}
          </div>
          <div className="comp_coupon_ticket">
            <div className="coupon_ticket_content">
              <div className="ticke_info">
                <div className="top_info">
                  <div className="sum">
                    <div className="unit_money">
                      <span className="unit">¥</span>
                      <span className="money">{sum}</span>
                    </div>
                    <div
                      className="use_condition"
                      style={{
                        visibility: min_product_price > 0 ? "visible" : "hidden",
                      }}
                    >
                      {`满${min_product_price}元可用`}
                    </div>
                  </div>
                  <div className="vaild_course" id="vaild_course">
                    {getSuitableCourse(validCourses, title)}
                  </div>
                </div>
                <div className="bottom_info">
                  <div className="start_end_date">
                    {mode == 2
                      ? `领取后${period_interval}小时有效`
                      : `${startDate} - ${endDate}`}
                  </div>
                </div>
              </div>
            </div>
            <div className="coupon_ticket_cover">{CollectNow()}</div>
            <div
              className="coupon_ticket_close"
              onClick={() => closeClick()}
            ></div>
          </div>
        </div>
      </div>
    );
  } else {
    const [couponStyles, setCouponStyles] = useState({});
    const imgBgRef = useRef();
    useEffect(() => {
      const bgWidth = imgBgRef.current.clientWidth;
      const ratio = bgWidth / 700;
      setCouponStyles({
        headerContainer: { top: 150 * ratio },
        header: { width: 400 * ratio },
        priceContainer: { top: 230 * ratio },
        price: { width: 500 * ratio, display: 'flex', justifyContent: 'center' },
        descriptionContainer: { top: 350 * ratio, display: 'flex', alignItems: 'center', height: 95 * ratio },
        description: { maxWidth: 400 * ratio },
        effectiveContainer: { top: 555 * ratio },
        effective: { width: 400 * ratio },
        collectContainer: { top: 610 * ratio, lineHeight: `${110 * ratio}px` },
        collect: { width: 500 * ratio },
      })
    }, []);

    return (
      <div className="comp_activity_coupon_ticket">
        <div className="activity_coupon_ticket_box activity_coupon_ticket_box_new_style">
          <img className="img-bg" src={coupon_bg} ref={imgBgRef} />
          <div className="coupon-container" style={couponStyles.headerContainer}>
            <div className="coupon-header" style={couponStyles.header}>
              {props.is_new_user_batch_ecoupon && props.share_user?.photo_url ?
                <div className="share-user"><img src={props.share_user.photo_url} /><span>送您一张优惠券</span></div> : <span>{hint || "天降神券，请立即领取使用！"}</span>
              }
            </div>
          </div>
          <div className="coupon-container" style={couponStyles.priceContainer}>
            <div className="coupon-price" style={couponStyles.price}>
              {props.is_new_user_batch_ecoupon && <div className="new-user">新人半价券</div>}
              {!props.is_new_user_batch_ecoupon &&
                <div className="unit_money">
                  <span className="unit">¥</span>
                  <span className="money">{sum}</span>
                </div>
              }
              {!props.is_new_user_batch_ecoupon && min_product_price > 0 &&
                <span className="condition">{`满${min_product_price}元可用`}</span>
              }
            </div>
          </div>
          <div className="coupon-container" style={couponStyles.descriptionContainer}>
            <div className="vaild_course" id="vaild_course" style={couponStyles.description}>
              {getSuitableCourse(validCourses, title)}
            </div>
          </div>
          <div className="coupon-container" style={couponStyles.effectiveContainer}>
            <div className="bottom_info" style={couponStyles.effective}>
              <div className="start_end_date">
                {mode === 2
                  ? `领取后${period_interval}小时有效`
                  : (mode === 1 ? `领取后${period_interval}天有效` : `有效期: ${startDate} - ${endDate}`)}
              </div>
            </div>
          </div>
          <div className="coupon-container" style={couponStyles.collectContainer}>
            <div className="coupon_ticket_cover" style={couponStyles.collect}>{CollectNow()}</div>
          </div>
          <img className="btn-close" src={icon_close} onClick={() => closeClick()} />
        </div>
      </div>
    );
  }
};

export default ActivityCouponTicket;
