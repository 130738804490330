/* eslint-disable react/prop-types */
import React, { memo } from "react";
import ErrorBoundary from "../../utils/ErrorBoundary";
import icon_top_nav from "think_customized/icon_top_nav.png";
import option_tif from "study_package_activity/arrow.gif";
import icon_bottom_position from "think_customized/icon_bottom_position.png";
import "./ActivityBanner.scss";

const Banner1 = memo(() => {
  const headBanner1 = <img src={icon_top_nav} data-sizes="auto" alt="" />;
  return (
    <div className="option_top_content">
      <div className="periods_time_top">{headBanner1}</div>
      {/* <div className="options_center">
        <div className="options_center_c">
          <h3>适合孩子的才是最好的</h3>
          <p>下拉定制我的课程</p>
        </div>
      </div> */}

      {/* <div className="envelop_container">
        <div className="top_content">
          <div className="bg"></div>
          <div className="content_box">
            <div className="content">
              <div className="quiz_text">
                <p>
                  <span>请花30秒，回答5个小问题</span>
                  <span>定制最适合孩子的培优课</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom_container">
          <div className="text">下拉开始定制</div>
        </div>
      </div> */}

      <div className="envelop_bottom_position">
        <img src={icon_bottom_position} alt="" />
      </div>

      <div className="drop_down_gif">
        <img src={option_tif} alt="" />
      </div>
      {/* <div className="add_box"></div> */}
    </div>
  );
});

export const ActivityBanner1 = (props) => {
  return (
    <ErrorBoundary>
      <Banner1 {...props} />
    </ErrorBoundary>
  );
};
