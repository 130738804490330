// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/arrow-right.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".trial_choose_background{background:rgba(0,0,0,.65);position:fixed;bottom:0;left:0;right:0;top:0;z-index:13;width:100%;height:100%}.trial_choose_background .trial_choose_tableview{padding:10px 15px;background:#fff;z-index:11;position:absolute;bottom:0;width:100%}.trial_choose_background .trial_choose_tableview .close_btn_right{width:20px;height:24px;position:absolute;top:-5px;right:10px;font-size:30px;color:#666}.trial_choose_background .trial_choose_tableview h3{text-align:center}.trial_choose_background .trial_choose_tableview .trial_choose_cell{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-position:center right 10px;background-size:6px}@media screen and (orientation:landscape)and (min-width:1024px){.trial_choose_background{width:500px;margin:0 auto}}", ""]);
// Exports
module.exports = exports;
