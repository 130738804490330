/* eslint-disable react/prop-types */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import "./RightFloatIcons.scss";

import iconBack from "left_float_icons/icon_back.png";
import iconComment from "left_float_icons/icon_comment.png";
import iconMakeComment from "left_float_icons/icon_lottery.png";
import iconReport from "left_float_icons/icon_presentation.png";
import iconRedMoney from "left_float_icons/icon_red_money.png";
import iconMoreCourse from "left_float_icons/icon_more_course.png";
import iconScrollTop from "left_float_icons/icon_scroll_top.png";
import IconSetting from "left_float_icons/icon_setting.png";
import iconWrongBook from "left_float_icons/icon_wrong_book.png";
import iconConsultant from "consult.png";
import iconContinueUser from "continue_user.png";

const icons = {
  IconWrongBook: (extraProps) => ({
    text: "错题本",
    photo: iconWrongBook,
    ...extraProps,
  }),
  IconContinueUser: (extraProps) => ({
    text: "续课优惠",
    photo: iconContinueUser,
    ...extraProps,
  }),
  IconReport: (extraProps) => ({
    text: "学习报告",
    photo: iconReport,
    ...extraProps,
  }),

  IconMakeComment: (extraProps) => ({
    text: "评价领券",
    photo: iconMakeComment,
    ...extraProps,
  }),
  IconRedMoney: (extraProps) => ({
    text: "推荐有奖",
    photo: iconRedMoney,
    ...extraProps,
  }),
  IconComment: (extraProps) => ({
    text: "家长评价",
    photo: iconComment,
    ...extraProps,
  }),
  IconBack: (extraProps) => ({
    text: "返回课程",
    photo: iconBack,
    ...extraProps,
  }),
  IconScrollTop: (extraProps) => ({
    text: "返回顶部",
    photo: iconScrollTop,
    ...extraProps,
  }),
  IconSetting: (extraProps) => ({
    text: "课程设置",
    photo: IconSetting,
    ...extraProps,
  }),
  IconConsultant: (extraProps) => ({
    text: "咨询老师",
    photo: iconConsultant,
    ...extraProps,
  }),
  IconMoreCourse: (extraProps) => ({
    text: "更多课程",
    photo: iconMoreCourse,
    ...extraProps,
  }),
};

const IconContainer = React.memo(function IconContainer({
  photo,
  text,
  onIconClick,
}) {
  return (
    <div className="common_icon" onClick={onIconClick}>
      <img src={photo} alt="" />
      <span>{text}</span>
    </div>
  );
});

IconContainer.propTypes = {
  photo: PropTypes.string,
  text: PropTypes.string,
  onIconClick: PropTypes.func,
};

const RightFloatIcons = React.memo(function LeftFloatIcons({
  isShowBack,
  isShowComment,
  isShowMakeComment,
  isShowReport,
  isShowRedMoney,
  isContinueUser,
  isShowConsultant,
  onBackClick,
  isShowWrongBook,
  onWongBookClick,
  onCommentClick,
  onMakeCommentClick,
  onReportClick,
  onRedMoneyClick,
  onConsultantClick,
  isShowMoreCourse,
  onMoreCourseClick,
  onContinueUserClick,
  isShowScrollTop,
  onScrollTop,
  isShowSetting,
  onSetting,
  bottomPosition,
  zIndexLevel,
}) {
  const mapExtraPropsToIconContainer = useMemo(
    () => ({
      IconBack: { filter: isShowBack, click: onBackClick },
      IconComment: { filter: isShowComment, click: onCommentClick },
      IconMakeComment: {
        filter: isShowMakeComment,
        click: onMakeCommentClick,
      },
      IconWrongBook: { filter: isShowWrongBook, click: onWongBookClick },
      IconReport: { filter: isShowReport, click: onReportClick },
      IconRedMoney: { filter: isShowRedMoney, click: onRedMoneyClick },
      IconScrollTop: { filter: isShowScrollTop, click: onScrollTop },
      IconSetting: { filter: isShowSetting, click: onSetting },
      IconConsultant: { filter: isShowConsultant, click: onConsultantClick },
      IconMoreCourse: { filter: isShowMoreCourse, click: onMoreCourseClick },
      IconContinueUser: { filter: isContinueUser, click: onContinueUserClick },
    }),
    [
      isShowBack,
      isShowWrongBook,
      onBackClick,
      isShowComment,
      onCommentClick,
      isShowMakeComment,
      onMakeCommentClick,
      isShowReport,
      onReportClick,
      isShowRedMoney,
      onRedMoneyClick,
      isShowScrollTop,
      onScrollTop,
      isShowSetting,
      onSetting,
      isShowConsultant,
      onConsultantClick,
      isShowMoreCourse,
      onMoreCourseClick,
      isContinueUser,
      onContinueUserClick,
      onWongBookClick,
    ]
  );

  const iconsArr = useMemo(() => {
    const iconKeys = Object.keys(icons);
    // console.log("33333333",iconKeys)
    return iconKeys
      .filter((icon) => mapExtraPropsToIconContainer[icon].filter)
      .map((filteredIcon) =>
        icons[filteredIcon]({
          onClick: mapExtraPropsToIconContainer[filteredIcon].click,
        })
      );
  }, [mapExtraPropsToIconContainer]);

  if (iconsArr && iconsArr.length === 0) {
    return null;
  }

  return (
    <div className="comp_activity_left_icons">
      <div
        className="float_icons"
        style={{ bottom: bottomPosition || 75, zIndex: zIndexLevel || 3 }}
      >
        {iconsArr.map((icon, index) => (
          <IconContainer
            photo={icon.photo}
            text={icon.text}
            key={index}
            onIconClick={icon.onClick}
          />
        ))}
      </div>
    </div>
  );
});

RightFloatIcons.propTypes = {
  isShowBack: PropTypes.bool,
  isShowComment: PropTypes.bool,
  isShowMakeComment: PropTypes.bool,
  isShowReport: PropTypes.bool,
  isShowRedMoney: PropTypes.bool,
  isShowMoreCourse: PropTypes.bool,
  onBackClick: PropTypes.func,
  onCommentClick: PropTypes.func,
  onMakeCommentClick: PropTypes.func,
  onReportClick: PropTypes.func,
  onRedMoneyClick: PropTypes.func,
  onMoreCourseClick: PropTypes.func,
  bottomPosition: PropTypes.number,
  zIndexLevel: PropTypes.number,
};

export default RightFloatIcons;
