/* eslint-disable react/prop-types */
import React, { PureComponent } from "react";

import PropTypes from "prop-types";

import { isDevelopment, GA } from "../utils/Env";
import MinCommentLists from "../utils/comment_lists/MinCommentLists";
import CommentTags from "../utils/comment_tags/CommentTags";

import "./StudyPackageActivity.scss";
import "../utils/ReactMarkDown.scss";

export default class Comments extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      hasBoughtAny: this.props.has_bought_any,
      current_tag_id: 0,
    };
  }

  componentDidMount() {
    // 初始化
    // $(function () {
    //   // 窗口滚动事件
    //   $(window).scroll(function () {
    //     var offset_top = document.getElementById("course_container").offsetTop
    //     var scroll_top = $(document).scrollTop()
    //     if (scroll_top > offset_top) {
    //       document.getElementById("switch_tab").classList.add("switch_title_fixed");
    //     } else {
    //       document.getElementById("switch_tab").classList.remove("switch_title_fixed");
    //     }
    //   })
    // })
  }

  tabBarClick = (idx) => {
    GA("Courses", idx, this.props.study_package_activity_id);
    let { activity_detail_url } = this.props;
    switch (idx) {
      case 1:
        window.location.href = activity_detail_url + "&tab=1";
        break;
      case 2:
        window.location.href = activity_detail_url + "&tab=2";
        break;
      default:
        window.location.href = activity_detail_url + "&ref=comment";
        break;
    }
  };

  tabBarItem() {
    const hasCommentedAuth =
      this.props.comment_state == "uncommented" ||
      this.props.comment_state == "commented";
    const {
      start_time,
      study_package_category_id,
      sub_study_package_activities,
      faq,
      total_comments_count,
      is_experience,
      activity_category_type,
    } = this.props;

    let tabs = [];
    // tab第1个值
    if (study_package_category_id === 18) {
      tabs.push("课程介绍");
    } else if (is_experience === true) {
      tabs.push("活动介绍");
    } else {
      tabs.push("课程介绍");
    }

    // tab第2.3个值
    if (
      study_package_category_id === 18 &&
      "sub_study_package_activities" in this.props
    ) {
      [...sub_study_package_activities]
        .reverse()
        .slice(0, 2)
        .map((sub_activity, index) => {
          tabs.push(sub_activity.navigate_name);
        });
    }

    // tab第4个值
    if (study_package_category_id != 18 && faq) {
      if (is_experience === true) {
        tabs.push("常见问题");
      } else {
        tabs.push("试听/常见问题");
      }
    }

    // 评论tab
    if (!is_experience) {
      if (total_comments_count > 0) {
        tabs.push(
          "评价 (" +
            (total_comments_count >= "10000" ? "9999+" : total_comments_count) +
            ")"
        );
      } else {
        hasCommentedAuth ? tabs.push("评价") : null;
      }
    }

    console.log(tabs);

    return (
      <div
        className={`sign_tab ${
          activity_category_type == 4 ? "remove_margin" : ""
        }`}
        id="switch_tab"
      >
        <ul
          className="switchTitle"
          style={{ marginTop: start_time ? null : "0" }}
        >
          {tabs.map((tab, idx) => (
            <li
              key={idx}
              className={idx == tabs.length - 1 ? "active" : ""}
              onClick={this.tabBarClick.bind(this, idx)}
              data-index={idx}
            >
              {tab}
            </li>
          ))}
        </ul>
      </div>
    );
  }

  handleTagID = (tag_id) => {
    GA("Comments", "tag", tag_id);
    this.setState({
      current_tag_id: tag_id,
    });
  };

  render() {
    console.log("comments props: ", this.props);
    const {
      comment_tags,
      total_comments_count,
      activity_detail_url,
      is_calc_series,
    } = this.props;
    const { current_tag_id } = this.state;

    return (
      <div className="comp-study-package-activity">
        {!this.props.is_customized_course && (
          <a className="comments_header_back" href={activity_detail_url}>
            评价（{`${total_comments_count}`}）
          </a>
        )}
        <div className="activity_list comments_list">
          {
            // 导航 tab
            // this.tabBarItem()
          }

          <div
            id="course_container"
            className="course_container"
            style={{ display: this.state.isCourseContainer }}
          >
            {!is_calc_series && comment_tags && (
              <CommentTags
                tags={comment_tags}
                current_tag_id={current_tag_id || 0}
                handle={this.handleTagID.bind(this)}
                isCustomizedCourse={this.props.is_customized_course}
              />
            )}
            <MinCommentLists
              ref={(isCourseContainer) =>
                (this.isCourseContainer = isCourseContainer)
              }
              request_comment_url={this.props.request_comment_url}
              comments={this.props.comments}
              create_recommend_request_url={
                this.props.create_recommend_request_url
              }
              comment_target={this.props.comment_target}
              total_comments_count={this.props.total_comments_count}
              pre_has_bought={this.props.pre_has_bought}
              bought={this.props.bought}
              current_user_comment={this.props.current_user_comment}
              comment_state={this.props.comment_state}
              rate={this.props.rate}
              bought_phase={this.props.bought_phase}
              promotion={this.props.promotion}
              system_time={this.props.system_time}
              current_user_comment_state={this.props.current_user_comment_state}
              subStudyPackageActivities={
                this.props.sub_study_package_activities
              }
              studyPackageActivity={this.props.study_package_activity}
              uncommented={this.props.uncommented}
              current_tag_id={current_tag_id}
              current_tab="comment"
              handle={this.handleTagID.bind(this)}
              isCustomizedCourse={this.props.is_customized_course}
            />
          </div>
        </div>
        {!this.props.is_customized_course && (
          <a className="comments_bottom_back" href={activity_detail_url}>
            返回报名页
          </a>
        )}
      </div>
    );
  }
}
