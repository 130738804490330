import React from "react";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
import "./VersionIntroDialog.scss";

const VersionIntroDialog = ({ ...props }) => {
  return (
    <InnerModal>
      <ModalMask className="version-intro-dialog-mask">
        <div className="version-intro-dialog-container">
          <div className="version-intro-dialog" style={{ bottom: props.bottom }}>
            <div
              className="modal_close"
              onClick={props.onModalClose}
            ></div>
            <div className="modal_content">
              <div className="version">
                <div className="title">老师批改版：</div>
                <div className="notes">
                  <div className="note"><span className="icon-dot"></span><span>小朋友每周完成纸质作业后，拍照上传到APP中，由课程老师进行批改；</span></div>
                  <div className="note"><span className="icon-dot"></span><span>老师将在1~2个工作日内完成，并反馈完成情况；</span></div>
                  <div className="note"><span className="icon-dot"></span><span>《{props.name}》共{props.exerciseCount}次作业，每次可节约家长15分钟。</span></div>
                </div>
              </div>
              <div className="version" style={{ marginTop: 20 }}>
                <div className="title">自助批改版：</div>
                <div className="notes">
                  <div className="note"><span className="icon-dot"></span><span>小朋友每周完成纸质作业后，由家长/小朋友自己进行批改核对；</span></div>
                  <div className="note"><span className="icon-dot"></span><span>其他内容与“老师批改版”相同。</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalMask>
    </InnerModal>
  );
};

export default VersionIntroDialog;
