/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useLayoutEffect,
  memo,
} from "react";
import Uri from "jsuri";
import ErrorBoundary from "../../utils/ErrorBoundary";
import { GA } from "../../utils/Env";
import { onNavigate } from "../../utils/helpers";
import { TabPane, Tabs } from "../../utils/Tabs/Tabs.js";
import CommentTags from "../../utils/comment_tags/CommentTags";
import MinCommentLists from "../../utils/comment_lists/MinCommentLists";
import LazyloadContent from "../../utils/lazyload/LazyloadContent";
import ProblemFold from "../../utils/ProblemFold";
import Comments from "../../study_package_activities/Comments";
import SubActivityTabs from "../SubActivityTabs.jsx";
import { UnlockAll } from "../BottomFromOut";

const SubStudyPackageBriefsInActivityBrief = memo((props) => {
  console.log("SubStudyPackageBriefsInActivityBrief_props", props);
  return (
    <SubActivityTabs
      recommend_activity={
        props.recommend_activity != null
          ? props.sub_study_package_activities.find(
              (act) => act.id === props.recommend_activity.id
            )
          : ""
      }
      content_activities={props?.content_activities.map((act) => {
        if (
          !props.sub_study_package_activities.find(
            (subAct) => subAct.id === act.id
          )
        ) {
          return {
            ...act,
            canBuy: false,
            isBuyTime: false,
            isNotExpirdOfGroupon: props.isNotExpirdOfGroupon,
          };
        }
        return {
          ...act,
          canBuy: true,
          isNotExpirdOfGroupon: props.isNotExpirdOfGroupon,
          isBuyTime: !(
            new Date(props.system_time) < new Date(props.buy_starttime)
          ),
        };
      })}
      isDirGroup={props.sale_mode === "direct_shopping_group"}
    />
  );
});

const UserCommentsInFirstTab = memo(
  ({
    is_calc_series,
    total_comments_count,
    comment_tags,
    isCommentTabView = true,
    ...props
  }) => {
    const [currentTagId, setTagId] = useState(0);
    const onTagClick = (tag_id) => {
      const { study_package_activity_id } = props;
      GA("Comments", study_package_activity_id + "_tag", tag_id);
      setTagId(tag_id);
    };
    const commentsMore = (e, tag_id) => {
      const { study_package_activity_id, comments_url } = props;
      GA("Comments", study_package_activity_id + "_more", tag_id);
      if (comments_url) {
        onNavigate(e, comments_url);
      }
    };

    return (
      <React.Fragment>
        {isCommentTabView && (
          <div
            className={`overview_comments_head ${
              props.is_customized_course
                ? "overview_comments_head_customized"
                : ""
            }`}
          >
            <h3>
              家长评价
              {props.is_customized_course ? (
                <span>{total_comments_count}</span>
              ) : (
                <span>({total_comments_count})</span>
              )}
            </h3>
          </div>
        )}
        {!is_calc_series && comment_tags && (
          <CommentTags
            tags={comment_tags}
            current_tag_id={currentTagId}
            handle={onTagClick}
            isCustomizedCourse={props.is_customized_course}
          />
        )}
        <MinCommentLists
          request_comment_url={props.request_comment_url}
          comments={props.comments}
          create_recommend_request_url={props.create_recommend_request_url}
          comment_target={props.comment_target}
          total_comments_count={total_comments_count}
          pre_has_bought={props.pre_has_bought}
          bought={props.bought}
          current_user_comment={props.current_user_comment}
          comment_state={props.comment_state}
          rate={props.rate}
          bought_phase={props.bought_phase}
          promotion={props.promotion}
          system_time={props.system_time}
          current_user_comment_state={props.current_user_comment_state}
          subStudyPackageActivities={props.sub_study_package_activities}
          studyPackageActivity={props.study_package_activity}
          uncommented={props.uncommented}
          current_tag_id={currentTagId}
          current_tab={isCommentTabView ? "overview" : "comment"}
          handle={onTagClick}
          isCustomizedCourse={props.is_customized_course}
        />
        {isCommentTabView && props.comments_url && (
          <div className="overview_comments_foot">
            <span
              onClick={(e) => commentsMore(e, currentTagId)}
              className={
                props.is_customized_course ? "customized_more_span" : ""
              }
            >
              更多评价 »
            </span>
          </div>
        )}
      </React.Fragment>
    );
  }
);

const ActivityBriefTabContent = ({ ...props }) => {
  return (
    <ErrorBoundary>
      <div
        className={`course_container ${
          props.activity_category_type === 3 ? "is_series_container" : ""
        }`}
        id="switch_tab"
      >
        <React.Fragment>
          {props.comment_tags.length > 0 &&
            props.total_comments_count > 0 &&
            !props.is_customized_course && (
              <UserCommentsInFirstTab
                is_calc_series={props.is_calc_series}
                total_comments_count={props.total_comments_count}
                comment_tags={props.comment_tags}
                {...props}
              />
            )}
          <React.Fragment>
            <div className="markDownWrapper">
              <LazyloadContent
                content={props.description}
                noneLazy={props.study_package_category_id === 18}
              />
            </div>
            {props?.content_activities?.length > 0 &&
              (props.sub_course_type === "thinking" ||
                props.is_calc_series ||
                props.is_grade1_sync ||
                props.is_poetry) && (
                <SubStudyPackageBriefsInActivityBrief {...props} />
              )}
            {props.study_package_activity?.feature_photo_url && (
              <img
                src={props.study_package_activity.feature_photo_url}
                alt=""
                style={
                  props.isNoneRenderBottomBar
                    ? { width: "100%" }
                    : { width: "100%", paddingBottom: 100 }
                }
                onClick={() => {
                  $("._tab_group_container")
                    .eq(
                      Array.from($("._tab_group_container")).findIndex(
                        (item) => item.innerHTML === "课程特色"
                      ) >= 0
                        ? Array.from($("._tab_group_container")).findIndex(
                            (item) => item.innerHTML === "课程特色"
                          )
                        : ""
                    )
                    .trigger("click");
                  window.scroll(0, 0);
                }}
              />
            )}
          </React.Fragment>
        </React.Fragment>
      </div>
    </ErrorBoundary>
  );
};

const ActivityFeatureTabContent = ({ study_package_activity }) => {
  return (
    <div className="course_feature">
      <LazyloadContent
        content={study_package_activity.feature}
        noneLazy={false}
      />
    </div>
  );
};

const ActivityParentEvaluate = ({ ...props }) => {
  return (
    props.comment_tags.length > 0 &&
    props.total_comments_count > 0 && (
      <div className="activity_parent_evaluate">
        <UserCommentsInFirstTab
          is_calc_series={props.is_calc_series}
          total_comments_count={props.total_comments_count}
          comment_tags={props.comment_tags}
          {...props}
        />
      </div>
    )
  );
};

const TabGroup = memo(({ ...props }) => {
  console.log(props, "ActivityTabGroup==============");
  const isDisplayContentActivitiesTab = useMemo(() => {
    return (
      props.sub_course_type !== "thinking" &&
      !props.is_calc_series &&
      !props.is_grade1_sync &&
      !props.is_poetry &&
      props?.content_activities?.length > 0
    );
  }, [
    props?.content_activities?.length,
    props.is_calc_series,
    props.is_grade1_sync,
    props.is_poetry,
    props.sub_course_type,
  ]);

  const tabs = useMemo(() => {
    const tabGroup = [];
    tabGroup.push({
      name: props.is_experience ? "活动介绍" : "课程介绍",
      content: (props) => <ActivityBriefTabContent {...props} />,
    });

    if (props.study_package_activity.feature) {
      tabGroup.push({
        name: "课程特色",
        content: (props) => <ActivityFeatureTabContent {...props} />,
      });
    }

    if (isDisplayContentActivitiesTab) {
      tabGroup.push(
        ...props.content_activities.map((contentActivity) => {
          return {
            name: contentActivity.navigate_name,
            content: ({ study_package_category_id }) => {
              return (
                <div>
                  <LazyloadContent
                    content={contentActivity.description}
                    noneLazy={study_package_category_id === 18}
                  />
                </div>
              );
            },
          };
        })
      );
    }

    if (props.faq) {
      tabGroup.push({
        name: "常见问题",
        content: ({ faq }) => <ProblemFold faq={faq} noneRef />,
      });
    }

    if (props.is_customized_course) {
      tabGroup.push({
        name: "家长评价",
        // content: (props) => <ActivityParentEvaluate {...props} />,
        content: (props) => <Comments {...props} />,
      });
    }
    if (props.promotion_method === "trial") {
      tabGroup.push({
        name: "课程大纲",
        content: (props) => (
          <div className="trial-two">
            <img
              src={props.promotion_trial.trial_photo_url}
              onClick={() => {
                GA(
                  "Courses",
                  "promotion_trial_embed_click",
                  props.study_package_activity_id
                );
                window.location.href = props.promotion_trial.trial_url;
              }}
            ></img>
            <img
              src={props.promotion_trial.trial_lock_url}
              onClick={() => {
                GA(
                  "Courses",
                  "promotion_trial_unlock_click",
                  props.study_package_activity_id
                );
                setShowUnlock(true);
              }}
            ></img>
          </div>
        ),
      });
    }
    return tabGroup;
  }, [
    isDisplayContentActivitiesTab,
    props?.content_activities,
    props.faq,
    props.is_experience,
    props.study_package_activity.feature,
    props.is_customized_course,
  ]);
  const [showUnlock, setShowUnlock] = useState(false);
  const [isCommentHashLocated, setCommentHashLocated] = useState(false);
  useLayoutEffect(() => {
    if (window.location.href.includes("#comment")) {
      setCommentHashLocated(true);
    }
  }, []);

  useEffect(() => {
    const tabsContainer = document.getElementsByClassName("switchTitle")[0];
    const tags = tabsContainer?.childNodes;
    const tabsArr = tags
      ? Array.from(tags).filter((tag) => tag.nodeName === "LI")
      : [];

    const subTabIndex = new Uri(window.location.href).getQueryParamValue("tab");

    const index = Number(subTabIndex);
    if (typeof index === "number" && index) {
      GA("Courses", `Tab${index}`, props.study_package_activity_id);
      $(tabsArr[index - 1]).trigger("click");
      return;
    }

    if (subTabIndex === "question") {
      const questionTab = tabsArr?.findIndex?.(
        (tab) => tab.innerHTML === "常见问题"
      );
      if (questionTab) {
        GA("tabQuestion", props.study_package_activity_id);
        $(tabsArr[questionTab]).trigger("click");
      }
    }
  }, [props.study_package_activity_id]);

  useEffect(() => {
    const tabContainer = document.getElementsByClassName("switchTitle")[0];
    if (window.location.href.includes("#tab")) {
      // eslint-disable-next-line no-unused-expressions
      tabContainer?.scrollIntoView?.();
    }
  }, []);

  const tabClick = useCallback(
    (e, tabId) => {
      setCommentHashLocated(false);
      GA("Courses", `Tab${Number(tabId) + 1}`, props.study_package_activity_id);
    },
    [props.study_package_activity_id]
  );

  return (
    <React.Fragment>
      <Tabs
        as="ul"
        defaultAction={tabClick}
        styles={`sign_tab ${
          // eslint-disable-next-line eqeqeq
          props.activity_category_type == 4 ? "remove_margin" : ""
        } switchTitle`}
        defaultActiveIndex={0}
      >
        {tabs.map((tab, index) => (
          <TabPane
            activeStyle={
              isCommentHashLocated
                ? ""
                : props.is_customized_course
                ? "customized_active"
                : "active"
            }
            key={index}
            tab={tab.name}
            as="li"
            styles={props.is_customized_course ? "li_customized" : ""}
          >
            {!isCommentHashLocated ? tab.content({ ...props }) : ""}
          </TabPane>
        ))}
      </Tabs>
      {props.total_comments_count > 0 && isCommentHashLocated && (
        <div>
          <UserCommentsInFirstTab
            is_calc_series={false}
            total_comments_count={props.total_comments_count}
            comment_tags={props.comment_tags}
            isCommentTabView={false}
            {...props}
          />
        </div>
      )}
      {showUnlock && (
        <UnlockAll
          activity={props.sub_study_package_activities[0]}
          study_package_activity_id={props.study_package_activity_id}
          originPrice={props.sub_study_package_activities[0].max_price}
          finalPrice={props.sub_study_package_activities[0].min_price}
          study_package_activity_buy_url={props.study_package_activity_buy_url}
          cancelClick={() => {
            setShowUnlock(false);
          }}
          // virtual_account={props.virtual_account}
        ></UnlockAll>
      )}
    </React.Fragment>
  );
});

export const ActivityTabGroup = (props, is_customized_course) => (
  <ErrorBoundary>
    <TabGroup {...props} />
  </ErrorBoundary>
);
