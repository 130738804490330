import React from "react";
import PropTypes from "prop-types";
import Divider from "../utils/content_divider/Divider";
import "./CouponGift.scss";
import alert_dialog_share from "coupon_tips_share.png";
import CouponTicket from "./CouponTicket";
import "./TicketCommon.scss";

export default class CouponGift extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      onShareGuide: false,
    };
    this.onAlertShareGuide = this.onAlertShareGuide.bind(this);
  }

  componentDidMount() {
    console.log(this.props.share_url);
    const share_title = this.props.share_title;
    const share_description = this.props.share_description;
    const share_image_url = this.props.share_image_url;
    const share_url = this.props.share_url;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }

  onAlertShareGuide() {
    this.setState((preState) => ({
      onShareGuide: !preState.onShareGuide,
    }));
  }

  render() {
    const { onShareGuide } = this.state;
    const { ecoupon } = this.props;
    return ecoupon ? (
      <div className="comp_coupon_gift">
        <ShareGuide
          onShareGuide={onShareGuide}
          onAlertShareGuide={this.onAlertShareGuide}
        />
        <CouponGiftedTicket
          startDate={ecoupon.start_date.replace(/-/g, ".")}
          endDate={ecoupon.end_date.replace(/-/g, ".")}
          allAvailable={ecoupon.all_available}
          canBuies={ecoupon.can_buies}
          validExperiences={ecoupon.experience_available}
          title={ecoupon.title}
          price={ecoupon.price}
          rule={ecoupon.rule}
          name={ecoupon.name}
          minProductPrice={ecoupon.min_product_price}
        />
        <TicketGiftedBtton
          onAlertShareGuide={this.onAlertShareGuide}
          canPresent={ecoupon.can_present}
        />
        <Divider content={"优惠券赠送规则"} key="two" />
        <RuleArean key="three" />
      </div>
    ) : (
      <div className="comp_coupon_gift">
        <div className="comp_coupon_gift_none">无可用券</div>
        <Divider content={"优惠券赠送规则"} />
        <RuleArean />
      </div>
    );
  }
}

const RuleArean = ({}) => {
  return (
    <div className="rules">
      <p>
        <span>1. </span>
        <span>标识为“可赠送”的优惠券，可以赠送给朋友使用；</span>
      </p>
      <p>
        <span>2. </span>
        <span>1张优惠券仅能被1个朋友领取1次；</span>
      </p>
      <p>
        <span>3. </span>
        <span>
          赠送优惠券，不改变优惠券本身的使用限制，如适用课程及有效期等；
        </span>
      </p>
      <p>
        <span>4. </span>
        <span>优惠券被领取之后，该优惠券将从你的优惠券列表中删除；</span>
      </p>
    </div>
  );
};

const ShareGuide = ({ onShareGuide, onAlertShareGuide }) => {
  return (
    onShareGuide && (
      <div className="alert_dialog_share" onClick={onAlertShareGuide}>
        <img src={alert_dialog_share} />
      </div>
    )
  );
};

const CouponGiftedTicket = ({
  startDate,
  endDate,
  allAvailable,
  canBuies,
  title,
  validExperiences,
  price,
  rule,
  name,
  minProductPrice,
}) => {
  let getValid = function (canBuies, title) {
    // if (allAvailable) {
    //   let title = "可用于所有课程报名"
    //   if (validExperiences) {
    //     title = "可用于所有课程及经验讲座报名"
    //   }
    //   return(
    //       <div className="vaild_courses">
    //         <div className="title_all">{title}</div>
    //       </div>
    //   )
    // } else if (validExperiences) {
    //   return(
    //       <div className="vaild_courses">
    //         <div className="title_all">可用于所有经验讲座报名</div>
    //       </div>
    //   )
    // } else if (canBuies && canBuies.length > 0) {
    //   return(
    //       <div className="vaild_courses">
    //         < div className="title">可用于报名以下课程：</div>
    //         {
    //           canBuies.map((course, index) =>
    //               <div className="course" key={index}>{course}</div>
    //           )
    //         }
    //     </div>
    //   )
    // }

    if (canBuies && canBuies.length > 0) {
      return (
        <div className="vaild_courses">
          <div className="title">可用于报名以下课程：</div>
          {canBuies.map((course, index) => (
            <div className="course" key={index}>
              {course}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="vaild_courses">
          <div className="title_all">{title}</div>
        </div>
      );
    }
  };

  return (
    <div className="coupon_ticket">
      <div className="ticket_left">
        <TicketPriceCondition price={price} minProductPrice={minProductPrice} />
      </div>
      <div className="ticket_right">
        {name && <div className="name">{name}</div>}
        {getValid(canBuies, title)}
        <div className="date">
          {startDate} - {endDate}
        </div>
        {rule && <div className="present_describe">{rule}</div>}
      </div>
    </div>
  );
};

// CouponTicket.propTypes = {
//   onAlertShareGuide: PropTypes.func.isRequired
// }
const TicketGiftedBtton = ({ onAlertShareGuide, canPresent }) => {
  if (canPresent) {
    return (
      <div className="send_button" onClick={onAlertShareGuide}>
        立即赠送
      </div>
    );
  }
  return <div className="send_button_in_active">不可赠送</div>;
};

export const TicketPriceCondition = ({ price, minProductPrice, half_price }) => {
  return (
    <div className="ticket_price_condition">
      {half_price ? <div className="half-price">5<span className="half-price-text">折</span></div> :
        <div className="price">
          <span className="unit">¥</span>
          <span className="sum">{price}</span>
        </div>
      }
      {minProductPrice > 0 && (
        <div className="use_condition">{`满${minProductPrice}元可用`}</div>
      )}
    </div>
  );
};

TicketGiftedBtton.propTypes = {
  onAlertShareGuide: PropTypes.func.isRequired,
};
