import styled from "styled-components";

export const StyledInvitationTip = styled.div`
  font-size: 16px;
  color: #333333;
  text-align: center;
  ${({ isSub }) =>
    isSub
      ? `
      margin-top: 6px;
      font-size: 14px;
      display: inline-block;
      padding: 4px 13px;
      text-align: center;
      border-radius: 0px 15px 0px 15px;
      background: #FFF2E4;
   `
      : "font-weight: bold;"}
  .strong {
    color: #ff4d2a;
    font-size: 20px;
  }
`;

export const StyledInvitationBroad = styled.div`
  width: 100%;
  background: #fff8f1;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
  padding: 40px 20px 20px 20px;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 25px;
`;

export const StyledSingleInvitationPerson = styled.div`
  display: flex;
  flex-direction: column;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* overflow: hidden; */
    border: 1px solid #ff973c;
    position: relative;
    margin: 0 10px;
    ${({ isActive }) =>
      !isActive ? "border:1px solid rgba(255, 255, 255, 0);" : ""}
    &::before {
      content: "";
      margin-top: 20px;
      width: 11px;
      height: 2px;
      background: #ff973c;
      display: block;
      position: absolute;
      right: -12px;
      ${({ isActive }) => (!isActive ? "opacity: 0.31" : "")}
    }
    &::after {
      content: "";
      margin-top: -25px;
      width: 10px;
      height: 2px;
      background: #ff973c;
      display: block;
      position: absolute;
      left: -10px;
      ${({ isActive }) => (!isActive ? "opacity: 0.31" : "")}
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
  .index_person {
    font-size: 14px;
    text-align: center;
    line-height: 1em;
    margin: 6px 0;
    color: #333333;
  }
  .price {
    line-height: 18px;
    font-size: 14px;
    color: #ff4d2a;
    text-align: center;
    ${({ isActive }) => (!isActive ? "color: #666666;font-size: 12px;" : "")}
    ${({ isFinallyActive }) => (isFinallyActive ? "font-weight: bold;" : "")}
  }
`;

export const StyledInvitationProgress = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 35px;
  ${StyledSingleInvitationPerson}:first-of-type {
    .avatar {
      &::after {
        content: none;
      }
    }
  }
  ${StyledSingleInvitationPerson}:last-of-type {
    .avatar {
      &::before {
        content: none;
      }
    }
  }
`;

export const StyledTimePad = styled.div`
  /* padding: 4px 2px 0px 2px; */
  background: #4e4e4e;
  border-radius: 3px;
  margin: 0 3px;
  font-size: 14px;
  color: #ffffff;
  min-width: 22px;
  text-align: center;
`;

export const StyledRefoundButton = styled.div`
  width: 76%;
  height: 40px;
  border: 1px solid #666666;
  margin: 0 12%;
  border-radius: 200px;
  line-height: 40px;
  text-align: center;
  font-size: 18px;
  color: #666666;
`;

export const StyledToClass = styled(StyledRefoundButton)`
  background: #ff4d2a;
  border: none;
  color: #ffffff;
`;

export const StyledBuyNow = styled(StyledRefoundButton)`
  /* flex: 1.2; */
  padding: 0 5px;
  background: #fff8f1;
  border: 1px solid #ff4d2a;
  color: #ff4d2a;
  margin: 0;
  width: initial;
  padding: 0 14px;
  display: flex;
  align-items: center;
  span {
    font-size: 14px;
  }
`;

export const StyledInviteFriends = styled(StyledToClass)`
  flex: 1;
  margin: 0;
  margin-left: 20px;
`;

export const StyledTimeExtendModal = styled.div`
  background: #ffffff;
  border: 1px solid #707070;
  opacity: 1;
  border-radius: 20px;
  margin: 0 5%;
  width: 90%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-top: 30vh;
  padding-bottom: 30px;
  p {
    /* white-space: pre; */
    font-size: 16px;
    text-align: center;
  }
  .bnt {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
  }
  .cancel {
    background: rgba(255, 151, 60, 0.16);
    border-radius: 200px;
    color: #ff973c;
    padding: 6px 34px;
    font-size: 16px;
  }
  .confirm {
    color: #ffffff;
    background: #ff973c;
    border-radius: 200px;
    padding: 6px 34px;
    font-size: 16px;
  }
`;

export const StyledFailedInvitation = styled.ul`
  margin-top: 30px;
  p {
    color: #333333;
    font-size: 14px;
    margin-top: 2px;
  }
`;
