/* eslint-disable react/prop-types */
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
  memo,
} from "react";
import Raven from "raven-js";
import { GA } from "../utils/Env";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import { Tabs, TabPane } from "../utils/Tabs/Tabs.js";
import "./SubActivityTabs.scss";

const ChildLearningTip = ({ recommend_activity, onChildLearningTipClick }) => {
  return (
    <div className="child_learning_tip">
      <p className="tip">根据小朋友的年级，建议学习</p>
      <p className="learning_level" onClick={onChildLearningTipClick}>
        {recommend_activity.name}
      </p>
    </div>
  );
};

// const TabsWrapper = ({ list, selectedTabIndex, setSelectedTabIndex }) => {
//   const onTabClick = useCallback(
//     e => {
//       const selectedTab = e.target.getAttribute("data-index");
//       if (selectedTab != null && typeof (selectedTab * 1) === "number") {
//         setSelectedTabIndex(selectedTab * 1);
//       }
//     },
//     [setSelectedTabIndex]
//   );

//   return (
//     <div className="sub_tabs" onClick={onTabClick}>
//       {list)}
//     </div>
//   );
// };

const SubActivityBrief = ({
  desc,
  canBuy,
  actId,
  code,
  isBuyTime,
  isNotExpirdOfGroupon,
  isDirGroup,
}) => {
  console.log("SubActivityBrief_props", canBuy, isBuyTime);
  const onBuyClick = () => {
    GA("Courses", code, actId);
    if (isDirGroup) {
      $(".new_shopping_bottom_group").trigger("click");
      return;
    }
    if (!isBuyTime || !isNotExpirdOfGroupon) {
      try {
        $(".activity-sign-before-sale").eq(0).trigger("click");
      } catch (e) {
        console.log(e);
        Raven.captureException(e);
      }
      return;
    }
    try {
      $(".activitiy_sign_in").eq(0).trigger("click");

      // $("#course_list_container_ul").scrollTop(0);

      $("#course_list_container_ul")
        .children()
        .each(function () {
          if ($(this).data("id") === actId) {
            $(this).trigger("click");
          }
        });

      setTimeout(() => {
        try {
          let scrollValue = 0;
          $("#course_list_container_ul")
            .children()
            .each(function (index) {
              scrollValue = $(this).height() + scrollValue;
              if ($(this).data("id") === actId) {
                $("#course_list_container_ul").scrollTop(0);
                $("#course_list_container_ul").scrollTop(
                  index === 0 ? 0 : scrollValue
                );
              }
            });
        } catch (e) {
          Raven.captureException(e);
          console.error(e);
        }
      }, 0);
    } catch (e) {
      Raven.captureException(e);
      console.error(e);
    }
  };

  return (
    <div className="sub_activity_brief">
      <LazyloadContent content={desc} noneLazy={true} />
      <div
        className={`buy_btn ${canBuy ? "can_buy" : "not_online"}`}
        onClick={!canBuy && !isBuyTime ? Function.prototype : onBuyClick}
      >
        {!canBuy && !isBuyTime
          ? "等待上线"
          : canBuy && (!isBuyTime || !isNotExpirdOfGroupon)
          ? "提前预约"
          : "立即报名"}
      </div>
    </div>
  );
};

const SubActivityTabs = memo(
  ({ recommend_activity, content_activities, isDirGroup }) => {
    const fakeTriggerTabClick = useRef(false);
    const pageMounted = useRef();
    const matchRecommendIndex = () => {
      const index = content_activities.findIndex(
        (act) => act.id === recommend_activity.id
      );
      if (index === -1) return 0;
      return index;
    };
    const [selectedTabIndex, setSelectedTabIndex] = useState(
      matchRecommendIndex
    );

    const tabClick = (e, activeTabIndex) => {
      setSelectedTabIndex(activeTabIndex * 1);
    };

    useLayoutEffect(() => {
      if (!pageMounted.current) {
        pageMounted.current = true;
        return;
      }
      if (!fakeTriggerTabClick.current) {
        document
          .getElementsByClassName("sub_activity_brief")
          [selectedTabIndex]?.scrollIntoView(true);
        window.scrollBy(0, -120);
      }
    }, [selectedTabIndex]);

    const onChildLearningTipClick = () => {
      $(".sub_tab_wrapper").eq(matchRecommendIndex()).trigger("click");
    };

    useEffect(() => {
      $(".sub_tabs").on("click", function (event) {
        if (event.originalEvent === undefined) {
          fakeTriggerTabClick.current = true;
        } else {
          fakeTriggerTabClick.current = false;
        }
      });
      const toScrollEles = document.getElementsByClassName(
        "sub_activity_brief"
      );
      const eleArr = Array.from(toScrollEles);
      const scroll = () => {
        eleArr.forEach((ele, index) => {
          const { top, height } = ele.getBoundingClientRect();
          // 下滑和上滑
          if (
            (top >= 0 && top <= 105) ||
            (top < 0 && height - Math.abs(top) >= 105)
          ) {
            $(".sub_tab_wrapper").eq(index).trigger("click");
          }
        });
      };
      document.addEventListener("scroll", scroll);
      return () => {
        document.removeEventListener("scroll", scroll);
      };
    }, []);

    // useEffect(() => {
    //   setTimeout(() => window.scroll(0, 0), 200);
    // }, []);

    return (
      <div className="comp_sub_activity_tabs">
        {recommend_activity && (
          <ChildLearningTip
            recommend_activity={recommend_activity}
            onChildLearningTipClick={onChildLearningTipClick}
          />
        )}
        <Tabs
          defaultAction={tabClick}
          styles="sub_tabs"
          defaultActiveIndex={matchRecommendIndex()}
          animatedTabTitle={true}
          noAnimatedScroll={true}
        >
          {content_activities
            .map((act) => act.navigate_name)
            .map((item, index) => {
              return (
                <TabPane
                  tab={<div className="sub_tab">{item}</div>}
                  styles="sub_tab_wrapper"
                  activeStyle="active"
                  key={index}
                >
                  {""}
                </TabPane>
              );
            })}
        </Tabs>

        <div className="mark_down_brief" id="tab_briefs">
          {content_activities.map((act) => (
            <SubActivityBrief
              code={act.code}
              desc={act.description}
              canBuy={act.canBuy}
              key={act.id}
              actId={act.id}
              isBuyTime={act.isBuyTime}
              isNotExpirdOfGroupon={act.isNotExpirdOfGroupon}
              isDirGroup={isDirGroup}
            />
          ))}
        </div>
      </div>
    );
  }
);

export default SubActivityTabs;
