/* eslint-disable */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogManager } from "../utils/Dialog";
import UserAgent from "../utils/UserAgent";
import URI from "jsuri";
import QueryString from "query-string";
import CitySelectList from "../utils/CitySelectList";
import CityArea from "../utils/city_area/CityArea.jsx";
import { SharePoster } from "../promotion_activities/SharePoster";
import queryString from "query-string";
import withAppPrivacy from "./WithAppPrivacy.jsx";
import PoetryAbsenteeInputTimeDialog from "./PoetryAbsenteeInputTimeDialog.jsx";
import { onMiniProgram, convertURLToJSON, print } from "../utils/helpers";
import smallQR from "qrcode_cherry.jpg";
import giftImg from "icon-gift.png";
import useDescribe from "icon_use_describe.png";
import alertQR from "qrcode_teacher_alert.png";
import arrowRight from "arrow-right.png";
import defaultUserAvatar from "icon_avatar.png";
import icon_settings from "coupon/icon_set.png";
import icon_ticket from "coupon/icon_ticket.png";
import qrcode from "media_lesson_activity/app_download.png";
import speedCalcQrcode from "media_lesson_activity/mp_speed_calc.jpg";
import poetryAppLearn from "tips_download.png";

import "./StudyPackageActivityList.scss";

// 评论组件
const Comments = ({
  comments,
  study_package_activity,
  initialIndex = 6,
  onTouchEnd,
  onTouchStart,
  onClick,
}) =>
  comments && comments.length > 0
    ? comments.map((comment, index) => (
        <Comment
          comment={comment}
          zIndex={initialIndex - index}
          onTouchEnd={onTouchEnd}
          onTouchStart={onTouchStart}
          study_package_activity={study_package_activity}
          key={comment.shortId}
          index={index}
          onMiniProgramClick={onClick}
        />
      ))
    : "";
const Comment = ({
  comment,
  zIndex,
  onTouchEnd,
  onTouchStart,
  study_package_activity,
  index,
  onMiniProgramClick,
}) => {
  let commentUrl = study_package_activity.comments_url;
  return (
    <div
      className="comment_wrapper"
      style={{ zIndex: zIndex }}
      data-index={comment.shortId}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <div className="comment_header">
        <img
          data-src={comment.photo_url}
          className="user_avatar lazyload"
        ></img>
        <div className="user_wrapper">
          <span className="user_name">{comment.name}</span>
          <span className="phase">({comment.phase})</span>
        </div>
      </div>
      <div className="comment_content">
        <a
          href="javascript:void(0)"
          data-url={`${commentUrl}&ref=home`}
          onClick={onMiniProgramClick}
        >
          <div
            className="content_comment"
            style={{
              width:
                index + 1 == study_package_activity.comments.length
                  ? "87%"
                  : "",
            }}
          >
            {comment.comment}
          </div>
        </a>
      </div>
      {index + 1 == study_package_activity.comments.length ? (
        <a
          className="readMore"
          href="javascript:void(0)"
          data-url={commentUrl}
          onClick={onMiniProgramClick}
        >
          查看更多
        </a>
      ) : (
        ""
      )}
    </div>
  );
};

const ActivityDescription = ({
  is_experience,
  description,
  start_time,
  phase,
  end_time,
  course_day_count,
  media_lessons_count,
  course_type,
  media_courses_count,
  is_parent_class,
  is_interview,
  is_spell,
}) => {
  if (is_experience) {
    return <p>{description}</p>;
  } else {
    return is_parent_class || is_interview ? (
      <p>{description}</p>
    ) : (
      [
        phase && start_time && end_time
          ? start_time && (
              <p key="time">
                上课时间：
                {(start_time.replace(/-/g, ".") + "").substr(0, 10)} —{" "}
                {(end_time.replace(/-/g, ".") + "").substr(0, 10)}
              </p>
            )
          : "",
        course_day_count && media_lessons_count ? (
          <p className="day_count_lessons_count" key="day_count_lessons_count">
            <span className="solid_circle_divider" />
            <span>{course_day_count}天</span>
            <span className="solid_circle_divider" />
            {course_type === "poetry" ? (
              is_spell ? (
                <span>{media_courses_count}节课</span>
              ) : (
                <span>{media_courses_count}首诗</span>
              )
            ) : (
              <span>{media_lessons_count}节课</span>
            )}
          </p>
        ) : (
          ""
        ),
      ]
    );
  }
};

// 单个活动上面部分 -- 已购买
const PaidActvityStatusContent = ({
  study_package_activity,
  onMiniProgramClick,
  onActivityStatusClick,
}) => {
  const absentee = study_package_activity["absentee"] === true;
  const start_time_selected =
    study_package_activity["start_time_input_required"] === false;
  const is_not_pre_paid = study_package_activity["bought_state"] != "pre_paid";
  const fixAwaitCourseDate = absentee && start_time_selected && is_not_pre_paid;

  return [
    <div className="buy_state" key="buy_state">
      已购买
    </div>,
    <a
      style={{
        borderRadius: !UserAgent.isMiniProgram()
          ? study_package_activity.id == 415
            ? "6px"
            : null
          : "6px",
        backgroundImage: study_package_activity.photo_url
          ? `url(${study_package_activity.photo_url})`
          : "",
      }}
      href="javascript:void(0)"
      onClick={onMiniProgramClick}
      data-mp-qrcode-label={
        study_package_activity.mp_qrcode_url
          ? "请下载『心芽学堂』App进行学习"
          : null
      }
      data-mp-qrcode-url={study_package_activity.mp_qrcode_url ? qrcode : null}
      data-is-speed-calc={study_package_activity.is_speed_calc}
      data-url={
        study_package_activity.user
          ? study_package_activity.study_url
          : study_package_activity.buy_url
      }
      key="url"
    >
      <h2
        style={{
          paddingLeft:
            study_package_activity.buy_format === "bargain" ? "5px" : "",
        }}
      >
        {study_package_activity.name}
      </h2>
      {study_package_activity.instance && <p>{study_package_activity.brief}</p>}
      {study_package_activity.id != 415 &&
        // instance: true, 代表 “不按天排课的课程”
        !study_package_activity.instance && (
          <ActivityDescription {...study_package_activity} />
        )}
      <p
        className="activity_status"
        style={{
          whiteSpace: fixAwaitCourseDate ? "nowrap" : "",
          position: fixAwaitCourseDate ? "relative" : "",
          marginTop: study_package_activity.id == 415 ? "55px" : "",
        }}
        onClick={onActivityStatusClick.bind(null, study_package_activity)}
      >
        <span style={{ paddingRight: fixAwaitCourseDate ? "55px" : "" }}>
          {
            // TODO:
            study_package_activity.id == 415
              ? "发货中"
              : study_package_activity.status
          }
        </span>
        {
          // 移除右箭头icon 样式
          fixAwaitCourseDate ? (
            ""
          ) : (
            <img data-src={arrowRight} className="lazyload" />
          )
        }
        {
          // 增加”修改"样式
          fixAwaitCourseDate && <span className="fix_await_date">修改</span>
        }
      </p>
    </a>,
  ];
};
// 单个活动上面部分 -- 未购买
const UnPaidActvityStatusContent = ({
  study_package_activity,
  onMiniProgramClick,
}) => {
  return [
    <div key="buy_state">
      {study_package_activity.price
        ? !UserAgent.isMiniProgram() && (
            <div className="buy_state">
              {study_package_activity.status === "等待下期"
                ? "等待下期"
                : study_package_activity.buy_format.includes("groupon") ||
                  study_package_activity.buy_format == "bargain"
                ? "¥" + study_package_activity.price + " 起"
                : "¥" + study_package_activity.price}
            </div>
          )
        : ""}
    </div>,
    <a
      href="javascript:void(0)"
      onClick={onMiniProgramClick}
      data-url={study_package_activity.buy_url}
      style={{
        backgroundImage: study_package_activity.photo_url
          ? `url(${study_package_activity.photo_url})`
          : "",
      }}
      key="url"
    >
      <h2
        style={{
          paddingLeft:
            study_package_activity.buy_format === "bargain" ? "5px" : "",
        }}
      >
        {study_package_activity.name}
      </h2>
      {study_package_activity.brief ? (
        <p>{study_package_activity.brief}</p>
      ) : (
        ""
      )}
      {study_package_activity.suitable_grades &&
      study_package_activity.suitable_grades.length > 0 ? (
        <p>适合：{study_package_activity.suitable_grades.join("/")}</p>
      ) : (
        ""
      )}
      {study_package_activity.is_parent_class ||
      study_package_activity.is_interview ? (
        ""
      ) : study_package_activity.course_day_count &&
        study_package_activity.media_lessons_count ? (
        <p className="day_count_lessons_count">
          {study_package_activity.status !== "等待下期" ? (
            <span className="solid_circle_divider"></span>
          ) : (
            ""
          )}
          {study_package_activity.status !== "等待下期" &&
            study_package_activity.start_time && (
              <span>
                {`${study_package_activity.start_time
                  .replace(/-/g, ".")
                  .substr(5, 5)}开课`}
              </span>
            )}
          {study_package_activity.start_time && (
            <span className="solid_circle_divider"></span>
          )}
          <span>{study_package_activity.course_day_count}天</span>
          <span className="solid_circle_divider"></span>
          <span>{study_package_activity.media_lessons_count}节课</span>
        </p>
      ) : (
        ""
      )}
    </a>,
  ];
};

// 邀请奖励
const InvitationBonus = ({ study_package_activity }) => {
  if (study_package_activity.buy_format.split(" ").includes("promotion")) {
    //  非砍价活动优惠
    if (study_package_activity.bonus != 0) {
      return (
        <div className="text">
          每邀请一位新朋友报名成功且不退款，
          <span className="reward-attention">
            奖励{study_package_activity.bonus}元奖学金（拼团除外）
          </span>
          ！可累加不封顶！(新朋友：未购买过任何心芽课程的用户)
        </div>
      );
    }
  } else if (study_package_activity.buy_format == "bargain") {
    // 砍价活动优惠
    return (
      <div className="text">
        每邀请1位新朋友关注『心芽学堂』公众号，即可
        <span className="reward-attention">优惠10元。</span>
      </div>
    );
  }
  //  非砍价活动优惠后台为0
  return (
    <div className="text">
      好课程就要与朋友分享，邀请朋友一起报名学习，共同进步！
    </div>
  );
};

// 单个活动包
class SingleActivity extends PureComponent {
  constructor(props) {
    super(props);
    function doInitialData(props) {
      let swipedShortId = [];
      let tempList = [];
      let current_time = new Date(
        props.study_package_activity.study_package_system_time
      );
      let start_time = new Date(props.study_package_activity.start_time);
      let end_time = new Date(props.study_package_activity.end_time);
      let bought_state = props.study_package_activity.bought_state;
      let status = props.study_package_activity.status;

      let shortId =
        status == "等待开课"
          ? props.study_package_activity.shortId
          : props.study_package_activity.comments &&
            props.study_package_activity.comments.length > 0
          ? "price" in props.study_package_activity ||
            "bonus" in props.study_package_activity
            ? props.study_package_activity.comments[0].shortId
            : props.study_package_activity.comments[1].shortId
          : "";

      if (
        status != "等待开课" &&
        props.study_package_activity.comments &&
        props.study_package_activity.comments.length > 0
      ) {
        swipedShortId.push(props.study_package_activity.shortId);
      }
      tempList.push(props.study_package_activity.shortId);
      props.study_package_activity.comments
        ? props.study_package_activity.comments.forEach((comment) => {
            tempList.push(comment.shortId);
          })
        : "";
      return { swipedShortId, shortId, tempList, status };
    }

    let initialData = doInitialData(props);
    this.state = {
      showRechargeOptions: this.props.remain_amount <= 0,
      status: initialData.status,
      shortId: initialData.shortId,
      handleTouchList: initialData.tempList,
      isAlertQR: false,
      swipedShortId: initialData.swipedShortId,
    };
    // 初始化触摸变量
    this.touchPosition = {
      startX: 0,
      startY: 0,
    };
    this.props.study_package_activity["status"] = initialData.status;
  }
  // 点击小圆点事件，用于处理点击原点改变滑块的状态和t触摸滑动步调一致
  onDotClick = (e) => {
    let shortId = e.currentTarget.getAttribute("data-index");
    let currentIndex = this.state.handleTouchList.indexOf(shortId);
    let tempList = [];
    tempList = this.state.handleTouchList.slice(0, currentIndex);
    this.setState({
      swipedShortId: tempList,
      shortId: shortId,
    });
  };
  onHandleTouch = (e) => {
    let shortId = e.currentTarget.getAttribute("data-index");
    let currentIndex = this.state.handleTouchList.indexOf(shortId);
    let moveEndX = e.changedTouches[0].pageX;
    let moveEndY = e.changedTouches[0].pageY;
    let X = moveEndX - this.touchPosition.startX;
    let Y = moveEndY - this.touchPosition.startY;
    if (Math.abs(X) > Math.abs(Y) && X > 0) {
      if (currentIndex == "-1" || currentIndex - 1 < 0) {
        currentIndex = 0;
      } else {
        this.state.swipedShortId.pop(shortId);
        currentIndex--;
      }
    } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
      if (
        currentIndex == "-1" ||
        currentIndex + 1 == this.state.handleTouchList.length
      ) {
        currentIndex = this.state.handleTouchList.length - 1;
      } else {
        this.state.swipedShortId.push(shortId);
        this.state.swipedShortId = [...new Set(this.state.swipedShortId)];
        currentIndex++;
      }
    }
    this.setState({
      preShortId: shortId,
      shortId: this.state.handleTouchList[currentIndex],
      swipedShortId: this.state.swipedShortId,
    });
  };

  onHandleTouchStart = (e) => {
    let startX = e.changedTouches[0].pageX;
    let startY = e.changedTouches[0].pageY;
    this.touchPosition.startX = startX;
    this.touchPosition.startY = startY;
  };
  onAlertQRCode = (e) => {
    let imageSrc = e.currentTarget.src;
    const { onAlert } = this.props;
    if (imageSrc) {
      onAlert(true, imageSrc);
    }
  };
  render() {
    const { study_package_activity } = this.props;
    const { status } = this.state;
    return (
      <div className="number-course-wrapper">
        <div className="course_module_entrance">
          <div className="box_shadow">
            {study_package_activity.buy_format === "bargain" ? (
              <div className="interview_subject_wrapper">
                {this.props.render(study_package_activity)}
              </div>
            ) : (
              <div className="number_course_list">
                {this.props.render(study_package_activity)}
              </div>
            )}

            {!UserAgent.isMiniProgram() && study_package_activity.id != 415 && (
              <div className="task_comments_container" key="comments_container">
                <div className="padding_add">
                  {status.includes("等待开课") ||
                  status.includes("选择开课日期") ||
                  status === "等待拼团成功" ? (
                    <div
                      className="task_wrapper"
                      style={{
                        left: "-" + this.state.swipedShortId.length * 100 + "%",
                      }}
                    >
                      <div
                        className="task_list"
                        style={{ zIndex: "6" }}
                        data-index={study_package_activity.shortId}
                        onTouchEnd={this.onHandleTouch}
                        onTouchStart={this.onHandleTouchStart}
                      >
                        <a
                          className="gride invitation"
                          href="javascript:void(0)"
                          data-url={study_package_activity.buy_url}
                          onClick={study_package_activity.onMiniProgramClick}
                        >
                          <div className="gride_wrapper">
                            <img
                              data-src={giftImg}
                              alt=""
                              className="lazyload"
                            />
                            <p>邀请朋友</p>
                          </div>
                        </a>
                        <div className="divider"></div>
                        <div className="gride use_guider">
                          <a
                            className="gride_wrapper"
                            href="javascript:void(0)"
                            data-url={
                              study_package_activity.course_type === "poetry"
                                ? study_package_activity.study_url
                                : study_package_activity.note_url
                            }
                            onClick={study_package_activity.onMiniProgramClick}
                          >
                            <img
                              data-src={useDescribe}
                              alt=""
                              className="lazyload"
                            />
                            <p>使用说明</p>
                          </a>
                        </div>
                        <div className="divider"></div>
                        <div className="gride add_teacher">
                          <div className="gride_wrapper">
                            {"teacher_qrcode_url" in study_package_activity ? (
                              <img
                                data-src={
                                  study_package_activity.teacher_qrcode_url
                                }
                                alt=""
                                onClick={this.onAlertQRCode}
                                className="lazyload"
                              />
                            ) : (
                              <img
                                data-src={study_package_activity.smallQR}
                                alt=""
                                onClick={this.onAlertQRCode}
                                className="lazyload"
                              />
                            )}
                            <p>添加老师</p>
                          </div>
                        </div>
                      </div>
                      <Comments
                        comments={study_package_activity.comments}
                        study_package_activity={study_package_activity}
                        onTouchEnd={(e) => this.onHandleTouch(e)}
                        onTouchStart={(e) => this.onHandleTouchStart(e)}
                        onClick={study_package_activity.onMiniProgramClick}
                      />
                    </div>
                  ) : (
                    <div
                      className="invitation_container"
                      style={{
                        left: "-" + this.state.swipedShortId.length * 100 + "%",
                      }}
                    >
                      {"bonus" in study_package_activity ||
                      "price" in study_package_activity ? (
                        <div
                          className="invitation_wrapper"
                          style={{ zIndex: "6" }}
                          onTouchEnd={this.onHandleTouch}
                          data-index={study_package_activity.shortId}
                          onTouchStart={this.onHandleTouchStart}
                        >
                          <div className="invitation_content">
                            <div className="title">邀请朋友</div>
                            <InvitationBonus
                              study_package_activity={study_package_activity}
                            />
                          </div>
                          <a
                            className="invitation_btn"
                            href="javascript:void(0)"
                            data-url={`${study_package_activity.buy_url}&ref=homeshare`}
                            onClick={study_package_activity.onMiniProgramClick}
                          >
                            邀请
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                      <Comments
                        comments={study_package_activity.comments}
                        study_package_activity={study_package_activity}
                        onTouchEnd={(e) => this.onHandleTouch(e)}
                        onTouchStart={(e) => this.onHandleTouchStart(e)}
                        onClick={study_package_activity.onMiniProgramClick}
                      />
                    </div>
                  )}
                </div>
                <div className="dots">
                  {"price" in study_package_activity ||
                  "bonus" in study_package_activity
                    ? study_package_activity.comments &&
                      study_package_activity.comments.length > 0 && (
                        <div
                          className="dot"
                          style={{
                            backgroundColor:
                              study_package_activity.shortId ==
                              this.state.shortId
                                ? "black"
                                : "",
                          }}
                          onClick={this.onDotClick}
                          data-index={study_package_activity.shortId}
                        ></div>
                      )
                    : ""}
                  {study_package_activity.comments &&
                  study_package_activity.comments.length > 0
                    ? study_package_activity.comments.map((comment, index) => (
                        <div
                          className="dot"
                          data-index={comment.shortId}
                          key={comment.shortId}
                          onClick={this.onDotClick}
                          style={{
                            backgroundColor:
                              comment.shortId == this.state.shortId
                                ? "black"
                                : "",
                          }}
                        ></div>
                      ))
                    : ""}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// 购买的活动包
class BoughtStudyPackageActivities extends PureComponent {
  toRender = (paidActivity) =>
    (paidActivity.bought_state == "bought" ||
      paidActivity.bought_state == "pre_paid") && (
      <PaidActvityStatusContent
        study_package_activity={paidActivity}
        onMiniProgramClick={paidActivity.onMiniProgramClick}
        onActivityStatusClick={this.props.onActivityStatusClick}
      />
    );

  render() {
    return this.props.BoughtActivities.map((boughtActivity, index) => {
      boughtActivity["onMiniProgramClick"] = this.props.onMiniProgramClick;
      return (
        <SingleActivity
          study_package_activity={boughtActivity}
          onAlert={this.props.onAlert}
          key={index}
          render={this.toRender}
        />
      );
    });
  }
}

// 未购买的活动包
export class UnboughtStudyPackageActivities extends PureComponent {
  toRender = (UnPaidActvity) =>
    UnPaidActvity.bought_state !== "bought" &&
    UnPaidActvity.bought_state !== "pre_paid" && (
      <UnPaidActvityStatusContent
        study_package_activity={UnPaidActvity}
        onMiniProgramClick={UnPaidActvity.onMiniProgramClick}
      />
    );

  render() {
    return this.props.UnboughtActivities.map((UnboughtActivity, index) => {
      UnboughtActivity["onMiniProgramClick"] = this.props.onMiniProgramClick;
      return (
        <SingleActivity
          study_package_activity={UnboughtActivity}
          key={index}
          render={this.toRender}
        />
      );
    });
  }
}

// 用户信息组件
class UserInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      overlayDisplay: "none",
    };
  }

  // 点击居住地事件，唤醒城市列表选择框
  selectCityAreaClick = () => {
    this.props.displaySubPickerDialog();
  };

  render() {
    const { user, baby, ecoupons } = this.props;
    return (
      <div className="user_info_container">
        <div className="user_info_wrapper">
          <div className="user_avatar">
            <img
              data-src={user.photo_url || defaultUserAvatar}
              alt=""
              className="lazyload"
            />
          </div>
          {user.name ? (
            <div className="text_info">
              <p>{user.name}</p>
              <div className="baby_city_info">
                {baby && baby.nickname ? (
                  <div className="baby_info">
                    {<span>{baby.nickname}</span>}
                    {baby.grade && <span>{baby.grade}</span>}
                  </div>
                ) : (
                  <a className="add_baby_info" href={baby.new_baby_path}>
                    添加孩子信息&nbsp;&nbsp;
                  </a>
                )}
                <div
                  className={
                    this.props.area ? "city_info_area" : "add_city_info"
                  }
                >
                  {this.props.area === undefined ? (
                    this.props.city ? (
                      `${this.props.city ? this.props.city : ""}`
                    ) : (
                      <div
                        className="area_text"
                        onClick={this.selectCityAreaClick}
                      >
                        选择居住地
                      </div>
                    )
                  ) : this.props.area.city ? (
                    this.props.area.city.name
                  ) : (
                    this.props.area.province.name
                  )}
                </div>
              </div>
            </div>
          ) : (
            <a
              href="javascript:void(0)"
              data-url={user.setting_path}
              className="to_edit_user_info"
              onClick={this.props.onMiniProgramClick}
            >
              完善个人信息
            </a>
          )}
        </div>
        <a
          className="user_setting_box"
          href="javascript:void(0)"
          data-url={user.setting_path}
          onClick={this.props.onMiniProgramClick}
        >
          <div className="setting_box">
            <img src={icon_settings} alt="" />
            <div className="user_setting">设置</div>
          </div>
        </a>
        <a
          className="user_coupon"
          href="javascript:void(0)"
          data-url={ecoupons.url}
          onClick={this.props.onMiniProgramClick}
        >
          <div className="setting_box">
            {ecoupons.count > 0 && (
              <div className="ticket_count">
                <span>{ecoupons.count}</span>
              </div>
            )}
            <img src={icon_ticket} alt="" />
            <div className="user_setting">优惠券</div>
          </div>
        </a>
      </div>
    );
  }
}

// TODO
// 单个课程包
// class SingleActivityGroup extends PureComponent {
//   constructor(props) {
//     super(props)
//     function doInitialData(props) {
//       let swipedShortId = []
//       let tempList = []
//       // let current_time = new Date(props.study_package_activity.study_package_system_time)
//       let shortId = props.study_package_activity.comments && props.study_package_activity.comments.length > 0 ? props.study_package_activity.comments[0].shortId : ''
//       props.study_package_activity.comments ? props.study_package_activity.comments.forEach(comment => {
//         tempList.push(comment.shortId)
//       }) : ''
//       return { swipedShortId, shortId, tempList }
//     }

//     let initialData = doInitialData(props)
//     this.state = {
//       showRechargeOptions: this.props.remain_amount <= 0,
//       // status: initialData.status,
//       shortId: initialData.shortId,
//       handleTouchList: initialData.tempList,
//       isAlertQR: false,
//       swipedShortId: initialData.swipedShortId,
//     }
//     // 初始化触摸变量
//     this.touchPosition = {
//       startX: 0,
//       startY: 0
//     }
//     // this.props.study_package_activity['status'] = initialData.status
//   }
//   // 点击小圆点事件，用于处理点击原点改变滑块的状态和t触摸滑动步调一致
//   onDotClick = (e) => {
//     let shortId = e.currentTarget.getAttribute('data-index')
//     let currentIndex = this.state.handleTouchList.indexOf(shortId)
//     let tempList = []
//     tempList = this.state.handleTouchList.slice(0, currentIndex)
//     this.setState({
//       swipedShortId: tempList,
//       shortId: shortId
//     })
//   }
//   onHandleTouch = (e) => {
//     let shortId = e.currentTarget.getAttribute('data-index')
//     let currentIndex = this.state.handleTouchList.indexOf(shortId)
//     let moveEndX = e.changedTouches[0].pageX
//     let moveEndY = e.changedTouches[0].pageY
//     let X = moveEndX - this.touchPosition.startX
//     let Y = moveEndY - this.touchPosition.startY
//     if (Math.abs(X) > Math.abs(Y) && X > 0) {
//       if (currentIndex == '-1' || currentIndex - 1 < 0) {
//         currentIndex = 0
//       } else {
//         this.state.swipedShortId.pop(shortId)
//         currentIndex--
//       }
//     } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
//       if (currentIndex == '-1' || currentIndex + 1 == this.state.handleTouchList.length) {
//         currentIndex = this.state.handleTouchList.length - 1
//       } else {
//         this.state.swipedShortId.push(shortId)
//         this.state.swipedShortId = [...new Set(this.state.swipedShortId)]
//         currentIndex++
//       }
//     }
//     this.setState({
//       preShortId: shortId,
//       shortId: this.state.handleTouchList[currentIndex],
//       swipedShortId: this.state.swipedShortId
//     })
//   }

//   onHandleTouchStart = (e) => {
//     let startX = e.changedTouches[0].pageX
//     let startY = e.changedTouches[0].pageY
//     this.touchPosition.startX = startX
//     this.touchPosition.startY = startY
//   }

//   render() {
//     const { study_package_activity } = this.props
//     return (
//       <div className='number-course-wrapper'>
//         <div className='course_module_entrance'>
//           <div className="box_shadow">

//             <div className="interview_subject_wrapper" >
//               <a href={study_package_activity.buy_url} style={{ backgroundImage: "url(" + study_package_activity.photo_url + ")" }} onClick={study_package_activity.onMiniProgramClick} data-url={study_package_activity.buy_url} key="url">
//                 <h2 style={{ paddingLeft: study_package_activity.buy_format === 'bargain' ? '5px' : '' }}>{study_package_activity.name}</h2>
//                 {
//                   study_package_activity.suitable_brief ? <p>{study_package_activity.suitable_brief}</p> : ''
//                 }
//                 {
//                   study_package_activity.suitable_grades && study_package_activity.suitable_grades.length > 0
//                   ?
//                   <p>适合：{study_package_activity.suitable_grades.join('/')}</p> : ''
//                 }
//               </a>
//             </div>

//             <div className="task_comments_container" key="comments_container">
//               <div className="padding_add">
//                 <div className="invitation_container" style={{ left: '-' + this.state.swipedShortId.length * 100 + '%' }}>
//                   <Comments comments={study_package_activity.comments} study_package_activity={study_package_activity} onTouchEnd={(e) => this.onHandleTouch(e)} onTouchStart={(e) => this.onHandleTouchStart(e)} onClick={study_package_activity.onMiniProgramClick} />
//                 </div>
//               </div>
//               <div className="dots">
//                 {
//                   study_package_activity.comments && study_package_activity.comments.length > 0 ?
//                     study_package_activity.comments.map((comment, index) =>
//                       <div className="dot" data-index={comment.shortId} key={comment.shortId} onClick={this.onDotClick} style={{ backgroundColor: comment.shortId == this.state.shortId ? 'black' : '' }}></div>
//                     ) : ''
//                 }
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )
//   }
// }
// // 课程包
// const StudyPackageGroups = ({ study_package_groups }) => {

//   return (
//     <div>
//       {
//         study_package_groups.map((study_package_activity, idx) =>
//           <SingleActivityGroup study_package_activity={study_package_activity} idx={idx} key={study_package_activity.id} />
//         )
//       }

//     </div>
//   )
// }

// 课程列表页父组件
class StudyPackageActivityList extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    let groupCommentId = 1;
    this.props.study_package_groups.forEach((activity) => {
      activity.comments
        ? activity.comments.forEach((comment) => {
            comment["shortId"] = groupCommentId + "";
            groupCommentId++;
          })
        : "";
    });

    function doResortActivities(props) {
      let boughtActivities = [];
      let unboughtActivities = [];
      let closedActivities = [];
      let unClosedActivities = [];
      let absenteeActivities = [];
      let speedCalcActivities = [];
      let sortedActivities = [];
      let unlearnedActivities = [];
      let status = "";
      let initialId = 1;
      props.activities.forEach((activity, idx) => {
        let current_time = new Date(props.study_package_system_time);
        let start_time = new Date(activity.start_time);
        let end_time = new Date(activity.end_time);
        let groupon_end_time = new Date(activity.groupon_endtime);
        if (
          activity.bought_state === "bought" ||
          activity.bought_state === "pre_paid"
        ) {
          // activity.start_time_input_required
          // activity.absentee
          if (activity.bought_state == "pre_paid") {
            // 团购（囤课和有期数）
            unClosedActivities.push(activity);
          } else {
            if (activity.is_speed_calc) {
              speedCalcActivities.push(activity);
            } else if (activity.absentee) {
              absenteeActivities.push(activity);
            } else {
              if (activity.sort_time) {
                sortedActivities.push(activity);
              }
              if (activity.sort_start_time) {
                unlearnedActivities.push(activity);
              }
            }
          }

          if (activity.absentee) {
            // 囤课不显示时间
            activity.start_time = "";
            activity.end_time = "";
          }

          if (activity.start_time_input_required) {
            // 选择开课日期
            status = "选择开课日期";
            // unClosedActivities.push(activity)
          } else {
            if (
              current_time < start_time &&
              activity.bought_state === "bought"
            ) {
              status = `等待开课`;
              if (activity.absentee) {
                status = `等待开课：${activity.expected_start_time}`;
              }
              // unClosedActivities.push(activity)
            } else if (activity.bought_state == "pre_paid") {
              status = "等待拼团成功";
              // unClosedActivities.push(activity)
            } else {
              if (current_time > end_time) {
                // status = '已完课'
                status = "去上课";
                // closedActivities.push(activity)
              } else {
                // status = '上课中'
                status = "去上课";
                if (activity.buy_format == "bargain") {
                  status = "去练习";
                }
                // unClosedActivities.push(activity)
              }
            }
          }
        } else {
          if (current_time > groupon_end_time) {
            status = "等待下期";
            unboughtActivities.push(activity);
          } else {
            status = "未购买";
            unboughtActivities.push(activity);
          }
        }
        activity["shortId"] = "activity" + (idx + 1);
        activity.comments
          ? activity.comments.forEach((comment) => {
              comment["shortId"] = initialId + "";
              initialId++;
            })
          : "";
        const { query, url } = queryString.parseUrl(activity.buy_url);
        query.ref = "home";
        activity.buy_url = url + "?" + queryString.stringify(query);
        activity["status"] = status;
        activity["study_package_system_time"] = props.study_package_system_time;
        activity["free"] = props.free;
        activity["user"] = props.user;
        // activity['onMiniProgramClick'] = (e) => activityTap(e)
      });
      // boughtActivities = [...unClosedActivities, ...closedActivities]

      sortedActivities.sort(function (a, b) {
        if (a.sort_time && b.sort_time) {
          return new Date(b.sort_time) - new Date(a.sort_time);
        }
        return 0;
      });

      unlearnedActivities.sort(function (a, b) {
        if (a.sort_start_time && b.sort_start_time) {
          return new Date(b.sort_start_time) - new Date(a.sort_start_time);
        }
        return 0;
      });

      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }
      print(...unClosedActivities);
      print("-------1-------");
      print(...sortedActivities);
      print("-------2-------");
      print(...unlearnedActivities);
      boughtActivities = [
        ...unClosedActivities,
        ...sortedActivities,
        ...unlearnedActivities,
        ...absenteeActivities,
        ...speedCalcActivities,
      ].filter(onlyUnique);
      return { boughtActivities, unboughtActivities };
    }
    this.state = {
      ...doResortActivities(this.props),
      isAlertMiniQRCode: false,
    };
  }
  activityTap = (e) => {
    let miniQRCode = e.currentTarget.getAttribute("data-mp-qrcode-url");
    let miniQRCodeLabel = e.currentTarget.getAttribute("data-mp-qrcode-label");
    let mini_program_url = e.currentTarget.getAttribute("data-url");
    let isSpeedCalc = e.currentTarget.getAttribute("data-is-speed-calc");

    if (isSpeedCalc === "true") {
      this.setState({
        alertCodeType: "whitePad",
        isAlertMiniQRCode: true,
        miniQRCode: speedCalcQrcode,
        miniQRCodeLabel: "请扫码开始练习",
      });
      return;
    }

    if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          if (mini_program_url.includes("bargain_activities?id=2")) {
            wx.miniProgram.navigateTo({
              url: "/pages/calculate_training/list",
            });
          } else {
            onMiniProgram(e, mini_program_url, {});
          }
        } else {
          console.log("miniQRCode", miniQRCode);
          if (miniQRCode) {
            this.setState({
              alertCodeType: "",
              isAlertMiniQRCode: true,
              miniQRCode: poetryAppLearn,
              miniQRCodeLabel,
            });
          } else {
            window.location.href = mini_program_url;
          }
        }
      });
    } else if (UserAgent.isWebBrowser()) {
      window.location.href = mini_program_url;
    } else if (UserAgent.isNativeClient()) {
      XinYaNativeBridge.navigate({ href: mini_program_url });
    }
    e.preventDefault();
  };

  // 弹小程序课程二维码
  onAlertMini = (isAlert) => {
    this.setState({
      isAlertMiniQRCode: isAlert,
    });
  };

  // 等待开课，点击弹老师二维码
  onAlert = (isAlert, imageSrc) => {
    this.setState({
      isAlertQR: isAlert,
      QRCode: imageSrc,
    });
  };

  componentDidMount() {
    if (this.props.area_info_remind_required === true) {
      let new_baby_path = this.props.baby && this.props.baby.new_baby_path;

      // 页面初始化的时候弹框 去选择城市区域
      let that = this;
      const buttons = [
        {
          label: new_baby_path ? "下次再填" : "下次再选",
          onClick: () => {
            return this.confirmDialog.close();
          },
        },
        {
          label: (
            <span style={{ fontWeight: "700" }}>
              {new_baby_path ? "去填写" : "去选择"}{" "}
            </span>
          ),
          onClick: () => {
            new_baby_path
              ? (window.location.href = new_baby_path)
              : that.setState({
                  showCitySelect: true,
                  displayPickerDialogStatus: true,
                });

            return this.confirmDialog.close();
          },
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          <p style={{ paddingLeft: "12px", paddingRight: "12px" }}>
            {new_baby_path
              ? "为了提供更好的服务，请填写孩子信息。"
              : "为了提供更好的服务，请选择您的居住地。"}
          </p>
        </Dialog>
      );
    }

    const share_title = this.props.share.share_title;
    const share_description = this.props.share.share_desc;
    const share_image_url = this.props.share.share_image_url;
    const share_url = window.location.href;

    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });

    if (UserAgent.isMiniProgram()) {
      let urlString = convertURLToJSON(share_url);
      wx.miniProgram.postMessage({
        data: { title: share_title, imgUrl: share_image_url, link: urlString },
      });
    }
  }

  updateUserAddress = (city) => {
    this.setState({
      city: city,
    });
  };

  // 父组件中 displayPickerDialogStatus 直接控制 dialog的显隐
  displayPickerDialog = (bool = false) => {
    this.setState({
      displayPickerDialogStatus: bool,
    });
  };

  // 需要传递给子组件调用的方法
  displaySubPickerDialog = () => {
    this.setState({
      displaySubPickerDialogStatus: true,
    });
  };

  onActivityStatusClick = (activity, e) => {
    if (activity.course_type === "poetry" && activity.absentee) {
      e.stopPropagation();

      if (!activity.poetry_start_time_select_url) {
        if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.navigate({ href: activity.buy_url });
        } else {
          window.location.href = activity.buy_url;
        }
        return;
      }
      this.setState({
        poetryAbsenteeActivity: activity,
        isShowAbsenteeStartTimeInputModal: true,
      });
    }
  };

  onCloseAbsenteeStartTimeInputModal = () => {
    this.setState({
      isShowAbsenteeStartTimeInputModal: false,
    });
  };

  render() {
    const {
      boughtActivities,
      unboughtActivities,
      QRCode,
      isShowAbsenteeStartTimeInputModal,
      poetryAbsenteeActivity,
    } = this.state;
    const { user, login_path, ecoupons } = this.props;

    const WithoutUser = (
      <div className="none_user" key="noneUser">
        <span className="none_user_text">请登录查看已报名的课程</span>
        <a href={login_path} className="none_user_loginBtn">
          登录
        </a>
      </div>
    );
    const Divider =
      boughtActivities.length > 0 &&
      unboughtActivities.length > 0 &&
      this.props.user ? (
        <div className="activity_divider" key="divider">
          <div className="left_line"></div>
          <div className="more_course">更多课程</div>
          <div className="right_line"></div>
        </div>
      ) : (
        ""
      );
    return (
      <div className="comp-number-course">
        {isShowAbsenteeStartTimeInputModal && (
          <PoetryAbsenteeInputTimeDialog
            activity={poetryAbsenteeActivity}
            onDialogClose={this.onCloseAbsenteeStartTimeInputModal}
          />
        )}
        {!this.props.user
          ? [
              WithoutUser,
              <UnboughtStudyPackageActivities
                UnboughtActivities={unboughtActivities}
                onMiniProgramClick={this.activityTap}
                key="unboughtStudyPackageActivities"
              />,
            ]
          : [
              <UserInfo
                province={this.state.province}
                city={this.state.city}
                user={this.props.user}
                baby={this.props.baby}
                onMiniProgramClick={this.activityTap}
                area={this.props.area}
                // show={this.state.showCitySelect}
                // user_url={this.props.user_url}
                displayPickerDialog={this.displayPickerDialog}
                displaySubPickerDialog={this.displaySubPickerDialog}
                ecoupons={ecoupons}
                key="userInfo"
              />,

              // 课程组
              // <StudyPackageGroups
              //   study_package_groups={this.props.study_package_groups}
              //   key="studyPackageGroup"
              // />,

              <BoughtStudyPackageActivities
                BoughtActivities={boughtActivities}
                onAlert={this.onAlert}
                onActivityStatusClick={this.onActivityStatusClick}
                onMiniProgramClick={this.activityTap}
                key="boughtStudyPackageActivities"
              />,
              Divider,
              <UnboughtStudyPackageActivities
                UnboughtActivities={unboughtActivities}
                onMiniProgramClick={this.activityTap}
                key="unboughtStudyPackageActivities"
              />,
            ]}
        {this.state.isAlertQR ? (
          <div className="alert_qr_code" onClick={() => this.onAlert(false)}>
            <div
              className="qr_phone_prompt"
              style={{ background: "white", top: "27vh" }}
            >
              <p style={{ paddingTop: "12px", fontSize: "15x" }}>
                请一定备注购课时的手机号码
              </p>
              <img
                data-src={QRCode}
                className="lazyload"
                style={{ width: 250 }}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        {
          // 点击弹框相应的二维码
          this.state.isAlertMiniQRCode && (
            <div className="alert_qr_code">
              <div
                className="qr_phone_prompt"
                style={
                  this.state.alertCodeType === "whitePad"
                    ? { background: "white", top: "27vh" }
                    : {}
                }
              >
                <div
                  className="close_minicode"
                  onClick={() => this.onAlertMini(false)}
                  style={
                    this.state.alertCodeType === "whitePad"
                      ? { right: -15, top: -40 }
                      : {}
                  }
                ></div>
                {this.state.alertCodeType === "whitePad" && (
                  <p style={{ paddingTop: "12px", fontSize: "15x" }}>
                    {this.state.miniQRCodeLabel}
                  </p>
                )}
                <img
                  data-src={this.state.miniQRCode}
                  className="lazyload"
                  style={
                    this.state.alertCodeType === "whitePad"
                      ? { width: 250 }
                      : {}
                  }
                />
              </div>
            </div>
          )
        }
        {(this.state.showCitySelect ||
          this.state.displaySubPickerDialogStatus) &&
          (this.state.displayPickerDialogStatus ||
            this.state.displaySubPickerDialogStatus) && (
            <CityArea
              provinces={this.props.provinces}
              area={this.props.area}
              user_url={this.props.user_url}
              updateUserAddress={this.updateUserAddress}
              displayPickerDialog={this.displayPickerDialog}
            />
          )}
      </div>
    );
  }
}

StudyPackageActivityList.propTypes = Object.assign({}, SharePoster.propTypes, {
  activities: PropTypes.array.isRequired,
});

StudyPackageActivityList.defaultProps = {
  free: "study_package",
};

export default withAppPrivacy(StudyPackageActivityList);
