/* eslint-disable react/prop-types */
/* eslint-disable lines-between-class-members */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { request, csrfHeaders } from "../request";
import { ToastManager } from "../Toast";
import "./CommentTags.scss";

class CommentTags extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_tag_id: this.props.current_tag_id,
      is_full: false,
    };
    this.getTaggedCommentList = this.getTaggedCommentList.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    let { current_tag_id } = nextProps;
    if (current_tag_id != this.props.current_tag_id) {
      this.setState({
        current_tag_id,
      });
    }
  }

  // 获取标签列表

  // 获取标签下的评论
  getTaggedCommentList = (tag_id) => {
    // this.toast = ToastManager.showLoading("拼命加载中...")
    var request_comment_url = this.props.request_comment_url;
    let url = request_comment_url;

    this.setState({
      current_tag_id: tag_id,
    });
    this.props.handle(tag_id);
  };

  // 评论标签伸缩
  toggleTags = () => {
    this.setState({
      is_full: !this.state.is_full,
    });
  };
  // 评论标签
  showCommentTags = (tags) => {
    const { current_tag_id, is_full } = this.state;
    tags = is_full ? tags : tags.slice(0, 12);
    return (
      <ul className={"tags " + (!is_full ? "tags_mini" : "")}>
        {tags.map((tag, idx) => (
          <li
            key={idx}
            onClick={this.getTaggedCommentList.bind(this, tag.id)}
            className={
              current_tag_id == tag.id
                ? this.props.isCustomizedCourse
                  ? "cutomized_comment_active"
                  : "active"
                : ""
            }
          >
            {tag.name}
            {this.props.isCustomizedCourse
              ? ` ${tag.count}`
              : ` (${tag.count})`}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { tags } = this.props;
    const { is_full } = this.state;
    return (
      <div className="comment_tags_content">
        {this.showCommentTags(tags)}
        {tags.length > 6 && (
          <div
            onClick={this.toggleTags.bind(this)}
            className={
              "comment_tag_arrow " +
              (is_full ? "comment_tag_arrow_up" : "comment_tag_arrow_down")
            }
          ></div>
        )}
        <div></div>
      </div>
    );
  }
}

CommentTags.propTypes = {};

export default CommentTags;
