import React from "react";
import styled from "styled-components";

import qrcode from "qrcode_wanwu.png";
import iconClose from "study_package/btn_close.png";

import icon_shade_dialog from "think_customized/icon_shade_dialog.png";
import icon_security_certificate from "think_customized/icon_security_certificate.png";

const StyledUserBoughtSuccessDialog = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  overflow-y: scroll;
  .user_bought_success_dialog {
    .btn-close {
      position: absolute;
      right: 0;
      top: -35px;
      width: 30px;
      height: 30px;
    }

    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    background: rgb(255, 255, 255);
    border-radius: 20px 20px 16px 16px;
    padding-bottom: 20px;
    .top_success_text {
      position: relative;
      color: #fff;
      background: #fff url(${icon_shade_dialog}) no-repeat left center;
      background-size: 100% 100%;
      height: 112px;
      width: 100%;
      border-radius: 20px 20px 0 0;
      display: flex;
      justify-content: center;

      .col-1 {
        position: absolute;
        top: 29px;
        left: 20px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
      }

      .col-2 {
        flex: 1;
        padding-left: 24px;
        text-align: center;
      }
      h3 {
        font-size: 24px;
        font-weight: 700;
        text-shadow: 0 0 4px #4e8de9;
        height: 50px;
        line-height: 50px;
        padding-top: 29px;
      }
      p {
        font-size: 16px;
        text-shadow: 0 0 4px #4e8de9;
      }
    }
    .center_join_group_text {
      .reply-text {
        margin-left: 5px;
        color: orange;
      }
      text-align: center;
      .join_text {
        font-size: 16px;
        font-weight: 700;
      }
      .group_box {
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        margin: 0 10px;
        padding: 5px 15px;
        img {
          width: 56px;
          height: 56px;
        }
        .group_content {
          font-size: 14px;
          padding-left: 10px;
          .group_name {
            .text {
              font-size: 16px;
              color: #333333;
              span {
                color: #fda634;
              }
            }
          }
          .free {
            color: orange;
            font-weight: bold;
          }
          .group_start_time {
            font-size: 14px;
            padding-top: 2px;
          }
        }
      }
    }
    .qrcode-placeholder {
      height: 157px;
      margin: 0 0 25px 0;
    }
    .qrcode {
      width: 157px;
      height: 157px;
      box-sizing: content-box;
      padding: 100px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .security_certificate {
      display: inline-block;
      font-size: 12px;
      color: #64c665;
      width: 100%;
      text-align: center;
      background: #f3fef6;
      .text {
        display: inline-block;
        background: #f3fef6 url(${icon_security_certificate}) no-repeat left
          center;
        background-size: 17px;
        padding: 5px 0 5px 25px;
      }
    }
  }
`;

export default class QRCodePopup extends React.Component {
  constructor(props) {
    super(props);

    this.state = { qrcodeVisible: false };
  }

  componentDidMount() {
    const width = this.rootContainer.clientWidth;
    const height = this.rootContainer.clientHeight;
    let paddingHoriz = (width - 157) / 2;
    let paddingTop = this.qrcodePlaceholder.offsetTop;
    let paddingBottom = height - this.qrcodePlaceholder.offsetTop - 157;
    this.setState({ qrcodeVisible: true, qrcodePadding: `${paddingTop}px ${paddingHoriz}px ${paddingBottom}px ${paddingHoriz}px` });
  }

  render() {
    const props = this.props;
    const state = this.state;
    return (
      <StyledUserBoughtSuccessDialog>
        <div className="user_bought_success_dialog" ref={ rootContainer => { this.rootContainer = rootContainer } }>
          <img class="btn-close" src={iconClose} onClick={ () => { props.onModalClose() } } />
          <div className="top_success_text">
            <img class="col-1" src={props.avatar_url} />
            <div class="col-2">
                <h3>仅差一步！</h3>
            </div>
          </div>
          <div className="center_join_group_text">
            <div className="group_box">
                <div className="group_content">
                  <div className="group_name">
                    <div className="join_text" style={{ marginBottom: 5 }}>长按二维码关注</div>
                    <p style={{ fontWeight: 'bold' }}>回复：<span className="reply-text">{ props.reply_text }</span>，你也可以<span className="free">免费领</span></p>
                  </div>
                </div>
            </div>
          </div>
          <div className="qrcode-placeholder" ref={ qrcodePlaceholder => { this.qrcodePlaceholder = qrcodePlaceholder } }></div>
          { state.qrcodeVisible && <img className="qrcode" src={props.qrcode_url} alt="" style={{ padding: state.qrcodePadding }} /> }
          <div className="center_join_group_text">
          </div>
          <div className="security_certificate">
            <div className="text">此二维码已通过安全认证，请放心扫码</div>
          </div>
        </div>
      </StyledUserBoughtSuccessDialog>
    );
  }
}
