/* eslint-disable */
import React, { Component } from "react";

export default class StudyPackageActivitySign extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          margin: "0 auto",
          left: "0",
          right: "0",
          top: "40%",
        }}
      >
        <p style={{ fontWeight: "700", fontSize: "17px" }}>
          小程序暂不支持报名
        </p>
        <p style={{ color: "#a9a6a6", fontSize: "13px" }}>
          仅供课程用户上课使用
        </p>
      </div>
    );
  }
}
