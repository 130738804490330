/* eslint-disable react/prop-types */
import React, { useEffect, useState, memo } from "react";
import ErrorBoundary from "../utils/ErrorBoundary"; // 组件错误
import { ActivityBanner1 } from "./activity_components"; // 上面的图片
import { request, csrfHeaders } from "../utils/request"; // 数据请求
import { ToastManager } from "../utils/Toast";
// import Option_Img from "../../../assets/images/11.10img/2.png"
// import TopImg from "./activity_components/ActivityBanner"
import {
  StudyTimeDemonstrate,
  CustomizationPieChart,
} from "./ThinkingCustomize";
import DiscountCourseList from "../study_package_activities/DiscountCourseList";
import { calcAllMediaLessonsCount } from "./ThinkingDetails"; // 计算总课程课时数
import { showBoughtSuccessDialog } from "../study_package_activities/ThinkingCustomize";
import { ThinkingBottomButton } from "../study_package_activities/ThinkingDetails";
import { onNavigate } from "../utils/helpers"; // 课程详情里面的东西
import {
  StyledCustomizeCourseContainer, // 课程大容器
  StyledCustomizeCourseContent, // 课程小容器块
  StyledSingleKnowledgeSection, // 课程小块
  StyledTopTitleAndDescribe, // 选项顶部的课题及描述
  StyledQuestionLocked, // 样式问题锁定
  StyledButtonOptionAndKnowledgeModule, // 样式按钮选项
  StyledKnowledgeModule, // 计算能力（阶梯等级模块）
  StyledOptionLi, // 样式选项
  StyledStudyTargetBox, // 学习目标
  StyledChooseCourseSuggest, // 资源建议
  StyledExclusiveCourse, // 排他性
  StyledCourseDetails, // 最后面的查看课程详情
  StyledRecommendContent, // 推荐内容
  StyledFriendPresentCoureContainer,
  // TopImg,
} from "./ThinkingCustomize.styled";
import icon_custom_list from "think_customized/icon_custom_list.png";
import icon_x_shade from "think_customized/icon_x_shade.png";
import icon_exclusive from "think_customized/icon_exclusive.png";
import icon_placeholder from "think_customized/icon_placeholder.png";
import icon_course_right from "../../../assets/images/icon_course_right.png";
import icon_header_shadow_close from "../../../assets/images/icon_header_shadow_close.png";
import "./Customize.scss";
const allQuizzes = new Array(5).fill(false);
const customizedDataArr = []; // 收集每一步的customized_data 成一个整体的集合
const topRankBackgrounds = [
  {
    background: "#D0F5ED",
    color: "#11CCA2",
  },
  {
    background: "#CEE2FF",
    color: "#7BAEF8",
  },
  {
    background: "#FFE4DD",
    color: "#FF6F4D",
  },
  {
    background: "#FFDCE7",
    color: "#FD739E",
  },
];

const judgeQuizBool = (questionIndex) => {
  return allQuizzes.forEach((_item, index) => {
    questionIndex >= index
      ? (allQuizzes[index] = true)
      : (allQuizzes[index] = false);
  });
};
// allQuizzes 五个数组
// questionIndex 显示到

// 选项顶部的课题及描述
const TopTitleAndDescribe = memo(
  ({ questionIndex, questionName, questionDescription }) => {
    return (
      <StyledTopTitleAndDescribe>
        <div className="index_name">
          <span>{questionIndex + 1}</span>
          {questionName}
        </div>
        {questionDescription && (
          <div className="desc">{questionDescription}</div>
        )}
      </StyledTopTitleAndDescribe>
    );
  }
);

// 未答（锁死状态）
const QuestionLocked = memo(({ desc }) => {
  return (
    <StyledQuestionLocked desc={desc}>
      <p>请先回答上个问题，才能继续哦~</p>
    </StyledQuestionLocked>
  );
});

const SingleKnowledgeSection = ({
  questionIndex,
  questionName,
  questionDescription,
  renderMainContent,
  options,
}) => {
  return (
    <StyledSingleKnowledgeSection
      questionIndex={questionIndex + 1}
      questionsLength={5}
    >
      <TopTitleAndDescribe
        questionIndex={questionIndex}
        questionName={questionName}
        questionDescription={questionDescription}
      />
      {renderMainContent({ questionIndex, options })}
    </StyledSingleKnowledgeSection>
  );
};
//  <TopImg>
//    <img src={Option_Img} alt=""/>
//  </TopImg>
// 第一题
const MainContentSection1 = ({ ...props }) => {
  console.log("第一题 props: ", props);
  const {
    questionIndex,
    question_result_url,
    options,
    setNextStep,
    setThirdRequestAllNextQuestion,
    setFourRequestAllNextQuestion,
    setFourPlusRequestAllNextQuestion,
    setFiveRequestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setActiveOptionButton,
    setSecondRequestAllNextQuestion,
    // requestAllNextQuestion,
  } = props;
  // const {
  //   customized_data,
  //   next_question,
  //   table_data,
  //   table_header,
  //   recommend_option,
  // } = requestAllNextQuestion;
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  console.log(
    props,
    "第一题nextQuestionRelativeData: ",
    nextQuestionRelativeData
  );
  if (nextQuestionRelativeData?.next_question) {
    console.log(
      props,
      "第一题nextQuestionRelativeData00000000: ",
      nextQuestionRelativeData
    );
    setSecondRequestAllNextQuestion(nextQuestionRelativeData);
  } else {
    console.log(
      props,
      "第一题nextQuestionRelativeData1111111111: ",
      nextQuestionRelativeData
    );
    setSecondRequestAllNextQuestion(nextQuestionRelativeData);
  }
  console.log(
    props,
    "第一题nextQuestionRelativeData33333333: ",
    nextQuestionRelativeData
  );
  setSecondRequestAllNextQuestion(nextQuestionRelativeData);
  setThirdRequestAllNextQuestion("");
  setFourRequestAllNextQuestion("");
  setFourPlusRequestAllNextQuestion("");
  setFiveRequestAllNextQuestion("");
  setLastResultRequestAllNextQuestion("");
  return (
    <>
      {
        <StyledButtonOptionAndKnowledgeModule
          data-questionindex={questionIndex}
        >
          <ButtonOptionUl
            questionResultUrl={question_result_url}
            questionIndex={questionIndex + 1}
            options={options}
            nextQuestionRelativeData={nextQuestionRelativeData}
            setNextQuestionRelativeData={setNextQuestionRelativeData}
            setNextStep={setNextStep}
            setActiveOptionButton={setActiveOptionButton}
          />
        </StyledButtonOptionAndKnowledgeModule>
      }
    </>
  );
};

const ButtonOptionUl = ({
  questionResultUrl,
  questionIndex,
  options,
  nextQuestionRelativeData,
  setNextQuestionRelativeData,
  setNextStep,
  setTableDataHighlight,
  setActiveOptionButton,
  setFourPlusRequestAllNextQuestion,
  setFiveRequestAllNextQuestion,
  setLastResultRequestAllNextQuestion,
}) => {
  const [activeOptionIndex, setActiveOptionIndex] = useState(-1);
  console.warn("questionIndex: ", questionIndex);
  console.warn("集合 customizedDataArr: ", customizedDataArr);

  const handleOptionClick = (e) => {
    setFourPlusRequestAllNextQuestion && setFourPlusRequestAllNextQuestion("");
    setFiveRequestAllNextQuestion && setFiveRequestAllNextQuestion("");
    setLastResultRequestAllNextQuestion &&
      setLastResultRequestAllNextQuestion("");
    console.warn(
      questionIndex,
      "集合 customizedDataArr: ",
      customizedDataArr.length
    );
    if (questionIndex !== 6) {
      judgeQuizBool(questionIndex - 1);
      setNextStep(questionIndex);
      if (questionIndex !== 5) {
        if (customizedDataArr.length >= questionIndex) {
          customizedDataArr.splice(
            questionIndex <= 0 ? 0 : questionIndex - 1,
            customizedDataArr.length - questionIndex + 1
            // data.customized_data
          );
        }
      } else {
        if (customizedDataArr[customizedDataArr.length - 1].question_id == 5) {
          customizedDataArr.splice(customizedDataArr.length - 1, 1);
        }
      }
    } else {
      customizedDataArr.splice(4, customizedDataArr.length - 3);
    }
    console.warn("集合 customizedDataArr1: ", customizedDataArr);
    setActiveOptionButton(true);

    const option_index = e.currentTarget.getAttribute("data-index");
    const option_id = e.currentTarget.getAttribute("data-id");

    if (options[option_index].highlight) {
      const highLight = options[option_index].highlight;
      setTableDataHighlight && setTableDataHighlight(highLight);
    }

    setActiveOptionIndex(option_index);
    const toast = ToastManager.showLoading("加载中...");
    // console.warn(
    //   "=============customizedDataArr===============",
    //   customizedDataArr,
    //   customizedDataArr.length
    // );

    // 注意这里所获取的findCurrentQuestionPreIndex，因为当前题目返回的customized_data要在接口请求之后，才能拿到，
    // 所以此时的customizedDataArr集合是当前上一次的，所以此处匹配的时候，直接匹配当前这一项就可以拿到所需要的值了
    // 不需要再去上一层索引查找
    // let findCurrentQuestionPreIndex = customizedDataArr.findIndex((item) =>
    //   (item.question_id == questionIndex) == 6 ? 4 : questionIndex - 1
    // );
    let findCurrentQuestionPreIndex = customizedDataArr.length - 1;
    // if (questionIndex == 6) {
    //   findCurrentQuestionPreIndex = 4;
    // } else {
    //   findCurrentQuestionPreIndex = customizedDataArr.findIndex(
    //     (item) => item.question_id == questionIndex
    //   );
    // }

    console.log("findCurrentQuestionPreIndex: ", findCurrentQuestionPreIndex);

    // 维护当前触发题目上一题的customized_data;
    const preCustomizedData = customizedDataArr[findCurrentQuestionPreIndex];

    console.log("197 preCustomizedData: ", preCustomizedData);

    const params = {
      question_id: parseInt(questionIndex),
      option_id: parseInt(option_id),
      pre_question_id: preCustomizedData?.question_id,
      sub_option_id: "",
      customized_data: JSON.stringify(preCustomizedData),
    };
    console.log("params: ", params);
    request({
      url: questionResultUrl,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {
        toast.cancel();
        const data = resp.data;
        console.log(questionIndex, "接口请求----data: ", data, allQuizzes);
        if (data && data.customized_study_package_activity) {
          for (let index = questionIndex; index < allQuizzes.length; index++) {
            allQuizzes[index] = true;
          }
        } else {
          allQuizzes[questionIndex] = true;
        }
        // data?.customized_study_package_activity

        setNextQuestionRelativeData(data);
        customizedDataArr.push(data.customized_data);
        // if (customizedDataArr.length > 0) {
        //   const alreadyQuestionIndex = customizedDataArr.findIndex(
        //     (item) => item?.question_id === data.customized_data?.question_id
        //   );
        //   // console.log("alreadyQuestionIndex: ", alreadyQuestionIndex);
        //   if (alreadyQuestionIndex >= 0) {
        //     customizedDataArr.splice(
        //       alreadyQuestionIndex <= 0 ? 0 : alreadyQuestionIndex,
        //       customizedDataArr.length -
        //         (alreadyQuestionIndex <= 0 ? 0 : alreadyQuestionIndex) -
        //         1,
        //       data.customized_data
        //     );
        //   } else {
        //     customizedDataArr.push(data.customized_data);
        //   }
        // } else {
        //   customizedDataArr.push(data.customized_data);
        // }
      })
      .catch((err) => {
        toast.cancel();
        console.log(err);
      });
  };

  console.log("59 activeOptionIndex: ", activeOptionIndex);
  console.log("nextQuestionRelativeData: ", nextQuestionRelativeData);
  return (
    options?.length > 0 && (
      <ul key={questionIndex}>
        {options.map((option, index) => {
          return (
            <StyledOptionLi
              key={index}
              data-id={option.id}
              data-index={index}
              hintExit={!!options.some((option) => option.hint)}
              active={activeOptionIndex == index}
              onClick={(e) => handleOptionClick(e)}
            >
              <div className="option_list">
                <span className="first_span">{option.name}</span>
                {option.hint && (
                  <span
                    className="last_span"
                    style={activeOptionIndex == index ? { color: "#fff" } : {}}
                  >
                    {option.hint}
                  </span>
                )}
              </div>
            </StyledOptionLi>
          );
        })}
      </ul>
    )
  );
};

const MainContentSection2 = ({ ...props }) => {
  console.log("第二题 props: ", props);
  const {
    is_thinking_continues_user,
    questionIndex,
    question_result_url,
    setNextStep,
    requestAllNextQuestion,
    setThirdRequestAllNextQuestion,
    setFourRequestAllNextQuestion,
    setFourPlusRequestAllNextQuestion,
    setFiveRequestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setActiveOptionButton,
  } = props;

  const [tableDataHighlight, setTableDataHighlight] = useState([]);

  console.log("tableDataHighlight: ", tableDataHighlight);
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  console.log("第二题-nextQuestionRelativeData: ", nextQuestionRelativeData);
  const {
    customized_data,
    next_question,
    table_data,
    table_header,
    recommend_option,
  } = requestAllNextQuestion;

  // 防止值没有更新导致污染，所以统一清除子属性 "highlight"
  if (table_data?.length > 0) {
    table_data.forEach((ele) => {
      ele.highlight && delete ele.highlight;
    });
  }

  if (table_data?.length > 0) {
    table_data.forEach((element) => {
      tableDataHighlight.forEach((ele) => {
        if (element.id == ele) {
          element["highlight"] = true;
        }
      });
    });
    console.log("264==table_data: ", table_data);
  }

  setThirdRequestAllNextQuestion(nextQuestionRelativeData);

  // setThirdRequestAllNextQuestion("");
  setFourRequestAllNextQuestion("");
  setFourPlusRequestAllNextQuestion("");
  setFiveRequestAllNextQuestion("");
  setLastResultRequestAllNextQuestion("");

  return (
    <StyledButtonOptionAndKnowledgeModule data-questionindex={questionIndex}>
      {
        // 计算能力（阶梯等级模块）
        table_data?.length > 0 && (
          <StyledKnowledgeModule>
            <div className="single_knowledge_list">
              <div
                className="top_rank"
                style={{
                  backgroundColor: "#CEE2FF",
                  color: "#11CCA2",
                  height: "34px",
                }}
              ></div>
              <div
                className="bottom_text"
                dangerouslySetInnerHTML={{
                  __html: customized_data.grade_name,
                }}
                style={{ backgroundColor: "#CEE2FF" }}
              ></div>
            </div>
            {table_data.map((item, index) => (
              <div className="single_knowledge_list" key={index}>
                <div
                  className="top_rank"
                  style={{
                    backgroundColor: topRankBackgrounds[index].background,
                    color: topRankBackgrounds[index].color,
                    height: `${59 + index * 5}px`,
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: table_header[index].name,
                    }}
                  ></p>
                  {table_header[index].equal &&
                    is_thinking_continues_user !== false && (
                      <p
                        style={{
                          marginTop: 3 * index,
                          marginLeft: 9,
                          marginRight: 9,
                          fontSize: 12,
                          fontWeight: 400,
                          lineHeight: "17px",
                        }}
                      >
                        {" ≈" + table_header[index].equal}
                      </p>
                    )}
                </div>
                <div
                  className="bottom_text"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  style={
                    item.highlight
                      ? { backgroundColor: "#FDB556", color: "#fff" }
                      : item.enable == true
                      ? { backgroundColor: "#f5f7fb" }
                      : item.enable == false
                      ? {
                          background: `#f5f7fb url(${icon_x_shade}) no-repeat center / 18px`,
                        }
                      : {}
                  }
                ></div>
              </div>
            ))}
          </StyledKnowledgeModule>
        )
      }
      {next_question?.options && (
        <ButtonOptionUl
          questionResultUrl={question_result_url}
          questionIndex={questionIndex + 1}
          options={next_question?.options}
          nextQuestionRelativeData={nextQuestionRelativeData}
          setNextQuestionRelativeData={setNextQuestionRelativeData}
          setNextStep={setNextStep}
          setTableDataHighlight={setTableDataHighlight}
          setActiveOptionButton={setActiveOptionButton}
        />
      )}
      {recommend_option && (
        <RecommendContent recommendOption={recommend_option} />
      )}
    </StyledButtonOptionAndKnowledgeModule>
  );
};
const MainContentSection3 = ({ ...props }) => {
  console.log("第三题 props: ", props);
  const {
    is_thinking_continues_user,
    questionIndex,
    question_result_url,
    setNextStep,
    requestAllNextQuestion,
    setFourRequestAllNextQuestion,
    setFourPlusRequestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setFiveRequestAllNextQuestion,
    setActiveOptionButton,
  } = props;
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  console.log("第三题-nextQuestionRelativeData: ", nextQuestionRelativeData);
  const [tableDataHighlight, setTableDataHighlight] = useState([]);
  const {
    customized_data,
    next_question,
    table_data,
    table_header,
    recommend_option,
  } = requestAllNextQuestion;

  // 防止值没有更新导致污染，所以统一清除子属性 "highlight"
  if (table_data?.length > 0) {
    table_data.forEach((ele) => {
      ele.highlight && delete ele.highlight;
    });
  }

  if (table_data?.length > 0) {
    table_data.forEach((element) => {
      tableDataHighlight.forEach((ele) => {
        if (element.id == ele) {
          element["highlight"] = true;
        }
      });
    });
    console.log("264==table_data: ", table_data);
  }

  setFourRequestAllNextQuestion(nextQuestionRelativeData);
  setFourPlusRequestAllNextQuestion("");
  setFiveRequestAllNextQuestion("");
  setLastResultRequestAllNextQuestion("");

  return (
    <StyledButtonOptionAndKnowledgeModule data-questionindex={questionIndex}>
      {
        // 课外培训经历（阶梯等级模块）
        table_data?.length > 0 && (
          <StyledKnowledgeModule>
            <div className="single_knowledge_list">
              <div
                className="top_rank"
                style={{
                  backgroundColor: "#CEE2FF",
                  color: "#11CCA2",
                  height: "34px",
                }}
              ></div>
              <div
                className="bottom_text"
                dangerouslySetInnerHTML={{
                  __html: customized_data.grade_name,
                }}
                style={{
                  backgroundColor: "#CEE2FF",
                }}
              ></div>
            </div>
            {table_data.map((item, index) => (
              <div className="single_knowledge_list" key={index}>
                <div
                  className="top_rank"
                  style={{
                    backgroundColor: topRankBackgrounds[index].background,
                    color: topRankBackgrounds[index].color,
                    height: `${59 + index * 5}px`,
                  }}
                >
                  <p
                    dangerouslySetInnerHTML={{
                      __html: table_header[index].name,
                    }}
                  ></p>
                  {table_header[index].equal &&
                    is_thinking_continues_user !== false && (
                      <p
                        style={{
                          marginTop: 3 * index,
                          marginLeft: 9,
                          marginRight: 9,
                          fontSize: 12,
                          fontWeight: 400,
                          lineHeight: "17px",
                        }}
                      >
                        {"≈" + table_header[index].equal}
                      </p>
                    )}
                </div>
                <div
                  className="bottom_text"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                  style={
                    item.highlight
                      ? {
                          backgroundColor: "#FDB556",
                          color: "#fff",
                        }
                      : item.enable == true
                      ? {
                          backgroundColor: "#f5f7fb",
                        }
                      : item.enable == false
                      ? {
                          background: `#f5f7fb url(${icon_x_shade}) no-repeat center / 18px`,
                        }
                      : {}
                  }
                ></div>
              </div>
            ))}
          </StyledKnowledgeModule>
        )
      }
      {next_question?.options && (
        <ButtonOptionUl
          questionResultUrl={question_result_url}
          questionIndex={questionIndex + 1}
          options={next_question?.options}
          nextQuestionRelativeData={nextQuestionRelativeData}
          setNextQuestionRelativeData={setNextQuestionRelativeData}
          setNextStep={setNextStep}
          setTableDataHighlight={setTableDataHighlight}
          setActiveOptionButton={setActiveOptionButton}
        />
      )}
      {recommend_option && (
        <RecommendContent recommendOption={recommend_option} />
      )}
    </StyledButtonOptionAndKnowledgeModule>
  );
};

const MainContentSection4 = ({ ...props }) => {
  console.log("第四题 props: ", props);
  const {
    questionIndex,
    question_result_url,
    setNextStep,
    requestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setFiveRequestAllNextQuestion,
    setFourPlusRequestAllNextQuestion,
    setActiveOptionButton,
  } = props;
  const [tableDataHighlight, setTableDataHighlight] = useState([]);
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  const {
    customized_data,
    next_question,
    table_data,
    table_header,
    recommend_option,
  } = requestAllNextQuestion;
  // 防止值没有更新导致污染，所以统一清除子属性 "highlight"
  if (table_data?.length > 0) {
    table_data.forEach((ele) => {
      ele.highlight && delete ele.highlight;
    });
  }

  if (table_data?.length > 0) {
    table_data.forEach((element) => {
      tableDataHighlight.forEach((ele) => {
        if (element.id == ele) {
          element["highlight"] = true;
        }
      });
    });
    console.log("2644==table_data: ", table_data);
  }

  if (nextQuestionRelativeData?.customized_study_package_activity) {
    console.log(
      "2644==table_datacustomized_study_package_activity: ",
      nextQuestionRelativeData
    );
    setLastResultRequestAllNextQuestion(nextQuestionRelativeData);
    setFourPlusRequestAllNextQuestion("");
    setFiveRequestAllNextQuestion("");
  } else if (nextQuestionRelativeData?.next_question?.id == 6) {
    console.log("2644==next_question==6: ", nextQuestionRelativeData);
    setFourPlusRequestAllNextQuestion(nextQuestionRelativeData);
    setFiveRequestAllNextQuestion("");
    setLastResultRequestAllNextQuestion("");
  } else if (nextQuestionRelativeData?.next_question?.id == 5) {
    console.log("2644==next_question==5: ", nextQuestionRelativeData);
    setFiveRequestAllNextQuestion(nextQuestionRelativeData);
    setFourPlusRequestAllNextQuestion("");
    setLastResultRequestAllNextQuestion("");
  } else {
    console.log("2644==next_question==null: ", nextQuestionRelativeData);
    setFourPlusRequestAllNextQuestion("");
    setFiveRequestAllNextQuestion("");
    setLastResultRequestAllNextQuestion("");
  }
  return (
    <StyledButtonOptionAndKnowledgeModule data-questionindex={questionIndex}>
      {table_data?.length > 0 && (
        <StyledStudyTargetBox>
          <div className="left_study_target">
            <div className="top_rank"></div>
            <div
              className="bottom_text"
              dangerouslySetInnerHTML={{
                __html: customized_data.grade_name,
              }}
            ></div>
          </div>
          <div className="right_study_target">
            <div className="top_rank top_right_rank">
              <p
                dangerouslySetInnerHTML={{
                  __html: table_header[0]?.name,
                }}
              ></p>
            </div>
            <div className="bottom_split_text">
              {table_data.map((item, index) => (
                <div
                  key={index}
                  className="fully_super_version"
                  style={
                    item.highlight
                      ? {
                          backgroundColor: "#FDB556",
                          color: "#fff",
                        }
                      : {}
                  }
                >
                  <div className="left">{item.name}</div>
                  <div className="right">{`${
                    item.media_lessons_count
                  }节课，${parseInt(item.total_days / 5)}周学完`}</div>
                </div>
              ))}
            </div>
          </div>
        </StyledStudyTargetBox>
      )}
      {next_question?.options && (
        <ButtonOptionUl
          questionResultUrl={question_result_url}
          questionIndex={questionIndex + 1}
          options={next_question?.options}
          nextQuestionRelativeData={nextQuestionRelativeData}
          setNextQuestionRelativeData={setNextQuestionRelativeData}
          setNextStep={setNextStep}
          setTableDataHighlight={setTableDataHighlight}
          setActiveOptionButton={setActiveOptionButton}
          setFourPlusRequestAllNextQuestion={setFourPlusRequestAllNextQuestion}
          setFiveRequestAllNextQuestion={setFiveRequestAllNextQuestion}
          setLastResultRequestAllNextQuestion={
            setLastResultRequestAllNextQuestion
          }
        />
      )}
      {recommend_option && (
        <RecommendContent recommendOption={recommend_option} />
      )}
    </StyledButtonOptionAndKnowledgeModule>
  );
};

const MainContentSection4Plus = ({ ...props }) => {
  console.log("第四_1题 MainContentSection4Plus: ", props);
  const {
    questionIndex,
    question_result_url,
    setNextStep,
    setNextShowList,
    requestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setFiveRequestAllNextQuestion,
    setActiveOptionButton,
  } = props;
  const [tableDataHighlight, setTableDataHighlight] = useState([]);
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  const {
    customized_data,
    next_question,
    table_data,
    table_header,
    recommend_option,
  } = requestAllNextQuestion;
  // 防止值没有更新导致污染，所以统一清除子属性 "highlight"
  if (table_data?.length > 0) {
    table_data.forEach((ele) => {
      ele.highlight && delete ele.highlight;
    });
  }

  if (table_data?.length > 0) {
    table_data.forEach((element) => {
      tableDataHighlight.forEach((ele) => {
        if (element.id == ele) {
          element["highlight"] = true;
        }
      });
    });
    console.log("264==table_data: ", table_data);
  }
  if (nextQuestionRelativeData?.next_question) {
    setFiveRequestAllNextQuestion(nextQuestionRelativeData);
    setLastResultRequestAllNextQuestion("");
  } else {
    setLastResultRequestAllNextQuestion(nextQuestionRelativeData);
    setFiveRequestAllNextQuestion("");
  }
  return (
    <StyledButtonOptionAndKnowledgeModule data-questionindex={questionIndex}>
      {/* {table_data?.length > 0 && ( */}
      <div className="icon_custom_lists">
        {/* {[1, 2, 3].map((item, index) => { */}
        {/* return ( */}
        <div className="icon_custom_list">
          <img src={icon_custom_list}></img>
          <div className="icon_custom_list_item">
            {next_question.phase +
              "≈" +
              next_question.equivalent +
              "，系统检测到您之前已购买"}
            {next_question.bought_study_package_activities.map(
              (item, index) => {
                return index == 0 ? "《" + item + "》" : "和《" + item + "》";
              }
            )}
            {"。"}
          </div>
        </div>

        <div
          className="icon_custom_list"
          onClick={() => {
            setNextShowList(true);
          }}
        >
          <img src={icon_custom_list}></img>
          <div className="icon_custom_list_item">
            {"基于不同时期的版本差异，目前" +
              next_question.phase +
              "比你原购买的“" +
              next_question.equivalent +
              "”阶段新增了 "}
            <span style={{ color: "#FDA634" }}>
              {next_question.additional_media_lessons_count + "节课"}
            </span>
            {"（"}
            <span style={{ color: "#7BAEF8" }}>点击这里查看新增课时</span>
            <img src={icon_course_right}></img>
            {"）"}
          </div>
        </div>
        <div className="icon_custom_list">
          <img src={icon_custom_list}></img>
          <div className="icon_custom_list_item">
            {"已有课时无需付费，新增的" +
              next_question.additional_media_lessons_count +
              "节课需支付"}
            <span style={{ color: "#FDA634" }}>
              {"￥"}
              {next_question.extra_price.toFixed(2)}
            </span>
            {"，您是否需要？"}
          </div>
        </div>
        {/* );
          })} */}
      </div>
      {/* )} */}
      {next_question?.options && (
        <ButtonOptionUl
          questionResultUrl={question_result_url}
          questionIndex={next_question?.id}
          options={next_question?.options}
          nextQuestionRelativeData={nextQuestionRelativeData}
          setNextQuestionRelativeData={setNextQuestionRelativeData}
          setNextStep={setNextStep}
          setTableDataHighlight={setTableDataHighlight}
          setActiveOptionButton={setActiveOptionButton}
        />
      )}
      {recommend_option && (
        <RecommendContent recommendOption={recommend_option} />
      )}
    </StyledButtonOptionAndKnowledgeModule>
  );
};

const MainContentSection5 = ({ ...props }) => {
  console.log("第五题 props: ", props);
  const {
    questionIndex,
    question_result_url,
    setNextStep,
    requestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
    setActiveOptionButton,
  } = props;
  const [nextQuestionRelativeData, setNextQuestionRelativeData] = useState({});
  const {
    next_question,
    table_data,
    table_header,
    recommend_option,
  } = requestAllNextQuestion;

  setLastResultRequestAllNextQuestion(nextQuestionRelativeData);
  return (
    <StyledButtonOptionAndKnowledgeModule data-questionindex={questionIndex}>
      <StyledChooseCourseSuggest>
        {table_header?.length > 0 && (
          <div className="top_suggest_title">{table_header[0]?.name}</div>
        )}
        <div className="bottom_suggest_course">
          {table_data?.pre_study_packages?.length > 0 && (
            <div className="study_premise_content">
              <div className="left_name">学习前提</div>
              {table_data?.pre_study_packages.map((item, index) => (
                <div className="right_text" key={index}>
                  {`"${item.name}"`}
                  {/* <span>（C2计算课todo）</span> */}
                </div>
              ))}
            </div>
          )}
          {table_data?.calc_study_packages?.length > 0 && (
            <div className="study_suggest_content">
              <div className="left_name">需同步学习</div>
              <div className="right_text right_text_box">
                {table_data?.calc_study_packages.map((item, index) => (
                  <div className="text_content" key={index}>
                    <p>
                      {`"${item.name}"`}
                      {/* <span>（C3计算课todo）</span> */}
                    </p>
                    <div
                      className="recommend_text"
                      style={
                        item.state == "建议学习"
                          ? {
                              backgroundColor: "#FDA634",
                            }
                          : {}
                      }
                    >
                      {item.state}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </StyledChooseCourseSuggest>
      {next_question?.options && (
        <ButtonOptionUl
          questionResultUrl={question_result_url}
          questionIndex={questionIndex == 5 ? questionIndex : questionIndex + 1}
          options={next_question?.options}
          nextQuestionRelativeData={nextQuestionRelativeData}
          setNextQuestionRelativeData={setNextQuestionRelativeData}
          setNextStep={setNextStep}
          setActiveOptionButton={setActiveOptionButton}
        />
      )}
      {recommend_option && (
        <RecommendContent recommendOption={recommend_option} />
      )}
    </StyledButtonOptionAndKnowledgeModule>
  );
};

const RecommendContent = ({ recommendOption }) => {
  return (
    <StyledRecommendContent>
      根据您在心芽的学习记录，推荐选择：
      <span>{recommendOption.name}</span>
    </StyledRecommendContent>
  );
};

// 专属课程
const PersonExclusiveCourse = ({
  tracksUrl,
  detailsUrl,
  lastResultRequestAllNextQuestion,
  activeOptionButton,
  allQuizzes,
  secondRequestAllNextQuestion,
  thirdRequestAllNextQuestion,
  fourRequestAllNextQuestion,
  fiveRequestAllNextQuestion,
  props,
}) => {
  useEffect(() => {
    // 获取结果，滑动到最底部
    window.scrollTo(0, document.body.scrollHeight);
  }, [lastResultRequestAllNextQuestion.customized_study_package_activity]);

  let total_course_lessons = 0;
  let allTotalStudyTimeMinutes = 0;
  if (lastResultRequestAllNextQuestion?.sub_study_packages?.length > 0) {
    total_course_lessons = calcAllMediaLessonsCount([
      ...lastResultRequestAllNextQuestion?.sub_study_packages,
    ]);
    // eslint-disable-next-line no-unused-expressions
    lastResultRequestAllNextQuestion?.sub_study_packages.reduce((_pre, cur) => {
      return (allTotalStudyTimeMinutes += cur.total_days);
    }, 0);
  }
  const exclusiveCourseTitle = () => {
    return (
      <div className="exclusive_course_title">
        <div className="course_title">
          <span className="left_circle"></span>
          <div className="title">我的专属课程</div>
          <span className="right_circle"></span>
        </div>
      </div>
    );
  };
  const hasExclusiveCourseContent = () => {
    return (
      <>
        {exclusiveCourseTitle()}
        <div className="has_exclusive_course_content">
          <div className="top_title">
            <img src={icon_exclusive} alt="" />
            <div className="title">
              <h3>
                {
                  lastResultRequestAllNextQuestion
                    ?.customized_study_package_activity?.name
                }
              </h3>
              {lastResultRequestAllNextQuestion?.calc_study_packages?.length >
                0 && (
                <p>
                  连报：
                  {lastResultRequestAllNextQuestion?.calc_study_packages.map(
                    (item, index) =>
                      item.name +
                      `${
                        index ==
                        lastResultRequestAllNextQuestion?.calc_study_packages
                          .length -
                          1
                          ? ""
                          : "、"
                      }`
                  )}
                </p>
              )}
            </div>
          </div>
          <StudyTimeDemonstrate
            totalCourseLessons={total_course_lessons}
            allTotalStudyTimeMinutes={allTotalStudyTimeMinutes}
          />
          <div className="course_distribution">
            <div className="distribution_title">课程分布</div>
            {lastResultRequestAllNextQuestion?.sub_study_packages?.length >
              0 && (
              <CustomizationPieChart
                sub_study_packages={
                  lastResultRequestAllNextQuestion?.sub_study_packages
                }
              />
            )}
            <ButtonCourseDetails
              url={detailsUrl}
              tracksUrl={tracksUrl}
              customizedActivity={
                lastResultRequestAllNextQuestion.customized_study_package_activity
              }
              props={props}
            />
          </div>
        </div>
      </>
    );
  };
  const noExclusiveCourseContent = () => {
    return (
      <>
        {exclusiveCourseTitle()}
        <div className="no_exclusive_course_content">
          <div className="no_course_box">
            <img src={icon_placeholder} alt="" />
            <p>暂无适合孩子的课程</p>
          </div>
          <ButtonCourseDetails
            url={detailsUrl}
            tracksUrl={tracksUrl}
            customizedActivity={
              lastResultRequestAllNextQuestion.customized_study_package_activity
            }
            props={props}
          />
        </div>
      </>
    );
  };
  return (
    <StyledExclusiveCourse>
      {activeOptionButton && (
        <>
          {
            // 最后一题答完才可以出结果
            allQuizzes[allQuizzes.length - 1] == true &&
              Object.keys(lastResultRequestAllNextQuestion)?.length > 0 &&
              hasExclusiveCourseContent()
          }
          {(secondRequestAllNextQuestion?.customized_data?.level === null ||
            thirdRequestAllNextQuestion?.customized_data?.level === null ||
            fourRequestAllNextQuestion?.customized_data?.level === null ||
            fiveRequestAllNextQuestion?.customized_data?.level === null) &&
            noExclusiveCourseContent()}
        </>
      )}
    </StyledExclusiveCourse>
  );
};

const ButtonCourseDetails = ({ url, tracksUrl, customizedActivity, props }) => {
  console.log("ButtonCourseDetails——props: ", props);
  console.log("ButtonCourseDetails: ", url, tracksUrl, customizedActivity);
  const handleClick = (e) => {
    if (props.is_thinking_user || !customizedActivity || !props.logged_in) {
      onNavigate(e, url);
    } else {
      showBoughtSuccessDialog(props);
    }

    if (tracksUrl) {
      let params = {};
      if (!props.is_thinking_user) {
        params = {
          operate: "free",
          tag: customizedActivity && customizedActivity.id,
        };
      } else {
        params = {
          operate: "check",
          tag: customizedActivity && customizedActivity.id,
        };
      }

      console.log("params: ", params);
      request({
        url: tracksUrl,
        method: "POST",
        headers: csrfHeaders,
        data: params,
      })
        .then((resp) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };
  if (customizedActivity?.id) {
    props.customizedActivityId = customizedActivity.id;
  }

  // TODO: 如果customized_study_package_activity中存在group_code, 将值塞到props中
  // if (customizedActivity.group_code) {
  //   props.group_code = customizedActivity.group_code;
  // }
  return props.is_thinking_user || !customizedActivity || !props.logged_in ? (
    <StyledCourseDetails>
      <div onClick={(e) => handleClick(e)}>
        <span>查看课程详情</span>
      </div>
    </StyledCourseDetails>
  ) : (
    <ThinkingBottomButton {...props} />
  );
};
const FriendPresentContents = ({
  study_package_activity_customize_url,
  share_user,
  buy_endtime,
  batch_ecoupons_amount,
}) => {
  return (
    <div className="friend_present_contents">
      <div className="top_course_content">
        <div className="normal_course_content">
          {share_user && (
            <div className="invite_user">
              <div className="user">
                <img
                  src={`${share_user?.photo_url}?imageView2/1/w/96/h/96`}
                  alt=""
                />
                <p>{share_user?.name}</p>
              </div>
              <span>送您</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ({ ...props }) => {
  console.log("Customize_props: ---Customize", props);
  const [activeOptionButton, setActiveOptionButton] = useState(false);
  const [
    secondRequestAllNextQuestion,
    setSecondRequestAllNextQuestion,
  ] = useState({});
  const [
    thirdRequestAllNextQuestion,
    setThirdRequestAllNextQuestion,
  ] = useState({});
  const [fourRequestAllNextQuestion, setFourRequestAllNextQuestion] = useState(
    {}
  );
  const [fiveRequestAllNextQuestion, setFiveRequestAllNextQuestion] = useState(
    {}
  );
  const [
    fourPlusRequestAllNextQuestion,
    setFourPlusRequestAllNextQuestion,
  ] = useState({});

  const [
    lastResultRequestAllNextQuestion,
    setLastResultRequestAllNextQuestion,
  ] = useState({});

  console.log("第二题secondRequest...: ", secondRequestAllNextQuestion);
  console.log("第三题thirdRequest...: ", thirdRequestAllNextQuestion);
  console.log("第四题fourRequest...: ", fourRequestAllNextQuestion);
  console.log("第五题fiveRequest...: ", fiveRequestAllNextQuestion);
  console.log("第4_1题fourPlusRequest...: ", fourPlusRequestAllNextQuestion);
  console.log(
    "最后一题提交后的结果 lastResultRequestAllNextQuestion: ",
    lastResultRequestAllNextQuestion
  );
  const resultUserOperationId =
    lastResultRequestAllNextQuestion?.user_operation_id;

  let studyPackageDetaisUrl = "";
  if (resultUserOperationId) {
    studyPackageDetaisUrl = `${props.study_package_activity_details_url}&user_operation_id=${resultUserOperationId}`;
  } else {
    studyPackageDetaisUrl = props.study_package_activity_details_url;
  }
  console.log("studyPackageDetaisUrl: ", studyPackageDetaisUrl);

  allQuizzes[0] = true;
  console.log("allQuizzes: ", allQuizzes);

  const [nextStep, setNextStep] = useState(0);
  const [nextShowList, setNextShowList] = useState(false);
  console.log("nextStep: ", nextStep);
  console.log("最终-allQuizzes: ", allQuizzes);

  // 第二题的接口成功的所有数据
  const isSecondRequestAllNextQuestion = allQuizzes[1]
    ? secondRequestAllNextQuestion
    : {};

  // 第三题的接口成功的所有数据
  const isThirdRequestAllNextQuestion = allQuizzes[2]
    ? thirdRequestAllNextQuestion
    : {};
  // 第四题的接口成功的所有数据
  const isFourRequestAllNextQuestion = allQuizzes[3]
    ? fourRequestAllNextQuestion
    : {};
  // 第五题的接口成功的所有数据
  const isFiveRequestAllNextQuestion =
    fiveRequestAllNextQuestion &&
    Object.keys(fiveRequestAllNextQuestion).length > 0
      ? fiveRequestAllNextQuestion
      : {};
  console.log("22222222222222222222222", fourPlusRequestAllNextQuestion);
  const isFourPlusRequestAllNextQuestion =
    fourPlusRequestAllNextQuestion &&
    Object.keys(fourPlusRequestAllNextQuestion).length > 0
      ? fourPlusRequestAllNextQuestion
      : {};
  const ownedApiResponseStudyPackages = [];
  fourPlusRequestAllNextQuestion &&
    Object.keys(fourPlusRequestAllNextQuestion).length > 0 &&
    ownedApiResponseStudyPackages.push(
      isFourPlusRequestAllNextQuestion.next_question.extra_media_lessons
    );
  const secondQuizLevelIsNull =
    secondRequestAllNextQuestion?.customized_data?.level === null;
  const thirdQuizLevelIsNull =
    thirdRequestAllNextQuestion?.customized_data?.level === null;
  const fourQuizLevelIsNull =
    fourRequestAllNextQuestion?.customized_data?.level === null;
  const fiveQuizLevelIsNull =
    fiveRequestAllNextQuestion?.customized_data?.level === null;
  const fourPlusQuizLevelIsNull =
    fourPlusRequestAllNextQuestion?.customized_data?.level === null;

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  const handelShow = () => {
    setNextShowList(false);
  };
  return (
    <>
      {nextShowList &&
        isFourPlusRequestAllNextQuestion &&
        Object.keys(isFourPlusRequestAllNextQuestion).length && (
          <DiscountCourseList
            ownedApiResponseStudyPackages={ownedApiResponseStudyPackages}
            onModalClose={handelShow}
          />
          // <div className="course_list">
          //   <div className="course_list_cancle">
          //     <img
          //       src={icon_header_shadow_close}
          //       onClick={() => {
          //         setNextShowList(false);
          //       }}
          //     ></img>
          //   </div>
          //   <ul className="course_list_lists">
          //     {isFourPlusRequestAllNextQuestion.next_question?.extra_media_lessons.map(
          //       (item, i) => {
          //         return <li key={"b" + i}>{item}</li>;
          //       }
          //     )}
          //   </ul>
          // </div>
        )}
      <ErrorBoundary>
        <StyledCustomizeCourseContainer
          style={!props.is_thinking_user ? { paddingBottom: "60px" } : {}}
        >
          <StyledFriendPresentCoureContainer>
            {/* <FriendPresentContents {...props} /> */}
          </StyledFriendPresentCoureContainer>
          <ActivityBanner1 />
          <StyledCustomizeCourseContent>
            <SingleKnowledgeSection
              questionIndex={0}
              questionName={props.questions[0]?.name}
              questionDescription={props.questions[0]?.description}
              renderMainContent={(extraProps) => (
                <MainContentSection1
                  {...extraProps}
                  setNextStep={setNextStep}
                  // requestAllNextQuestion={props.questions[0]}
                  setSecondRequestAllNextQuestion={
                    setSecondRequestAllNextQuestion
                  }
                  setThirdRequestAllNextQuestion={
                    setThirdRequestAllNextQuestion
                  }
                  setFourRequestAllNextQuestion={setFourRequestAllNextQuestion}
                  setFiveRequestAllNextQuestion={setFiveRequestAllNextQuestion}
                  setFourPlusRequestAllNextQuestion={
                    setFourPlusRequestAllNextQuestion
                  }
                  setLastResultRequestAllNextQuestion={
                    setLastResultRequestAllNextQuestion
                  }
                  setActiveOptionButton={setActiveOptionButton}
                  {...props}
                />
              )}
              options={props.questions[0].options}
            />

            {activeOptionButton && secondQuizLevelIsNull ? (
              ""
            ) : (
              <SingleKnowledgeSection
                questionIndex={1}
                questionName={props.questions[1]?.name}
                questionDescription={props.questions[1]?.description}
                renderMainContent={(extraProps) => {
                  return Object.keys(isSecondRequestAllNextQuestion)?.length ==
                    0 ? (
                    <QuestionLocked desc="" />
                  ) : (
                    <MainContentSection2
                      {...extraProps}
                      setNextStep={setNextStep}
                      requestAllNextQuestion={isSecondRequestAllNextQuestion}
                      setThirdRequestAllNextQuestion={
                        setThirdRequestAllNextQuestion
                      }
                      setFourRequestAllNextQuestion={
                        setFourRequestAllNextQuestion
                      }
                      setFiveRequestAllNextQuestion={
                        setFiveRequestAllNextQuestion
                      }
                      setFourPlusRequestAllNextQuestion={
                        setFourPlusRequestAllNextQuestion
                      }
                      setLastResultRequestAllNextQuestion={
                        setLastResultRequestAllNextQuestion
                      }
                      setActiveOptionButton={setActiveOptionButton}
                      {...props}
                    />
                  );
                }}
              />
            )}
            {activeOptionButton &&
            (secondQuizLevelIsNull || thirdQuizLevelIsNull) ? (
              ""
            ) : (
              <SingleKnowledgeSection
                questionIndex={2}
                questionName={props.questions[2]?.name}
                questionDescription={props.questions[2]?.description}
                renderMainContent={(extraProps) => {
                  return Object.keys(isThirdRequestAllNextQuestion)?.length ==
                    0 ? (
                    <QuestionLocked desc="" />
                  ) : (
                    <MainContentSection3
                      {...extraProps}
                      setNextStep={setNextStep}
                      requestAllNextQuestion={isThirdRequestAllNextQuestion}
                      setFourRequestAllNextQuestion={
                        setFourRequestAllNextQuestion
                      }
                      setFiveRequestAllNextQuestion={
                        setFiveRequestAllNextQuestion
                      }
                      setFourPlusRequestAllNextQuestion={
                        setFourPlusRequestAllNextQuestion
                      }
                      setLastResultRequestAllNextQuestion={
                        setLastResultRequestAllNextQuestion
                      }
                      setActiveOptionButton={setActiveOptionButton}
                      {...props}
                    />
                  );
                }}
              />
            )}
            {activeOptionButton &&
            (secondQuizLevelIsNull ||
              thirdQuizLevelIsNull ||
              fourQuizLevelIsNull) ? (
              ""
            ) : (
              <SingleKnowledgeSection
                questionIndex={3}
                questionName={props.questions[3]?.name}
                questionDescription={
                  isFourRequestAllNextQuestion?.next_question?.description ||
                  props.questions[3]?.description
                }
                renderMainContent={(extraProps) => {
                  return Object.keys(isFourRequestAllNextQuestion)?.length ==
                    0 ? (
                    <QuestionLocked desc="" />
                  ) : (
                    <MainContentSection4
                      {...extraProps}
                      setNextStep={setNextStep}
                      requestAllNextQuestion={isFourRequestAllNextQuestion}
                      setLastResultRequestAllNextQuestion={
                        setLastResultRequestAllNextQuestion
                      }
                      setFiveRequestAllNextQuestion={
                        setFiveRequestAllNextQuestion
                      }
                      setFourPlusRequestAllNextQuestion={
                        setFourPlusRequestAllNextQuestion
                      }
                      setActiveOptionButton={setActiveOptionButton}
                      {...props}
                    />
                  );
                }}
              />
            )}
            {(activeOptionButton &&
              (secondQuizLevelIsNull ||
                thirdQuizLevelIsNull ||
                fourQuizLevelIsNull ||
                fourPlusQuizLevelIsNull)) ||
            !isFourPlusRequestAllNextQuestion ||
            Object.keys(isFourPlusRequestAllNextQuestion)?.length == 0 ? (
              ""
            ) : (
              <SingleKnowledgeSection
                questionIndex={4}
                questionName={
                  isFourPlusRequestAllNextQuestion?.next_question?.name
                }
                questionDescription={
                  isFourPlusRequestAllNextQuestion?.next_question?.description
                }
                renderMainContent={(extraProps) => {
                  return Object.keys(isFourPlusRequestAllNextQuestion)
                    ?.length == 0 ? (
                    <QuestionLocked desc="" />
                  ) : (
                    <MainContentSection4Plus
                      {...extraProps}
                      setNextStep={setNextStep}
                      requestAllNextQuestion={isFourPlusRequestAllNextQuestion}
                      setLastResultRequestAllNextQuestion={
                        setLastResultRequestAllNextQuestion
                      }
                      setNextShowList={setNextShowList}
                      setFiveRequestAllNextQuestion={
                        setFiveRequestAllNextQuestion
                      }
                      setActiveOptionButton={setActiveOptionButton}
                      {...props}
                    />
                  );
                }}
              />
            )}
            {activeOptionButton &&
            (secondQuizLevelIsNull ||
              thirdQuizLevelIsNull ||
              fourQuizLevelIsNull ||
              fourPlusQuizLevelIsNull ||
              fiveQuizLevelIsNull ||
              !isFiveRequestAllNextQuestion?.next_question) ? (
              ""
            ) : (
              <SingleKnowledgeSection
                questionIndex={
                  isFourPlusRequestAllNextQuestion &&
                  Object.keys(isFourPlusRequestAllNextQuestion).length
                    ? 5
                    : 4
                }
                questionName={props.questions[4]?.name}
                questionDescription={props.questions[4]?.description}
                renderMainContent={(extraProps) => {
                  return Object.keys(isFiveRequestAllNextQuestion)?.length ==
                    0 ? (
                    <QuestionLocked desc="" />
                  ) : (
                    <MainContentSection5
                      {...extraProps}
                      setNextStep={setNextStep}
                      requestAllNextQuestion={isFiveRequestAllNextQuestion}
                      setLastResultRequestAllNextQuestion={
                        setLastResultRequestAllNextQuestion
                      }
                      setActiveOptionButton={setActiveOptionButton}
                      {...props}
                    />
                  );
                }}
              />
            )}
          </StyledCustomizeCourseContent>

          {/* 专属课程 */}
          <PersonExclusiveCourse
            tracksUrl={props.user_interaction_tracks_url}
            detailsUrl={studyPackageDetaisUrl}
            lastResultRequestAllNextQuestion={lastResultRequestAllNextQuestion}
            activeOptionButton={activeOptionButton}
            allQuizzes={allQuizzes}
            secondRequestAllNextQuestion={secondRequestAllNextQuestion}
            thirdRequestAllNextQuestion={thirdRequestAllNextQuestion}
            fourRequestAllNextQuestion={fourRequestAllNextQuestion}
            fiveRequestAllNextQuestion={fiveRequestAllNextQuestion}
            props={props}
          />
        </StyledCustomizeCourseContainer>
      </ErrorBoundary>
    </>
  );
};
