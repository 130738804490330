/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import {
  convertMonthDay,
  addMonth,
  format as formatTime,
} from "../utils/formatAudioTime";
// import { Object } from 'core-js';
import { print, isEmpty } from "../utils/helpers";
import { Dialog, DialogManager } from "../utils/Dialog";
import "./ChoosePhase.scss";

export default class ChoosePhase extends React.Component {
  constructor(props) {
    super(props);
    print("choose_pahse: ", props);
    console.log("choosePhaseShow: ", this.props.choosePhaseShow);

    props.absenteeActivity.phases.sort((prev, next) => {
      return new Date(prev.start_time) - new Date(next.start_time);
    });
    // var result = []
    // if (props.absenteeActivity.selected_start_time) {
    //   result = props.absenteeActivity.selected_start_time.match(/(\d{0,4})年(\d{0,2})月/)
    // }
    const nextSixMonths = [1, 2, 3, 4, 5, 6].map((e) => {
      var date = props.absenteeActivity.phases.length
        ? new Date([...props.absenteeActivity.phases].pop().start_time)
        : "";
      var formatDate = addMonth(date, e);
      let nextDate = {
        id: 10000 - e,
        className: "select",
        start_time: `${formatDate.getFullYear()}年${
          formatDate.getMonth() + 1
        }月${props.absenteeActivity.is_instance_activity ? "1日" : ""}`,
      };
      // if (result.length > 0) {
      //   let year = result[1]
      //   let month = result[2]
      //   if (year && month && (year == formatDate.getFullYear()) && (month == (formatDate.getMonth()+1))) {
      //     nextDate['className'] = 'selected'
      //   }
      // }
      if (
        props.absenteeActivity.selected_start_time &&
        props.absenteeActivity.selected_start_time === nextDate.start_time
      ) {
        nextDate["className"] = "selected";
      }
      return nextDate;
    });

    let lastPhase = {
      id: -1,
      name: props.absenteeActivity.is_instance_activity ? "其他开课时间" : "没想好，先选择个大概开课时间",
      start_time: "",
      end_time: "",
    };
    // if (props.absenteeActivity.selected_start_time) {
    //   lastPhase['start_time'] = props.absenteeActivity.selected_start_time
    // }
    props.absenteeActivity.phases.push(lastPhase);

    const phases = props.absenteeActivity.phases.map((element) => {
      // if (element.id == -1 && !isEmpty(element.start_time)) {
      //   return Object.assign({className: 'selected'}, element)
      // } else {
      // }
      return Object.assign({ className: "select" }, element);
    });

    this.state = {
      showDate: false,
      choosePhaseDisplay: props.isNoShowChoosePhase ? "none" : "block",
      phases: phases,
      nextSixMonths: nextSixMonths,
      // chooseDate: props.absenteeActivity.selected_start_time ? lastPhase : null
    };
  }

  closebtnClick = (e) => {
    e.preventDefault();
    // if (this.props.getChoosePhaseShowStatus) {
    //   this.props.getChoosePhaseShowStatus(false);
    // } else {
    this.setState({
      choosePhaseDisplay: "none",
    });
    // }
    let dismissAction = this.props.closeBtnAction;
    if (typeof dismissAction === "function") {
      dismissAction();
    }
  };

  componentWillReceiveProps(nextProps) {
    // console.error(this.props.isNoShowChoosePhase, nextProps.isNoShowChoosePhase);
    if (this.props.isNoShowChoosePhase !== nextProps.isNoShowChoosePhase) {
      this.setState({
        choosePhaseDisplay: "block",
      });
    }
  }

  sureBtnClick = (e) => {
    e.preventDefault();
    if (!this.state.chooseDate) {
      const buttons = [
        {
          label: "我知道了",
          onClick: () => this.confirmDialog.close(),
        },
      ];
      this.confirmDialog = DialogManager.open(
        <Dialog buttons={buttons}>
          请选择《{this.props.absenteeActivity.name}》上课时间
        </Dialog>
      );
      return;
    }
    this.setState({
      choosePhaseDisplay: "none",
    });
    let sureAction = this.props.sureBtnAction;
    if (typeof sureAction === "function") {
      // let selectedPhase = this.state.phases.find(e => {
      //   if (e.className == 'selected') {
      //     return e
      //   }
      // })
      // sureAction(selectedPhase)
      print("sureBtnClick chooseDate", this.state.chooseDate);
      sureAction(this.state.chooseDate);
    }
  };

  choosePhase = (e) => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("data-id");
    let phase = this.state.phases.find((e) => {
      if (e.id == id) return e;
    });
    var newPhases = this.state.phases.map((element) => {
      var newPhase = element;
      if (phase.id == element.id) {
        // newPhase.className = 'selected'
        if (newPhase.id == -1 && isEmpty(newPhase.start_time)) {
          newPhase.className = "select";
        }
      } else {
        newPhase.className = "select";
      }
      return newPhase;
    });

    if (phase.id == -1) {
      this.setState({
        showDate: true,
      });
      return;
    }

    print("choosePhase method:", newPhases, phase);

    // this.setState({
    //   phases: newPhases,
    //   chooseDate: phase,
    // })

    if (
      this.props.absenteeActivity.current_study_phases &&
      this.props.absenteeActivity.current_study_phases.length > 0
    ) {
      let currentStudyPhase = this.props.absenteeActivity.current_study_phases.find(
        (studyPhase) => {
          let studyStartTime = new Date(studyPhase.start_time);
          let studyEndTime = new Date(studyPhase.end_time);
          let absenteeStartTime = new Date(phase.start_time);
          let absenteeEndTime = new Date(phase.end_time);
          // phase结束时间 < current_study_phases开始时间
          // phase开始时间 > current_study_phases结束时间
          if (
            !(
              absenteeEndTime < studyStartTime ||
              absenteeStartTime > studyEndTime
            )
          ) {
            return studyPhase;
          }
        }
      );
      print("currentStudyPhase", currentStudyPhase);
      if (currentStudyPhase) {
        if (
          this.state.currentStudyPhase &&
          this.state.currentStudyPhase.id == currentStudyPhase.id
        ) {
          phase.className = "selected";
          this.setState({
            phases: newPhases,
            chooseDate: phase,
          });
          return;
        }
        const buttons = [
          {
            label: "好的",
            onClick: () => {
              this.setState({
                currentStudyPhase: currentStudyPhase,
              });
              this.confirmDialog.close();
            },
          },
        ];
        this.confirmDialog = DialogManager.open(
          <Dialog buttons={buttons}>
            <div style={{ textAlign: "left" }}>
              您的课程《{currentStudyPhase.name}》结课时间是
              {formatTime(currentStudyPhase.end_time, "YYYY年MM月DD日")}
              ，为避免时间太紧影响学习效果，
              <span style={{ color: "#6ad33b" }}>建议</span>您选择
              {formatTime(currentStudyPhase.end_time, "YYYY年MM月DD日")}
              之后的期数上课（建议仅供参考，您仍然可按孩子的安排选择开课时间）。
            </div>
          </Dialog>
        );
      } else {
        phase.className = "selected";
        this.setState({
          phases: newPhases,
          chooseDate: phase,
        });
      }
    } else {
      phase.className = "selected";
      this.setState({
        phases: newPhases,
        chooseDate: phase,
      });
    }
  };

  chooseMonth = (e) => {
    e.preventDefault();
    let id = e.currentTarget.getAttribute("data-id");
    let chooseMonth = this.state.nextSixMonths.find((e) => {
      if (e.id == id) return e;
    });
    let newSixMonths = this.state.nextSixMonths.map((element, idx) => {
      var newMonth = element;
      if (chooseMonth.id == element.id) {
        newMonth.className = "selected";
      } else {
        newMonth.className = "select";
      }
      return newMonth;
    });
    var choosePhase = Object.assign({}, chooseMonth);
    choosePhase.id = -1;

    var newPhases = this.state.phases.map((e) => {
      var n = e;
      if (n.id === -1) {
        n.start_time = chooseMonth.start_time;
        n.className = "selected";
      }
      return n;
    });

    // var newSixMonths = this.state.nextSixMonths.map(element => {
    //   var newMonth = element
    //   if (phase.id == element.id) {
    //     newMonth.className = 'selected'
    //   } else {
    //     newMonth.className = 'select'
    //   }
    //   return newMonth
    // });
    // var newPhases = this.state.phases.map(e => {
    //   var n = e
    //   if (n.id === -1) {
    //     n.start_time = phase.start_time
    //   }
    //   return n
    // })

    this.setState({
      nextSixMonths: newSixMonths,
      showDate: false,
      phases: newPhases,
      chooseDate: choosePhase,
    });
    print("chooseMonth method:", choosePhase, newSixMonths);
  };

  backBtnClick = (e) => {
    this.setState({
      showDate: false,
    });
  };

  showTime = (phase) => {
    const startTime = convertMonthDay(phase.start_time);
    const endTime = convertMonthDay(phase.end_time);
    if (startTime != "" && endTime != "") {
      return <p>{`${startTime} - ${endTime}`}</p>;
    }
    if (startTime != "" && endTime == "") {
      return <p>{`${phase.start_time}`}</p>;
    }
    return "";
  };

  render() {
    return (
      <div
        className="choose_phase_background"
        style={{
          // 注：choosePhaseShow属性是《新版·首页》传过来的，为了解决当前display: none的问题
          display:
            // this.props.choosePhaseShow == false
            //   ? "none":
            this.state.choosePhaseDisplay,
        }}
      >
        <div className="choose_phase_tableview">
          <h3>请选择《{this.props.absenteeActivity.name}》上课时间</h3>
          <div
            className="close_btn_right"
            onClick={this.closebtnClick}
            style={{ display: !this.state.showDate ? "block" : "none" }}
          >
            &times;
          </div>
          <div className="choose_phase_tableview_content">
            <ul style={{ display: !this.state.showDate ? "block" : "none" }}>
              {this.state.phases.map((phase) => {
                return (
                  <li
                    key={phase.id}
                    onClick={this.choosePhase}
                    data-id={phase.id}
                    className={`tableview_cell border_content ${phase.className}`}
                  >
                    <div className={`cell_content ${phase.className}`}>
                      {this.props.isUserStudyPackage && phase.id != -1 ? (
                        <h3>近期开课，可选7天内任意一天开课</h3>
                      ) : (
                        <>
                          <h3>{phase.name}</h3>
                          {this.showTime(phase)}
                        </>
                      )}
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="choose_phase_tableview_content">
            <div
              className="back_btn_left"
              style={{ display: this.state.showDate ? "block" : "none" }}
              onClick={this.backBtnClick.bind(this)}
            ></div>
            <ul style={{ display: this.state.showDate ? "block" : "none" }}>
              {this.state.nextSixMonths.map((month) => {
                return (
                  <li
                    key={month.id}
                    onClick={this.chooseMonth}
                    data-id={month.id}
                    className={`tableview_cell border_content ${month.className}`}
                  >
                    <div className={`cell_content ${month.className}`}>
                      <p>{month.start_time}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          {!this.props.absenteeActivity.is_instance_activity && (
            <div className="bottom_tip">
              期数选定之后不能修改，如不确定请选择大概开课时间
            </div>
          )}
          <div
            className={
              this.state.chooseDate
                ? "sure_btn_bottom_enable"
                : "sure_btn_bottom_disable"
            }
            style={{ display: this.state.showDate ? "none" : "block" }}
            onClick={this.sureBtnClick}
          >
            确定
          </div>
        </div>
      </div>
    );
  }
}
