import React from "react";
import "./Divider.scss";
export default class Divider extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="divider">
        <div className="left_line"></div>
        <div className="more_course">{this.props.content}</div>
        <div className="right_line"></div>
      </div>
    );
  }
}
