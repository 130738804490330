// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/icon_quiz_pass.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comp_absentee_input_time_dialog{padding-top:15px;position:absolute;bottom:0;left:0;width:100%;background-color:#fff}.comp_absentee_input_time_dialog .on_close{color:rgba(0,0,0,.7);width:20px;height:24px;position:absolute;top:-7px;right:10px;font-size:32px}.comp_absentee_input_time_dialog p{line-height:1em}.comp_absentee_input_time_dialog .title{font-size:16px}.comp_absentee_input_time_dialog .title_desc{padding:15px 0;font-size:14px}.comp_absentee_input_time_dialog .title,.comp_absentee_input_time_dialog .title_desc{color:rgba(0,0,0,.6);text-align:center}.comp_absentee_input_time_dialog .poetry_courses{padding:0 20px}.comp_absentee_input_time_dialog .poetry_course_item{padding:15px;border-radius:6px}.comp_absentee_input_time_dialog .poetry_course_item p{font-size:16px}.comp_absentee_input_time_dialog .poetry_course_item p:first-of-type{margin-bottom:12px;font-weight:700}.comp_absentee_input_time_dialog .hight_light{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100%;background-origin:content-box;background-size:20px 20px;background-repeat:no-repeat;border:1px solid #49c114;box-shadow:0 0 5px rgba(73,193,20,.5)}.comp_absentee_input_time_dialog .input_btn_group{margin-top:12px;width:100%;height:48px;display:-webkit-flex;display:flex;-webkit-flex-wrap:nowrap;flex-wrap:nowrap}.comp_absentee_input_time_dialog .input_btn_group div{height:100%;width:50%;text-align:center;line-height:48px;color:#fff;font-weight:700}.comp_absentee_input_time_dialog .input_btn_group .input_confirm{background-color:#49c114}.comp_absentee_input_time_dialog .input_btn_group .input_cancel{background-color:#76abf0}", ""]);
// Exports
module.exports = exports;
