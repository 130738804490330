import React from "react";
import "./Wuhan.scss";
export default class Wuhan extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <ol>
        <li>
          本优惠券仅限武汉地区的用户领取（根据当前访问IP地址判断），其他地区的用户不可领取；
        </li>
        <li>每人限领取1张，优惠券可兑换『心芽学堂』里的任意一个课程；</li>
        <li>优惠券兑换的课程，不赠送实体教具；</li>
        <li>本优惠券单次使用，不叠加、不找零；</li>
        <li>心芽学堂拥有本次活动的最终解释权。</li>
      </ol>
    );
  }
}
