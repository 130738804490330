/* eslint-disable react/prop-types */
import React, { memo } from "react";

export const ActivityCourseFitDescribe = memo(
  ({ suitableBrief, isExperience }) => {
    return (
      <div className="course_fit_describe">
        {isExperience ? "" : <div className="fit_title">适合：</div>}
        <div className="fit_text">{suitableBrief}</div>
      </div>
    );
  }
);
export const ActivityCourseFitDescribeRed = memo(
  ({ sub_study_package_activities }) => {
    console.log("ppppppppppppp", sub_study_package_activities);
    let sub_study_package_activities_limt = [];
    sub_study_package_activities &&
      sub_study_package_activities.map((it, i) => {
        it.limit_users_count && sub_study_package_activities_limt.push(it);
      });
    return sub_study_package_activities_limt.length == 0 ? (
      ""
    ) : (
      <div className="course_fit_describe">
        <div className="fit_title" style={{ color: "red" }}>
          限量：
        </div>

        <div className="fit_text">
          {sub_study_package_activities_limt.map((its, idx) => {
            return (
              <div key={"idx" + idx}>
                {`${its.name}限量${its.limit_users_count}份，目前仅剩`}
                <span style={{ color: "red" }}>{its.remaining_count}</span>
                {"份"}
              </div>
            );
          })}

          {/* <div>
            {`xxxx限量xxx份，目前仅剩`}{" "}
            <span style={{ color: "red" }}>xxx</span>
            {"份"}
          </div> */}
        </div>
      </div>
    );
  }
);
