/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback } from "react";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
import { showFissionWelfareModal } from "./StudyPackageActivityTemplate.jsx";
import DiscountCourseList from "./DiscountCourseList.jsx";
import "./DiscountPriceDialog.scss";

const DiscountPriceDialog = ({ ...props }) => {
  console.log("DiscountPriceDialog_props: ", props);
  const {
    subActivities,
    packageStateIsOkArray,
    discountPriceContent,
    onModalClose,
    saleMode,
  } = props;
  const singleCourseJudge =
    subActivities.length == 1 &&
    subActivities[0]?.owned_media_lesson_count >=
      subActivities[0]?.media_lessons_count;

  const onModalCloseClick = useCallback(() => {
    if (saleMode == "shopping_group_new_member_only") {
      showFissionWelfareModal();
    }
    onModalClose();
  }, [onModalClose, saleMode]);

  const useDiscountModal = (owned_study_packages) => {
    return new ModalManager({
      render: (_, modalClose) => {
        const onClose = () => {
          modalClose();
        };
        return (
          <DiscountCourseList
            ownedApiResponseStudyPackages={owned_study_packages}
            onModalClose={onClose}
          />
        );
      },
    });
  };

  const alreadyHasMediaLessonsAjaxRequest = (requestUrl) => {
    const toast = ToastManager.showLoading("加载中...");
    request({
      url: requestUrl,
      method: "POST",
      headers: csrfHeaders,
      data: {
        boughtable_activity_ids: packageStateIsOkArray,
      },
    })
      .then((res) => {
        toast.cancel();
        const { owned_study_packages } = res.data;
        console.warn("owned_study_packages: ", owned_study_packages);

        const discountModal = useDiscountModal(owned_study_packages);
        if (owned_study_packages.length > 0) {
          discountModal.open();
        }
      })
      .catch((err) => {
        console.error(err);
        toast.cancel();
      });
  };

  const ownedCount = discountPriceContent.calcOwnedCount;

  const lastOwnedCount = Number(ownedCount).toFixed(1);
  console.log("lastOwnedCount: ", lastOwnedCount);
  return (
    <InnerModal>
      <ModalMask>
        <div className="discount_price_dialog_container">
          <div className="discount_price_dialog">
            <div
              className="modal_close"
              onClick={() => onModalCloseClick()}
            ></div>
            <div className="modal_content">
              <div className="dialog_discount_remind">
                {subActivities.length > 1 ? (
                  <>
                    <p>您已拥有以下课程中的部分内容：</p>
                    <div className="section_content">
                      {discountPriceContent.contentList
                        .split("，")
                        .map((item, index) => (
                          <span key={index}>{item}</span>
                        ))}
                    </div>
                    <p>
                      <strong>
                        {`报名正价课时最高可为您抵扣￥${lastOwnedCount}。`}
                      </strong>
                    </p>
                  </>
                ) : singleCourseJudge ? (
                  <>
                    您已拥有{discountPriceContent.contentList}抵扣
                    <strong>{`￥${lastOwnedCount}`}</strong>
                    ，可直接免费领取。
                  </>
                ) : (
                  <>
                    您已拥有{discountPriceContent.contentList}抵扣
                    <strong>{`￥${lastOwnedCount}`}</strong>。
                  </>
                )}
                {/* <div
                  className="go_search"
                  onClick={() =>
                    alreadyHasMediaLessonsAjaxRequest(discountPriceContent.url)
                  }
                >
                  查看已有课时 »
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </ModalMask>
    </InnerModal>
  );
};

export default DiscountPriceDialog;
