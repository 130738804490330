/* eslint-disable */
import React, { useRef, useCallback, useState, useEffect } from "react";
import CountDownTime from "../utils/CountDown";
import null_group_icon from "../../../assets/images/null_group_icon.png";
import icon_group_bg from "../../../assets/images/icon_group.png";
import { GA } from "../utils/Env";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { trialShow } from "./BaseStudyPackageActivity";
import { Dialog, DialogManager } from "../utils/Dialog";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
import Uri from "jsuri";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { pay } from "../utils/pay";
import UserAgent from "../utils/UserAgent";
import Raven from "raven-js";

const inviteFriend = (isShow, props) => {
  // new ShareHintDialogProxy("点击右上角分享给朋友<br><span>邀请好友参加拼团</span>").open()
  const {
    sale_mode,
    study_package_activity_id,
    poster_url,
    poster_img_src,
    free,
    bonus,
    bonus_type,
    has_teaching_aid_package,
    remaining_shopping_group_count,
    eventOperate,
    eventCategory,
    poster_photo_template_url,
    poster_creation,
    avatar_location,
    buy_endtime,
  } = props;

  GA(eventCategory, eventOperate, study_package_activity_id);

  if (poster_url) {
    const sharePosterProxy = new SharePosterProxy({
      posterTemplate: poster_photo_template_url,
      posterCreation: poster_creation,
      mode: "front_end",
      posterUrl: poster_url,
      posterImgSrc: poster_img_src,
      free: free,
      bonus: bonus,
      bonus_type: bonus_type,
      sale_mode: isShow ? sale_mode : null,
      remaining_shopping_group_count: isShow
        ? remaining_shopping_group_count
        : null,
      has_teaching_aid_package: has_teaching_aid_package,
      singleCourse: props.sub_study_package_activities.length <= 1,
      avatar_location: avatar_location,
      end_time: buy_endtime,
      half_price_ecoupon_promotion: props.half_price_ecoupon_promotion,
    });
    sharePosterProxy.open();
  }
};

const showJoinGrouponUser = (props, state, setState) => {
  const {
    shopping_group,
    buy_endtime,
    bought,
    complete_users,
    group_max_count,
    can_pay_remaining,
    sub_study_package_activities,
    overall_bought,
  } = props;
  console.log("showJoinGrouponUserprops", props);
  let complete = bought;
  if (sub_study_package_activities) {
    complete = overall_bought;
  }

  const handleCountdownEnd = (e) => {
    if (can_pay_remaining) {
      setState({
        ...state,
        canPayRemaining: can_pay_remaining,
      });
    } else {
      setState({
        ...state,
        isNotExpirdOfGroupon: false,
      });
    }
    if (props.sale_mode != "invitation_fission") {
      window.location.reload();
    }
  };

  if (
    (props.sale_mode == "shopping_group" ||
      props.sale_mode == "shopping_group_bargain" ||
      props.sale_mode == "shopping_group_new_member_only") &&
    !shopping_group &&
    buy_endtime &&
    (new Date(buy_endtime) - new Date()) / (60 * 60 * 24 * 1000) < 7 &&
    !bought
  ) {
    return (
      <div className="activitiy_amount tips_text">
        <CountDownTime
          endTime={new Date(buy_endtime)}
          overText="活动已结束"
          onEnd={handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                {/* 距离报名截止：{`${d == "00" ? "" : `${d}天`}`} {h}:{m}:{s} */}
                距离报名截止：{`${d}天`} {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      </div>
    );
  }
  if (
    (!shopping_group || !("user_shopping_groups" in shopping_group)) &&
    !complete
  ) {
    return "";
  }
  var users = [];
  if (bought && complete_users) {
    for (let i = 0; i < group_max_count; i++) {
      const completeUser = complete_users[i];
      if (completeUser) {
        users.push(completeUser.photo_url);
      } else {
        users.push(icon_group_bg);
      }
    }
  } else if (
    shopping_group &&
    "user_shopping_groups" in shopping_group &&
    shopping_group.user_shopping_groups.length
  ) {
    for (let i = 0; i < group_max_count; i++) {
      const user_shopping_group = shopping_group.user_shopping_groups[i];
      if (user_shopping_group) {
        users.push(user_shopping_group.user.photo_url);
      } else {
        users.push(icon_group_bg);
      }
    }
  }
  return (
    <div className="group_head_amount tips_text">
      {!complete &&
      (new Date(buy_endtime) - new Date()) / (60 * 60 * 24 * 1000) < 7 ? (
        <CountDownTime
          endTime={new Date(buy_endtime)}
          overText="活动已结束"
          onEnd={handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                距离拼团截止：{d}天 {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      ) : complete ? (
        <span>报名成功！</span>
      ) : (
        ""
      )}
      <div>
        {users.map((photo_url, index) => (
          <img
            className="lazyload"
            data-src={photo_url}
            data-sizes="auto"
            key={index}
          />
        ))}
      </div>
    </div>
  );
};
const showJoinGrouponUserNew = (props, state, setState) => {
  const {
    shopping_group,
    buy_endtime,
    bought,
    complete_users,
    group_max_count,
    can_pay_remaining,
    sub_study_package_activities,
    overall_bought,
  } = props;
  console.log("showJoinGrouponUserpropsnew", props);
  let complete = bought;
  if (sub_study_package_activities) {
    complete = overall_bought;
  }

  const handleCountdownEnd = (e) => {
    if (can_pay_remaining) {
      setState({
        ...state,
        canPayRemaining: can_pay_remaining,
      });
    } else {
      setState({
        ...state,
        isNotExpirdOfGroupon: false,
      });
    }
    if (props.sale_mode != "invitation_fission") {
      window.location.reload();
    }
  };

  if (
    (props.sale_mode == "shopping_group" ||
      props.sale_mode == "shopping_group_bargain" ||
      props.sale_mode == "shopping_group_new_member_only") &&
    !shopping_group &&
    buy_endtime &&
    (new Date(buy_endtime) - new Date()) / (60 * 60 * 24 * 1000) < 7 &&
    !bought
  ) {
    return (
      <div className="activitiy_amount tips_text">
        <CountDownTime
          endTime={new Date(buy_endtime)}
          overText="活动已结束"
          onEnd={handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                {/* 距离报名截止：{`${d == "00" ? "" : `${d}天`}`} {h}:{m}:{s} */}
                距离报名截止：{`${d}天`} {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      </div>
    );
  }
  if (
    (!shopping_group || !("user_shopping_groups" in shopping_group)) &&
    !complete
  ) {
    return "";
  }
  var users = [];
  if (bought && complete_users) {
    for (let i = 0; i < group_max_count; i++) {
      const completeUser = complete_users[i];
      if (completeUser) {
        users.push(completeUser.photo_url);
      } else {
        users.push(null);
      }
    }
  } else if (
    shopping_group &&
    "user_shopping_groups" in shopping_group &&
    shopping_group.user_shopping_groups.length
  ) {
    for (let i = 0; i < group_max_count; i++) {
      const user_shopping_group = shopping_group.user_shopping_groups[i];
      if (user_shopping_group) {
        users.push(user_shopping_group.user.photo_url);
      } else {
        users.push(null_group_icon);
      }
    }
  }
  return (
    <div className="group_head_amount group_head_amount1 tips_text">
      {!complete &&
      (new Date(buy_endtime) - new Date()) / (60 * 60 * 24 * 1000) < 7 ? (
        <CountDownTime
          endTime={new Date(buy_endtime)}
          overText="活动已结束"
          onEnd={handleCountdownEnd}
        >
          {({ d, h, m, s }) => {
            return (
              <span className="new_end_time">
                距离拼团截止:<span>{d}</span>天 <span>{h}</span> :
                <span>{m}</span>:<span>{s}</span>
              </span>
            );
          }}
        </CountDownTime>
      ) : complete ? (
        <span>报名成功！</span>
      ) : (
        ""
      )}
      <div>
        {users.map((photo_url, index) => {
          return photo_url ? (
            <img
              className="lazyload"
              data-src={photo_url}
              data-sizes="auto"
              key={index}
            />
          ) : (
            <div className="null-group-people">+</div>
          );
        })}
      </div>
    </div>
  );
};
// const hasBought = (props, state, useState) => {
//   const {
//     bought, complete_users, sale_mode, course_type,
//     current_bought_study_package_url, note_url, is_parent_class
//   } = props
//
//   let linkUrl, afterBoughtTextStyle
//   if (course_type === 'poetry') {
//     linkUrl = current_bought_study_package_url
//   } else {
//     linkUrl = note_url
//   }
//
//   if (sale_mode == 'normal') {
//     if (is_parent_class) {
//       afterBoughtTextStyle = { width: "100%" }
//     } else {
//       afterBoughtTextStyle = { width: "66%" }
//     }
//   } else {
//     afterBoughtTextStyle = { width: "100%" }
//   }
//
//   let showJoinGrouponUserHtml = showJoinGrouponUser(props, state, useState)
//
//   return (
//       <div className="activity_bought">
//         {
//           complete_users ? '' : (sale_mode === 'shopping_group' && showJoinGrouponUserHtml)
//         }
//         <a
//             className="free_experience course_start_notice"
//             href={linkUrl}
//             style={afterBoughtTextStyle}
//         >
//           开课须知
//         </a>
//         {
//           // this.inviteFriend.bind(null, false)
//           (sale_mode === "normal" && !is_parent_class) &&
//           <div className="study_group_price" onClick={inviteFriend.bind(null, false, props)}>
//             <div className="invite_friends_group">
//               <span>邀请好友</span>
//             </div>
//           </div>
//         }
//       </div>
//   )
// }

// 已过期
const expiredShopGroupState = (props) => {
  const { list_price, price, refund_policy, study_package_activity } = props;
  const { state } = props;
  return (
    <div className="activity_box_foot">
      <div className="activitiy_amount tips_text">
        <span>本期名额已满</span>
      </div>
      <div className="activity_box_bg clearfix">
        <div className="activitiy_price">
          <p>
            ¥{price}
            <span className="origin_price">
              &nbsp;&nbsp;原价：<del>¥{list_price}</del>
            </span>
          </p>
          <div className="price_subscribe">{refund_policy}</div>
        </div>
        <div className="activitiy_sign_in activity_sign_end">等待下期</div>
        {trialShow(state.trialActivities, study_package_activity.id)}
      </div>
    </div>
  );
};

// 往期已购买用户进入报名页后的页面状态
const showPreviousUserBuyState = (props) => {
  // 往期已购买用户进入报名页，点击邀请好友
  const inviteFriendRecommend = (e) => {
    GA("Courses", "shareCourse", props.study_package_activity_id);
    if (props.poster_url) {
      // if (!this.sharePosterProxy) {
      new SharePosterProxy({
        posterUrl: props.poster_url,
        posterImgSrc: props.poster_img_src,
        free: props.free,
        bonus: props.bonus,
        avatar_location: props.avatar_location,
      }).open();
      // }
    }
  };

  var price = "";
  switch (props.sale_mode) {
    case "normal":
      price = (
        <div className="activitiy_price">
          <p>
            ¥{props.price}
            <span className="origin_price">
              &nbsp;&nbsp;原价：<del>¥{props.list_price}</del>
            </span>
          </p>
          <div className="price_subscribe">{props.refund_policy}</div>
        </div>
      );
      break;
    case "bargain":
      price = (
        <div className="activitiy_price">
          {props.activity ? (
            <p>¥{props.activity.lowest_price + "-" + props.activity.price}</p>
          ) : (
            <p>
              ¥{props.price}
              <span className="origin_price">
                &nbsp;&nbsp;原价：<del>¥{props.list_price}</del>
              </span>
            </p>
          )}
          <div className="price_subscribe">{props.refund_policy}</div>
        </div>
      );
    case "invite":
      price = (
        <div className="activitiy_price">
          <p>
            ¥{props.price}
            <span className="origin_price">
              &nbsp;&nbsp;原价：<del>¥{props.list_price}</del>
            </span>
          </p>
          <div className="price_subscribe">{props.refund_policy}</div>
        </div>
      );
    default:
      price = (
        <div className="activitiy_price">
          <p>
            {props.group_max_count}人拼团价：¥{props.group_price}
          </p>
          <div className="price_subscribe">
            <span>
              原价:<del>¥{props.list_price}</del>
            </span>{" "}
            &nbsp;{props.refund_policy}
          </div>
        </div>
      );
      break;
  }
  return (
    <div className="activity_box_foot">
      {/* {this.showJoinGrouponUser()} */}
      <div className="activity_box_bg clearfix">
        {price}
        <div className="activitiy_sign_in" onClick={inviteFriendRecommend}>
          邀请报名
        </div>
        {props.study_package_group_state == "partial" ||
        props.study_package_group_state == "superset" ? (
          <a
            className="free_experience"
            href={props.study_package_activities_url}
          >
            我的课程
          </a>
        ) : (
          <a className="free_experience" href={props.study_package_url}>
            进入课程
          </a>
        )}
      </div>
    </div>
  );
};

const onGroupBuy = (
  amount,
  group_state,
  selectActivityId,
  ecouponId,
  virtualAccountMoney,
  study_package_activity_group_buy_url,
  trial,
  props
) => {
  console.log(
    "拼团购买ing/价格:" +
      amount +
      "元," +
      "类型:" +
      group_state +
      "," +
      "课程Id:" +
      selectActivityId +
      "," +
      "优惠券Id:" +
      ecouponId +
      "," +
      "抵用金额:" +
      virtualAccountMoney
  );
  console.log("onGroupBuy_props", props);
  buy(
    study_package_activity_group_buy_url,
    {
      amount: amount,
      group_state: group_state,
      target_id: selectActivityId,
      ecoupon_id: ecouponId,
      virtual_account_money: virtualAccountMoney,
      shopping_group_id:
        group_state !== "direct" && props ? props.firstGroup?.id : null,
      shopping_group:
        group_state !== "direct" && props ? props.firstGroup : null,
    },
    props
  );
};

const onDirectBuy = (
  amount,
  group_state,
  selectActivityId,
  ecouponId,
  virtualAccountMoney,
  study_package_activity_buy_url,
  trial,
  props
) => {
  console.log("onDirectBuy_props", props);
  console.log(
    "直接购买ing/价格:" +
      amount +
      "元," +
      "类型:" +
      group_state +
      "," +
      "课程Id:" +
      selectActivityId +
      "," +
      "优惠券Id:" +
      ecouponId +
      "," +
      "抵用金额:" +
      virtualAccountMoney
  );

  buy(
    study_package_activity_buy_url,
    {
      amount: amount,
      group_state: group_state,
      target_id: selectActivityId,
      ecoupon_id: ecouponId,
      virtual_account_money: virtualAccountMoney,
      trial,
    },
    props
  );
};

// 完善用户信息·弹框引导
const addUserInfoDialog = (url, err_code, user_id) => {
  let confirmDialog;
  const buttons = [
    {
      label: "确定",
      onClick: () => {
        confirmDialog.close();
        if (err_code == "err_no_baby") {
          GA("Register", "skipPhone", user_id);
        }
        window.location.href = url;
      },
    },
  ];
  confirmDialog = DialogManager.open(
    <Dialog buttons={buttons}>
      为了给您提供更有针对性的服务，请先完善用户信息。
    </Dialog>
  );
};

const buy = (url, params, props) => {
  const toast = ToastManager.showLoading("加载中...");
  console.log("上层传递过来的 buy props: ", props);
  console.log("购买链接：" + url);
  console.log("购买参数：" + JSON.stringify(params));
  // return;
  if (UserAgent.isMiniProgram()) {
    params["client_from"] = "MiniProgram";
  }
  request({
    url: url,
    method: "POST",
    headers: csrfHeaders,
    data: params,
  })
    .then((resp) => {
      // if (!UserAgent.isWebBrowser()) {
      //   this.toast.cancel()
      // }
      toast.cancel();
      const data = resp.data;
      console.log("data: ", data);
      if (
        data.redirect_url &&
        (data.err_code == "err_no_baby" ||
          data.err_code == "err_unbound_mobile")
      ) {
        addUserInfoDialog(data.redirect_url, data.err_code, data.user_id);
        return;
      }

      if (data.redirect_url) {
        window.location.href = data.redirect_url;
        return;
      }

      if (data.err_code) {
        switch (data.err_code) {
          case "err_unauthenticated":
            new AuthenticationDialogProxy(data.redirect_url).open();
            break;
        }
        return;
      }

      if (data.err_code) {
        alert("网络异常，请重新尝试");
        return;
      }
      // alert(data)
      var params = Object.assign(data, {
        callback: (isSuccess, res) => {
          console.log("enter_params_callback");
          // 注意: 这里的字段·是小驼峰命名, 应该是上层数据处理完带过来的
          let url = new Uri(props.noteUrl || props.note_url);
          // let gift_commodities_url = new Uri(
          //   props.giftCommoditiesUrl || props.gift_commodities_url
          // );
          // if (props.shoppingGroup) {
          //   url.addQueryParam("shopping_group_id", props.shoppingGroup.id);
          // }
          // // if (props.courseType === "poetry") {
          // //   window.location.reload(true);
          // //   return;
          // // }
          // if (props.hasTeachingAidPackage || props.has_teaching_aid_package) {
          //   window.location.href = gift_commodities_url;
          //   return;
          // }
          window.location.href = url;
        },
      });

      if (UserAgent.isWebBrowser()) {
        params["toast"] = toast;
      }

      pay(params);
    })
    .catch((err) => {
      Raven.captureException(err);
      toast.cancel();
    });
};

export {
  showJoinGrouponUser,
  showJoinGrouponUserNew,
  // hasBought,
  expiredShopGroupState,
  showPreviousUserBuyState,
  inviteFriend,
  onGroupBuy,
  onDirectBuy,
  buy,
};
