/* eslint-disable react/prop-types */
import React from "react";
import styled from "styled-components";
import { request, csrfHeaders } from "../utils/request";
import LazyloadContent from "../utils/lazyload/LazyloadContent";
import icon_landing_button from "think_customized/icon_landing_button.gif";
import { LimitDiscountsCountdown } from "../study_package_activities/ThinkingDetails";
import { showBoughtSuccessDialog } from "../study_package_activities/ThinkingCustomize";

export const StyledLaningPage = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  padding-bottom: 110px;
  .discount_count_down {
    margin: 15px 10px 10px;
  }
  .markdown_content {
    padding: 0;
  }
  .bottom_button_container {
    background-color: #fff;
    position: fixed;
    bottom: 0;
    width: 100%;
    box-shadow: 0 -1px 4px rgba(122, 36, 36, 0.1);
    z-index: 4;
    @media screen and (orientation: landscape) and (min-width: 1024px) {
      max-width: 500px;
      margin: 0 auto;
    }
  }
  .button {
    display: block;
    img {
      width: 100%;
    }
  }
`;

const handleClick = (props) => {
  if (props.user_interaction_tracks_url) {
    const params = {
      operate: "free",
      tag: props.from_user?.id,
    };
    console.log("params: ", params);
    request({
      url: props.user_interaction_tracks_url,
      method: "POST",
      headers: csrfHeaders,
      data: params,
    })
      .then((resp) => {})
      .catch((err) => {
        console.log(err);
      });
  }
  showBoughtSuccessDialog(props);
};

const Landing = ({ ...props }) => {
  console.log("Landing_props: ", props);
  const {
    landing_description,
    group_qrcode_url,
    buy_countdown_endtime,
    group_code,
  } = props;

  return (
    <StyledLaningPage>
      {landing_description && <LazyloadContent content={landing_description} />}
      <div className="bottom_button_container">
        <LimitDiscountsCountdown
          isThinkingUser={true}
          originalPrice={""}
          buyCountdownEndtime={buy_countdown_endtime}
        />
        <div className="button" onClick={(e) => handleClick(props)}>
          <img src={icon_landing_button} alt="" />
        </div>
        {
          // <a className="button" href={contact_url}>
          //   <img src={icon_landing_button} alt="" />
          // </a>
        }
      </div>
    </StyledLaningPage>
  );
};

export default Landing;
