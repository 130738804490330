/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
// import { Object } from 'core-js';
import "./TrialChoose.scss";
export default class TrialChoose extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      isShowTrials: true,
    };
  }

  closebtnClick = () => {
    this.setState({
      isShowTrials: !this.state.isShowTrials,
    });
    this.props.cancelChoose();
  };

  render() {
    const { trialActivities } = this.props;
    return (
      <div
        className="trial_choose_background"
        style={{ display: this.state.isShowTrials ? "block" : "none" }}
      >
        <div className="trial_choose_tableview">
          <h3>请选择试听课程</h3>
          <div className="close_btn_right" onClick={this.closebtnClick}>
            &times;
          </div>
          <ul>
            {trialActivities.map((ac, idx) => {
              return (
                <li
                  id={ac.id}
                  key={ac.id}
                  className="tableview_cell trial_choose_cell"
                >
                  <a href={ac.url}>{ac.name}</a>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
}

TrialChoose.propTypes = Object.assign(
  {},
  {
    cancelChoose: PropTypes.func.isRequired,
    trialActivities: PropTypes.array.isRequired,
  }
);
