/* eslint-disable react/prop-types */
/* eslint-disable eqeqeq */
import React, { useEffect, useMemo, useCallback } from "react";
import Raven from "raven-js";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import ModalMask from "../utils/Modal/ModalMask";
import {
  request as axios,
  getAxiosErrorText,
  csrfHeaders,
} from "../utils/request";
import { awaitWrapper, showDialog } from "../utils/helpers";
import { ToastManager } from "../utils/Toast";

import "./RemoveShoppingGroupDialog.scss";

const request = axios.create({
  timeout: 15000,
});

const TIPS = [
  `每个课程拼团仅<span class="strong">1次</span>移团机会，操作后不可取消；`,
  `移团功能仅适用<span class="strong">1人团</span>，团中人数≥2人不可移团；`,
  `移团后将关闭您原有的团，不再享受<span class="strong">团长奖励</span>。`,
];

const RemoveShoppingGroupDialog = ({ onClose, onRemoveClick, bonus }) => {
  return (
    <div className="remove_group_comp">
      <p className="header_p">系统检测您的拼团中仅有您1人，</p>
      <p className="header_p">是否需要加入该朋友的拼团？</p>
      <div className="btn main" onClick={onRemoveClick}>
        加入
      </div>
      <div className="btn sub" onClick={onClose}>
        不加入
      </div>

      <div className="tip_container">
        <p className="tip">注：</p>
        <div className="tip_wrapper">
          {TIPS.map((tip, index) => {
            if (index === 2 && bonus <= 0) {
              return "";
            } else {
              return (
                <div className="tip" key={index}>
                  <span className="index">{`${index + 1}.`}</span>
                  <p dangerouslySetInnerHTML={{ __html: tip }}></p>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

const ActionDialog = ({ text, onConfirm }) => {
  return (
    <div className="comp_action_dialog">
      <p dangerouslySetInnerHTML={{ __html: text }}></p>
      <div className="btn" onClick={onConfirm}>
        确定
      </div>
    </div>
  );
};

const useRemoveShoppingGroupDialog = ({
  joinOtherGroupUrl,
  onAlertPoster,
  bonus,
}) => {
  const cannotDialog = useCallback(
    ({ actionCode, remainingGroupCount, noteUrl }) => {
      let text = "";
      let closeCallback = onAlertPoster;
      if (remainingGroupCount) {
        text = `您已加入朋友的拼团，仅差<span>${remainingGroupCount}</span>人成团！`;
        closeCallback = () => {
          window.location.reload(true);
        };
      } else if (actionCode == 1) {
        text = "已有朋友加入您的拼团，无法移团。";
        closeCallback = () => {
          window.location.reload(true);
        };
      } else if (actionCode == 0) {
        text = "您已加入朋友的拼团，拼团成功！";
        closeCallback = () => {
          window.location.href = noteUrl;
        };
      } else {
        text = "请求返回异常";
        Raven.captureException(
          `error_code:${actionCode}, remainingGroupCount:${remainingGroupCount}`
        );
      }
      return new ModalManager({
        render: (_, modalClose) => {
          const onClose = () => {
            modalClose();
            closeCallback();
          };
          return (
            <ModalMask>
              <ActionDialog text={text} onConfirm={onClose} />
            </ModalMask>
          );
        },
        isCreateModalDynamic: true,
      });
    },
    [onAlertPoster]
  );

  const requestJoinOtherGroupUrl = useCallback(async () => {
    const toast = ToastManager.showLoading("请求中...");
    const [error, res] = await awaitWrapper(request)({
      url: joinOtherGroupUrl,
      headers: csrfHeaders,
      method: "POST",
    });

    toast.cancel();

    if (error) {
      console.log(error);
      Raven.captureException(error);
      const dialogOptions = {
        text: getAxiosErrorText(error, "请求"),
        mainLabel: "确定",
        mainCallback: () => {},
      };
      showDialog.call(this, dialogOptions);
    } else if (res) {
      const { data } = res;
      cannotDialog({
        actionCode: data?.error_code,
        remainingGroupCount: data?.remaining_shopping_group_count,
        noteUrl: data?.note_url,
      }).open();
    }
  }, [cannotDialog, joinOtherGroupUrl]);

  const queryDialog = useMemo(
    () =>
      new ModalManager({
        render: (_, modalClose) => {
          const onNotRemove = () => {
            modalClose();
            onAlertPoster();
          };
          const onRequestJoinOtherGroupUrl = () => {
            modalClose();
            requestJoinOtherGroupUrl();
          };
          return (
            <ModalMask>
              <RemoveShoppingGroupDialog
                bonus={bonus}
                onClose={onNotRemove}
                onRemoveClick={onRequestJoinOtherGroupUrl}
              />
            </ModalMask>
          );
        },
        isCreateModalDynamic: true,
      }),
    [bonus, onAlertPoster, requestJoinOtherGroupUrl]
  );

  useEffect(() => {
    if (joinOtherGroupUrl) {
      queryDialog.open();
    } else {
      onAlertPoster();
    }
  }, [joinOtherGroupUrl, onAlertPoster, queryDialog]);
};

export default useRemoveShoppingGroupDialog;
