/* eslint-disable react/prop-types */
import React, { memo } from "react";
import ErrorBoundary from "../../utils/ErrorBoundary";
const Banner = memo(({ mainVideo, mainPhotoUrl }) => {
  let headBanner = "";
  if (mainVideo) {
    headBanner = (
      <video
        style={{ display: "block" }}
        controls
        width="100%"
        preload="none" // if poster has value, setting preload="none"
        poster={mainPhotoUrl}
        src={mainVideo}
      />
    );
  } else {
    headBanner = <img src={mainPhotoUrl} data-sizes="auto" alt="" />;
  }
  return <div className="periods_time_top">{headBanner}</div>;
});
export const ActivityBanner = (props) => {
  return (
    <ErrorBoundary>
      <Banner {...props} />
    </ErrorBoundary>
  );
};
