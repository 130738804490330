/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import "./DiscountPriceDialog.scss";

const FissionWelfareDialog = ({ ...props }) => {
  const { onModalClose } = props;
  useEffect(() => {
    const tips_text_ele = document.getElementsByClassName("tips_text")[0];
    const fission_ele = document.getElementsByClassName(
      "fission_shopping_dialog_container"
    )[0];
    if (!tips_text_ele && fission_ele) {
      fission_ele.style.cssText = "bottom: -45px";
    }
  }, []);
  return (
    <div className="fission_shopping_dialog_container">
      <div className="discount_price_dialog fission_welfare_dialog">
        <div className="modal_close" onClick={() => onModalClose()}></div>
        <div className="modal_content">
          <div className="dialog_discount_remind">
            <img
              src="https://upload.fireflybaby.cn/study_package_activity/6be2beca43ddad989fa5bfb36e92eb87.png"
              alt=""
            />
            <div className="text">
              限时福利！邀请得现金+实物双重奖励！
              <a href="https://mp.weixin.qq.com/s/cECMgnd0e8BOzFlFkhk8ZA">
                查看详情 »
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FissionWelfareDialog;
