/* eslint-disable react/prop-types */
import React, { memo } from "react";
import ListContainer from "../../utils/ListContainer/ListContainer.jsx";
import PoetryBabyAudio from "../../study_packages/poetries/v2/components/PoetryBabyAudio.jsx";
import ErrorBoundary from "../../utils/ErrorBoundary";

const UserRecommend = memo((props) => {
  const {
    shareUser: {
      baby_audio_recorders,
      share_user_comment,
      photo_url,
      name,
      phase,
      baby,
    },
    totalCommentsCount,
    onClickEvalute,
  } = props;
  return (
    <div className="recommand_comments">
      <div className="header">
        <div className="user_info">
          <img data-src={photo_url} className="lazyload" data-sizes="auto" />
          <p>
            {name}
            <span>
              {phase && "(" + phase + ")"}
              向你推荐
            </span>
          </p>
        </div>
        {totalCommentsCount > 0 && (
          <div className="remommand" onClick={onClickEvalute}>
            更多评价 »
          </div>
        )}
      </div>
      {share_user_comment && (
        <div className="recommand_text">
          <div className="text">{share_user_comment}</div>
        </div>
      )}
      {baby_audio_recorders && baby_audio_recorders.length > 0 && (
        <div className="poetry_baby_audios">
          <p>听听我家{baby ? baby.nickname : ""}读的诗：</p>
          <div className="scroll_container">
            <ListContainer
              list={baby_audio_recorders}
              listItem={({ itemIndex, ...audio }) => (
                <PoetryBabyAudio
                  key={itemIndex}
                  index={itemIndex}
                  audioSrc={audio.entire_record_url}
                  poetryName={audio.name}
                  baby={baby ? baby.nickname : ""}
                  audioInfoUrl={audio.audio_info_url}
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export const ActivityUserRecommend = (props) => {
  return (
    <ErrorBoundary>
      <UserRecommend {...props} />
    </ErrorBoundary>
  );
};
