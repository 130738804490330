import React from "react";
import ReactDOM from "react-dom";
import ReactXinyaUi from "./XinyaUi/index";
import "./XinyaUi/xinya_ui.scss";

import "./DialogCourseBuy.scss";

class DialogCourseBuy extends React.Component {
  render() {
    return <ReactXinyaUi.Dialog type="ios" show={true} {...this.props} />;
  }
}

class DialogCourseBuyProxy {
  constructor(component) {
    this.node = document.createElement("div");
    this.node.className = "comp-study-package-activity";
    document.body.appendChild(this.node);
    ReactDOM.render(component, this.node);
  }

  close() {
    ReactDOM.unmountComponentAtNode(this.node);
  }
}

const DialogCourseBuyManager = {
  open(component) {
    const proxy = new DialogCourseBuyProxy(component);
    return proxy;
  },

  showMsg(options) {
    let dialog;
    const { msg, btnLabel = "确认", onClick = () => dialog.close() } = options;
    const buttons = [
      {
        label: btnLabel,
        onClick: () => {
          if (typeof onClick == "function") {
            onClick();
          }
        },
      },
    ];
    return (dialog = DialogCourseBuyManager.open(
      <DialogCourseBuy buttons={buttons}>{msg}</DialogCourseBuy>
    ));
  },
};

export { DialogCourseBuy, DialogCourseBuyManager };
