/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";

import { switchQALogic } from "../utils/ProblemFold";

import "../utils/ReactMarkDown.scss";
import "./StudyPackageActivityNote.scss";

export default class StudyPackageActivityNote extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
  }

  componentDidMount() {
    switchQALogic();
    //   const share_url = this.props.share_url
    //   const share_image_url = this.props.share_image_url
    //   const share_title = this.props.share_title
    //   const share_description = this.props.share_description

    //   wx.ready(function () {
    //     function on_share_success() {
    //     }
    //     var sharedJson = {
    //       link: share_url,
    //       imgUrl: share_image_url,
    //       title: share_title,
    //       desc: share_description,
    //       success: on_share_success
    //     };
    //     wx.onMenuShareTimeline(sharedJson);
    //     wx.onMenuShareAppMessage(sharedJson);
    //   });
  }

  render() {
    const { study_package_activity_url, absentee_activity } = this.props;
    const urlArr = window.location.href.split("?");
    let urlQuery = urlArr[1];
    return (
      <div className="comp-study-package-activity-note">
        <div className="markdown_content" id="common_problem">
          <ReactMarkdown
            source={this.props.note}
            escapeHtml={false}
            skipHtml={false}
          />
        </div>
        {urlQuery !== "hidden" && (
          <a href={study_package_activity_url} className="invite_friend">
            {absentee_activity
              ? `选择《${absentee_activity.name}》开课时间`
              : "返回报名页"}
          </a>
        )}
      </div>
    );
  }
}
