/**
 * 用来存放弹框的界面
 */
import React from "react";
import ModalManager from "./Modal/ModalManage.jsx";
import {
  StyledFixedBody,
  StyledAbsoluteContent,
  StyledDoubleElevenModal,
  StyledBuyButton,
  StyledCloseModal,
  StyledSuccessRedemptionModal,
  StyledGoFinishExchangeModal,
  StyledUploadScreenShotModal,
  StyledConfirmButton,
} from "./DialogTemplate.styled";
import icon_top_activity from "promotion/icon_top_activity.png";
import icon_success from "promotion/icon_success.png";

export const DoubleElevenModal = (fit_course) => {
  console.log("fit_course: ", fit_course);
  // fit_course: "all" 全场课程限时
  // fit_course: "peiyou" 培优课
  // fit_course: "reading" 阅读课

  // 报名页弹框
  // return new ModalManager({
  //   render: (_, modalClose) => {
  //     const onClose = () => {
  //       modalClose();
  //     };

  //     const allCourse = () => {
  //       // 全场课程限时
  //       return (
  //         <>
  //           <div className="course_discount_content">
  //             <div className="fit_course_name">全场课程限时：</div>
  //             <div className="discount_text all_discount_text">
  //               <p>6.5折免拼团！</p>
  //             </div>
  //           </div>
  //           <div className="course_rule_content">
  //             <p>活动时间：2020.11.03 ~ 2020.11.12</p>
  //             <div className="course_describe">
  //               <p>特殊课程：</p>
  //               <ul>
  //                 <li>
  //                   <span>1</span>
  //                   “数学培优系列"限时低至5折
  //                 </li>
  //                 <li>
  //                   <span>2</span>
  //                   “阅读理解系统课"一口价报名立减500元
  //                 </li>
  //               </ul>
  //             </div>
  //           </div>
  //         </>
  //       );
  //     };

  //     const peiyouCourse = () => {
  //       // 培优课限时
  //       return (
  //         <>
  //           <div className="course_discount_content">
  //             <div className="fit_course_name">培优课限时：</div>
  //             <div className="discount_text peiyou_discount_text">
  //               <p>6折免拼团！</p>
  //             </div>
  //             <div className="discount_brief" style={{ paddingBottom: 0 }}>
  //               完成指定任务，更可低至
  //               <span>5折！</span>
  //             </div>
  //           </div>
  //           <div className="course_rule_content">
  //             <p>活动时间：2020.11.03 ~ 2020.11.12</p>
  //           </div>
  //         </>
  //       );
  //     };

  //     const readingCourse = () => {
  //       // 阅读课限时
  //       return (
  //         <>
  //           <div className="course_discount_content">
  //             <div className="fit_course_name">阅读课一口价报名</div>
  //             <div className="discount_text reading_discount_text">
  //               <div className="discount_price">
  //                 <span>立减￥500</span>
  //               </div>
  //               <div className="parallelogram_text">
  //                 限时只需￥
  //                 <span>1198</span>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="course_rule_content">
  //             <p>活动时间：2020.11.03 ~ 2020.11.12</p>
  //           </div>
  //         </>
  //       );
  //     };
  //     return (
  //       <StyledFixedBody>
  //         <StyledAbsoluteContent bg="#ffcb39" borderRadius="16" width="90">
  //           <StyledDoubleElevenModal>
  //             <div className="content_container">
  //               <img src={icon_top_activity} alt="" />
  //               {fit_course == "all"
  //                 ? allCourse()
  //                 : fit_course == "peiyou"
  //                 ? peiyouCourse()
  //                 : readingCourse()}
  //             </div>
  //             <StyledBuyButton onClick={() => onClose()}>
  //               立即报名
  //             </StyledBuyButton>
  //             <StyledCloseModal onClick={() => onClose()}></StyledCloseModal>
  //           </StyledDoubleElevenModal>
  //         </StyledAbsoluteContent>
  //       </StyledFixedBody>
  //     );
  //   },
  //   isCreateModalDynamic: true,
  // }).open();
};

export const SuccessRedemptionModal = ({ ...props }) => {
  console.log("SuccessRedemptionModal: ", props);
  // 恭喜兑换成功
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return (
        <StyledFixedBody>
          <StyledAbsoluteContent borderRadius="16" width="90">
            <StyledSuccessRedemptionModal>
              <img src={icon_success} alt="" />
              <p>恭喜兑换成功</p>
              <StyledConfirmButton onClick={() => onClose()}>
                确定
              </StyledConfirmButton>
            </StyledSuccessRedemptionModal>
          </StyledAbsoluteContent>
        </StyledFixedBody>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};

export const GoFinishExchangeModal = ({ ...props }) => {
  console.log("GoFinishExchangeModal: ", props);
  // 完成任务，进行兑换
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };

      const handleClose = () => {
        const exchange_collection = document.getElementById(
          "exchange_collection"
        );
        if (exchange_collection) {
          exchange_collection.scrollIntoView();
          window.scrollBy({
            top: -30,
          });
          onClose();
        }
      };
      return (
        <StyledFixedBody>
          <StyledAbsoluteContent borderRadius="16" width="90">
            <StyledGoFinishExchangeModal>
              <p>
                您还没有足够兑换券可使用，可通过
                <br />
                任务获取哦~
              </p>
              <StyledConfirmButton onClick={() => handleClose()}>
                去完成
              </StyledConfirmButton>
            </StyledGoFinishExchangeModal>
          </StyledAbsoluteContent>
        </StyledFixedBody>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};
export const UploadScreenShotModal = (tokenType) => {
  // 完成任务，进行兑换
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      const handleCloseDialog = () => {
        const answer_photos_article = document.getElementById(
          "answer_photos_article"
        );
        const answer_photos_poster = document.getElementById(
          "answer_photos_poster"
        );
        if (answer_photos_article && tokenType == "article_share") {
          console.log("上传再看");
          $("#answer_photos_article").trigger("click");
          onClose();
        }
        if (answer_photos_poster && tokenType == "poster_share") {
          console.log("上传海报");
          $("#answer_photos_poster").trigger("click");
          onClose();
        }
      };
      return (
        <StyledFixedBody>
          <StyledAbsoluteContent borderRadius="16" width="90">
            <StyledUploadScreenShotModal>
              <p>
                请上传正确的完成任务截图，若截图审核不通过，我们有权撤回您免费领取的礼品。
              </p>
              <StyledConfirmButton onClick={() => handleCloseDialog()}>
                我知道了
              </StyledConfirmButton>
            </StyledUploadScreenShotModal>
          </StyledAbsoluteContent>
        </StyledFixedBody>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};

export const FinishPhotoUploadModal = () => {
  // 图片上传成功
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      const handleClose = () => {
        onClose();
        window.location.reload(true);
      };
      return (
        <StyledFixedBody>
          <StyledAbsoluteContent borderRadius="16" width="90">
            <StyledGoFinishExchangeModal>
              <p>上传成功，恭喜您获得兑换券1张！</p>
              <StyledConfirmButton onClick={() => handleClose()}>
                确定
              </StyledConfirmButton>
            </StyledGoFinishExchangeModal>
          </StyledAbsoluteContent>
        </StyledFixedBody>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};
