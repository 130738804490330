import UserAgent from "./UserAgent";
import AuthenticationDialogProxy from "../shared/AuthenticationDialog";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { reloadPage } from "./helpers";
import Raven from "raven-js";

export function buy(url, params) {
  this.toast = ToastManager.showLoading("加载中...");
  request({
    url: url,
    method: "POST",
    headers: csrfHeaders,
    data: params,
    complete: () => this.toast.cancel(),
  })
    .then((resp) => {
      alert(resp.data);
      this.toast.cancel();

      const data = resp.data;

      if (data.redirect_url) {
        if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.navigate({ href: data.redirect_url });
        } else {
          window.location.href = data.redirect_url;
        }
        return;
      }
      if (data.err_code) {
        switch (data.err_code) {
          case "err_unauthenticated":
            new AuthenticationDialogProxy(data.redirect_url).open();
            break;
        }
        return;
      }

      if (data.err_code) {
        alert("网络异常，请重新尝试");
        return;
      }
      pay(data);
    })
    .catch((err) => {
      alert(err);
      this.toast.cancel();
    });
}

export function pay(params) {
  if (UserAgent.isWebBrowser()) {
    var toast = params.toast;

    window.location.href = params.redirect_url;
    if (toast) {
      toast.cancel();
    }
  } else if (UserAgent.isWechat()) {
    let isWxReadyWorked = false;
    let payParams = {
      timestamp: params.timeStamp,
      nonceStr: params.nonceStr,
      package: params.package,
      signType: params.signType,
      paySign: params.paySign,
      success: (res) => {
        if (params.callback) {
          params.callback(true, res);
        } else {
          reloadPage();
        }
      },
      fail: (res) => {
        alert("支付失败");
        Raven.captureException(res);
        if (params.callback) {
          params.callback(false, res);
        }
      },
    };
    // Raven.captureMessage(`wx in pay. wx ${typeof wx},  isWxReadyWorked ${isWxReadyWorked}`)
    wx.ready(function () {
      isWxReadyWorked = true;
      // Raven.captureMessage("get wx.ready chooseWXPay")
      wx.chooseWXPay(payParams);
    });

    if (!isWxReadyWorked) {
      Raven.captureMessage(
        `wx ready not worked ${isWxReadyWorked}, direct use chooseWXPay`
      );
      try {
        wx.chooseWXPay(payParams);
      } catch (e) {
        Raven.captureException(e);
      }
    }
  } else if (UserAgent.isNativeClient()) {
    let callback = params.callback;
    if (params.callback) {
      delete params.callback;
    }
    XinYaNativeBridge.wxpay(
      {
        pay_params: params,
      },
      function (success) {
        if (callback) {
          callback(success);
        } else if (success) {
          reloadPage();
        }
      }
    );
  } else if (UserAgent.isMiniProgram()) {
    let payParams = {
      timeStamp: params.timeStamp,
      nonceStr: params.nonceStr,
      package: params.package,
      signType: params.signType,
      paySign: params.paySign,
    };
    const url =
      "/pages/wxPay/wxPay?params=" +
      encodeURIComponent(JSON.stringify(payParams));
    wx.ready(function () {
      wx.miniProgram.navigateTo({ url: url });
    });
  }
}
