/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef, memo } from "react";
import echarts from "echarts";
import ErrorBoundary from "../utils/ErrorBoundary";
import { formatDotDate } from "../utils/formatAudioTime";
import { checkDecimal, onNavigate } from "../utils/helpers";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import { ActivityBanner } from "./activity_components";
import RightFloatIcons from "./RightFloatIcons";
import { ActivityTabGroup } from "./activity_components/ActivityTabGroup";
import { WechatFollow } from "../study_package_activities/WechatFollow";

import {
  ThinkingBottomButton,
  findDefaultSelectedTicket,
  calcAllMediaLessonsCount,
  calcSubMediaLessonsCountTotal,
} from "./ThinkingDetails";
import { SharePosterProxy } from "../promotion_activities/SharePoster";
import { sortECouponsOnlyForCouponUseMode } from "./CourseCoupons";
import ThinkingCoupons from "./ThinkingCoupons";
import { DoubleElevenModal } from "../utils/DialogTemplate";
import {
  StyledCustomizationDescribe,
  StyledCustomizationSubDescribe,
  StyledCustomizationTitleDescribe,
  StyledCustomizationCourseContent,
  StyledCourseContentColum,
  StyledCustomizationCourseContentPieChart,
  StyledCourseOutlineButton,
  StyledCourseTeacherRecommend,
  StyledCustomizationCoursePrice,
  StyledThinkingCustomizeBox,
  StyledStudyIntroduceModal,
  StyledThinkingDetailList,
  StyleedPieChart,
  StyledRightList,
  StyledLeftListIndex,
  StyledCustomizationContainer,
  StyledCustomizationContentConverage,
  StyledCustomizationCourseStartTime,
  StyledUserBoughtSuccessDialog,
  StyledDoubleElevenActivity,
} from "./ThinkingCustomize.styled";
import CustomizationTitleDescribe_png from "../../../assets/images/CustomizationTitleDescribe.png";
import icon_button2 from "../../../assets/images/icon_button2.png";
import icon_button3 from "../../../assets/images/icon_button3.png";
import icon_button4 from "../../../assets/images/icon_button4.png";
import course_link_img from "../../../assets/images/course_link_img.png";

import icon_book from "think_customized/icon_book.png";
import icon_calendar from "think_customized/icon_calendar.png";
import icon_clock_remind from "think_customized/icon_clock_remind.png";
import iocn_top_paint from "think_customized/iocn_top_paint.png";
import icon_wx_group from "think_customized/icon_wx_group.png";
import double_eleven from "promotion/coupon.png";
import icon_customization_sub from "../../../assets/images/icon_customization_sub.png";
import "./StudyPackageActivity.scss";
import "./SubActivityTabs.scss";

const singleDetailColor = {
  background: "#ebf3ff",
  frame_background: "#e1eeff",
  frame_border: "#c8dffe",
  frame_inset_boder: "#d6e7ff",
  font_background: "#82b2f8",
};

// 共用模块
export const CustomizationSubDescribe = ({
  title,
  renderContent,
  renderHeader,
}) => {
  return (
    <StyledCustomizationSubDescribe>
      {title && <div className="fit_describe">{title}</div>}
      {renderHeader && (
        <div className="img_describe">
          <div className="img_describe_text">最适合您的课程为</div>
        </div>
      )}
      {renderHeader && <div style={{ width: "100%", height: "70px" }}></div>}
      {renderHeader && renderHeader()}
      {renderContent()}
    </StyledCustomizationSubDescribe>
  );
};

const ThinkingDetailList = memo(
  ({ sub_study_packages, course_details_url }) => {
    console.log("2345", course_details_url);
    const study_module = sub_study_packages[0].study_modules[0];
    let media_lessons_total = 0;
    const mediaLessonsCount = study_module.media_courses.reduce(
      (pre, cur, index) => {
        return (media_lessons_total += cur.media_lessons_count);
      },
      0
    );
    return (
      <StyledThinkingDetailList style={{ padding: "22px 11px 2px" }}>
        <div className="detail_lists_box">
          <div
            className="detail_lists"
            // key={idx}
            style={{ background: singleDetailColor.background }}
          >
            <div className="sub_title">
              <span>1</span>
              <div className="package_name">{study_module.name}</div>
            </div>
            <React.Fragment>
              <div
                className="single_list_box"
                style={{
                  border: `2px solid ${singleDetailColor.frame_border}`,
                }}
              >
                <div className="knowledge_text">{`知识点（${mediaLessonsCount}节课）`}</div>
                {study_module.media_courses.map(
                  (media_course, index) =>
                    index < 5 && (
                      <div
                        className="list_box"
                        key={index}
                        style={{
                          borderTop: `1px solid ${singleDetailColor.frame_inset_boder}`,
                        }}
                      >
                        <div className="left_list">
                          <StyledLeftListIndex
                            frameInsetColor={
                              singleDetailColor.frame_inset_boder
                            }
                            fontBackground={singleDetailColor.font_background}
                          >
                            {index + 1}
                          </StyledLeftListIndex>
                          <div className="left_list_name">
                            {media_course.name}
                          </div>
                        </div>
                        <StyledRightList
                          frameInsetColor={singleDetailColor.frame_inset_boder}
                        >
                          {`${media_course.media_lessons_count}节课`}
                        </StyledRightList>
                      </div>
                    )
                )}
              </div>
              {/* {study_module.photo_url && (
          <div className="detail_photo_url">
            <img src={study_module.photo_url} alt="" />
          </div>
        )} */}
            </React.Fragment>
          </div>
          {study_module.media_courses.length >= 5 ? (
            <div className="mengceng"></div>
          ) : (
            <div className="mengceng2"></div>
          )}
        </div>
        <div>
          <CourseOutlineButton4 course_details_url={course_details_url} />
        </div>
      </StyledThinkingDetailList>
    );
  }
);

// 标题描述
const CustomizationTitleDescribe = memo(({ ...props }) => {
  console.log("CustomizationTitleDescribeprops", props);
  return (
    <StyledCustomizationTitleDescribe
      style={{ marginBottom: props.equal && props.product_code ? 0 : -10 }}
    >
      <div className="title_describe">
        <h3>{props.customized_study_package_activity?.name}</h3>
        {props.calc_study_packages?.length > 0 && (
          <>
            <h5>
              连报：
              {props.calc_study_packages.map(
                (item, index) =>
                  item.name +
                  `${
                    index == props?.calc_study_packages.length - 1 ? "" : "、"
                  }`
              )}
            </h5>
          </>
        )}
        {props.equal && props.product_code && (
          <div className="title_describe_test">
            <img src={CustomizationTitleDescribe_png} style={{}} />
            {props.product_code}
            {"≈"}
            {props.equal}
          </div>
        )}
      </div>
    </StyledCustomizationTitleDescribe>
  );
});

// 课程需要的时间和学习周期 · 共用模块
export const StudyTimeDemonstrate = memo(
  ({ totalCourseLessons, allTotalStudyTimeMinutes }) => {
    console.log("105_totalCourseLessons: ", totalCourseLessons);
    console.log("106_allTotalStudyTimeMinutes: ", allTotalStudyTimeMinutes);
    return (
      <StyledCourseContentColum>
        <div className="left_content">
          <img src={icon_book} alt="" />
          <div className="text">
            <span>{totalCourseLessons}</span>节课
          </div>
        </div>
        <div className="center_content">
          <img src={icon_clock_remind} alt="" />
          <div className="text">
            <span>{parseInt(allTotalStudyTimeMinutes / 5)}</span>
            周学完
          </div>
        </div>
        <div className="right_content">
          <img src={icon_calendar} alt="" />
          <div className="text">
            <span>15</span>min*<span>5</span>次/周
          </div>
        </div>
      </StyledCourseContentColum>
    );
  }
);

// 课程内容 · 共用模块
export const CustomizationCourseContent = memo(({ ...props }) => {
  const total_course_lessons = calcAllMediaLessonsCount([
    ...props?.sub_study_packages,
  ]);

  let allTotalStudyTimeMinutes = 0;
  if (props?.sub_study_packages) {
    props.sub_study_packages.reduce((pre, cur, index) => {
      return (allTotalStudyTimeMinutes += cur.total_days);
    }, 0);
  }
  return (
    <StyledCustomizationCourseContent>
      <StudyTimeDemonstrate
        totalCourseLessons={total_course_lessons}
        allTotalStudyTimeMinutes={allTotalStudyTimeMinutes}
      />
      {/* <div className="course_outline">
        <div className="outline_text">课程大纲</div>
        <div
          className="button_look"
          onClick={(e) => onNavigate(e, props.course_details_url)}
        >
          查看
        </div>
      </div> */}
    </StyledCustomizationCourseContent>
  );
});

// 饼状图 + 知识点 · 共用模块
export const CustomizationCourseContentPieChart = ({ ...props }) => {
  console.log("96-props: ", props);
  // 总课时数
  const total_course_lessons = calcAllMediaLessonsCount([
    ...props?.sub_study_packages,
  ]);

  let allTotalStudyTimeMinutes = 0;
  if (props?.sub_study_packages) {
    props.sub_study_packages.reduce((pre, cur, index) => {
      return (allTotalStudyTimeMinutes += cur.total_days);
    }, 0);
  }

  console.log(
    "雷达图总课时数 total_lessons_counts, allTotalStudyTimeMinutes: ",
    total_course_lessons,
    allTotalStudyTimeMinutes
  );
  return (
    <StyledCustomizationCourseContentPieChart className="CustomizationCourseContentPieChart">
      <CustomizationKnowledgeDescribe
        courseCount={total_course_lessons}
        studyTime={parseInt(allTotalStudyTimeMinutes / 5)}
      />
      <CustomizationPieChart {...props} />
    </StyledCustomizationCourseContentPieChart>
  );
};

// 内容覆盖图
const CustomizationContentConverage = memo(({ ...props }) => {
  return (
    <StyledCustomizationContentConverage>
      <div className="radar_img">
        <img src={props.coverage_photo_url} alt="" />
      </div>
    </StyledCustomizationContentConverage>
  );
});

const getWeeklyDay = (start_time) => {
  const NUMBER = ["日", "一", "二", "三", "四", "五", "六"];
  const day = `周${NUMBER[start_time.getDay()]}`;
  return day;
};

// 开课时间
const CustomizationCourseStartTime = memo(({ startTime }) => {
  const start_time = new Date(startTime);
  const monthAndDay = `${start_time.getMonth() + 1}月${start_time.getDate()}日`;
  return (
    <StyledCustomizationCourseStartTime>
      <div className="course_start_time">
        <span>{getWeeklyDay(start_time)}</span>
        {`（${monthAndDay}）开课`}
      </div>
    </StyledCustomizationCourseStartTime>
  );
});

const ShowCourseIntroduceModal = ({ setShowModal }) => {
  return (
    <StyledStudyIntroduceModal>
      <div className="study_introduce_modal">
        <div className="introduce_content">
          <div className="introduce_circle"></div>
          <p className="time_and_count">
            按每次课<span>15分钟</span>，每周<span>5次课</span>计算。
          </p>
          <p className="text">开课后，您也可根据孩子的时间，自主调整。</p>
        </div>
        <div
          className="week_learn_close"
          onClick={() => {
            setShowModal(false);
          }}
        >
          我知道了
        </div>
      </div>
    </StyledStudyIntroduceModal>
  );
};

const CustomizationKnowledgeDescribe = ({ courseCount, studyTime }) => {
  const [isShowModal, setShowModal] = useState(false);
  return (
    <div className="course_content_count">
      共<span>{courseCount}</span>节课，大约需要<span>{studyTime}</span>周学完
      <div
        className="course_introduce_dialog"
        onClick={() => {
          setShowModal(true);
        }}
      ></div>
      {isShowModal && <ShowCourseIntroduceModal setShowModal={setShowModal} />}
    </div>
  );
};

// 饼状图 · 共用模块
export const CustomizationPieChart = ({ ...props }) => {
  console.log("饼状图 props: ", props);
  const media_lessons_collection = [];
  [...props?.sub_study_packages].map((act, index) => {
    const mediaLessonsCollection = calcSubMediaLessonsCountTotal(
      act.study_modules
    );
    return media_lessons_collection.push({
      value: mediaLessonsCollection,
      name: `${mediaLessonsCollection}节 ${act.customize_category}课`,
    });
  });
  console.log("饼状图 media_lessons_collection: ", media_lessons_collection);

  const chartColorCollect = ["#7BAEF8", "#FDA634", "#11CCA2"];
  useEffect(() => {
    var chart1 = document.getElementById("chart1");
    var option = {
      title: {
        text: "",
        subtext: "",
        left: "center",
      },
      tooltip: {
        trigger: "item",
        formatter: "{b} {d}%",
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: [],
      },
      series: [
        {
          name: "",
          type: "pie",
          // radius: document.body.clientWidth < 375 ? "70%" : "80%",
          radius: "60%",
          center: ["50%", "50%"],

          minAngle: 5,
          avoidLabelOverlap: true,
          hoverAnimation: false,

          label: {
            // 箭头指向的文字颜色
            fontSize: 14,
            // color: "#333",
            normal: {
              textStyle: {
                fontWeight: "bold",
                // color: "#333", // 改变标示文字的颜色
              },
              formatter(v) {
                const text = v.name;
                return text.length < 4
                  ? text
                  : `${text.slice(0, 4)}\n${text.slice(4)}`;
              },
              // rich: {
              //   b: {
              //     color: "red",
              //     align: "center",
              //   },
              //   c: {
              //     color: "#49c114",
              //     align: "center",
              //   },
              //   d: {
              //     color: "blue",
              //     align: "center",
              //   },
              // },
            },
          },
          labelLine: {
            normal: {
              length: 5,
              lineStyle: {
                color: "#333",
              },
            },
          },
          itemStyle: {
            borderWidth: 1.3, // 设置border的宽度有多大
            borderColor: "#fff",
          },
          data: media_lessons_collection,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
      color: chartColorCollect,
    };

    // 计算canvas画布的高度
    console.log("series data length: ", option.series[0].data.length);
    if (option.series[0].data.length <= 6) {
      option.series[0].data.length = 6;
    }
    if (chart1) {
      chart1.style.height = option.series[0].data.length * 2.5 + "rem";
    }
    var myChart = echarts.init(chart1);
    myChart.setOption(option);
    window.onresize = function () {
      myChart.resize();
    };
  }, [chartColorCollect, media_lessons_collection]);
  return (
    <StyleedPieChart>
      <div id="chart1" className="e_chart" />
    </StyleedPieChart>
  );
};

const userUseCouponStatus = (
  ecoupons,
  validCoupons,
  discountValue,
  ecouponsAvailable
) => {
  return ecoupons && ecoupons.length
    ? validCoupons && validCoupons.length > 0 && ecouponsAvailable
      ? discountValue * 1 === 0
        ? "不使用优惠券"
        : `- ¥${discountValue}`
      : "暂无可用优惠券"
    : "暂无优惠券";
};

const roundNumber = (money) => {
  let moneyOfNumber = money;
  if (typeof money === "string") {
    moneyOfNumber = Number(money);
  }
  if (moneyOfNumber == null || moneyOfNumber < 0) {
    return 0.0;
  }
  if (checkDecimal(moneyOfNumber)) {
    // console.log("money: "+ moneyOfNumber.constructor.name)
    return moneyOfNumber.toFixed(2);
  } else {
    return moneyOfNumber;
  }
};
const virtualMoney = (virtualAccount) => {
  const virtualMoney =
    virtualAccount && virtualAccount.money ? virtualAccount.money : 0.0;
  return roundNumber(virtualMoney);
};

const discountPrice = (ecouponsAvailable, discountValue) => {
  let discountPrice = 0.0;
  if (ecouponsAvailable) {
    discountPrice = discountValue;
  }
  return roundNumber(discountPrice);
};

const useVirtualMoney = (
  ecouponsAvailable,
  discountValue,
  coursePayFinalPrice,
  virtualAccount
) => {
  const virtual_money = virtualMoney(virtualAccount);
  const discountMoney = discountPrice(
    ecouponsAvailable,
    discountValue,
    coursePayFinalPrice
  );
  const remainingMoney = coursePayFinalPrice - discountMoney;
  console.log(
    "remainingMoney && virtualMoney：" + remainingMoney,
    virtual_money
  );
  if (remainingMoney >= virtual_money) {
    console.log("奖学金virtualMoney：" + virtual_money);
    return roundNumber(virtual_money);
  } else {
    console.log("奖学金remainingMoney：" + remainingMoney);
    return roundNumber(remainingMoney);
  }
};

const accFloat = (arg1, arg2) => {
  // arg1: 原折扣  arg2: 需要折扣的比率
  // 计算精度丢失的问题。
  // 用法：accFloat(1-0.66) => 0.34
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2));
  // last modify by deeka
  // 动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return Number((arg1 * m - arg2 * m) / m).toFixed(n);
};

// 课程价格 · 共用模块
export const CustomizationCoursePrice = ({ ...props }) => {
  console.log("课程价格表格模块-props: ", props);
  const {
    collectCouponData,
    setCollectLastPayParams,
    setCloseCouponDialog,
    ecoupons_available,
  } = props;
  // 总课时数
  const total_lessons_counts = calcAllMediaLessonsCount([
    ...props?.sub_study_packages,
  ]);

  // 默认可用券
  const defaultSelectedTicket = findDefaultSelectedTicket(props);
  // 汇总
  const amountSummary =
    parseInt(total_lessons_counts) * parseInt(props.price_per_media_lesson);

  // 之前课程已拥有
  const preOwnedLessonsCounts =
    parseInt(props.owned_media_lesson_count) *
    parseInt(props.price_per_media_lesson);

  // 汇总 减 之前课程已拥有的
  const amountSummarySubtractOwnedLessons =
    amountSummary - preOwnedLessonsCounts;
  console.log(
    "汇总 减 之前课程已拥有的 amountSummarySubtractOwnedLessons: ",
    amountSummarySubtractOwnedLessons
  );

  // 双11的折扣优惠：取”汇总-已拥有课程“*单价*折扣
  const activityDiscount = (
    accFloat(1, props.activity_discount) * amountSummarySubtractOwnedLessons
  ).toFixed(2);

  console.log("双11的折扣优惠 activityDiscount: ", activityDiscount);
  console.log("547-collectCouponData: ", collectCouponData);
  console.log("548-defaultSelectedTicket: ", defaultSelectedTicket);

  const surplusActivityDiscountPrice =
    amountSummarySubtractOwnedLessons - activityDiscount;

  const { coupons, validCoupons } = sortECouponsOnlyForCouponUseMode(
    props?.activity_ecoupons,
    props.system_time,
    "direct",
    surplusActivityDiscountPrice
  );

  console.log(
    "OrderDetailConfirm coupons，validCoupons: ",
    coupons,
    validCoupons
  );
  // 优惠券金额
  const discountValue =
    collectCouponData?.coupon_value >= 0
      ? parseInt(collectCouponData?.coupon_value)
      : validCoupons && validCoupons.length > 0
      ? parseInt(validCoupons[0].price)
      : 0;
  console.log("OrderDetailConfirm-discountValue: ", discountValue);

  // 折扣的奖学金
  const lastVirtualMoney = useVirtualMoney(
    props.ecoupons_available,
    discountValue,
    surplusActivityDiscountPrice,
    props?.virtual_account
  );
  console.log("OrderDetailConfirm-lastVirtualMoney: ", lastVirtualMoney);

  // 需实付：取”汇总价格“-”已拥有课程价格“-活动优惠价格（双11）-”优惠券“-”奖学金“
  const needPayMoney =
    amountSummary -
    preOwnedLessonsCounts -
    activityDiscount -
    discountValue -
    lastVirtualMoney;
  console.log("需实付 needPayMoney: ", needPayMoney);

  // 最后支付的价格
  const lastNeedPayMoney = needPayMoney > 0 ? needPayMoney : 0;
  console.log("需实付 最终要付钱的金额 lastNeedPayMoney: ", lastNeedPayMoney);

  // 共优惠：取”已拥有课程价格“+”活动优惠价格（双11）“+”优惠券“+”奖学金“
  const totalDiscountPrice =
    Number(preOwnedLessonsCounts) +
    Number(activityDiscount) +
    Number(discountValue) +
    Number(lastVirtualMoney);
  console.log(
    492,
    Number(preOwnedLessonsCounts),
    Number(activityDiscount),
    Number(discountValue),
    Number(lastVirtualMoney)
  );
  console.log("492-1-totalDiscountPrice: ", totalDiscountPrice);
  console.log("共优惠 totalDiscountPrice: ", totalDiscountPrice);

  const payEcouponId =
    collectCouponData && collectCouponData.id
      ? collectCouponData.id
      : validCoupons && validCoupons.length > 0
      ? validCoupons[0].id
      : -1;
  console.log("616-payEcouponId: ", payEcouponId);

  // 收集最后支付的一些参数
  const payParams = {
    lastNeedPayMoney: lastNeedPayMoney, // 汇总支付的实付价
    originalPrice: amountSummary, // 累计，初始总课时的价格
    discountValue: discountValue, // 优惠券价格
    lastVirtualMoney: lastVirtualMoney, // 抵扣的奖学金价格
    payEcouponId: payEcouponId, // 优惠券id
    amountSummarySubtractOwnedLessons: amountSummarySubtractOwnedLessons, // 累计减去之前课程已拥有的价格
    surplusActivityDiscountPrice: surplusActivityDiscountPrice, // 活动折扣后盈余的价格
  };
  console.log("收集最后支付的一些参数payParams: ", payParams);
  useEffect(() => {
    setCollectLastPayParams(payParams);
    // 券的依赖项必填
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payEcouponId]);

  const chooseEntranceCoupon = () => {
    setCloseCouponDialog(true);
  };

  console.log(
    "优惠券相关参数: ",
    coupons,
    validCoupons,
    discountValue,
    ecoupons_available
  );

  // 抵扣券的文案输出方式
  const discountTicketText = userUseCouponStatus(
    coupons,
    validCoupons,
    discountValue,
    ecoupons_available
  );
  console.log("572-discountTicketText: ", discountTicketText);
  return (
    <StyledCustomizationCoursePrice>
      <div className="order_detail_text">
        <div className="order_text_title flex_control">
          <span className="weight title_weight">课程配置包</span>
          <span className="weight title_weight">课时数</span>
          <span className="weight title_weight">价格</span>
        </div>
        <div className="course_package_introduce_box">
          {[...props?.sub_study_packages].map((sub_study_package, index) => {
            const totalMediaLessonsCount = calcSubMediaLessonsCountTotal(
              sub_study_package.study_modules
            );
            return (
              <div
                className="course_package_introduce flex_control"
                key={index}
              >
                <span>{sub_study_package.name}</span>
                <span>+ {totalMediaLessonsCount}节</span>
                <span className="weight">
                  ￥
                  {parseInt(totalMediaLessonsCount) *
                    parseInt(props.price_per_media_lesson)}
                </span>
              </div>
            );
          })}
        </div>
        <div className="course_package_discount_container">
          <div className="course_package_discount_box">
            <div className="course_package_discount flex_control">
              <span>累计</span>
              <span>{total_lessons_counts}节</span>
              <span className="weight">￥{amountSummary}</span>
            </div>
            {props.owned_media_lesson_count == 0 ? (
              ""
            ) : (
              <div className="course_package_discount flex_control">
                <span>之前课程已拥有</span>
                <span>- {props.owned_media_lesson_count}节</span>
                <span className="weight" style={{ color: "#FF6F4D" }}>
                  -￥{preOwnedLessonsCounts}
                </span>
              </div>
            )}
          </div>
          <div className="course_package_discount_box">
            <div className="course_package_discount flex_control">
              <span>需实付</span>
              <span>
                {total_lessons_counts - props.owned_media_lesson_count}节
              </span>
              <span className="weight">
                ￥{amountSummary - preOwnedLessonsCounts}
              </span>
            </div>
            {props.activity_discount < 1 && (
              <div className="course_package_discount flex_control">
                <span style={{ color: "#FF6F4D" }}>
                  {props.promotion_description}
                </span>
                <span className="weight" style={{ color: "#FF6F4D" }}>
                  - ￥{activityDiscount}
                </span>
              </div>
            )}

            {props?.activity_ecoupons?.length > 0 && (
              <div
                className="course_package_discount flex_control"
                onClick={chooseEntranceCoupon}
              >
                <span style={{ flex: 3.5 }}>
                  优惠券
                  {
                    // discountValue === defaultSelectedTicket.defaultSelectPrice &&
                    // discountValue != 0 &&
                    discountTicketText.indexOf("优惠券") <= -1 && (
                      <span
                        className="border_control"
                        style={{ fontSize: "12px" }}
                      >
                        已选最佳优惠券 »
                      </span>
                    )
                  }
                </span>
                <span className="weight" style={{ color: "#FF6F4D" }}>
                  {discountTicketText}
                </span>
              </div>
            )}
            {props?.virtual_account?.money > 0 && (
              <div className="course_package_discount flex_control">
                <span style={{ flex: 3.5 }}>
                  奖学金
                  <span className="border_control" style={{ fontSize: "12px" }}>
                    可用余额￥{props?.virtual_account?.money}
                  </span>
                </span>
                <span className="weight" style={{ color: "#FF6F4D" }}>
                  - ￥{Number(lastVirtualMoney).toFixed(2)}
                </span>
              </div>
            )}
          </div>
          <div className="course_package_discount_box double_eleven">
            <div className="course_package_discount flex_control">
              <span>汇总</span>
              <span>{total_lessons_counts}节</span>
              <span className="weight" style={{ fontSize: 16 }}>
                ￥{lastNeedPayMoney}
              </span>
            </div>
            <div className="total_discount">
              共优惠
              <span>{` -￥${Number(totalDiscountPrice).toFixed(2)}`}</span>
            </div>
          </div>
        </div>
      </div>
    </StyledCustomizationCoursePrice>
  );
};
export const CourseLink = ({ ...props }) => {
  const { study_progresses } = props;

  let maxP = study_progresses.map((item, index1) => {
    return maxP?.percent > item.percent ? maxP : item;
  });

  return (
    <div className="course-link">
      <div className="course-link-desc">
        {maxP.percent > 50
          ? `完成${maxP.name}学习后，继续${props.product_code}学习~`
          : `学习记录将同步到${props.product_code}课表中， 您可在新课表中继续学习~`}
      </div>
      <div className="course-link-img">
        <img src={course_link_img} className="course-link-img1"></img>
      </div>
    </div>
  );
};
const CourseOutlineButton2 = ({ ...props }) => {
  // 快捷支付按钮，非底部浮动按钮
  return (
    <StyledCourseOutlineButton>
      {
        // 1元学一周的状态 <i>￥</i>0.01
        !props.is_thinking_user ? (
          <div
            className="shortcut_button right_oneyuan_button"
            onClick={() => {
              $("#thinking_series_trial")?.trigger?.("click");
            }}
          >
            <p className="top">
              <span style={{ fontSize: 20 }}>免费</span>
              学一周
              <span style={{ fontSize: "12px", fontWeight: "300" }}>
                （正式课内容）
              </span>
            </p>

            <p className="bottom">
              还可得
              <span className="strong_num">￥100</span>
              课程优惠券
            </p>
          </div>
        ) : (
          <div
            className="shortcut_button2"
            onClick={() => {
              $("#think_series_course")?.trigger?.("click");
            }}
          >
            <div>
              符合我的要求，立即报名
              <span className="strong_num" style={{ fontSize: 18 }}>
                <i>￥</i>
                {Number(props.collectLastPayParams?.lastNeedPayMoney).toFixed(
                  2
                )}
              </span>
            </div>
            <img src={icon_button2} className="position-img"></img>
          </div>
        )
      }
    </StyledCourseOutlineButton>
  );
};
const CourseOutlineButton3 = ({ ...props }) => {
  // 快捷支付按钮，非底部浮动按钮
  return (
    <StyledCourseOutlineButton>
      <div
        className="shortcut_button3"
        onClick={(e) => {
          // $("#think_series_course")?.trigger?.("click");
          onNavigate(e, props.study_package_activity_customize_url);
        }}
      >
        <div className="sin_num"> 孩子基础已变，重新选择阶段</div>
        <img src={icon_button3} className="position-img"></img>
      </div>
    </StyledCourseOutlineButton>
  );
};
const CourseOutlineButton4 = ({ course_details_url }) => {
  // 快捷支付按钮，非底部浮动按钮
  return (
    <StyledCourseOutlineButton style={{ margin: "-20px 61px 10px" }}>
      <div
        className="shortcut_button shortcut_button2"
        style={{ backgroundColor: "#fda634", height: 40 }}
        onClick={(e) => onNavigate(e, course_details_url)}
      >
        <div className="sin_num">查看完整大纲</div>
        <img
          src={icon_button4}
          style={{
            width: 10,
            height: 12,
            display: "inline-block",
            marginLeft: "6px",
          }}
        ></img>
      </div>
    </StyledCourseOutlineButton>
  );
};
const CourseOutlineButton = ({ ...props }) => {
  // 快捷支付按钮，非底部浮动按钮
  return (
    <StyledCourseOutlineButton>
      {
        // 1元学一周的状态 <i>￥</i>0.01
        !props.is_thinking_user ? (
          <div
            className="shortcut_button right_oneyuan_button"
            onClick={() => {
              $("#thinking_series_trial")?.trigger?.("click");
            }}
          >
            <p className="top">
              <span style={{ fontSize: 20 }}>免费</span>
              学一周
              <span style={{ fontSize: "12px", fontWeight: "300" }}>
                （正式课内容）
              </span>
            </p>

            <p className="bottom">
              还可得
              <span className="strong_num">￥100</span>
              课程优惠券
            </p>
          </div>
        ) : (
          <div
            className="shortcut_button"
            onClick={() => {
              $("#think_series_course")?.trigger?.("click");
            }}
          >
            <p className="top">
              <span className="strong_num" style={{ fontSize: 20 }}>
                <i>￥</i>
                {Number(props.collectLastPayParams?.lastNeedPayMoney).toFixed(
                  2
                )}
              </span>
              <span
                style={{
                  fontSize: "12px",
                  fontWeight: "300",
                  paddingLeft: "6px",
                }}
              >
                <del>￥{props.collectLastPayParams?.originalPrice}</del>
              </span>
            </p>
            <p className="bottom" style={{ fontSize: 14 }}>
              立享优惠报名
            </p>
          </div>
        )
      }
    </StyledCourseOutlineButton>
  );
};
const CourseTeacherRecommend = ({ ccInfo }) => {
  return (
    <StyledCourseTeacherRecommend>
      <div className="top_cc_teacher_text">您的专属课程老师</div>
      <div className="course_teacher_recommend">
        <div className="cc_info">
          <div className="cc_name">{ccInfo.teacher_name}</div>
          <p>{`微信号：${ccInfo.teacher_wx}`}</p>
        </div>
        <img src={ccInfo.cc_qrcode_url} alt="" />
        <div className="recomend_text">
          更多课程内容，优惠问题 <br />
          可扫码咨询老师
        </div>
      </div>
    </StyledCourseTeacherRecommend>
  );
};

const UserBoughtSuccessDialog = memo(({ ...props }) => {
  return (
    <StyledUserBoughtSuccessDialog>
      <div className="user_bought_success_dialog">
        <div className="top_success_text">
          <h3>报名成功！</h3>
          <p>差一步您就可开课学习啦~</p>
        </div>
        <div className="center_join_group_text">
          {props.no_customized ? (
            <div className="join_text">长按联系老师，激活课程</div>
          ) : props.is_trial ? (
            <div className="join_text">立即扫码，入学习群</div>
          ) : (
            <div className="join_text">立即扫码，添加课程老师</div>
          )}
          {/* <div className="group_box"> */}
          {/* <img src={icon_wx_group} alt="" /> */}
          {/* <div className="group_content"> */}
          {/*
              <div className="group_name">{`心芽·数学培优${props.group_code}训练营`}</div>
              <div className="group_start_time">{`${formatDotDate(
                props.start_date
              )} ${getWeeklyDay(
                new Date(props.start_date.replace(/-/g, "/"))
              )} 开课`}
              </div>
              */}
          {/* </div> */}
          {/* </div> */}
        </div>
        <div className="bottom_wx_qrcode">
          <img src={props.group_qrcode_url} alt="" />
        </div>
        <div className="center_join_group_text">
          <div className="group_box">
            <div className="group_content">
              <div className="group_name">
                {props.no_customized ? (
                  <div className="text">
                    {`联系老师时，请发送`}
                    <span>{`激活码：${props.group_code}`}</span>
                  </div>
                ) : props.is_trial ? (
                  <div className="text">
                    {`扫码后，请发送`}
                    <span>{`入群码：${props.group_code}`}</span>
                  </div>
                ) : (
                  <div className="text">
                    {`添加后发送：`}
                    <span>{`${props.group_code}`}</span>
                    {`，获取专属老师服务`}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="security_certificate">
          <div className="text">此二维码已通过安全认证，请放心扫码</div>
        </div>
      </div>
    </StyledUserBoughtSuccessDialog>
  );
});

// 报名成功弹框 · 共用模块
export const showBoughtSuccessDialog = ({ ...props }) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return <UserBoughtSuccessDialog onModalClose={onClose} {...props} />;
    },
    isCreateModalDynamic: true,
  }).open();
};

const CustomizationDescribe = ({ ...props }) => {
  console.log("CustomizationDescribe_props: ", props);
  const [closeCouponDialog, setCloseCouponDialog] = useState(false);
  const [collectCouponData, setCollectCouponData] = useState({});
  const [collectLastPayParams, setCollectLastPayParams] = useState({});

  console.log("报名页-collectLastPayParams: ", collectLastPayParams);
  useEffect(() => {
    const {
      share_title,
      share_url,
      share_description,
      share_image_url,
    } = props;
    wx.ready(function () {
      function on_share_success() {}
      var sharedJson = {
        title: share_title,
        link: share_url,
        desc: share_description,
        imgUrl: share_image_url,
        success: on_share_success,
      };
      wx.onMenuShareTimeline(sharedJson);
      wx.onMenuShareAppMessage(sharedJson);
    });
  }, []);
  useEffect(() => {
    if (props.group_qrcode_url) {
      showBoughtSuccessDialog(props);
    }
    if (props.promotion_20201111 && props.sub_course_type == "thinking") {
      DoubleElevenModal("peiyou");
    }
  }, []);
  return (
    <StyledCustomizationDescribe>
      <ActivityBanner
        mainVideo={props.main_video_url}
        mainPhotoUrl={props.main_photo_url}
      />

      <StyledThinkingCustomizeBox>
        <div className={props.study_progresses ? "top_paper1" : "top_paper"}>
          <img src={iocn_top_paint} alt="" />
          {!props.study_progresses && (
            <div
              className="re_customized"
              onClick={(e) =>
                onNavigate(e, props.study_package_activity_customize_url)
              }
            >
              重新定制
            </div>
          )}
        </div>
        <div className="thinking_customize_content">
          {!props.study_progresses && <CustomizationTitleDescribe {...props} />}
          {props.study_progresses && props.study_progresses.length && (
            <div className="study_details">
              <div className="study_detail_title">根据您的学习记录：</div>
              {props.study_progresses.map((item, ii) => {
                return (
                  <div className="study_detail" key={"key" + ii}>
                    <div style={{ whiteSpace: "nowrap" }}>{item.name}</div>
                    <div className="study_detail_data">
                      {item.percent > 0 ? (
                        <>
                          <div className="progress_bar">
                            <div
                              className="progress"
                              style={{
                                width: `${item.percent}%`,
                                // width: calcAlreadyStudyDayPercent(
                                //   this.props.studiedCourseDayCount,
                                //   this.props.courseDayCount
                                // ),
                              }}
                            ></div>
                          </div>
                          <div className="progress-data">{`${item.percent}%`}</div>
                        </>
                      ) : (
                        "尚未开课"
                      )}
                    </div>
                  </div>
                );
              })}

              {/* <div className="study_detail">
              <div>T3：大班</div>
              <div className="study_detail_data"></div>
            </div> */}
            </div>
          )}
          {/* <CustomizationTitleDescribe {...props} /> */}
          {props.study_progresses ? (
            <CustomizationSubDescribe
              // title="课程内容"
              renderContent={() => {
                return <CustomizationCourseContent {...props} />;
              }}
              renderHeader={() => {
                return <CustomizationTitleDescribe {...props} />;
              }}
            />
          ) : (
            <CustomizationSubDescribe
              title="课程内容"
              renderContent={() => {
                return <CustomizationCourseContent {...props} />;
              }}
            />
          )}

          {props.sub_study_packages[0]?.study_modules[0]?.media_courses
            .length && (
            <CustomizationSubDescribe
              title="课程大纲"
              renderContent={() => {
                return <ThinkingDetailList {...props} />;
              }}
            />
          )}

          {/* <CustomizationSubDescribe
            // title="课程内容"
            renderContent={() => {
              return <CustomizationCourseContent {...props} />;
            }}
            renderHeader={() => {
              return <CustomizationTitleDescribe {...props} />;
            }} 
          />*/}
          <CustomizationSubDescribe
            title="课程覆盖度"
            renderContent={() => {
              return <CustomizationContentConverage {...props} />;
            }}
          />
          <CustomizationSubDescribe
            title="开课时间"
            renderContent={() => {
              return (
                <CustomizationCourseStartTime
                  startTime={props.start_date.replace(/-/g, "/")}
                />
              );
            }}
          />
          {
            // props.is_thinking_user && (
            <CustomizationSubDescribe
              title="课程价格"
              renderContent={() => {
                return (
                  <CustomizationCoursePrice
                    collectCouponData={collectCouponData}
                    setCollectLastPayParams={setCollectLastPayParams}
                    setCloseCouponDialog={setCloseCouponDialog}
                    {...props}
                  />
                );
              }}
            />
            // )
          }
          {props.study_progresses && (
            <CustomizationSubDescribe
              title="学习衔接"
              renderContent={() => {
                return (
                  <CourseLink {...props} />
                  // pressents={props.study_progresses.map()}
                  // <CustomizationCoursePrice
                  //   collectCouponData={collectCouponData}
                  //   setCollectLastPayParams={setCollectLastPayParams}
                  //   setCloseCouponDialog={setCloseCouponDialog}
                  //   {...props}
                  // />
                );
              }}
            />
          )}
          {props.study_progresses && (
            <CourseOutlineButton2
              collectLastPayParams={collectLastPayParams}
              {...props}
            />
          )}
          {props.study_progresses && (
            <CourseOutlineButton3
              collectLastPayParams={collectLastPayParams}
              {...props}
            />
          )}
          {!props.bought && !props.study_progresses && (
            <CourseOutlineButton
              collectLastPayParams={collectLastPayParams}
              {...props}
            />
          )}
          {props.is_thinking_user && (
            <StyledDoubleElevenActivity href="/promotion_activity">
              <img src={double_eleven} alt="" />
            </StyledDoubleElevenActivity>
          )}
          {props.cc_info && <CourseTeacherRecommend ccInfo={props.cc_info} />}
        </div>
      </StyledThinkingCustomizeBox>
      <ThinkingBottomButton
        setCloseCouponDialog={setCloseCouponDialog}
        collectCouponData={collectCouponData}
        collectLastPayParams={collectLastPayParams}
        wechatRef={props.wechatRef}
        {...props}
      />
      {closeCouponDialog && (
        <ThinkingCoupons
          eCouponData={props?.activity_ecoupons}
          closeCouponDialog={closeCouponDialog}
          setCloseCouponDialog={setCloseCouponDialog}
          setCollectCouponData={setCollectCouponData}
          collectCouponData={collectCouponData}
          defaultSelectedTicket={findDefaultSelectedTicket(props)}
          coursePayFinalPrice={
            collectLastPayParams?.surplusActivityDiscountPrice
          }
          {...props}
        />
      )}
    </StyledCustomizationDescribe>
  );
};
export default ({ ...props }) => {
  console.log("props: ", props);
  props.is_customized_course = true;
  const wechatRef = useRef();

  const onInvitationIconClick = () => {
    const {
      poster_photo_template_url: posterTemplate,
      poster_creation: posterCreation,
      bonus,
      bonus_type,
      avatar_location,
    } = props;
    const free = "is_customize_course";
    new SharePosterProxy({
      posterTemplate,
      posterCreation,
      mode: "front_end",
      free: free,
      bonus,
      bonus_type,
      avatar_location,
    }).open();
  };
  return (
    <ErrorBoundary>
      <StyledCustomizationContainer>
        <CustomizationDescribe wechatRef={wechatRef} {...props} />
        <ActivityTabGroup {...props} />
        <RightFloatIcons
          isShowRedMoney={!!props.bonus}
          onRedMoneyClick={onInvitationIconClick}
          bottomPosition={
            props.buy_countdown_endtime && !props.bought ? 140 : 100
          }
        />
        <WechatFollow ref={wechatRef} qrcodeUrl={props.qrcode_url} />
      </StyledCustomizationContainer>
    </ErrorBoundary>
  );
};
