/* eslint-disable */
import React from "react";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import { showDialog } from "../utils/helpers";
import UserAgent from "../utils/UserAgent";

import "./Group.scss";

const groupItems = [
  {
    area: "全市招生",
    startTime: "2月25日",
    endTime: "3月3号",
  },
  {
    area: "体制外",
    startTime: "3月4日",
    endTime: "3月10号",
  },
  {
    area: "浦东新区",
    startTime: "3月11日",
    endTime: "3月17号",
  },
  {
    area: "徐汇区",
    startTime: "3月18日",
    endTime: "3月24号",
  },
  {
    area: "闵行区",
    startTime: "3月25日",
    endTime: "3月31号",
  },
  {
    area: "杨浦/虹口区",
    startTime: "4月1日",
    endTime: "4月7号",
  },
  {
    area: "静安/长宁/普陀/黄浦区",
    startTime: "4月28日",
    endTime: "4月14号",
  },
  {
    area: "专项能力培养",
    startTime: "4月15日",
    endTime: "4月21号",
  },
];

export default class GroupSelect extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = {
      selectIndexes: [],
    };
  }
  itemSelect = (e) => {
    let selectedIndex = e.currentTarget.getAttribute("data-index");
    let foundIndex = this.state.selectIndexes.indexOf(selectedIndex + "");
    //取消选择
    if (foundIndex !== -1) {
      this.setState((pre) => {
        pre.selectIndexes.splice(foundIndex, 1);
        return {
          selectIndexes: pre.selectIndexes,
        };
      });
      return;
    }

    let preSelectIndexes = [...this.state.selectIndexes];
    preSelectIndexes.push(selectedIndex);
    if (preSelectIndexes.length > 2) {
      let dialogOptions = {
        text: "每人最多只可选2个主题群加入",
        mainLabel: "确定",
        mainCallback: () => {},
      };
      showDialog.call(this, dialogOptions);
      return;
    }
    this.setState(() => ({
      selectIndexes: preSelectIndexes,
    }));
  };
  submitGroupSelection = () => {
    if (this.state.selectIndexes.length === 0) {
      let dialogOptions = {
        text: "请选择主题群",
        mainLabel: "确定",
        mainCallback: () => {},
      };
      showDialog.call(this, dialogOptions);
      return;
    }

    let selectAreas = [...this.state.selectIndexes].map((item) => {
      return groupItems[parseInt(item)].area;
    });
    console.log(selectAreas.join(","));
    request({
      url: this.props.group_select_url,
      method: "POST",
      headers: csrfHeaders,
      data: {
        group_names: selectAreas.join("，"),
      },
    })
      .then((res) => {
        ToastManager.showToastSuccess("提交成功");
        if (UserAgent.isNativeClient()) {
          XinYaNativeBridge.navigate({ href: res.data.redirect_url });
        } else {
          window.location.href = res.data.redirect_url;
        }
      })
      .catch((err) => {
        ToastManager.showToastError("提交出错");
        console.log(err);
      });
  };
  render() {
    const { selectIndexes } = this.state;
    const { group_names, redirect_url } = this.props;
    return [
      <div className="comp_group_select" key="comp_group_select">
        <div className="header_title">
          <h1>请选择要加入的主题群</h1>
          <p>每人最多选2个，添加老师时请备注</p>
        </div>
        <div className="select_area">
          {groupItems.map((item, index) => {
            return (
              <div
                className={`item_select ${
                  selectIndexes.indexOf(index + "") !== -1 ? "selected" : ""
                }`}
                key={index}
                onClick={this.itemSelect}
                data-index={index}
              >
                <p>{item.area}</p>
                {/* <p>{`${item.startTime} - ${item.endTime}`}</p> */}
              </div>
            );
          })}
        </div>
        <div className="confirm_button_area">
          <div className="button" onClick={this.submitGroupSelection}>
            确定
          </div>
        </div>
      </div>,

      group_names && (
        <div className="comp_group_select_alert" key="comp_group_select_alert">
          <div className="modal">
            <div className="modal_top">您已选择主题群：{group_names}</div>
            <div className="modal_bottom">
              <a href={redirect_url || "/"}>确定</a>
            </div>
          </div>
        </div>
      ),
    ];
  }
}
