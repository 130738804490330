/* istanbul ignore file */
export default class UserAgent {
  static currentType() {
    if (document.querySelector("meta[name='data:user_agent']")) {
      const json = JSON.parse(
        document
          .querySelector("meta[name='data:user_agent']")
          .getAttribute("content")
      );
      const { UNKNOWN, IOS, ANDROID, WECHAT, MINIPROGRAM } = json.types;
      const curType = json.cur_type;
      return curType;
    } else {
      return 0;
    }
  }

  static isIOS() {
    let curType = UserAgent.currentType();
    return curType == 1;
  }

  static isAndroid() {
    let curType = UserAgent.currentType();
    return curType == 2;
  }

  static isNativeClient() {
    return UserAgent.isIOS() || UserAgent.isAndroid();
  }

  static isWechat() {
    let curType = UserAgent.currentType();
    return curType == 3;
  }

  static isWebBrowser() {
    return UserAgent.isUnknown();
  }

  static isMiniProgram() {
    let curType = UserAgent.currentType();
    return curType == 4;
  }

  static isUnknown() {
    let curType = UserAgent.currentType();
    return curType == 0;
  }

  // Copied from xgplayer-hls.js/src/utils/index.js
  static getBrowserVersion() {
    let userAgent = navigator.userAgent //取得浏览器的userAgent字符串
    if(userAgent.toLowerCase().indexOf("mobile") > -1) {
      return "Unknown"
    }
    if (userAgent.indexOf("Firefox") > -1) {
      let version = userAgent.match(/firefox\/[\d.]+/gi)[0].match(/[\d]+/)[0]
      return "Firefox "+version
    } else if (userAgent.indexOf("Edge") > -1) {
      let version = userAgent.match(/edge\/[\d.]+/gi)[0].match(/[\d]+/)[0]
      return "Edge "+version
    } else if (userAgent.indexOf("rv:11") > -1) {
      return "IE 11"
    } else if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
      if (userAgent.indexOf("Opera") > -1) {
        let version = userAgent.match(/opera\/[\d.]+/gi)[0].match(/[\d]+/)[0]
        return "Opera "+version
      }
      if (userAgent.indexOf("OPR") > -1) {
        let version = userAgent.match(/opr\/[\d.]+/gi)[0].match(/[\d]+/)[0]
        return "Opera "+version
      }
    } else if (userAgent.indexOf("Chrome") > -1) {
      let version = userAgent.match(/chrome\/[\d.]+/gi)[0].match(/[\d]+/)[0]
      return "Chrome "+version
    } else if (userAgent.indexOf("Safari") > -1) {
      let version = userAgent.match(/safari\/[\d.]+/gi)[0].match(/[\d]+/)[0]
      return "Safari "+version
    } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
      if (userAgent.indexOf("MSIE") > -1) {
        let version = userAgent.match(/msie [\d.]+/gi)[0].match(/[\d]+/)[0]
        return "IE "+version
      }
      if (userAgent.indexOf("Trident") > -1) {
        let versionTrident = userAgent.match(/trident\/[\d.]+/gi)[0].match(/[\d]+/)[0]
        let version = parseInt(versionTrident) + 4
        return "IE "+version
      }
    } else {
      return "Unknown"
    }
  }
}

// Copied from xgplayer/src/utils/sniffer.js
Object.defineProperty(UserAgent, 'device', {
  get: function () {
    let r = UserAgent.os
    return r.isPc ? 'pc' : 'mobile'
    // return r.isPc ? 'pc' : r.isTablet ? 'tablet' : 'mobile'
  }
})

Object.defineProperty(UserAgent, 'browser', {
  get: function () {
    let ua = navigator.userAgent.toLowerCase()
    let reg = {
      ie: /rv:([\d.]+)\) like gecko/,
      firfox: /firefox\/([\d.]+)/,
      chrome: /chrome\/([\d.]+)/,
      opera: /opera.([\d.]+)/,
      safari: /version\/([\d.]+).*safari/
    }
    return [].concat(Object.keys(reg).filter(key => reg[key].test(ua)))[0] || ''
  }
})

Object.defineProperty(UserAgent, 'os', {
  get: function () {
    let ua = navigator.userAgent
    let isWindowsPhone = /(?:Windows Phone)/.test(ua)
    let isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone
    let isAndroid = /(?:Android)/.test(ua)
    let isFireFox = /(?:Firefox)/.test(ua)
    let isTablet = /(?:iPad|PlayBook)/.test(ua) || (isAndroid && !/(?:Mobile)/.test(ua)) || (isFireFox && /(?:Tablet)/.test(ua))
    let isPhone = /(?:iPhone)/.test(ua) && !isTablet
    let isPc = !isPhone && !isAndroid && !isSymbian && !isTablet
    return {
      isTablet,
      isPhone,
      isAndroid,
      isPc,
      isSymbian,
      isWindowsPhone,
      isFireFox
    }
  }
})
