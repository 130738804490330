/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import "./WechatFollow.scss";

const WechatFollow = forwardRef((props, ref) => {
  const { qrcodeUrl, alertQRCode, title } = props;

  const wechatFollowRef = useRef();

  useImperativeHandle(ref, () => ({
    showWechatFollow: () => {
      wechatFollowRef.current.className = "guide_wechat_follow show";
    },
  }));

  const onCloseDialog = useCallback(() => {
    if (typeof alertQRCode === "function") {
      alertQRCode();
    } else {
      wechatFollowRef.current.className = "guide_wechat_follow hide";
    }
  }, [alertQRCode]);

  return (
    <div
      className={
        alertQRCode ? "guide_wechat_follow show" : "guide_wechat_follow"
      }
      ref={wechatFollowRef}
    >
      <div className="guide_wechat_follow_absolute">
        <div className="dialog_qrcode_body">
          <div className="btn-close-wechat" onClick={onCloseDialog}>
            &times;
          </div>
          {title ? (
            <p>{title}</p>
          ) : (
            <p>
              请先关注公众号
              <br />
              接收学习提醒及报告
            </p>
          )}
          <img data-src={qrcodeUrl} className="lazyload" data-sizes="auto" />
        </div>
      </div>
    </div>
  );
});

export { WechatFollow };
