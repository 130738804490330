/* eslint-disable */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import URI from "jsuri";
import UserAgent from "../utils/UserAgent";

import { onMiniProgram } from "../utils/helpers";

import arrowRight from "arrow-right.png";

import defaultUserAvatar from "icon_avatar.png";

import icon_settings from "coupon/icon_set.png";

import "../study_package_activities/StudyPackageActivityList.scss";

// 评论组件
const Comments = ({
  comments,
  study_package_activity,
  initialIndex = 6,
  onTouchEnd,
  onTouchStart,
  onClick,
}) =>
  comments && comments.length > 0
    ? comments.map((comment, index) => (
        <Comment
          comment={comment}
          zIndex={initialIndex - index}
          onTouchEnd={onTouchEnd}
          onTouchStart={onTouchStart}
          study_package_activity={study_package_activity}
          key={comment.shortId}
          index={index}
          onMiniProgramClick={(e) => {
            e.preventDefault();
          }}
        />
      ))
    : "";

const Comment = ({
  comment,
  zIndex,
  onTouchEnd,
  onTouchStart,
  study_package_activity,
  index,
  onMiniProgramClick,
}) => {
  let commentUrl = study_package_activity.buy_url + "#comment";
  return (
    <div
      className="comment_wrapper"
      style={{ zIndex: zIndex }}
      data-index={comment.shortId}
      onTouchEnd={onTouchEnd}
      onTouchStart={onTouchStart}
    >
      <div className="comment_header">
        <img
          data-src={comment.photo_url}
          className="user_avatar lazyload"
        ></img>
        <div className="user_wrapper">
          <span className="user_name">{comment.name}</span>
          <span className="phase">({comment.phase})</span>
        </div>
      </div>
      <div className="comment_content">
        <a
          // href={commentUrl}
          data-url={commentUrl}
          onClick={onMiniProgramClick}
        >
          <div
            className="content_comment"
            style={{
              width:
                index + 1 == study_package_activity.comments.length
                  ? "87%"
                  : "",
            }}
          >
            {comment.comment}
          </div>
        </a>
      </div>
      {/* {
        index + 1 == study_package_activity.comments.length 
        ?
        <a className="readMore" href={commentUrl} data-url={commentUrl} onClick={onMiniProgramClick}></a>
        : ''
      } */}
    </div>
  );
};

// 单个活动上面部分 -- 已购买
const PaidActvityStatusContent = ({
  study_package_activity,
  onMiniProgramClick,
}) => {
  return [
    // <div className="buy_state" key="buy_state">已购买</div>,
    <a
      style={{ borderRadius: !UserAgent.isMiniProgram() ? null : "6px" }}
      className="lazyload"
      style={{
        borderRadius: study_package_activity.comments.length === 0 ? "6px" : "",
      }}
      href={
        study_package_activity.user
          ? study_package_activity.study_url
          : study_package_activity.buy_url
      }
      onClick={onMiniProgramClick}
      data-bgset={study_package_activity.photo_url}
      data-url={
        study_package_activity.user
          ? study_package_activity.study_url
          : study_package_activity.buy_url
      }
      key="url"
    >
      <h2
        style={{
          paddingLeft:
            study_package_activity.buy_format === "bargain" ? "5px" : "",
        }}
      >
        {study_package_activity.name}
      </h2>
      {study_package_activity.phase &&
      study_package_activity.start_time &&
      study_package_activity.end_time
        ? study_package_activity.start_time && (
            <p>
              上课时间：
              {(
                study_package_activity.start_time.replace(/-/g, ".") + ""
              ).substr(0, 10)}{" "}
              —{" "}
              {(study_package_activity.end_time.replace(/-/g, ".") + "").substr(
                0,
                10
              )}
            </p>
          )
        : ""}
      {study_package_activity.course_day_count &&
      study_package_activity.media_lessons_count ? (
        <p className="day_count_lessons_count">
          <span className="solid_circle_divider"></span>
          <span>{study_package_activity.course_day_count}天</span>
          <span className="solid_circle_divider"></span>
          {study_package_activity.course_type === "poetry" ? (
            <span>{study_package_activity.media_courses_count}首诗</span>
          ) : (
            <span>{study_package_activity.media_lessons_count}节课</span>
          )}
        </p>
      ) : (
        ""
      )}
      <p className="activity_status">
        <span>{study_package_activity.status}</span>
        <img data-src={arrowRight} className="lazyload" />
      </p>
    </a>,
  ];
};

// 邀请奖励
const InvitationBonus = ({ study_package_activity }) => {
  if (study_package_activity.buy_format.split(" ").includes("promotion")) {
    //  非砍价活动优惠
    if (study_package_activity.bonus != 0) {
      return (
        <div className="text">
          每邀请一位新朋友报名成功且不退款，
          <span className="reward-attention">
            奖励{study_package_activity.bonus}元
          </span>
          ！可累加不封顶！(新朋友：未购买过任何心芽课程的用户)
        </div>
      );
    }
  } else if (study_package_activity.buy_format == "bargain") {
    // 砍价活动优惠
    return (
      <div className="text">
        每邀请1位新朋友关注『心芽学堂』公众号，即可
        <span className="reward-attention">优惠10元。</span>
      </div>
    );
  }
  //  非砍价活动优惠后台为0
  return (
    <div className="text">
      好课程就要与朋友分享，邀请朋友一起报名学习，共同进步！
    </div>
  );
};

class SingleActivity extends PureComponent {
  constructor(props) {
    super(props);
    function doInitialData(props) {
      let swipedShortId = [];
      let tempList = [];
      let current_time = new Date(
        props.study_package_activity.study_package_system_time
      );
      let start_time = new Date(props.study_package_activity.start_time);
      let end_time = new Date(props.study_package_activity.end_time);
      let bought_state = props.study_package_activity.bought_state;
      let status = props.study_package_activity.status;

      let shortId =
        status == "等待开课"
          ? props.study_package_activity.shortId
          : props.study_package_activity.comments &&
            props.study_package_activity.comments.length > 0
          ? "price" in props.study_package_activity ||
            "bonus" in props.study_package_activity
            ? props.study_package_activity.comments[0].shortId
            : props.study_package_activity.comments[1].shortId
          : "";

      if (
        status != "等待开课" &&
        props.study_package_activity.comments &&
        props.study_package_activity.comments.length > 0
      ) {
        // swipedShortId.push(props.study_package_activity.shortId)
      }
      // tempList.push(props.study_package_activity.shortId)
      props.study_package_activity.comments
        ? props.study_package_activity.comments.forEach((comment) => {
            tempList.push(comment.shortId);
          })
        : "";

      return { swipedShortId, shortId, tempList, status };
    }

    let initialData = doInitialData(props);
    this.state = {
      showRechargeOptions: this.props.remain_amount <= 0,
      status: initialData.status,
      shortId: initialData.shortId,
      handleTouchList: initialData.tempList,
      isAlertQR: false,
      swipedShortId: initialData.swipedShortId,
    };
    // 初始化触摸变量
    this.touchPosition = {
      startX: 0,
      startY: 0,
    };
    this.props.study_package_activity["status"] = initialData.status;
  }
  // 点击小圆点事件，用于处理点击原点改变滑块的状态和t触摸滑动步调一致
  onDotClick = (e) => {
    let shortId = e.currentTarget.getAttribute("data-index");
    let currentIndex = this.state.handleTouchList.indexOf(shortId);
    let tempList = [];
    tempList = this.state.handleTouchList.slice(0, currentIndex);
    this.setState({
      swipedShortId: tempList,
      shortId: shortId,
    });
  };
  onHandleTouch = (e) => {
    let shortId = e.currentTarget.getAttribute("data-index");
    let currentIndex = this.state.handleTouchList.indexOf(shortId);
    let moveEndX = e.changedTouches[0].pageX;
    let moveEndY = e.changedTouches[0].pageY;
    let X = moveEndX - this.touchPosition.startX;
    let Y = moveEndY - this.touchPosition.startY;
    if (Math.abs(X) > Math.abs(Y) && X > 0) {
      if (currentIndex == "-1" || currentIndex - 1 < 0) {
        currentIndex = 0;
      } else {
        this.state.swipedShortId.pop(shortId);
        currentIndex--;
      }
    } else if (Math.abs(X) > Math.abs(Y) && X < 0) {
      if (
        currentIndex == "-1" ||
        currentIndex + 1 == this.state.handleTouchList.length
      ) {
        currentIndex = this.state.handleTouchList.length - 1;
      } else {
        this.state.swipedShortId.push(shortId);
        this.state.swipedShortId = [...new Set(this.state.swipedShortId)];
        currentIndex++;
      }
    }
    this.setState({
      preShortId: shortId,
      shortId: this.state.handleTouchList[currentIndex],
      swipedShortId: this.state.swipedShortId,
    });
  };

  onHandleTouchStart = (e) => {
    let startX = e.changedTouches[0].pageX;
    let startY = e.changedTouches[0].pageY;
    this.touchPosition.startX = startX;
    this.touchPosition.startY = startY;
  };
  onAlertQRCode = (e) => {
    let imageSrc = e.currentTarget.src;
    const { onAlert } = this.props;
    onAlert(true, imageSrc);
  };
  render() {
    const { study_package_activity } = this.props;
    const { status } = this.state;
    return (
      <div className="number-course-wrapper">
        <div className="course_module_entrance">
          <div className="box_shadow">
            {study_package_activity.buy_format === "bargain" ? (
              <div className="interview_subject_wrapper">
                {this.props.render(study_package_activity)}
              </div>
            ) : (
              <div className="number_course_list">
                {this.props.render(study_package_activity)}
              </div>
            )}

            {
              // (!UserAgent.isMiniProgram() && !UserAgent.isIOS()) &&
              study_package_activity.comments.length > 0 && (
                <div
                  className="task_comments_container"
                  key="comments_container"
                >
                  <div className="padding_add">
                    {status === "等待开课" || status === "等待拼团成功"
                      ? study_package_activity.comments.length > 0 && (
                          <div
                            className="task_wrapper"
                            style={{
                              left:
                                "-" +
                                this.state.swipedShortId.length * 100 +
                                "%",
                            }}
                          >
                            {/* <div 
                          className="task_list" 
                          style={{ zIndex: '6' }} 
                          data-index={study_package_activity.shortId} 
                          onTouchEnd={this.onHandleTouch} 
                          onTouchStart={this.onHandleTouchStart}
                        >
                          <a 
                            className="gride invitation" 
                            href={study_package_activity.buy_url} 
                            data-url={study_package_activity.buy_url} 
                            onClick={study_package_activity.onMiniProgramClick}
                          >
                            <div className="gride_wrapper">
                              <img data-src={giftImg} alt="" className="lazyload"/>
                              <p>邀请朋友</p>
                            </div>
                          </a>
                          <div className="divider"></div>
                          <div className="gride use_guider">
                            <a 
                              className="gride_wrapper" 
                              href={study_package_activity.course_type ==='poetry' ? study_package_activity.study_url:study_package_activity.note_url } 
                              data-url={study_package_activity.course_type ==='poetry' ? study_package_activity.study_url:study_package_activity.note_url} 
                              onClick={study_package_activity.onMiniProgramClick}
                            >
                              <img data-src={useDescribe} alt="" className="lazyload"/>
                              <p>使用说明</p>
                            </a>
                          </div>
                          <div className="divider"></div>
                          <div className="gride add_teacher" >
                            <div className="gride_wrapper">
                              {
                                'teacher_qrcode_url' in study_package_activity
                                ?
                                <img data-src={study_package_activity.teacher_qrcode_url} alt="" onClick={this.onAlertQRCode} className="lazyload"/>
                                :
                                <img data-src={smallQR} alt="" onClick={this.onAlertQRCode} className="lazyload"/>
                              }
                              <p>添加老师</p>
                            </div>
                          </div>
                        </div> */}
                            <Comments
                              comments={study_package_activity.comments}
                              study_package_activity={study_package_activity}
                              onTouchEnd={(e) => this.onHandleTouch(e)}
                              onTouchStart={(e) => this.onHandleTouchStart(e)}
                              // onClick={study_package_activity.onMiniProgramClick}
                            />
                          </div>
                        )
                      : study_package_activity.comments.length > 0 && (
                          <div
                            className="invitation_container"
                            style={{
                              left:
                                "-" +
                                this.state.swipedShortId.length * 100 +
                                "%",
                            }}
                          >
                            {/* {
                          ("bonus" in study_package_activity) || ("price" in study_package_activity) ?
                          <div 
                            className="invitation_wrapper" 
                            style={{ zIndex: '6' }} 
                            onTouchEnd={this.onHandleTouch} 
                            data-index={study_package_activity.shortId} 
                            onTouchStart={this.onHandleTouchStart}
                          >
                            <div className="invitation_content">
                              <div className="title">邀请朋友</div>
                              <InvitationBonus 
                                study_package_activity={study_package_activity} 
                              />
                            </div>
                            <a 
                              className="invitation_btn" 
                              href={study_package_activity.buy_url} 
                              data-url={study_package_activity.buy_url} 
                              onClick={study_package_activity.onMiniProgramClick}
                            >邀请
                            </a>
                          </div> 
                          : ''
                        } */}
                            <Comments
                              comments={study_package_activity.comments}
                              study_package_activity={study_package_activity}
                              onTouchEnd={(e) => this.onHandleTouch(e)}
                              onTouchStart={(e) => this.onHandleTouchStart(e)}
                              onClick={
                                study_package_activity.onMiniProgramClick
                              }
                            />
                          </div>
                        )}
                  </div>
                  <div className="dots">
                    {/* {
                    ("price" in study_package_activity) || ("bonus" in study_package_activity) 
                    ? (study_package_activity.comments && study_package_activity.comments.length > 0)
                      &&
                      <div 
                        className="dot" 
                        style={{ backgroundColor: study_package_activity.shortId == this.state.shortId ? 'black' : '' }} 
                        onClick={this.onDotClick} 
                        data-index={study_package_activity.shortId}
                      >
                      </div>     
                    :''
                  } */}
                    {study_package_activity.comments &&
                    study_package_activity.comments.length > 0
                      ? study_package_activity.comments.map(
                          (comment, index) => (
                            <div
                              className="dot"
                              data-index={comment.shortId}
                              key={comment.shortId}
                              onClick={this.onDotClick}
                              style={{
                                backgroundColor:
                                  comment.shortId == this.state.shortId
                                    ? "black"
                                    : "",
                              }}
                            ></div>
                          )
                        )
                      : ""}
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

class BoughtStudyPackageActivities extends PureComponent {
  toRender = (paidActivity) =>
    (paidActivity.bought_state == "bought" ||
      paidActivity.bought_state == "pre_paid") && (
      <PaidActvityStatusContent
        study_package_activity={paidActivity}
        onMiniProgramClick={paidActivity.onMiniProgramClick}
      />
    );

  render() {
    return this.props.BoughtActivities.map((boughtActivity, index) => {
      boughtActivity["onMiniProgramClick"] = this.props.onMiniProgramClick;
      return (
        <SingleActivity
          study_package_activity={boughtActivity}
          onAlert={this.props.onAlert}
          key={index}
          render={this.toRender}
        />
      );
    });
  }
}

class UserInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      overlayDisplay: "none",
    };
  }

  // 点击居住地事件，唤醒城市列表选择框
  selectCityAreaClick = () => {
    this.props.displaySubPickerDialog();
  };

  render() {
    const { user, baby } = this.props;
    return (
      <div className="user_info_container">
        <div className="user_info_wrapper">
          <div className="user_avatar">
            <img
              data-src={user.photo_url || defaultUserAvatar}
              alt=""
              className="lazyload"
            />
          </div>
          {user.name ? (
            <div className="text_info">
              <p>{user.name}</p>
              <div className="baby_city_info">
                {baby && baby.nickname ? (
                  <div className="baby_info">
                    {<span>{baby.nickname}</span>}
                    {baby.grade && <span>{baby.grade}</span>}
                  </div>
                ) : (
                  <a className="add_baby_info" href={baby.new_baby_path}>
                    添加孩子信息&nbsp;&nbsp;
                  </a>
                )}
                <div
                  className={
                    this.props.area ? "city_info_area" : "add_city_info"
                  }
                >
                  {this.props.area === undefined ? (
                    this.props.city ? (
                      `${this.props.city ? this.props.city : ""}`
                    ) : (
                      <div
                        className="area_text"
                        onClick={this.selectCityAreaClick}
                      >
                        选择居住地
                      </div>
                    )
                  ) : this.props.area.city ? (
                    this.props.area.city.name
                  ) : (
                    this.props.area.province.name
                  )}
                </div>
              </div>
            </div>
          ) : (
            <a href={user.edit_user_path} className="to_edit_user_info">
              完善个人信息
            </a>
          )}
        </div>
        <a
          className="user_setting_box"
          href={user.setting_path}
          data-url={user.setting_path}
          onClick={this.props.onMiniProgramClick}
        >
          <div className="setting_box">
            <img src={icon_settings} alt="" />
            <div className="user_setting">设置</div>
          </div>
        </a>
        {/* <a 
          className="user_coupon"
          href={ecoupons.url}
          data-url={ecoupons.url} 
          onClick={this.props.onMiniProgramClick}
        >
          <div className="setting_box">
            {
              ecoupons.count > 0 &&
              <div className="ticket_count">
                <span>{ecoupons.count}</span>
              </div>
            }
            <img src={icon_ticket} alt=""/>
            <div className="user_setting">优惠券</div>
          </div>
        </a> */}
      </div>
    );
  }
}

export default class MpPoetry extends PureComponent {
  constructor(props) {
    super(props);
    console.log(props);
    function doResortActivities(props) {
      let boughtActivities = [];
      let unboughtActivities = [];
      let closedActivities = [];
      let unClosedActivities = [];
      let status = "";
      let initialId = 1;
      props.activities.forEach((activity, idx) => {
        let current_time = new Date(props.study_package_system_time);
        let start_time = new Date(activity.start_time);
        let end_time = new Date(activity.end_time);
        let groupon_end_time = new Date(activity.groupon_endtime);
        if (
          activity.bought_state === "bought" ||
          activity.bought_state === "pre_paid"
        ) {
          if (current_time < start_time && activity.bought_state === "bought") {
            status = "等待开课";
            unClosedActivities.push(activity);
          } else if (activity.bought_state == "pre_paid") {
            status = "等待拼团成功";
            unClosedActivities.push(activity);
          } else {
            if (current_time > end_time) {
              status = "已完课";
              closedActivities.push(activity);
            } else {
              status = "上课中";
              if (activity.buy_format == "bargain") {
                status = "去练习";
              }
              unClosedActivities.push(activity);
            }
          }
        } else {
          if (current_time > groupon_end_time) {
            status = "等待下期";
            unboughtActivities.push(activity);
          } else {
            status = "未购买";
            unboughtActivities.push(activity);
          }
        }
        activity["shortId"] = "activity" + (idx + 1);
        activity.comments
          ? activity.comments.forEach((comment) => {
              comment["shortId"] = initialId + "";
              initialId++;
            })
          : "";
        activity.buy_url = new URI(activity.buy_url).addQueryParam(
          "ref",
          "home"
        );
        activity["status"] = status;
        activity["study_package_system_time"] = props.study_package_system_time;
        activity["free"] = props.free;
        activity["user"] = props.user;
        // activity['onMiniProgramClick'] = (e) => activityTap(e)
      });
      boughtActivities = [...unClosedActivities, ...closedActivities];
      return { boughtActivities, unboughtActivities };
    }
    this.state = {
      ...doResortActivities(this.props),
    };
  }

  activityTap = (e) => {
    let mini_program_url = e.currentTarget.getAttribute("data-url");
    if (UserAgent.isWechat() || UserAgent.isMiniProgram()) {
      wx.miniProgram.getEnv((res) => {
        if (res.miniprogram) {
          if (mini_program_url.includes("bargain_activities?id=2")) {
            wx.miniProgram.navigateTo({
              url: "/pages/calculate_training/list",
            });
          } else {
            onMiniProgram(e, mini_program_url, {});
          }
        } else {
          window.location.href = mini_program_url;
        }
      });
    } else if (UserAgent.isWebBrowser()) {
      window.location.href = mini_program_url;
    }
    e.preventDefault();
  };

  onAlert = (isAlert, imageSrc) => {
    this.setState({
      isAlertQR: isAlert,
      QRCode: imageSrc,
    });
  };

  render() {
    const { boughtActivities } = this.state;
    return (
      <div className="comp-number-course">
        <UserInfo
          province={this.state.province}
          city={this.state.city}
          user={this.props.user}
          baby={this.props.baby}
          onMiniProgramClick={this.activityTap}
          area={this.props.area}
          // show={this.state.showCitySelect}
          // user_url={this.props.user_url}
          displayPickerDialog={this.displayPickerDialog}
          displaySubPickerDialog={this.displaySubPickerDialog}
          // ecoupons={ecoupons}
          key="userInfo"
        />
        {boughtActivities.length > 0 ? (
          <BoughtStudyPackageActivities
            BoughtActivities={boughtActivities}
            onAlert={this.onAlert}
            onMiniProgramClick={this.activityTap}
            key="boughtStudyPackageActivities"
          />
        ) : (
          <p className="without_poetry_courses">仅供课程用户上课使用</p>
        )}
      </div>
    );
  }
}

MpPoetry.propTypes = {
  activities: PropTypes.array.isRequired,
};
