/* eslint-disable react/prop-types */
import React from "react";
import CountDownTime from "../../utils/CountDown";
import styled from "styled-components";

const NewGroupDiscountCountdown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  font-size: 12px;
  color: #fff;
  padding: 10px 5px;
  .bargain_amount {
    padding-right: 5px;
  }
  .count_down_time {
    white-space: nowrap;
  }
`;

const DiscountCountDown = ({ ...props }) => {
  console.log("DiscountCountDown_props: ", props);
  const { text, bargainEndTime, warnText } = props;
  return (
    <NewGroupDiscountCountdown>
      <div className="bargain_amount">{text}</div>
      {bargainEndTime && (
        <CountDownTime
          endTime={new Date(bargainEndTime)}
          overText={`${warnText}时间结束`}
          onEnd={() => {}}
        >
          {({ d, h, m, s }) => {
            return (
              <span>
                距离{warnText}结束：{`${d == "00" ? "" : `${d}天`}`} {h}:{m}:{s}
              </span>
            );
          }}
        </CountDownTime>
      )}
    </NewGroupDiscountCountdown>
  );
};

export default DiscountCountDown;
