import styled from "styled-components";
import icon_finish from "think_customized/icon_finish.png";
import iocn_bottom_paint from "think_customized/iocn_bottom_paint.png";
import icon_healine from "think_customized/icon_healine.png";
import icon_fit_print from "think_customized/icon_fit_print.png";
import icon_suitable from "think_customized/icon_suitable.png";
import icon_arrow_right from "think_customized/icon_arrow_right.png";
import icon_tab_active from "think_customized/icon_tab_active.png";

import icon_clock from "think_customized/icon_clock.png";
import icon_cloud from "think_customized/icon_cloud.png";
import icon_doubt from "think_customized/icon_doubt.png";

import icon_outline from "think_customized/icon_outline.png";

import icon_top_mask from "think_customized/icon_top_mask.png";
import icon_center_mask from "think_customized/icon_center_mask.png";
import icon_right_mask from "think_customized/icon_right_mask.png";
import icon_bottom_shade from "think_customized/icon_bottom_shade.png";

import icon_course_clock from "think_customized/icon_course_clock.png";
import icon_shade_dialog from "think_customized/icon_shade_dialog.png";
import icon_security_certificate from "think_customized/icon_security_certificate.png";
import arrow_right_white from "arrow-right-white.png";

export const StyledCustomizationContainer = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .course_container .markDownWrapper .markdown_content {
    padding: 20px 0 100px;
  }
  .comp-study-package-activity {
    .comments_list {
      padding-top: 0;
    }
  }
  .sign_tab {
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(122, 36, 36, 0.1);
    margin-top: 5px;
    position: sticky;
    top: -0.5px;
    z-index: 2;
  }
  .switchTitle {
    display: flex;
    height: 60px;
    line-height: 60px;
    font-size: 14px;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    li {
      // flex: 1;
      position: relative;
      text-align: center;
      max-width: 100%;
      margin: 0 15px;
      font-size: 16px;
      font-weight: 700;

      // &:first-child {
      //   margin-left: 15px;
      // }
      // &:last-child {
      //   margin-right: 15px;
      // }
    }
    .li_customized {
      font-weight: normal;
      color: #666;
    }
    .active {
      border-bottom: 3px solid #49c114;
      color: #49c114;
    }
    .customized_active {
      font-size: 18px;
      font-weight: 700;
      color: #333;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 26px;
        height: 8px;
        background: url(${icon_tab_active}) no-repeat;
        background-size: 26px 8px;
        bottom: 8px;
        border-radius: 50px;
        left: 50%;
        margin-left: -13px;
      }
    }
  }
  .activity_parent_evaluate {
    padding-bottom: 70px;
  }
  .overview_comments_head {
    position: relative;
    padding: 12px 0 6px;
    background: #fff;
    h3 {
      position: relative;
      color: #ff717d;
      font-size: 24px;
      font-style: italic;
      font-weight: normal;
      text-align: center;
      z-index: 1;
      span {
        font-size: 18px;
      }
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 10px;
      display: block;
      width: 44%;
      left: 50%;
      margin-left: -22%;
      height: 14px;
      background: #fff8c0;
      border-radius: 12px;
      z-index: 0;
    }
  }
  .overview_comments_head_customized {
    h3 {
      font-style: normal;
      color: #333;
      font-weight: 700;
      span {
        font-weight: normal;
        padding-left: 5px;
      }
    }
    &::after {
      content: none;
    }
  }
  .overview_comments_foot {
    position: relative;
    padding: 6px 0 20px;
    background: #fff;
    text-align: center;
    span {
      color: #ff717d;
      font-size: 16px;
      font-weight: bold;
      text-decoration: underline;
      &.customized_more_span {
        color: #92b7f6;
      }
    }
  }
`;

export const StyledCustomizationDescribe = styled.div`
  background: #e9f2ff;
  position: relative;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .periods_time_top {
    img {
      display: block;
      width: 100%;
    }
  }
`;

export const StyledThinkingCustomizeBox = styled.div`
  margin: -20px 0 -30px;
  padding-bottom: 30px;
  top_paper1 {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 10px;
      top: -2px;
      // background: url(${icon_suitable}) no-repeat;
      // background-size: 100% 100%;
      width: 120px;
      height: 88px;
      z-index: 1;
    }
    img {
      width: calc(100% - 13px);
      height: 123px;
      margin-left: 3px;
    }
    .re_customized {
      color: #92b7f6;
      position: absolute;
      right: 30px;
      top: 15px;
      background: url(${icon_arrow_right}) no-repeat right center/16px;
      padding-right: 20px;
      text-decoration: underline;
    }
  }
  .top_paper {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 3%;
      top: -2px;
      background: url(${icon_suitable}) no-repeat;
      background-size: 100% 100%;
      width: 120px;
      height: 88px;
      z-index: 1;
    }
    img {
      width: calc(100% - 13px);
      height: 123px;
      margin-left: 3px;
    }
    .re_customized {
      color: #92b7f6;
      position: absolute;
      right: 30px;
      top: 15px;
      background: url(${icon_arrow_right}) no-repeat right center/16px;
      padding-right: 20px;
      text-decoration: underline;
    }
  }
  .top_paper1 {
    position: relative;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   left: 10px;
    //   top: -2px;
    //   background: url(${icon_suitable}) no-repeat;
    //   background-size: 100% 100%;
    //   width: 120px;
    //   height: 88px;
    //   z-index: 1;
    // }
    img {
      width: calc(100% - 13px);
      height: 123px;
      margin-left: 3px;
    }
    .re_customized {
      color: #92b7f6;
      position: absolute;
      right: 30px;
      top: 15px;
      background: url(${icon_arrow_right}) no-repeat right center/16px;
      padding-right: 20px;
      text-decoration: underline;
    }
  }
  .thinking_customize_content {
    background: #fff;
    padding-bottom: 10px;
    margin: -60px 15px 30px 4%;
    position: relative;
    padding: 0 15px 30px;
    box-shadow: 0 1px 2px rgba(122, 36, 36, 0.1);
    &::after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background: url(${iocn_bottom_paint}) no-repeat;
      background-size: 100% 100%;
      width: 253px;
      height: 17px;
    }
  }
`;
export const StyledCustomizationTitleDescribe = styled.div`
  text-align: center;
  .title_describe {
    display: inline-block;
    h3,
    h5 {
      position: relative;
      font-size: 20px;
      font-weight: 700;
    }
    .title_describe_test {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      line-height: 22px;
      color: #999999;
      opacity: 1;
      img {
        width: 6px;
        height: 7px;
        padding-bottom: 1px;
        margin-right: 6px;
      }
    }
    h3 {
      z-index: 1;
      /* &::before {
        content: "";
        position: absolute;
        left: -17px;
        top: -10px;
        width: 30px;
        height: 30px;
        background: radial-gradient(
          farthest-side ellipse at 40% 30%,
          #fff,
          #7baef8
        );
        border-radius: 50px;
        z-index: -1;
      }
      &::after {
        content: "";
        position: absolute;
        right: 0;
        bottom: -5px;
        width: 20px;
        height: 20px;
        background: radial-gradient(
          farthest-side ellipse at 40% 30%,
          #fff,
          #7baef8
        );
        border-radius: 50px;
        z-index: -1;
      } */
    }
    h5 {
      font-size: 15px;
      padding: 0 5px;
      font-weight: normal;
    }
  }
`;
export const StyledCustomizationSubDescribe = styled.div`
  position: relative;
  margin: 50px 0;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 2px rgba(123, 174, 248, 0.2);
  background-color: #fff;
  .fit_describe {
    border: 1px solid #d2e2fd;
    height: 35px;
    line-height: 33px;
    min-width: 150px;
    font-weight: 700;
    text-align: center;
    border-radius: 50px;
    margin: 0 auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -15px;
    background: #eff5fe;
    font-size: 18px;
  }
`;
export const StyledCustomizationCourseContent = styled.div`
  padding: 50px 0 30px;
  .course_outline {
    background-color: #eff5fe;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    margin: 30px 20px 0;
    border-radius: 8px;
    .outline_text {
      padding-left: 30px;
      padding-top: 1px;
      background: url(${icon_outline}) no-repeat left center/24px;
    }
    .button_look {
      font-size: 13px;
      background-color: #f2a94e;
      color: #eff5fe;
      width: 56px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      border-radius: 50px;
    }
  }
`;

export const StyledCourseContentColum = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  .left_content,
  .center_content,
  .right_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 58px;
      height: 58px;
    }
    .text {
      padding-top: 10px;
      font-size: 16px;
      span {
        color: #f3a63b;
        font-weight: 700;
      }
    }
  }
  .center_content {
    padding-left: 18px;
  }
`;

export const StyledCustomizationCourseContentPieChart = styled.div`
  padding: 45px 10px 20px;
  background: #fff;
  border-radius: 8px;
  .course_content_count {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 20px; */
    span {
      color: #f3a63b;
      position: relative;
      font-size: 16px;
      &::after {
        content: "";
        left: 2%;
        bottom: 2px;
        width: 100%;
        height: 2px;
        background: #f3a63b;
        position: absolute;
      }
    }
    .course_introduce_dialog {
      background: url(${icon_doubt}) no-repeat;
      background-size: 12px;
      background-position: right center;
      width: 12px;
      height: 12px;
      margin-left: 10px;
    }
  }
`;

export const StyledCustomizationContentConverage = styled.div`
  .radar_img {
    img {
      width: 100%;
      margin-top: 30px;
      padding: 20px 10px;
    }
  }
`;
export const StyledCustomizationCourseStartTime = styled.div`
  padding: 45px 0 25px;
  text-align: center;
  .course_start_time {
    display: inline-block;
    background: url(${icon_course_clock}) no-repeat left center / 18px;
    padding-left: 30px;
    font-size: 16px;
    span {
      color: #f3a63b;
      font-size: 17px;
      font-weight: 700;
    }
  }
`;

export const StyledCourseOutlineButton = styled.div`
  margin: 30px 0 0;
  padding-bottom: 20px;
  .left_outline_button {
    flex: 1;
    border: 1px solid #ff6f4d;
    border-radius: 50px;
    text-align: center;
    height: 45px;
    font-size: 16px;
    color: #ff6f4d;
    background: #fff;
    .price_symbol {
      i {
        font-size: 12px;
      }
    }
    .text {
      font-size: 12px;
      margin-top: -3px;
    }
  }
  .shortcut_button3,
  .shortcut_button2,
  .shortcut_button,
  .right_oneyuan_button {
    width: 100%;
    height: 60px;
    border-radius: 50px;
    display: inline-flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    background-color: #ff6f4d;
    color: #ffffff;
    .top {
      font-weight: bold;
      text-align: center;
      font-size: 16px;
      line-height: 20px;
      i {
        font-size: 12px;
      }
    }
    .bottom {
      text-align: center;
      font-size: 13px;
      padding-top: 2px;
      .strong_num {
        color: #ffee50;
      }
    }
  }
  .shortcut_button3,
  .shortcut_button2 {
    font-size: 16px;
    font-family: PingFang SC;
    // font-weight: bold;
    line-height: 22px;

    height: 50px;
    flex-direction: row;
    img {
      width: 18px;
      height: 18px;
    }
    position_img {
      position: absolute;
      right: 28px;
    }
  }

  .shortcut_button3 {
    background: #ffffff;
    border: 1px solid #ff6f4d;
    color: #ff6f4d;
    margin: -30px 0 20px;
  }
  .right_oneyuan_button {
    margin-top: 5px;
  }
`;

export const StyledCourseTeacherRecommend = styled.div`
  margin: 10px 0px 30px;
  border: 2px solid #cee2ff;
  border-radius: 16px;
  .top_cc_teacher_text {
    text-align: center;
    font-weight: 700;
    height: 46px;
    line-height: 46px;
    background-color: #cee2ff;
    border-radius: 12px 12px 0 0;
    font-size: 17px;
  }
  .course_teacher_recommend {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 15px;
    .cc_info {
      padding-bottom: 10px;
      .cc_name {
        font-weight: 700;
      }
      p {
        font-size: 13px;
        margin-top: -2px;
      }
    }
    img {
      width: 144px;
      border: 1px dashed #333;
    }
    .recomend_text {
      padding: 10px 0 15px;
      width: 154px;
      font-size: 12px;
      color: #666;
    }
  }
`;
export const StyledStudyIntroduceModal = styled.div`
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  overflow-y: scroll;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .study_introduce_modal {
    background: #ffffff;
    border-radius: 0 0 10px 10px;
    margin: 0 5%;
    width: 90%;
    padding: 10px 0 10px;
    margin-top: 35vh;
    .introduce_content {
      .introduce_circle {
        width: 100%;
        height: 65px;
        border-radius: 50% / 100% 100% 0 0;
        margin-top: -75px;
        position: relative;
        z-index: 2;
        background: #f2f7ff;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          background: url(${icon_clock}) no-repeat;
          background-size: 100% 100%;
          margin-top: -35px;
          width: 142px;
          height: 142px;
          left: 50%;
          margin-left: -71px;
          z-index: -1;
        }
        &::after {
          content: "";
          background: url(${icon_cloud}) no-repeat;
          background-size: 100% 100%;
          background-position: left bottom;
          width: 100%;
          height: 34px;
          position: absolute;
          left: 50%;
          margin-left: -50%;
          bottom: 0;
        }
      }
      .time_and_count,
      .text {
        text-align: center;
        padding: 0 10px;
        position: relative;
        background: #fff;
        z-index: 2;
      }
      .time_and_count {
        padding: 10px 0 5px;
        text-align: center;
        span {
          &::after {
            display: none;
          }
        }
      }
    }
    .week_learn_close {
      background: rgb(133, 176, 243);
      border-radius: 50px;
      padding: 0px 20px;
      height: 35px;
      line-height: 35px;
      color: rgb(255, 255, 255);
      margin: 25px auto 15px;
      text-align: center;
      width: 140px;
      font-size: 16px;
    }
  }
`;

// 定制报名详情页
export const StyledCustomizationThinkDetails = styled.div`
  padding-bottom: 90px;
  min-height: 100vh;
  background: #eef5ff;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .comp_sub_activity_tabs {
    .mark_down_brief {
      padding-bottom: 20px;
    }
    .thinking_customized_tabs .sub_tab {
      padding: 12px 20px;
      box-shadow: 0 1px 5px 0 rgba(206, 226, 255, 0.3);
    }
  }
  .periods_time_top {
    img {
      display: block;
      width: 100%;
    }
  }
`;

export const StyledThinkingDetailList = styled.div`
  .main_title {
    position: relative;
    font-weight: 700;
    font-size: 18px;
    padding-left: 15px;
    margin: 10px 0 15px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 5px;
      background: #85b0f3;
      border-radius: 50px;
    }
    span {
      font-weight: normal;
      font-size: 14px;
    }
  }
  .detail_lists_box {
    .detail_lists {
      background-color: #eef5ff;
      border-radius: 10px;
      margin: 20px 0;
      padding: 0 0 20px;
      .sub_title {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 0;
        span {
          width: 18px;
          height: 16px;
          line-height: 16px;
          background: #fda634;
          border-radius: 0 50px 50px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          color: #fff;
          font-weight: 700;
        }
        .package_name {
          padding-left: 5px;
          font-size: 18px;
          font-weight: 700;
        }
      }
      .single_list_box {
        border: 2px solid #7baef8;
        border-radius: 8px;
        margin: 0 10px;
        .knowledge_text {
          text-align: center;
          padding: 5px 0;
          font-weight: 700;
        }
        .list_box {
          display: flex;
          justify-content: space-between;
          padding-left: 15px;
          padding-right: 30px;
          border-top: 1px solid #7baef8;
          height: 30px;
          .left_list {
            display: flex;
            align-items: center;
            width: 75%;
            .left_list_name {
              padding-left: 7px;
              font-size: 14px;
            }
          }
        }
      }
      .detail_photo_url {
        margin-top: 15px;
        padding: 5px 10px 0;
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
`;

export const StyleedPieChart = styled.div`
  .e_chart {
    height: 320px;
    /* height: 200px;
    min-width: 200px;
    width: 200px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    canvas {
      width: 100% !important;
      height: 100% !important;
    } */
  }
`;

export const StyledLeftListIndex = styled.div`
  border-radius: 50%;
  min-width: 16px;
  height: 16px;
  /* background: #85b0f3; */
  ${({ frameInsetColor, fontBackground }) =>
    frameInsetColor
      ? {
          background: `linear-gradient(160deg, ${frameInsetColor}, ${fontBackground})`,
        }
      : {}}
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #fff;
  font-weight: 700;
`;

export const StyledRightList = styled.div`
  font-size: 14px;
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    ${({ frameInsetColor }) =>
      frameInsetColor
        ? {
            background: `${frameInsetColor}`,
          }
        : {}}
    left: -30px;
  }
`;
export const StyleDetailCourseContent = styled.div`
  background: #fff;
  padding: 30px 10px 10px;
  border-radius: 20px 20px 0 0;
  margin-top: -15px;
  position: relative;
  .CustomizationCourseContentPieChart {
    margin: 10px 0;
    box-shadow: 0px 0px 10px 2px rgba(123, 174, 248, 0.2);
    position: relative;
    padding-bottom: 0;
    &::before {
      content: "课程内容";
      position: absolute;
      top: -8px;
      text-align: center;
      background: url(${icon_healine}) no-repeat center / 180px;
      width: 180px;
      height: 34px;
      left: 50%;
      margin-left: -90px;
      font-size: 18px;
      color: #fff;
      line-height: 34px;
    }
  }
`;
export const StyledCustomizationCoursePrice = styled.div`
  padding: 30px 0 10px;
  .order_detail_text {
    border: 1px solid #7baef8;
    box-shadow: 0px 3px 0px #7baef8;
    // border: 2px solid #85aef2;
    margin: 10px;
    border-radius: 8px;
    background: #eff5fe;
    position: relative;
    z-index: 1;
    &::after {
      content: "";
      background-color: #85aef2;
      right: 0;
      bottom: -4px;
      position: absolute;
      width: 100%;
      height: 30px;
      border-radius: 8px;
      z-index: -1;
    }
    .course_package_introduce_box {
      .course_package_introduce {
        span {
        }
      }
    }
    .course_package_discount_box {
      margin: 6px 0;
      .course_package_discount {
        span {
        }
      }
    }
    .last_order_text {
      padding: 10px;
    }
    .course_package_introduce_box,
    .course_package_discount_box,
    .last_order_text {
      background: #fff;
      padding: 5px 10px;
    }
    .course_package_discount_container {
      .course_package_discount_box {
        &:last-child {
          margin-bottom: 0;
          border-radius: 0 0 8px 8px;
        }
      }
    }
    .flex_control {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 5px 0;
      span {
        font-size: 13px;
        &:nth-of-type(1) {
          text-align: left;
          flex: 3;
        }
        &:nth-of-type(2) {
          text-align: right;
          flex: 2;
        }
        &:nth-of-type(3) {
          text-align: right;
          flex: 2;
        }
      }
    }
    .double_eleven {
      margin-top: -10px;
      background: #eff5fe;
      span {
        font-weight: 700;
        font-size: 14px;
      }
    }
    .total_discount {
      text-align: right;
      font-size: 12px;
      padding-bottom: 0;
      span {
        font-weight: normal;
        color: #ff6f4d;
      }
    }
    .order_text_title {
      padding: 10px;
      border-radius: 8px 8px 0 0;
      .title_weight {
        font-size: 14px;
        font-weight: 700;
      }
    }
    .last_order_text {
      padding: 10px;
      span {
        font-size: 14px;
      }
    }
    .border_control {
      color: #f3a63b;
      border: 1px solid #f3a63b;
      border-radius: 50px;
      padding: 0 5px;
      margin-left: 5px;
    }
  }
`;
// export const TopImg =styled.div`
// width:100%;
// `
// 底部按钮
export const StyledThinkingBottomButton = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -1px 4px rgba(122, 36, 36, 0.1);
  z-index: 4;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .think_bottom_button_box {
    text-align: center;
    color: #fff;
    background: #fff;
    padding: 0 0 10px;
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    .btn_right {
      width: calc(100% - 15px);
      height: 60px;
      margin-top: 10px;
      border-radius: 50px;
      display: inline-flex;
      flex-direction: column;
      vertical-align: middle;
      align-items: center;
      justify-content: center;
      background-color: #ff6f4d;
      color: #ffffff;
      .top {
        font-weight: bold;
        text-align: center;
        font-size: 16px;
        line-height: 20px;
        i {
          font-size: 12px;
        }
      }
      .bottom {
        text-align: center;
        font-size: 13px;
        .strong_num {
          color: #ffee50;
        }
      }
    }
  }
  .bought_after_buy {
    display: flex;
    padding: 10px 15px;
    text-align: center;
    color: #fff;
    background: #fff;
    .btn_left,
    .btn_right {
      height: 60px;
      background-color: #ff6f4d;
      color: #ffffff;
      margin-bottom: 10px;
      flex: 1;
      line-height: 60px;
      font-size: 18px;
    }
    .btn_left {
      border-radius: 50px 0 0 50px;
      background-color: #fda634;
    }
    .btn_right {
      border-radius: 0 50px 50px 0;
    }
  }
`;
export const StyleOrderDetailConfirm = styled.div`
  background: rgba(0, 0, 0, 0.65);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  overflow-y: scroll;
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  .order_detail_confirm_box {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
    border-radius: 15px 15px 0 0;
    .close_order_dialog {
      position: absolute;
      top: -5px;
      right: 5px;
      font-size: 30px;
      font-weight: 300;
      width: 40px;
      text-align: center;
      cursor: pointer;
    }
    .top_order_text {
      text-align: center;
      padding: 10px 0;
      margin: 0 10px;
      border-bottom: 1px solid #e2e2e2;
      font-size: 16px;
      span {
        color: #f3a63b;
        font-weight: 700;
        padding-right: 5px;
      }
    }
    .detail_content_box {
      max-height: 600px;
      overflow-y: scroll;
    }
    .center_order_title {
      text-align: center;
      padding: 10px 10px 0;
      h3 {
        font-size: 16px;
        font-weight: 700;
      }
      p {
        font-size: 13px;
      }
    }
    .order_detail_text {
      padding: 10px;
      .course_package_introduce_box {
        .course_package_introduce {
          span {
          }
        }
      }
      .course_package_discount_box {
        margin: 6px 0;
        .course_package_discount {
          span {
          }
        }
      }
      .last_order_text {
        padding: 10px;
      }
      .course_package_introduce_box,
      .course_package_discount_box,
      .last_order_text {
        background: #fff;
        border-radius: 8px;
        padding: 5px 10px;
        box-shadow: 0 0 2px 0 rgba(127, 106, 64, 0.3);
      }
      .flex_control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 0;
        span {
          font-size: 13px;
          &:nth-of-type(1) {
            text-align: left;
            flex: 3;
          }
          &:nth-of-type(2) {
            text-align: right;
            flex: 2;
          }
          &:nth-of-type(3) {
            text-align: right;
            flex: 2;
          }
        }
      }
      .order_text_title {
        padding: 10px;
      }
      .last_order_text {
        padding: 10px;
        span {
          font-size: 14px;
        }
      }
      .border_control {
        color: #f3a63b;
        border: 1px solid #f3a63b;
        border-radius: 50px;
        padding: 0 5px;
        margin-left: 5px;
      }
    }
    .confirm_pay_button {
      text-align: center;
      width: calc(100% - 20px);
      border-radius: 50px;
      background: #f3a63b;
      height: 40px;
      line-height: 40px;
      color: #fff;
      margin: 15px 10px 10px;
    }

    .weight {
      font-weight: 700;
    }
    .high_light {
      color: #f3a63b;
    }
  }
`;

export const StyledCustomizeCourseContainer = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  min-height: 100vh;
  background: #cee2ff;
  position: relative;
  z-index: 1;
  overflow: hidden;
  .periods_time_top {
    img {
      display: block;
      width: 100%;
    }
  }
`;
export const StyledCustomizeCourseContent = styled.div`
  padding-bottom: 70px;
  position: relative;
  padding-top: 30px;
  /* z-index: 111111; */
  /* @media screen and (orientation: landscape) and (min-width: 1024px) {
    margin-top: -85px;
  } */
  &::after {
    content: "";
    position: absolute;
    background: url(${icon_bottom_shade}) no-repeat bottom center/100%;
    background-size: 100% 100%;
    background-position: bottom center;
    left: 0;
    right: 0;
    bottom: 0;
    height: 36px;
    width: 100%;
  }
`;
export const StyledSingleKnowledgeSection = styled.div`
  min-height: 215px;
  width: calc(100% - 20px);
  position: relative;
  background: linear-gradient(-150deg, transparent 1.5em, #fff 0);
  border-radius: 1em;
  margin: 50px 0 0 10px;
  &:first-child {
    margin-top: 0;
    margin-top: 120px;
  }
  &::before {
    content: "";
    position: absolute;
    z-index: -1;
    overflow: hidden;
    ${({ questionIndex, questionsLength }) =>
      questionIndex == 1
        ? {
            background: `url(${icon_top_mask}) no-repeat`,
            backgroundSize: "100%",
            width: "286px",
            height: "286px",
            left: "-143px",
            bottom: "-245px",
          }
        : questionIndex == 3
        ? {
            background: `url(${icon_center_mask}) no-repeat`,
            backgroundSize: "100%",
            width: "98px",
            height: "185px",
            left: "-38px",
            bottom: "-160px",
          }
        : questionIndex == questionsLength
        ? {
            background: `url(${icon_right_mask}) no-repeat`,
            backgroundSize: "100%",
            width: "134px",
            height: "134px",
            right: "-70px",
            bottom: "-30px",
          }
        : {}}
  }
  &::after {
    content: "";
    position: absolute;
    top: -16px;
    right: -5px;
    background: linear-gradient(
        to left bottom,
        transparent 50%,
        rgb(255 255 255 / 20%) 0,
        rgb(216, 231, 253)
      )
      100% 0 no-repeat;
    width: 3em;
    height: 3em;
    transform: translateY(0em) rotate(-15deg);
    transform-origin: bottom right;
    border-bottom-left-radius: inherit;
    box-shadow: -0.15em 0.15em 0.1em -0.1em rgba(0, 0, 0, 0.15);
    border-radius: 60% 100%;
  }
`;
export const StyledTopTitleAndDescribe = styled.div`
  text-align: center;
  padding: 20px 10px 0;
  .index_name {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: #ffd7a8;
      font-style: italic;
      font-size: 30px;
      line-height: 30px;
      font-weight: 700;
      padding-right: 10px;
    }
  }
  .desc {
    font-size: 14px;
  }
`;
export const StyledQuestionLocked = styled.div`
  border: 1px solid #fda634;
  background-color: #fff4e7;
  position: relative;
  text-align: center;
  width: calc(100% - 20%);
  border-radius: 6px;
  box-shadow: 0px 3px 2px #ffd7a8;
  margin-left: 10%;
  margin-top: ${({ desc }) => (desc ? "30px" : "45px")};
  p {
    padding: 10px 0;
    color: #fda634;
  }
`;

export const StyledKnowledgeModule = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 30px;
  overflow: auto;
  .single_knowledge_list {
    border-radius: 8px;
    text-align: center;
    font-size: 12px;
    box-shadow: 0px 0px 1px 0 rgba(0, 0, 0, 0.1);
    margin-right: 2px;
    &:last-child {
      margin-right: 0;
    }
    .top_rank {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5px 0;
      border-radius: 6px 6px 0 0;
      border-bottom: 2px solid #fff;
      p {
        font-size: 15px;
        line-height: 15px;
        font-weight: 700;
        white-space: pre-wrap;
      }
      span {
      }
    }
    .bottom_text {
      width: 64px;
      height: 74px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f7fb;
      border-radius: 0 0 8px 8px;
      white-space: pre-wrap;
    }
  }
`;

export const StyledStudyTargetBox = styled.div`
  display: flex;
  margin-top: 30px;
  justify-content: center;
  width: 100%;
  padding: 0 4%;
  text-align: center;
  font-size: 12px;
  .left_study_target {
    background: #cee2ff;
    border-radius: 6px 6px 0 6px;
    width: 60px;
    border-right: 2px solid #fff;
    .bottom_text {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .right_study_target {
    background: bisque;
    border-radius: 6px;
    flex: 1;
    .bottom_split_text {
      .fully_super_version {
        height: 50px;
        line-height: 50px;
        display: flex;
        background-color: #f5f7fb;
        &:first-child {
          border-bottom: 2px solid #fff;
        }
        &:last-child {
          border-radius: 0 0 6px 0;
        }
        .left {
          flex: 1;
          border-right: 2px solid #fff;
        }
        .right {
          flex: 1;
        }
      }
    }
  }
  .top_rank {
    height: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid #fff;
    border-radius: 6px 6px 0 0;
    p {
    }
    span {
    }
  }
  .top_right_rank {
    background-color: #ffe4dd;
    color: #ff6f4d;
    p {
      font-size: 15px;
      line-height: 15px;
      font-weight: 700;
      white-space: pre-wrap;
    }
  }
`;

export const StyledChooseCourseSuggest = styled.div`
  text-align: center;
  margin-top: 30px;
  padding: 0 4%;
  font-size: 12px;
  .top_suggest_title {
    height: 40px;
    background-color: #cee2ff;
    line-height: 40px;
    border-radius: 8px 8px 0 0;
    font-size: 15px;
    font-weight: 700;
  }
  .bottom_suggest_course {
    border: 1px solid #cee2ff;
    border-radius: 0 0 8px 8px;
    background-color: #eef5ff;
    position: relative;
    &::before {
      content: "";
      width: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 81px;
      background-color: #cee2ff;
    }
    .study_premise_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      border-bottom: 1px solid #cee2ff;
      .left_name {
        width: 81px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        font-size: 13px;
      }
      .right_text {
        flex: 1;
        font-size: 13px;
        padding: 0 10px;
        span {
          font-size: 12px;
          color: #666;
        }
      }
    }

    .study_suggest_content {
      display: flex;
      padding: 15px 0;
      .left_name {
        width: 81px;
        font-weight: 700;
        font-size: 13px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      /* .right_text {} */
      .right_text_box {
        flex: 1;
        .text_content {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
          padding: 0 10px 5px;
          &:last-child {
            padding-bottom: 0;
          }
          p {
            font-size: 13px;
            span {
              font-size: 12px;
              color: #666;
            }
          }
          .recommend_text {
            min-width: 55px;
            background-color: #11cca2;
            border-radius: 5px;
            color: #fff;
          }
        }
      }
    }
  }
`;

export const StyledButtonOptionAndKnowledgeModule = styled.div`
  ul {
    width: 100%;
    padding: 30px 2%;
  }
`;
export const StyledOptionLi = styled.li`
  position: relative;
  display: inline-block;
  width: 46%;
  text-align: center;
  border: 1px solid #cee2ff;
  border-radius: 5px;
  margin: 2%;
  background: #eef5ff;
  padding: 10px 0;
  vertical-align: top;
  box-shadow: 0px 3px 2px #cee2ff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ hintExit }) =>
    hintExit
      ? {
          minHeight: "65px",
        }
      : { minHeight: "50px" }}
  .option_list {
    width: 100%;
    span {
      display: block;
      &.first_span {
        font-size: 14px;
        white-space: pre-wrap;
      }
      &.last_span {
        color: #666666;
        font-size: 12px;
      }
    }
  }
  ${({ active }) =>
    active
      ? {
          color: "#fff",
          background: "#7BAEF8",
          boxShadow: "0px 3px 2px #5D90DB",
          border: "1px solid #7BAEF8",
        }
      : {}}
`;
export const StyledExclusiveCourse = styled.div`
  background-color: #fff;
  margin-top: -30px;
  padding: 50px 0 30px;
  text-align: center;
  .exclusive_course_title {
    margin: 20px 0;
    .course_title {
      display: inline-block;
      width: 170px;
      font-size: 20px;
      font-weight: 700;
      padding: 0 0 15px;
      position: relative;

      span.left_circle,
      span.right_circle {
        &::before,
        &::after {
          content: "";
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }
      span.left_circle {
        &::before {
          background-color: #7baef8;
          left: -4px;
          top: 13px;
          z-index: 1;
        }
        &::after {
          background-color: #fda634;
          left: 3px;
          top: 8px;
          z-index: 0;
        }
      }
      .title {
      }
      span.right_circle {
        &::before {
          background-color: #7baef8;
          right: 0;
          top: 12px;
          z-index: 1;
        }
        &::after {
          background-color: #fda634;
          right: -6px;
          top: 8px;
          z-index: 0;
        }
      }
    }
  }
  .has_exclusive_course_content {
    margin: 10px 10px 0;
    padding: 80px 0 20px;
    box-shadow: 0px 0px 10px 2px rgba(123, 174, 248, 0.2);
    border-radius: 8px;
    position: relative;
    .top_title {
      position: absolute;
      top: -12px;
      left: 50%;
      transform: translateX(-50%);
      img {
        width: 248px;
        height: 70px;
      }
      .title {
        position: absolute;
        color: #fff;
        width: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        h3 {
          font-size: 16px;
          font-weight: 700;
          margin-top: -10px;
        }
        p {
          font-size: 12px;
          line-height: 1.2em;
          padding: 0 10px;
          white-space: pre-wrap;
          width: 200px;
          margin: 0 auto;
        }
      }
    }
    .course_distribution {
      .distribution_title {
        display: inline-block;
        margin: 35px 0 0;
        z-index: 1;
        font-weight: 700;
        position: relative;
        font-size: 18px;
        &::after {
          content: "";
          position: absolute;
          left: -3px;
          bottom: 0;
          height: 8px;
          border-radius: 50px;
          width: calc(100% + 6px);
          background: #cee2ff;
          z-index: -1;
        }
      }
      .thinking_bottom_button {
        margin-left: -10px;
        @media screen and (min-width: 1024px) and (orientation: landscape) {
          margin-left: -10px;
        }
      }
    }
  }
  .no_exclusive_course_content {
    .thinking_bottom_button {
      margin-left: -10px;
      @media screen and (min-width: 1024px) and (orientation: landscape) {
        margin-left: -10px;
      }
    }
    .no_course_box {
      padding-bottom: 30px;
    }
    img {
      width: 311px;
      height: 194px;
    }
  }
`;
export const StyledRecommendContent = styled.div`
  font-size: 12px;
  text-align: center;
  padding-bottom: 15px;
  span {
    color: #7baef8;
  }
`;

export const StyledCourseDetails = styled.div`
  display: block;
  background-color: #fda634;
  border-radius: 50px;
  width: 90%;
  margin: 0 0 10px 5%;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  a {
    display: block;
    color: #fff;
  }
`;

export const StyledUserBoughtSuccessDialog = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
  }
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 990;
  padding: 0px;
  overflow-y: scroll;
  .user_bought_success_dialog {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 30px);
    background: rgb(255, 255, 255);
    border-radius: 20px 20px 16px 16px;
    padding-bottom: 20px;
    .top_success_text {
      color: #fff;
      background: #fff url(${icon_shade_dialog}) no-repeat left center;
      background-size: 100% 100%;
      text-align: center;
      height: 112px;
      width: 100%;
      border-radius: 20px 20px 0 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        font-size: 24px;
        font-weight: 700;
        margin-top: -10px;
        text-shadow: 0 0 4px #4e8de9;
      }
      p {
        font-size: 16px;
        text-shadow: 0 0 4px #4e8de9;
      }
    }
    .center_join_group_text {
      text-align: center;
      .join_text {
        font-size: 16px;
        font-weight: 700;
        padding: 15px 0 0 0;
      }
      .group_box {
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        margin: 0 10px;
        padding: 5px 15px;
        img {
          width: 56px;
          height: 56px;
        }
        .group_content {
          font-size: 16px;
          padding-left: 10px;
          .group_name {
            padding-bottom: 30px;
            .text {
              font-size: 16px;
              color: #333333;
              span {
                color: #fda634;
              }
            }
          }
          .group_start_time {
            font-size: 14px;
            padding-top: 2px;
          }
        }
      }
    }
    .bottom_wx_qrcode {
      text-align: center;
      img {
        width: 157px;
        height: 157px;
        margin: 22px 0 17px 0;
      }
    }
    .security_certificate {
      display: inline-block;
      font-size: 12px;
      color: #64c665;
      width: 100%;
      text-align: center;
      background: #f3fef6;
      .text {
        display: inline-block;
        background: #f3fef6 url(${icon_security_certificate}) no-repeat left
          center;
        background-size: 17px;
        padding: 5px 0 5px 25px;
      }
    }
  }
`;

export const StyledDoubleElevenActivity = styled.a`
  display: block;
  img {
    width: 100%;
  }
`;

export const StyledFriendPresentCoureContainer = styled.div`
  @media screen and (orientation: landscape) and (min-width: 1024px) {
    max-width: 500px;
    margin: 0 auto;
    height: 0;
  }
  .friend_present_contents {
    box-shadow: 0px 6px 10px -6px rgba(123, 174, 248, 0.2);
    border-radius: 15px;
    position: relative;
    top: 0;
    height: 0;
    .top_course_content {
      position: relative;
      text-align: center;
      border-bottom: 1px dashed #cee2ff;
      margin: 0 10px;
      .normal_course_content {
        text-align: center;
        position: relative;
        .invite_user {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          position: relative;
          margin-top: 20px;
          z-index: 1;
          .user {
            display: flex;
            align-items: center;
            color: #fff;
            img {
              width: 32px;
              height: 32px;
              border-radius: 50%;
              border: 2px solid #cee2ff;
              z-index: 1;
              position: relative;
            }
            p {
              padding: 0 5px 0 15px;
              border-radius: 0 50px 50px 0;
              white-space: nowrap;
              background-color: #e6f0ff;
              z-index: 0;
              margin-left: -10px;
              font-size: 14px;
              background: #4e8de9;
            }
          }
          span {
            font-size: 14px;
            padding-left: 5px;
            color: #fff;
          }
        }
      }
    }
  }
`;

export const StyledLimitDiscountsCountdown = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #ffc170 0%, #fd8164 100%);
  color: #fff;
  margin: 10px 10px 0;
  padding: 10px;
  border-radius: 6px;
  .left_discount_text {
    font-size: 13px;
    background: url(${arrow_right_white}) no-repeat right center/10px;
    padding-right: 12px;
  }
  .right_discount_countdown {
    display: flex;
    justify-content: center;
    white-space: nowrap;
    position: relative;
    .count_down_time {
      font-size: 13px;
      span.expired_warn_text {
        padding-right: 6px;
      }
      span.day,
      span.hour,
      span.minus,
      span.seconds,
      span.ms {
        display: inline-block;
        background: #dd2b00;
        border-radius: 2px;
        font-size: 13px;
        min-width: 22px;
        margin: 0 2px;
        padding: 0 3px;
        color: #fff;
        text-align: center;
      }
    }
  }
`;
