import React from "react";
import PropTypes from "prop-types";
import { showDialog, isEmpty, debounce } from "../utils/helpers";
import { formatTimeStampYMDHM } from "../utils/formatAudioTime.js";
import { request, csrfHeaders } from "../utils/request";
import { ToastManager } from "../utils/Toast";
import Raven from "raven-js";
import "./QuestionnaireActivity.scss";
import new_tips_title from "study_package_activity/new_tips_title.png";
import tip_404 from "study_package_activity/tip_404.png";
import tip_result_title from "study_package_activity/tip_result_title.png";
import { onNavigate } from "../utils/helpers";
import { TicketPriceCondition } from "../batch_ecoupons/CouponGift.jsx";
import {
  OptionGroup,
  CheckBoxItem,
  RadioItem,
  ButtonFactory,
} from "./Questionnaire";

export default class QuestionnaireActivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentQuestion: {},
      showResult: false,
      recommendations: [],
    };
    this.answers = [];
  }
  setAnswers(type) {
    const handleRadio = (selectedQuestionId, checkedOptionId, isCheck) => {
      if (this.answers.length === 0) {
        this.answers.push({
          question_id: selectedQuestionId,
          option_ids: [checkedOptionId],
        });
      } else {
        for (const [index, answer] of this.answers.entries()) {
          if (answer.question_id === selectedQuestionId) {
            this.answers.splice(index, 1);
            break;
          }
        }
        if (isCheck) {
          this.answers.push({
            question_id: selectedQuestionId,
            option_ids: [checkedOptionId],
          });
        }
      }
      console.log("handleRadio: ", this.answers);
    };

    const handleCheckbox = (selectedQuestionId, selectedOptionId, isCheck) => {
      let answeredQuestionIds = [];
      if (this.answers.length === 0) {
        this.answers.push({
          question_id: selectedQuestionId,
          option_ids: Array(1).fill(selectedOptionId),
        });
      } else {
        for (const [index, answer] of this.answers.entries()) {
          answeredQuestionIds.push(answer.question_id);
          if (answer.question_id === selectedQuestionId) {
            if (answer.option_ids.length === 0 || isCheck) {
              answer.option_ids.push(selectedOptionId);
            } else {
              answer.option_ids.forEach((optionId, idx) => {
                if (optionId === selectedOptionId) {
                  if (!isCheck) {
                    answer.option_ids.splice(idx, 1);
                  }
                }
              });
            }
            break;
          }
        }
        if (
          answeredQuestionIds.length &&
          answeredQuestionIds.indexOf(selectedQuestionId) === -1
        ) {
          this.answers.push({
            question_id: selectedQuestionId,
            option_ids: Array(1).fill(selectedOptionId),
          });
        }
        console.log("handleCheckbox: ", this.answers);
      }
    };

    if (type === "radio") {
      return handleRadio;
    } else if (type === "checkbox") {
      return handleCheckbox;
    }
  }

  handleInputChange = (e) => {
    const inputType = e.currentTarget.type;
    const questionId = parseInt(e.currentTarget.dataset.questionId);
    const checkedOptionId = parseInt(e.currentTarget.dataset.optionId);

    this.setAnswers.call(this, inputType)(
      questionId,
      checkedOptionId,
      e.currentTarget.checked
    );
  };

  getInputType(question) {
    const { mode, single_selection } = question;
    if (mode === "standard" && single_selection) {
      return "单选";
    }
    if (mode === "standard" && !single_selection) {
      return "多选";
    }
  }

  renderOptions(question) {
    const getGroupName = function (name) {
      const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min)) + min;
      };
      return `${name.split("").splice(0, 4).join("")}-${getRandomInt(1, 10)}`;
    };
    const { mode, single_selection } = question;
    if (mode === "standard") {
      return (
        <OptionGroup
          name={getGroupName(question.name)}
          handleInputChange={this.handleInputChange}
          questionId={question.id}
        >
          {question.options.map((optionItem, index) => {
            return single_selection ? (
              <RadioItem
                key={index}
                optionId={parseInt(optionItem.id)}
                hint={optionItem.hint}
              >
                {optionItem.option}
              </RadioItem>
            ) : (
              <CheckBoxItem
                key={index}
                optionId={parseInt(optionItem.id)}
                hint={optionItem.hint}
              >
                {optionItem.option}
              </CheckBoxItem>
            );
          })}
        </OptionGroup>
      );
    }
  }

  getQuestionIndex = () => {
    const { currentQuestion, questions } = this.state;
    let index = questions.indexOf(currentQuestion);
    return index === -1 ? 1 : index + 1;
  };

  checkAnswer = () => {
    // console.log(this.answers);
    const { currentQuestion, questions } = this.state;
    let index = questions.indexOf(currentQuestion);
    let currentQuestionId = { ...currentQuestion }.id;
    let answeredQuestionIds = [];
    let showAlert = false;
    let dialogOptions = {
      text: "答案不能为空哦",
      mainLabel: "好的",
      mainCallback: () => {},
    };
    if (this.answers.length === 0) {
      showAlert = true;
    }
    for (const [idx, answer] of this.answers.entries()) {
      answeredQuestionIds.push(answer.question_id);
      if (answer.question_id === currentQuestionId) {
        if (!answer.option_ids && !answer.text) {
          showAlert = true;
          break;
        }
        if (
          answer.text &&
          (!answer.text.split("-")[0] || !answer.text.split("-")[1])
        ) {
          showAlert = true;
          break;
        }
        if (answer.option_ids && answer.option_ids.length === 0) {
          showAlert = true;
          break;
        }
      }
    }

    if (
      answeredQuestionIds.length &&
      answeredQuestionIds.indexOf(currentQuestionId) === -1
    ) {
      showAlert = true;
    }
    if (showAlert) {
      showDialog.call(this, dialogOptions);
    }
    return showAlert ? !!!showAlert : index;
  };

  goNext = () => {
    let result = this.checkAnswer();
    if (!(typeof result === "boolean")) {
      this.setNextQuestion(result + 1);
    }
  };

  setNextQuestion = (id) => {
    this.setState({
      currentQuestion: this.state.questions[id],
    });
  };

  submitAnswer = () => {
    let result = this.checkAnswer();
    if (typeof result === "boolean") return;
    this.toast = ToastManager.showLoading("提交中...");
    request({
      url: this.state.questionResultUrl,
      method: "POST",
      headers: csrfHeaders,
      data: this.answers,
    })
      .then((resp) => {
        this.toast.cancel();
        const result = resp.data;
        console.log("submitAnswer_result: ", result);
        this.setState({
          recommendations: result.recommendation || {},
          showResult: true,
        });
      })
      .catch((err) => {
        this.toast.cancel();
        Raven.captureException(err);
      });
  };

  start = () => {
    this.setState({
      isStart: true,
    });
    this.setNextQuestion(0);
  };

  renderBody = (
    showResult,
    isStart,
    { currentQuestion, questions, questionIndex, questionType }
  ) => {
    if (showResult) {
      return <ResultPage recommendations={this.state.recommendations} />;
    }
    if (isStart) {
      return (
        <QuestionItem
          questionIndex={questionIndex}
          questionName={currentQuestion.name}
          render={this.renderOptions(currentQuestion)}
          questionType={questionType}
          goNext={this.goNext}
          questionLength={questions.length}
          submitAnswer={debounce(this.submitAnswer)}
        />
      );
    }
  };

  componentDidMount() {
    document.querySelector("html, body").classList.add("no_scroll");
    this.toast = ToastManager.showLoading("取题中...");
    request({
      url: this.props.get_questions_url,
      method: "GET",
      headers: csrfHeaders,
    })
      .then((resp) => {
        const result = resp.data;
        this.toast.cancel();
        this.setState(
          {
            questionResultUrl: result.question_result_url,
            questionClosedUrl: result.question_closed_url,
            questions: result.questions,
            currentQuestion: result.questions[0],
          },
          () => {
            this.start();
          }
        );
      })
      .catch((err) => {
        this.toast.cancel();
        Raven.captureException(err);
      });
  }
  componentWillUnmount() {
    document.querySelector("html, body").classList.remove("no_scroll");
  }

  handleCloseDialogRequest = () => {
    this.props.onModalClose();
    if (this.state.showResult) {
      console.log(typeof this.props.handleQuestionaireResultClose());
      this.props.handleQuestionaireResultClose(true);
    } else {
      this.props.handleQuestionaireResultClose(false);
    }
    request({
      url: this.state.questionClosedUrl,
      method: "POST",
      headers: csrfHeaders,
    })
      .then(() => {})
      .catch((err) => {
        Raven.captureException(err);
      });
  };

  render() {
    const {
      currentQuestion,
      isStart,
      showResult,
      recommendations,
      questions,
    } = this.state;
    if (!questions) return "";
    const questionIndex = this.getQuestionIndex();
    const questionType = this.getInputType(currentQuestion);
    const renderBody = this.renderBody(showResult, isStart, {
      currentQuestion,
      questions,
      questionIndex,
      questionType,
    });
    return (
      <div className="comp_questionnaire_activity">
        <div className="question_ground">
          <div
            className={`question_body_box ${
              isEmpty(recommendations) ? "" : "show_result"
            }`}
          >
            <div
              className="modal_close"
              onClick={this.handleCloseDialogRequest}
            ></div>
            <div
              className="question_render_body"
              style={isEmpty(recommendations) ? { position: "initial" } : {}}
            >
              {renderBody}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ResultPage = ({ recommendations }) => {
  const {
    recommend_courses,
    batch_ecoupons_amount,
    redirect_url,
    batch_ecoupons,
    qrcode_url,
  } = recommendations;
  const handleUrlClick = (e) => {
    const url = e.currentTarget.getAttribute("data-url");
    onNavigate(e, url);
  };
  if (!isEmpty(recommendations)) {
    return (
      <>
        <RecommendationsCourse
          recommendCourses={recommend_courses}
          handleUrlClick={handleUrlClick}
          onlyRecommendCourse={
            (batch_ecoupons && batch_ecoupons.length > 0) || qrcode_url
          }
          batchEcouponsAmount={batch_ecoupons_amount}
          redirectUrl={redirect_url}
        />
        {batch_ecoupons ? (
          <EcouponTicket ecoupons={batch_ecoupons} />
        ) : (
          <FollowWechat qrcodeUrl={qrcode_url} />
        )}
      </>
    );
  } else {
    return (
      <div className="question_content show_result result">
        <img src={tip_404} alt="" />
      </div>
    );
  }
};

const ButtonUnlockWeekCourse = ({ batchEcouponsAmount, url }) => {
  return (
    <a className="button_unlock_week_course" href={url}>
      <div className="top_week_text">
        <span>1</span>元学一周
      </div>
      <div className="bottom_coupon_text">
        还可得<span>￥{batchEcouponsAmount}</span>课程优惠券
      </div>
    </a>
  );
};

const FollowWechat = ({ qrcodeUrl }) => {
  if (!!!qrcodeUrl) {
    return "";
  }
  return (
    <div className="ecoupon_ticket_body follow_wechat_guide">
      <div className="congratulations_text">
        <div className="text">
          关注公众号领取<strong>260元</strong>优惠券
        </div>
        <p>
          <strong>24小时</strong>内有效，请立即关注领取
        </p>
      </div>
      <img src={qrcodeUrl} alt="" />
    </div>
  );
};

const EcouponTicket = ({ ecoupons }) => {
  if (ecoupons.length == 0) {
    return "";
  }
  return (
    <div className="ecoupon_ticket_body">
      <div className="congratulations_text">
        <div className="text">恭喜你获得优惠券</div>
        <p>
          当前课程报名可用，有效期<strong>24小时</strong>，请尽快使用
        </p>
      </div>
      {ecoupons.map((ecoupon, index) => {
        const {
          price,
          min_product_price,
          name,
          start_time,
          end_time,
          can_buies,
          get_ecoupon_url,
        } = ecoupon?.batch_ecoupon;
        return (
          <div className="ecoupon_ticket" key={index}>
            <div className="ticket_left">
              <TicketPriceCondition
                price={price}
                minProductPrice={min_product_price}
              />
            </div>
            <div className="ticket_right">
              <div className="name">{name}</div>
              {can_buies.length > 0 && (
                <div className="ticket_name">
                  可用于报名：
                  {can_buies.map((course, index) => (
                    <span key={index}>
                      {course}
                      {can_buies.length - 1 == index ? "" : "、"}
                    </span>
                  ))}
                </div>
              )}
              <div className="date">
                {formatTimeStampYMDHM(start_time, end_time)}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const RecommendationsCourse = ({
  recommendCourses,
  handleUrlClick,
  onlyRecommendCourse,
  batchEcouponsAmount,
  redirectUrl,
}) => {
  return (
    <div
      className={`recommend_question_body ${
        !!onlyRecommendCourse ? "recommend_icon_circle" : ""
      }`}
    >
      <p className="recommendation_text">
        <img src={tip_result_title} alt="" />
      </p>
      <div className="recommend_list">
        {recommendCourses.map((recommend, index) => {
          const isPoetry = recommend.sub_course_type === "poetry";
          const lessonsOrCourseCount = isPoetry
            ? recommend.media_courses_count || 0
            : recommend.media_lessons_count || 0;
          const isShowLabels = !!(
            (recommend.suitable_grades &&
              recommend.suitable_grades.length > 0) ||
            lessonsOrCourseCount ||
            recommend.course_day_count
          );
          // console.log(
          //   "isPoetry, lessonsOrCourseCount, isShowLabels: ",
          //   isPoetry,
          //   lessonsOrCourseCount,
          //   isShowLabels
          // );

          return (
            <div
              key={index}
              className="activity_brief_content"
              data-url={`${recommend.buy_url}`}
              onClick={handleUrlClick}
            >
              <div className="left_thumbnail_common">
                <img
                  className="lazyload lazyloading"
                  data-src={recommend.recommend_thumb_photo_url}
                />
              </div>
              <div className="right_content_box">
                <div className="content_box">
                  <div className="course_title">{recommend.name}</div>
                  <div className="recomment_brief">
                    {recommend.recommend_brief}
                  </div>
                </div>
                {isShowLabels && (
                  <div className="labels">
                    {!!(lessonsOrCourseCount || recommend.course_day_count) && (
                      <div className="label_left">
                        {!!lessonsOrCourseCount && (
                          <>
                            <span className="lessons">
                              {`${lessonsOrCourseCount}${
                                recommend.isPoetry ? "首诗" : "节课"
                              }`}
                            </span>
                          </>
                        )}

                        {/* {!!(
                          lessonsOrCourseCount && recommend.course_day_count
                        ) && <span className="split">·</span>}

                        {!!recommend.course_day_count && (
                          <span className="days">{`${recommend.course_day_count}天`}</span>
                        )} */}
                      </div>
                    )}
                    {recommend.suitable_grades &&
                      recommend.suitable_grades.length > 0 && (
                        <div className="label_right">
                          <span className="suit">适合</span>
                          <span className="grades">
                            {recommend.suitable_grades.join(" ")}
                          </span>
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <ButtonUnlockWeekCourse
        batchEcouponsAmount={batchEcouponsAmount}
        url={redirectUrl}
      />
    </div>
  );
};

const QuestionItem = ({
  questionIndex,
  questionName,
  render,
  questionType,
  goNext,
  questionLength,
  submitAnswer,
}) => {
  const ConfirmButton = ButtonFactory({ action: goNext, text: "下一题" });
  const SubmitButton = ButtonFactory({ action: submitAnswer, text: "提交" });
  return (
    <>
      <div className="title_header">
        <img src={new_tips_title} alt="" />
      </div>
      <div className="question_content_box">
        <div className="question_content">
          <p className="question_index">
            {`${questionIndex}/${questionLength}`}.
          </p>
          <p className="question_name">
            <span>{questionName}</span>
          </p>
        </div>
        {render}
      </div>
      {questionIndex === questionLength ? <SubmitButton /> : <ConfirmButton />}
    </>
  );
};
