// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../../../../data/node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/images/new_experience/icon_close.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".study_package_activities-show .modal_mask.version-intro-dialog-mask{z-index:100000!important}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog{position:absolute;bottom:130px;width:90%;left:50%;margin-left:-45%;background:#fff;border-radius:8px}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog:after{content:\"\";width:0;height:0;position:absolute;border:10px solid transparent;border-top:8px solid #fff;right:30px}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .modal_close{position:absolute;right:-15px;width:36px;height:36px;text-align:center;top:-18px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 0/36px;border-radius:50px}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .modal_content{padding:15px}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .version .title{color:#fb636f;font-weight:700}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .version .notes .note{display:-webkit-flex;display:flex}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .version .notes .note span:last-child{-webkit-flex:1;flex:1}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .version .notes .note .icon-dot{width:15px;padding-top:10px;padding-left:5px}.study_package_activities-show .version-intro-dialog-container .version-intro-dialog .version .notes .note .icon-dot:before{content:\"\";width:3px;height:3px;display:block;background:#000;border-radius:3px}", ""]);
// Exports
module.exports = exports;
