/* eslint-disable */
import React from "react";
import { ToastManager } from "../utils/Toast";
import { request, csrfHeaders } from "../utils/request";
import { Dialog, DialogManager } from "../utils/Dialog";
import { formatDotDate } from "../utils/formatAudioTime";
import { GA } from "../utils/Env";
import StudyPackageActivity from "./StudyPackageActivity";
import { checkDecimal, debounce } from "../utils/helpers";
import { TicketPriceCondition } from "../batch_ecoupons/CouponGift.jsx";
import icon_select from "coupon/icon_select.png";
import icon_selected from "coupon/icon_selected.png";
import coupon_empty from "coupon/coupon_empty.png";

import "./CourseCoupons.scss";
import { number } from "prop-types";

const COURSE_PAY_MODE = [
  // 通用 不显示
  { inner_enum: [], name: "" },
  { inner_enum: ["join_groupon", "group"], name: "仅限团购模式" },
  { inner_enum: ["direct"], name: "仅限直接购买模式" },
];

const getCouponUseState = (
  coupon,
  systemTime,
  coursePayMode,
  coursePayFinalPrice
) => {
  let tipText = "";
  // 'remaining'
  if (
    coursePayMode &&
    coupon.use_mode != null &&
    !COURSE_PAY_MODE[coupon.use_mode]?.inner_enum.includes(coursePayMode)
  ) {
    tipText = COURSE_PAY_MODE[coupon.use_mode]?.name;
    tipText = tipText ? `（${tipText}）` : "";
  }

  if (new Date(coupon.start_time) > new Date(systemTime)) {
    tipText = "（使用时间未到）";
  }
  return {
    isCanUse: !(
      coursePayFinalPrice < coupon.min_product_price ||
      tipText ||
      coupon.valid === false
    ),
    tipText,
  };
};

export const sortECouponsOnlyForCouponUseMode = (
  coupons,
  systemTime,
  coursePayMode,
  coursePayFinalPrice
) => {
  if (coupons && coupons.length) {
    const copiedCoupons = [...coupons];
    let unValidIndexes = [];
    copiedCoupons.forEach((coupon, index) => {
      if (
        !getCouponUseState(
          coupon,
          systemTime,
          coursePayMode,
          coursePayFinalPrice
        ).isCanUse
      ) {
        coupon._valid_ = false;
        unValidIndexes.push(index);
      } else {
        coupon._valid_ = true;
      }
    });
    if (unValidIndexes.length) {
      unValidIndexes = unValidIndexes.map((idx) => {
        return [...coupons].splice(idx, 1)[0];
      });

      const validCoupons = copiedCoupons.filter((c) => c._valid_);
      return {
        coupons: [...validCoupons, ...unValidIndexes],
        validCoupons,
      };
    }
  }
  return { coupons, validCoupons: coupons };
};

const CouponCanNotUseTip = ({ tipText }) => {
  return tipText ? (
    <div className="consume_status">
      <div className="todo_consume">{tipText}</div>
    </div>
  ) : (
    ""
  );
};

export default class CourseCoupons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.resetStatesRelativeToDefaultSelectedTicket(
        this.props.eCouponData
      ),
      selectVirtualAccount: this.props.virtualAccount ? true : false,
      coursePayFinalPrice: this.props.coursePayFinalPrice
        ? Number(this.props.coursePayFinalPrice)
        : 0,
      isCouseCouponDisplay: this.props.isCouseCouponDisplay
        ? this.props.isCouseCouponDisplay
        : "none",
      hasCouponDisplay: "none",
      UnCouponDisplay: "none",
      refundButtonConfirmStatus: false,
    };
    console.log(this.state, "CourseCoupons_props", props);
  }

  // 由于整个组件使用了display来控制显示隐藏，所以这里需要动态的重置在constructor的状态
  resetStatesRelativeToDefaultSelectedTicket = (eCouponData) => {
    let defa_ticket;
    if (eCouponData) {
      if (this.props.ecouponsAvailable == true) {
        defa_ticket = eCouponData.find((ticket, index) => {
          ticket["id"] = ticket.id;
          ticket["index"] = index;
          return ticket;
        });
        console.log(
          "默认选中的优惠券: ",
          defa_ticket,
          "金额: ",
          defa_ticket && defa_ticket.price,
          "券的id: ",
          defa_ticket && defa_ticket.id
        );
        console.log("start-time: ", defa_ticket && defa_ticket.start_time);
      }
    }
    return {
      selectIndex:
        this.props.ecouponsAvailable == true
          ? defa_ticket && defa_ticket.index
          : -1, // 默认选中

      defaultSelectCouponId:
        this.props.ecouponsAvailable == true && defa_ticket
          ? defa_ticket.id
          : -1,
      defaultSelectPrice:
        this.props.ecouponsAvailable == true
          ? defa_ticket
            ? defa_ticket.price
            : 0
          : 0,
    };
  };

  // 以下几个事件分别对应不同的状态触发不同响应显示
  btnCloseCourseCouponClick = () => {
    if (this.props.onClose && typeof this.props.onClose === "function") {
      this.props.onClose();
    } else {
      this.setState({
        isCouseCouponDisplay: "none",
        refundButtonConfirmStatus: false,
      });
    }
  };

  // 唤醒选择优惠券弹框
  onSelectCouponClick = () => {
    this.setState({
      orderGuideDisplay: "none", // 隐藏当前弹窗
    });

    if (this.props.eCouponData && this.props.eCouponData.length > 0) {
      this.setState({
        hasCouponDisplay: "block", // 有优惠券 可选
      });
    } else {
      this.setState({
        UnCouponDisplay: "block", //无优惠券 可选
      });
    }
  };

  // 有优惠券回退关闭按钮
  btnCloseTicketSelectClick = () => {
    this.setState({
      hasCouponDisplay: "none",
      // isCouseCouponDisplay: 'block',
      orderGuideDisplay: "block",
    });
  };

  // 无优惠券回退关闭按钮
  btnCloseUnTicketSelectClick = () => {
    this.setState({
      UnCouponDisplay: "none",
      // isCouseCouponDisplay: 'block',
      orderGuideDisplay: "block",
    });
  };

  // 此事件是点击后获取优惠券的值
  selectCorrespondTicketClick = (e) => {
    const id = e.currentTarget.getAttribute("data-id");
    const index = e.currentTarget.getAttribute("data-index");
    const coupon_value = e.currentTarget.getAttribute("data-value");
    this.setState(
      {
        selectCouponId: id,
        selectIndex: index,
        selectCouponValue: coupon_value,
        hasCouponDisplay: "none",
        orderGuideDisplay: "block",
      },
      () => {
        console.log(
          "获取当前券的价格: ",
          this.state.selectCouponValue,
          "获取当前券的ID: ",
          this.state.selectCouponId
        );
      }
    );
  };

  findRemaingPayIndex = () => {
    const targetIndex = this.props.sub_study_package_activities.findIndex(
      (item) => item.id == this.props.id
    );
    return targetIndex;
  };

  // 计算折价对应的原课程折价金额百分比
  calcRefundableDiscount = (ajax_media_lessons_count) => {
    const { media_lessons_count } = this.findCurrentActivity();
    const refundDiscountPercent = (
      (ajax_media_lessons_count / media_lessons_count) *
      100
    ).toFixed(2);
    console.log("refundDiscountPercent", refundDiscountPercent);
    return refundDiscountPercent >= 20;
  };

  refundConfirmDialogWarn = () => {
    const { get_owned_media_lessons_url } = this.findCurrentActivity();
    this.toast = ToastManager.showLoading("加载中...");
    request({
      url: get_owned_media_lessons_url,
      method: "POST",
      headers: csrfHeaders,
    })
      .then((resp) => {
        this.toast.cancel();
        let data = resp.data;
        console.log("data: ", data);

        let refundable = false,
          media_lessons_count = 0,
          study_package_name = "";

        if (data.owned_study_packages && data.owned_study_packages.length > 0) {
          const respOwnedMediaLessons =
            data.owned_study_packages[0]?.owned_media_lessons[0];
          refundable = respOwnedMediaLessons?.refundable;
          media_lessons_count = respOwnedMediaLessons?.media_lessons_count;
          study_package_name = respOwnedMediaLessons?.study_package_name;
        }

        const openDialogStatus =
          this.calcRefundableDiscount(media_lessons_count) && refundable;
        console.log(
          "折扣百分比大于20%且可退_openDialogStatus: ",
          openDialogStatus
        );

        if (openDialogStatus) {
          let confirmDialog;
          const buttons = [
            {
              label: "确定",
              onClick: () => {
                confirmDialog.close();
                this.setState(
                  {
                    refundButtonConfirmStatus: true,
                  },
                  () => {
                    $(".button_go_buy").trigger("click");
                  }
                );
              },
            },
          ];
          confirmDialog = DialogManager.open(
            <Dialog buttons={buttons}>
              <div style={{ textAlign: "left" }}>
                {`使用抵扣价购买后，原课程《${study_package_name}》如需退课，本课程也将被同时退课。`}
              </div>
            </Dialog>
          );
        } else {
          this.payMoneyOperation();
        }
      })
      .catch((err) => {
        this.toast.cancel();
        console.error(err);
      });
  };

  findCurrentActivity = () => {
    const {
      subStudyPackageActivities,
      shopping_group,
      current_user,
    } = this.props;
    const { selectedActivityIndex } = this.state;
    let targetIndex;
    if (
      shopping_group &&
      current_user &&
      "launcher" in shopping_group &&
      "id" in current_user
    ) {
      targetIndex = this.findRemaingPayIndex();
    }
    let currentActivity =
      subStudyPackageActivities[selectedActivityIndex ?? targetIndex];
    console.log("currentActivity: ", currentActivity);
    return currentActivity;
  };

  judgeSubStudyPackageActivitiesExist = () => {
    const { subStudyPackageActivities } = this.props;
    return subStudyPackageActivities && subStudyPackageActivities.length > 0;
  };

  payMoneyClick = (e) => {
    const { subStudyPackageActivities, studyPackageActivityId } = this.props;
    const { selectActivityId, refundButtonConfirmStatus } = this.state;
    const study_package_activity_id = subStudyPackageActivities
      ? selectActivityId
      : studyPackageActivityId;
    GA("Courses", "Pay", study_package_activity_id);

    let owned_media_lesson_count = 0;
    let get_owned_media_lessons_url;
    if (!!this.judgeSubStudyPackageActivitiesExist()) {
      owned_media_lesson_count = this.findCurrentActivity()
        ?.owned_media_lesson_count;
      get_owned_media_lessons_url = this.findCurrentActivity()
        ?.get_owned_media_lessons_url;
    }
    if (
      !refundButtonConfirmStatus &&
      owned_media_lesson_count > 0 &&
      get_owned_media_lessons_url &&
      !this.state.trial
    ) {
      // 退款弹框按钮“确定”未触发 && 当前课程没有折扣 && 无相关折扣退款的接口请求
      // 退课弹框
      this.refundConfirmDialogWarn();
    } else {
      this.payMoneyOperation();
    }
  };

  payMoneyOperation = () => {
    const {
      subStudyPackageActivities,
      studyPackageActivityId,
      eCouponData,
    } = this.props;
    const study_package_activity_id = subStudyPackageActivities
      ? this.state.selectActivityId
      : studyPackageActivityId;

    let activity_type, sale_mode, singleAloneEle;
    if (!!this.judgeSubStudyPackageActivitiesExist()) {
      activity_type = this.findCurrentActivity()?.activity_type;
      sale_mode = this.findCurrentActivity()?.sale_mode;
      singleAloneEle =
        this.findCurrentActivity() &&
        (activity_type === StudyPackageActivity?.activityType?.group ||
          sale_mode == "normal");
    }
    const couponPriceValue = this.state.selectCouponValue
      ? this.state.selectCouponValue
      : this.state.defaultSelectPrice;

    let finalPrice = this.finalPrice(
      eCouponData,
      couponPriceValue,
      this.state.coursePayFinalPrice
    );
    // 优惠券id
    let ecouponId = this.state.selectCouponId
      ? this.state.selectCouponId
      : this.state.defaultSelectCouponId;

    let virtualAccountBalance = 0.0;
    if (this.props.virtualAccount && this.state.selectVirtualAccount) {
      virtualAccountBalance =
        finalPrice > 0
          ? this.props.virtualAccount?.money
          : this.state.coursePayFinalPrice - couponPriceValue;
      if (virtualAccountBalance < 0) {
        virtualAccountBalance = 0;
      }
    }
    virtualAccountBalance = this.roundNumber(virtualAccountBalance);

    if (finalPrice == 0.0) {
      let object = {
        source: "free",
        ecoupon_id: ecouponId,
        group_state: this.state.coursePayFinalState,
        target_id: this.props.subStudyPackageActivities
          ? this.state.selectActivityId
          : this.props.studyPackageActivityId,
        virtual_account_money: virtualAccountBalance,
        shopping_group_id:
          this.props.coursePayMode !== "direct" && this.props.firstGroup
            ? this.props.firstGroup.id
            : null,
        shopping_group:
          this.props.coursePayMode !== "direct" && this.props.firstGroup
            ? this.props.firstGroup
            : null,
      };
      console.log("object-----------", object);
      this.withoutCharge(object);
      return;
    } else {
      // 底部 “支付” 需要调用和传递的参数
      // 统一支付
      console.log("支付");
      const { payClickAction } = this.props;

      if (payClickAction && typeof payClickAction === "function") {
        // 是“拼团砍价”模式以及不是连报走“拼团砍价”购买链接
        const isShoppingBargain =
          this.props.sale_mode == "shopping_group_bargain" &&
          this.props.bargain &&
          !singleAloneEle;
        let activity_buy_url = isShoppingBargain
          ? this.props.bargain?.study_package_activity_buy_url
          : this.props.study_package_activity_buy_url;
        let url =
          this.props.coursePayMode === "group"
            ? this.props.study_package_activity_group_buy_url
            : activity_buy_url;
        console.log("最终实际支付的参数: ", {
          finalPrice: finalPrice,
          coursePayFinalState: this.state.coursePayFinalState,
          study_package_activity_id: study_package_activity_id,
          ecouponId: ecouponId,
          virtualAccountBalance: virtualAccountBalance,
          url: url,
        });
        payClickAction(
          finalPrice,
          this.state.coursePayFinalState
            ? this.state.coursePayFinalState
            : "direct",
          study_package_activity_id,
          ecouponId,
          virtualAccountBalance,
          url,
          this.state.trial,
          // this.props.coursePayMode,
          // this.props.firstGroup,
          this.props
        );
      }
      console.log("最终实际支付的finalPrice:", finalPrice);
      console.log("实际用的抵用金:", virtualAccountBalance);
    }
  };

  withoutCharge = (params) => {
    this.toast = ToastManager.showLoading("正在免费领取中 请耐心等待");
    const { sale_mode, activity } = this.props;

    let url =
      this.props.coursePayMode == "group" ||
      this.props.coursePayMode == "pay_remaining"
        ? this.props.groupFreeUrl
        : this.props.freeUrl;

    if (params["target_id"]) {
      if (sale_mode === "buy_bargain") {
        params["id"] = `${activity.id}`;
      } else {
        params["id"] = params["target_id"];
      }
    }

    if (this.state.trial) {
      params["trial"] = true;
    }
    console.log(url, "after_free_params: ", params);
    // return;
    /**
     *  group_state: params['group_state'],
        ecoupon_id: params['ecouponId'],
        target_id: params['target_id'],
        id: params['target_id'],
        source: params['source'],
        virtual_account_money: params['virtualAccountBalance']
     *  */
    request({
      url: url,
      method: "POST",
      headers: csrfHeaders,
      data: { ...params },
    })
      .then((resp) => {
        this.toast.cancel();
        console.log(resp.data);

        if (resp.data.err_code) {
          window.location.href = resp.data.redirect_url;
        } else {
          ToastManager.showToastText("领取成功", 2000);
          if (this.props.hasTeachingAidPackage) {
            // 如果教具包存在 就跳到地址填写页
            window.location.href = this.props.giftCommoditiesUrl;
            return;
          }

          if (resp.data.redirect_url) {
            window.location.href = resp.data.redirect_url;
            return;
          }

          if (this.props.noteUrl) {
            window.location.href = this.props.noteUrl;
            return;
          }

          window.location.reload(true);
        }
      })
      .catch((err) => {
        this.toast.cancel();
        console.log(err);
      });
  };

  finalPrice = (ecouponsAvailable, discountValue, coursePayFinalPrice) => {
    var virtualAccountMoney = this.props.virtualAccount
      ? this.props.virtualAccount.money
      : 0.0;
    if (!this.state.selectVirtualAccount) {
      virtualAccountMoney = 0.0;
    }

    const couponPriceValue = ecouponsAvailable ? discountValue : 0.0;
    const result =
      coursePayFinalPrice - virtualAccountMoney - couponPriceValue <= 0
        ? 0
        : coursePayFinalPrice - virtualAccountMoney - couponPriceValue;
    console.log(Number(result).toFixed(2), "result: ", result);
    if (checkDecimal(result)) {
      // console.log(Number(result).toFixed(2), "result: ", result);
      return Number(result).toFixed(2);
    } else {
      // console.log(Number(result).toFixed(2), "result: ", result);
      return Number(result).toFixed(2);
    }

    // this.props.eCouponData
    //                 ?
    //                 (
    //                   (this.state.coursePayFinalPrice - discountValue) > 0
    //                     ?
    //                     (this.state.coursePayFinalPrice - discountValue)
    //                     :
    //                     0
    //                 )
    //                 :
    //                 this.state.coursePayFinalPrice
  };

  showPrice = (
    ecouponsAvailable,
    discountValue,
    coursePayFinalPrice,
    virtualAccount
  ) => {
    // if (!ecouponsAvailable && !virtualAccount) {
    //   return (
    //       <div className="buy_price"></div>
    //   )
    // }
    let currentActivity = this.findCurrentActivity();
    let virtualOfAccount = virtualAccount;
    if (!virtualOfAccount) {
      virtualOfAccount = this.props.virtualAccount;
    }
    let discountInfo = 0;
    // 组团
    if (this.state.coursePayFinalState !== "pay_remaining") {
      discountInfo = currentActivity.max_price - coursePayFinalPrice;
    }

    // 课时优惠
    // discountInfo +=
    //   this.state.coursePayFinalState === "direct"
    //     ? currentActivity.subtractOwnedMediaDirect
    //     : currentActivity.subtractOwnedMediaGroup;
    let discountPrice = this.discountPrice(
      ecouponsAvailable,
      discountValue,
      coursePayFinalPrice
    );
    if (discountPrice > 0) {
      discountInfo += discountPrice;
    }
    let useMoney = this.useVirtualMoney(
      ecouponsAvailable,
      discountValue,
      coursePayFinalPrice,
      virtualOfAccount
    );
    if (useMoney > 0) {
      // if (discountInfo) {
      //   discountInfo += `，奖学金¥${useMoney}`;
      // } else {
      discountInfo += useMoney;
      // }
    }
    if (discountInfo && discountInfo !== "") {
      discountInfo = "共优惠：-￥" + Number(discountInfo).toFixed(2);
    }

    return (
      <div className="buy_price">
        <i>实付：</i>¥{" "}
        {Number(
          this.finalPrice(ecouponsAvailable, discountValue, coursePayFinalPrice)
        ).toFixed(2)}
        {Boolean(discountInfo) !== false && (
          <div className="detail_discount_info">{discountInfo}</div>
        )}
      </div>
    );
  };

  discountPrice = (ecouponsAvailable, discountValue) => {
    let discountPrice = 0.0;
    if (ecouponsAvailable) {
      discountPrice = discountValue;
    }
    return this.roundNumber(discountPrice);
  };

  virtualMoney = (virtualAccount) => {
    let virtualMoney =
      virtualAccount && virtualAccount.money ? virtualAccount.money : 0.0;
    if (!this.state.selectVirtualAccount) {
      virtualMoney = 0.0;
    }
    return this.roundNumber(virtualMoney);
  };

  useVirtualMoney = (
    ecouponsAvailable,
    discountValue,
    coursePayFinalPrice,
    virtualAccount
  ) => {
    let virtualMoney = this.virtualMoney(virtualAccount);
    let discountMoney = this.discountPrice(
      ecouponsAvailable,
      discountValue,
      coursePayFinalPrice
    );
    let remainingMoney = coursePayFinalPrice - discountMoney;
    // console.log('remainingMoney && virtualMoney：' + remainingMoney, virtualMoney)
    if (remainingMoney >= virtualMoney) {
      // console.log('奖学金virtualMoney：' + virtualMoney)
      return this.roundNumber(virtualMoney);
    } else {
      // console.log('奖学金remainingMoney：' + remainingMoney)
      return this.roundNumber(remainingMoney);
    }
  };

  roundNumber = (money) => {
    let moneyOfNumber = money;
    if (typeof money === "string") {
      moneyOfNumber = Number(money);
    }
    if (moneyOfNumber == null || moneyOfNumber < 0) {
      return 0.0;
    }
    if (checkDecimal(moneyOfNumber)) {
      // console.log("money: "+ moneyOfNumber.constructor.name)
      return Number(moneyOfNumber).toFixed(2);
    } else {
      return moneyOfNumber;
    }
  };

  chooseVirtualAccount = (e) => {
    this.setState({
      selectVirtualAccount: !this.state.selectVirtualAccount,
    });
  };

  componentDidUpdate(preProps, preStates) {
    if (
      this.state.isCouseCouponDisplay !== preStates.isCouseCouponDisplay ||
      this.state.defaultSelectPrice !== preStates.defaultSelectPrice
    ) {
      const ecoupons = sortECouponsOnlyForCouponUseMode(
        this.props.eCouponData,
        this.props.systemTime,
        this.props.coursePayMode,
        this.state.coursePayFinalPrice
      ).validCoupons;
      this.setState({
        ...this.resetStatesRelativeToDefaultSelectedTicket(ecoupons),
        selectCouponValue: 0,
      });
    }
  }
  // 优惠券
  userUseCouponStatus = (ecoupons, validCoupons, discountValue) => {
    return ecoupons && ecoupons.length
      ? validCoupons && validCoupons.length > 0 && this.props.ecouponsAvailable
        ? discountValue * 1 === 0
          ? "不使用优惠券"
          : `-¥${discountValue}`
        : "暂无可用优惠券"
      : "暂无优惠券";
  };

  render() {
    let currentActivity = this.findCurrentActivity();
    console.log(
      this.state,
      "CourseCoupons_props: ",
      this.props,
      currentActivity
    );
    // console.log("CourseCoupons_state: ", this.state);
    let eCouponData = this.props.eCouponData;
    const {
      courseStartDate,
      systemTime,
      activityCategoryType,
      coursePayMode,
    } = this.props;
    let { coursePayFinalPrice } = this.state;
    let discountValue = this.state.selectCouponValue
      ? this.state.selectCouponValue
      : this.state.defaultSelectPrice;
    let showText = function (ecoupon) {
      ecoupon.title;
      // if (ecoupon.all_available) {
      //   if (ecoupon.experience_available) {
      //     return "可用于所有课程及经验讲座报名"
      //     if (ecoupon.market_promotion_available) {
      //       return "可用于所有课程、经验讲座，及部分领学习好物系列活动报名"
      //     }
      //   }
      //   if (ecoupon.market_promotion_available) {
      //     return "可用于所有课程，及部分领学习好物系列活动报名"
      //   }
      //   return "可用于所有课程报名"
      // } else if (ecoupon.experience_available) {
      //   return "可用于所有经验讲座报名"
      //   if (ecoupon.market_promotion_available) {
      //     return "可用于所有经验，及部分领学习好物系列活动"
      //   }
      // } else if (ecoupon.market_promotion_available) {
      //   return "可用于所有经验，及部分领学习好物系列活动"
      // } else {
      //   return "可用于报名以下课程："
      // }
    };

    let virtualAccountClassName = function (props, state) {
      if (
        props.virtualAccount &&
        props.virtualAccount.money > 0 &&
        state.selectVirtualAccount
      ) {
        if (
          props.ecouponsAvailable &&
          props.eCouponData &&
          props.eCouponData.length > 0 &&
          coursePayFinalPrice > 0 &&
          discountValue - coursePayFinalPrice > 0
        ) {
          return "virtual_account";
        } else {
          return "virtual_account selected";
        }
      } else {
        return "virtual_account";
      }
    };
    console.log(
      "subStudyPackageActivities_selectedActivityIndex",
      this.props.subStudyPackageActivities,
      this.state.selectedActivityIndex
    );

    const { subStudyPackageActivities, studyPackageActivity } = this.props;
    let subInstance; // 处理单个的sub_study_activity 即买即上
    let subStartDate; // 最终的开始单个分级课处理之后的时间
    let subSingleStartTime; // 处理单个的sub_study_activity start_time
    let subSingleAbsentee; // 处理单个的sub的课程是否为囤课

    if (
      subStudyPackageActivities &&
      subStudyPackageActivities[this.state.selectedActivityIndex]
    ) {
      subSingleStartTime = formatDotDate(
        subStudyPackageActivities[this.state.selectedActivityIndex].start_time
      );
      subInstance =
        subStudyPackageActivities[this.state.selectedActivityIndex].instance;
      subSingleAbsentee =
        subStudyPackageActivities[this.state.selectedActivityIndex].absentee;
    }

    if (studyPackageActivity && studyPackageActivity.absentee) {
      subStartDate = "";
    } else {
      subStartDate =
        subSingleStartTime ||
        (courseStartDate && this.props.isShowStartTime ? courseStartDate : "");
    }

    console.log(
      "subInstance， subStartDate， subSingleStartTime， subSingleAbsentee: ",
      subInstance,
      subStartDate,
      subSingleStartTime,
      subSingleAbsentee
    );
    const { coupons, validCoupons } = sortECouponsOnlyForCouponUseMode(
      eCouponData,
      systemTime,
      coursePayMode,
      this.state.coursePayFinalPrice
    );
    console.log("825-validCoupons: ", validCoupons);
    eCouponData = coupons;
    // console.log("eCouponData_eCouponData", eCouponData);
    return !currentActivity ||
      (!this.state.selectedActivityIndex &&
        this.state.coursePayFinalState !== "pay_remaining") ? (
      ""
    ) : (
      <div
        className="course_coupon_list"
        style={{ display: this.state.isCouseCouponDisplay }}
      >
        <div
          className="ticket_box"
          style={{ display: this.state.orderGuideDisplay }}
        >
          <div className="overlay"></div>
          <div className="course_coupon_container">
            <div className="dialog_title">订单确认</div>
            <div
              className="close_btn_right"
              style={{ top: -2 }}
              onClick={this.btnCloseCourseCouponClick}
            >
              &times;
            </div>
            <div className="course_coupon_box">
              <div className="title_price">
                <div className="title_date">
                  <h3
                    dangerouslySetInnerHTML={{
                      __html: this.props.subStudyPackageActivities
                        ? this.state.selectName
                        : this.props.courseName,
                    }}
                  >
                    {/* {this.props.subStudyPackageActivities
                      ? this.state.selectName
                      : this.props.courseName} */}
                  </h3>
                  {
                    // 非教具包购买
                    activityCategoryType !== 4 && !subSingleAbsentee && (
                      <div className="date">
                        {subSingleStartTime ? `${subSingleStartTime}开课` : ""}
                      </div>
                    )
                  }
                </div>
                <div className="price">
                  {this.state.coursePayFinalState == "pay_remaining"
                    ? `￥${Number(this.state.coursePayFinalPrice).toFixed(2)}`
                    : `￥${Number(currentActivity.max_price).toFixed(2)}`}
                  {/* // ¥{Number(currentActivity.max_price).toFixed(2)} */}
                </div>
              </div>
              {currentActivity.stage !== 4 &&
                (this.state.coursePayFinalState == "join_groupon" ||
                  this.state.coursePayFinalState == "new_groupon" ||
                  this.state.coursePayFinalState == "group") && (
                  <div
                    className="coupon_discount"
                    // onClick={this.onSelectCouponClick}
                  >
                    <div className="discount_name">拼团优惠</div>
                    <div className=" discount_price1">
                      {"-￥" +
                        Number(
                          Number(currentActivity.max_price) -
                            Number(currentActivity.min_price)
                        ).toFixed(2)}
                    </div>
                  </div>
                )}
              {currentActivity.stage !== 4 &&
                this.state.coursePayFinalState !== "pay_remaining" &&
                currentActivity.owned_media_lesson_count !== 0 && (
                  <div
                    className="coupon_discount"
                    // onClick={this.onSelectCouponClick}
                  >
                    <div className="discount_name">已有课时抵扣</div>
                    <div className="discount_price1">
                      {"-￥" +
                        (this.state.coursePayFinalState === "direct"
                          ? Number(
                              currentActivity.subtractOwnedMediaDirect
                            ).toFixed(2)
                          : Number(
                              currentActivity.subtractOwnedMediaGroup
                            ).toFixed(2))}
                    </div>
                  </div>
                )}
              {this.props.ecouponsAvailable && (
                <div
                  className="coupon_discount"
                  onClick={this.onSelectCouponClick}
                >
                  <div className="discount_name">
                    优惠券
                    {
                      // 初始化默认的时候，如果选中默认最大金额，则显示“最佳....”
                      eCouponData &&
                        eCouponData.length > 0 &&
                        discountValue === this.state.defaultSelectPrice &&
                        discountValue !== 0 && <span>已选最佳优惠券</span>
                    }
                  </div>
                  <div className="discount_price">
                    {this.userUseCouponStatus(
                      coupons,
                      validCoupons,
                      discountValue
                    )}
                  </div>
                </div>
              )}
              {this.props.virtualAccount && (
                <div
                  className={virtualAccountClassName(this.props, this.state)}
                  onClick={this.chooseVirtualAccount}
                >
                  <h3>奖学金</h3>
                  <div className="virtual_account_money">
                    可用余额{this.props.virtualAccount.money}元
                  </div>
                </div>
              )}
            </div>
            <div className="course_coupon_buy">
              {this.showPrice(
                this.props.ecouponsAvailable,
                discountValue,
                coursePayFinalPrice,
                this.props.virtualAccount
              )}
              <div
                className="button_go_buy"
                onClick={debounce(this.payMoneyClick)}
              >
                <span>支付</span>
              </div>
            </div>
          </div>
        </div>

        {/* todo 有优惠券弹框选券 */}
        <div
          className="has_tickets"
          style={{ display: this.state.hasCouponDisplay }}
        >
          <div className="overlay"></div>
          <div className="has_ticket_top">
            <div className="dialog_title">选择优惠券</div>
            <div
              className="close_btn_right"
              onClick={this.btnCloseTicketSelectClick}
            ></div>
          </div>
          {eCouponData && (
            <ul>
              {eCouponData.map((ecoupon, index) => {
                const { isCanUse, tipText } = getCouponUseState(
                  ecoupon,
                  systemTime,
                  this.props.coursePayMode,
                  this.state.coursePayFinalPrice
                );
                return (
                  <li
                    key={index}
                    onClick={
                      !isCanUse ? null : this.selectCorrespondTicketClick
                    }
                    data-id={ecoupon.id}
                    data-index={index}
                    data-value={ecoupon.price}
                  >
                    <div
                      className="coupon_ticket"
                      style={{
                        height:
                          !ecoupon.course_availables.can_buy_names || ecoupon.course_availables.can_buy_names.length <= 1
                            ? "120px"
                            : null,
                      }}
                    >
                      <div
                        className="ticket_left"
                        style={{
                          backgroundColor: !isCanUse ? "#ddd" : null,
                        }}
                      >
                        <TicketPriceCondition
                          price={ecoupon.price}
                          minProductPrice={ecoupon.min_product_price}
                        />
                      </div>
                      <div
                        className="ticket_right"
                        style={{
                          backgroundImage:
                            this.state.selectIndex == index
                              ? `url(${icon_selected})`
                              : !isCanUse
                              ? null
                              : `url(${icon_select})`,
                        }}
                      >
                        <div className="ticket_list">
                          <div className="ticket_desc">
                            <div
                              className={
                                ecoupon.all_available === true
                                  ? "text_over_available"
                                  : "text"
                              }
                            >
                              {
                                // showText(ecoupon)
                                ecoupon.title
                              }
                            </div>
                          </div>
                          <div className="ticket_name">
                            {ecoupon.course_availables.can_buy_names.map((name, idx) => (
                              <p key={idx}>{name}</p>
                            ))}
                          </div>
                        </div>

                        <div className="ticket_discount_status">
                          <div className="date">
                            {(
                              ecoupon.start_time.replace(/-/g, ".") + ""
                            ).substr(0, 10)}{" "}
                            -{" "}
                            {(ecoupon.end_time.replace(/-/g, ".") + "").substr(
                              0,
                              10
                            )}
                          </div>
                          <CouponCanNotUseTip tipText={tipText} />
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
              <li
                onClick={this.selectCorrespondTicketClick}
                data-id="-1"
                data-index={eCouponData.length}
                data-value="0"
              >
                <div
                  className="coupon_ticket"
                  style={{
                    backgroundImage:
                      this.state.selectIndex == eCouponData.length
                        ? `url(${icon_selected})`
                        : `url(${icon_select})`,
                    position: "relative",
                    padding: "10px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "16px",
                    backgroundPosition: "right 10px center",
                  }}
                >
                  <div
                    className="icon_un_use"
                    style={{ position: "absolute", right: "10px" }}
                  ></div>
                  <div className="un_use_ticket">不使用优惠券</div>
                </div>
              </li>
            </ul>
          )}
        </div>

        {/* 无优惠券的情况下，弹窗显示提示 */}
        <div
          className="un_tickets"
          style={{ display: this.state.UnCouponDisplay }}
        >
          <div className="overlay"></div>
          <div className="has_ticket_top">
            <div className="dialog_title">选择优惠券</div>
            <div
              className="close_btn_right"
              onClick={this.btnCloseUnTicketSelectClick}
            ></div>
          </div>
          <div className="coupon_info">
            <img src={coupon_empty} alt="" />
            <span>您还没有优惠券</span>
          </div>
        </div>
      </div>
    );
  }
}
