/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import React from "react";
import { request, csrfHeaders } from "../utils/request";
import { onNavigate } from "../utils/helpers";
import { ToastManager } from "../utils/Toast";
import DiscountPriceDialog from "./DiscountPriceDialog.jsx";
import FissionWelfareDialog from "./FissionWelfareDialog.jsx";
import ModalManager from "../utils/Modal/ModalManage.jsx";
import icon_back from "../../../assets/images/icon_back.png";
import back_unlock_girl from "../../../assets/images/back_unlock_girl.png";
import QuestionnaireActivity from "../questionnaire/QuestionnaireActivity.jsx";

// 计算当前课程中抵扣价中的最低价格
export const calcActivityLowestPrice = (sub_study_package_activities) => {
  const getActivityMinPrice = [];
  const originalPriceCommon = (single_sub_activity) => {
    return single_sub_activity.activity_type == 1 ||
      single_sub_activity.sale_mode == "normal"
      ? single_sub_activity.max_price
      : single_sub_activity.launcher_price;
  };

  const subActivities =
    sub_study_package_activities.length <= 1
      ? sub_study_package_activities
      : sub_study_package_activities.filter((sub_act) => sub_act.stage != 4);

  subActivities.map((single_sub_activity, index) => {
    const originalPrice = originalPriceCommon(single_sub_activity);
    return getActivityMinPrice.push({
      name: single_sub_activity.name,
      discountPrice: calcDiscountPercentPrice(
        subActivities,
        originalPrice,
        single_sub_activity.base_price,
        index
      ),
      listPrice: originalPrice,
    });
  });

  const sortMinPriceArray = getActivityMinPrice.sort(function (a, b) {
    return a.discountPrice - b.discountPrice;
  });
  console.log("抵扣价排序：", sortMinPriceArray);
  return sortMinPriceArray;
};

// 计算课时按比例抵扣价格
export const calcDiscountPercentPrice = (
  filterOwnedCountArray,
  price,
  base_price_invalid,
  singleIndex
) => {
  const {
    owned_media_lesson_count,
    media_lessons_count,
    base_price,
    low_price_media_lesson_paid_amount,
  } = filterOwnedCountArray[singleIndex];

  let discountPercentPrice;
  if (owned_media_lesson_count > 0) {
    discountPercentPrice = Math.round(
      (1 - owned_media_lesson_count / media_lessons_count) * (price - base_price) + base_price
    );
  } else {
    discountPercentPrice = price;
  }
  console.log(
    "计算课时按比例抵扣价格_discountPercentPrice，课时数: ",
    discountPercentPrice - low_price_media_lesson_paid_amount, media_lessons_count
  );
  return discountPercentPrice - low_price_media_lesson_paid_amount;
};

// 过滤分级列表中owned_media_lesson_count是否有大于0的集合
export const filterSubActivityOweMediaCount = (
  sub_study_package_activities
) => {
  const filterOwnedCountArray = sub_study_package_activities.filter(
    (sub_activity) => sub_activity.total_owned_media_lessons_count > 0
  );
  const isActivityType = filterOwnedCountArray.filter(
    (item) => item.activity_type != 1
  );

  if (filterOwnedCountArray.length == 0) return;
  const loopGetDiscountMinusPrice = [];
  filterOwnedCountArray.forEach((single_sub_activity, index) => {
    loopGetDiscountMinusPrice.push({
      discountPrice: calcDiscountPercentPrice(
        filterOwnedCountArray,
        single_sub_activity.max_price,
        single_sub_activity.base_price,
        index
      ),
      listPrice: single_sub_activity.max_price,
    });
  });

  const sortDescendingArray = loopGetDiscountMinusPrice.sort(function (a, b) {
    return a.discountPrice - b.discountPrice;
  });
  // console.log("按比例抵扣价格排序_dsortDescendingArray", sortDescendingArray);
  const discountMaxPrice = sortDescendingArray
    .map((item) => item.listPrice - item.discountPrice)
    .sort((a, b) => a - b);

  console.log("discountMaxPrice: ", discountMaxPrice);
  const calcSortLastDerateValue = discountMaxPrice[discountMaxPrice.length - 1];

  const getLoopListDeal = () =>
    sub_study_package_activities.length > 1
      ? isActivityType.reduce((acc, cur, index) => {
          const describeContent =
            acc + `《${cur.name}》中的${cur.total_owned_media_lessons_count}节课`;
          if (index === isActivityType.length - 1) {
            return describeContent;
          }
          return `${describeContent}，`;
        }, "")
      : filterOwnedCountArray.reduce((acc, cur) => {
          return acc + `该课程中的${cur.total_owned_media_lessons_count}节课，`;
        }, "");

  // 已有课时数 ≥ 当前课程课时数（单课程免费领取）
  const singleCourseJudge =
    sub_study_package_activities.length == 1 &&
    sub_study_package_activities[0]?.owned_media_lesson_count >=
      sub_study_package_activities[0]?.media_lessons_count;

  const getLoopList =
    sub_study_package_activities.length > 1
      ? `${getLoopListDeal()}`
      : singleCourseJudge
      ? `该课程的全部内容，系统已自动为您`
      : `${getLoopListDeal()}报名正价课时最高可为您`;

  return {
    filterOwnedCountArray, // 找到列表中owned_media_lesson_count有大于0的集合
    calcSortLastDerateValue, // 计算可被抵扣的价格（原价 - 抵扣价）
    getLoopList, // 遍历处理弹框中的课程内容
  };
};

// 过滤取出state: ok的数组
export const filterPackageStateIsOkArray = (sub_study_package_activities) => {
  const getPackageStateIdCollect = [];
  sub_study_package_activities
    .filter((single_sub_activity) => single_sub_activity.state == "ok")
    .map((activity) => getPackageStateIdCollect.push(activity.id));
  return getPackageStateIdCollect;
};

// 按比例折价功能弹框
export const courseDiscountDialog = (
  contentList,
  calcOwnedCount,
  url,
  subStudyPackageActivities,
  saleMode
) => {
  const discountPriceContent = {
    contentList,
    calcOwnedCount,
    url,
  };
  console.log("discountPriceContent: ", discountPriceContent);

  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return (
        <DiscountPriceDialog
          subActivities={subStudyPackageActivities}
          packageStateIsOkArray={filterPackageStateIsOkArray(
            subStudyPackageActivities
          )}
          discountPriceContent={discountPriceContent}
          onModalClose={onClose}
          saleMode={saleMode}
        />
      );
    },
  }).open();
};

// 报名页测评问卷
export const showQuestionnaireActivityModal = (
  get_questions_mini_exam_url,
  handleQuestionaireResultClose
) => {
  return new ModalManager({
    isCreateModalDynamic: true,
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return (
        <QuestionnaireActivity
          get_questions_url={get_questions_mini_exam_url}
          onModalClose={onClose}
          handleQuestionaireResultClose={handleQuestionaireResultClose}
        />
      );
    },
  });
};

// 过滤出·体验课
export const findExperienceCourse = (activities) => {
  const singleExperienceCourse = [];
  if (activities && activities.length > 0) {
    [...activities].forEach((activity, index) => {
      // stage: 4, 是体验课程
      if (activity.stage === 4) {
        singleExperienceCourse.push({
          ...activity,
          singleExperienceIdx: index,
        });
      }
    });
  }
  return singleExperienceCourse;
};

// 弹框模块 拼团裂变福利
export const showFissionWelfareModal = () => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return <FissionWelfareDialog onModalClose={onClose} />;
    },
    isCreateModalDynamic: true,
  }).open();
};

// 弹框模块 扫码进提速营活动群弹框
export const showJoinGroupQrcodeModal = (
  group_url,
  reviewed_url,
  onAlertPoster
) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        onAlertPoster();
        modalClose();
        request({
          url: reviewed_url,
          method: "POST",
          headers: csrfHeaders,
        })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      };
      return (
        <div className="add_cc_dialog add_group_qrcode_dialog">
          <div className="cc_wrapper">
            <div className="cc_content">
              <div className="btn-close-cc" onClick={() => onClose()}>
                &times;
              </div>
              <div className="palyContent join_group_qrcode_content">
                <p>
                  扫码进“<span>提速营说明群</span>”
                </p>
                <img src={group_url} alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};

// 加减计算提速营课程开通弹框
export const showOpenSpeedTrainingCampModal = (
  training_camp_promotion,
  name,
  url
) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = (e) => {
        ToastManager.showLoading("加载中...");
        onNavigate(e, "https://xinya.me/");
      };
      const handleUpgradeCourse = (e) => {
        request({
          url: url,
          method: "POST",
          headers: csrfHeaders,
        })
          .then(({ data }) => {
            const { redirect_url } = data;
            if (redirect_url) {
              ToastManager.showLoading("正在开通中...");
              onNavigate(e, redirect_url);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      };

      let text = "";
      let button_text = "";
      if (training_camp_promotion == "select") {
        text = "课程仅限开通1个，开通后不可取消。确定开通《" + name + "》吗？";
        button_text = "取消";
      } else if (training_camp_promotion == "selected_other") {
        text = "您已开通过其他课表，无法再开通此课表啦~";
        button_text = "确定";
      }

      return (
        <div className="spend_training_camp_dialog">
          <div className="training_camp_box">
            <div className="camp_content">{text}</div>
            <div className="camp_buttons">
              <div
                className={`${
                  training_camp_promotion == "selected_other"
                    ? "camp_confirm"
                    : "camp_close"
                }`}
                style={{
                  marginLeft:
                    training_camp_promotion == "selected_other" ? "0" : "",
                }}
                onClick={(e) => onClose(e)}
              >
                {button_text}
              </div>
              {training_camp_promotion == "select" ? (
                <div
                  className="camp_confirm"
                  onClick={(e) => handleUpgradeCourse(e)}
                >
                  确定
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};

// questionnaire survey
export const showCustomizedQuestionnaireModal = (url) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return (
        <div className="unlock_week_learn_modal">
          <div className="unlock_week_learn_container">
            <img
              className="unlock_top_survey"
              src={icon_back}
              // src="https://upload.fireflybaby.cn/study_package_activity/66e78144e59f73183007ed7e9e106e18.png"
              alt=""
            />
            <div className="week_learn_box questionnaire_box">
              <div className="week_learn_content">
                <p>上课体验如何？6个小问题</p>
                <p>帮助我们为您提供更好的教学服务</p>
              </div>
              <a
                className="button_now_unlock button_questionnaire_fill"
                style={{ backgroundColor: "#66D167" }}
                href={url}
              >
                <span
                  className="now_fill"
                  style={{ backgroundColor: "#66D167" }}
                >
                  立即填写
                </span>
              </a>
            </div>
            <div className="week_learn_close" onClick={() => onClose()}></div>
          </div>
        </div>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};
// 一元学一周的弹框解锁
export const showUnlockWeekLearnModal = (url) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      return (
        <div className="unlock_week_learn_modal">
          <div className="unlock_week_learn_container">
            <img
              className="unlock_top_baby"
              src={back_unlock_girl}
              // src="https://upload.fireflybaby.cn/study_package_activity/e58ff03ea8aeb30576fca444057ec19c.png"
              alt=""
            />
            <div className="week_learn_box">
              <div className="week_learn_content">
                <p>
                  您的<span>1元学一周</span>体验已结束
                </p>
                <p>立即解锁全部课程继续学习</p>
              </div>
              <a className="button_now_unlock" href={`${url}&ref=dialog`}>
                <span className="now_unlock">立即解锁</span>
              </a>
            </div>
            <div className="week_learn_close" onClick={() => onClose()}></div>
          </div>
        </div>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};

// “赠课优惠”弹框
export const showPresentCourseDiscount = (
  afterChoosePayMode,
  selectMaxMoney,
  finalState,
  selectActivityId,
  selectName,
  coursePayMode,
  selectIndex,
  trial
) => {
  return new ModalManager({
    render: (_, modalClose) => {
      const onClose = () => {
        modalClose();
      };
      const handlePresentCourseDiscount = () => {
        onClose();
        afterChoosePayMode(
          parseInt(selectMaxMoney * 0.8),
          finalState,
          selectActivityId,
          selectName,
          coursePayMode,
          selectIndex,
          trial
        );
      };
      return (
        <div className="present_course_discount_container">
          <div className="present_course_discount">
            <div
              className="present_course_close"
              onClick={() => onClose()}
            ></div>
            <div className="present_course_content">
              <div className="present_gift"></div>
              <div className="present_top_text">赠课优惠</div>
              <div className="present_center_content">
                <p>
                  赠送1人，可享<span>9.5</span>折优惠，折后价：￥
                  {parseInt(selectMaxMoney * 0.95)}
                </p>
                <p>
                  赠送2人，可享<span>9.0</span>折优惠，折后价：￥
                  {parseInt(selectMaxMoney * 0.9)}
                </p>
                <p>
                  赠送3人，可享<span>8.5</span>折优惠，折后价：￥
                  {parseInt(selectMaxMoney * 0.85)}
                </p>
                <p>
                  赠送4人，可享<span>8.0</span>折优惠，折后价：￥
                  {parseInt(selectMaxMoney * 0.8)}
                </p>
                <div className="rule_describe">
                  好友报名您赠送的“1元学一周”课程，即为赠课成功
                </div>
              </div>
              <div className="present_bottom_content">
                <div className="present_course_rule">
                  <div className="rule_text">赠课规则</div>
                </div>
                <div className="present_text">
                  <div className="text_box">
                    <i>1</i>
                    <div className="text">新、老用户报名都算有效赠课；</div>
                  </div>
                  <div className="text_box">
                    <i>2</i>
                    <div className="text">
                      赠课有效期：48小时，期间您也可随时选择补差价完成报名
                      <span>（赠课人数越多，需补的差价越少）</span>；
                    </div>
                  </div>
                  <div className="text_box">
                    <i>3</i>
                    <div className="text">
                      48小时后赠课人数未达标，且未补差价，系统将自动为您退款，并清除赠课记录。
                    </div>
                  </div>
                </div>
                <div
                  className="present_button_content"
                  onClick={handlePresentCourseDiscount}
                >
                  <span>支付预付金</span>
                  <div className="present_price">
                    <i>￥</i>
                    {parseInt(selectMaxMoney * 0.8)}
                  </div>
                  <div className="present_people_number">需赠送4人</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    },
    isCreateModalDynamic: true,
  }).open();
};
