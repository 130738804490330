import React, { useCallback } from "react";
import "./DiscountCourseList.scss";

import InnerModal from "../utils/Modal/InnerModal.jsx";
import ModalMask from "../utils/Modal/ModalMask.jsx";
const DiscountCourseList = ({
  ownedApiResponseStudyPackages,
  onModalClose,
}) => {
  console.log("ownedApiResponseStudyPackages: ", ownedApiResponseStudyPackages);
  const onModalCloseClick = useCallback(() => {
    onModalClose();
  }, []);

  const findSubOwnedMediaLessons = (owned_media_lessons) => {
    console.log("ownedApiResponseStudyPackages: ", owned_media_lessons);
    return owned_media_lessons.filter(
      (lesson) =>
        (lesson.media_lesson && lesson.media_lesson.length > 0) ||
        (lesson.media_lessons && lesson.media_lessons.length > 0)
    );
  };
  return (
    <InnerModal>
      <ModalMask>
        <div className="discount_course_list_dialog">
          <div
            className="modal_close"
            onClick={() => onModalCloseClick()}
          ></div>
          <div className="modal_content">
            <div className="discount_course_container">
              {
                <div className="discount_course_box clear_scroll_bar">
                  <ol>
                    {ownedApiResponseStudyPackages.map(
                      (study_package, idx) =>
                        ((study_package.owned_media_lessons &&
                          study_package.owned_media_lessons.length > 0 &&
                          findSubOwnedMediaLessons(
                            study_package.owned_media_lessons
                          ).length > 0) ||
                          (study_package.study_modules &&
                            study_package.study_modules.length > 0 &&
                            findSubOwnedMediaLessons(
                              study_package.study_modules
                            ).length > 0)) && (
                          <li key={idx}>
                            <div className="name">
                              {study_package.owned_media_lessons
                                ? `您已拥有《${study_package.name}》课程的以下内容：`
                                : `${study_package.name}新增以下内容：`}
                            </div>
                            {study_package.owned_media_lessons &&
                              study_package.owned_media_lessons.length > 0 && (
                                <div className="sub_media_lesson_list">
                                  {study_package.owned_media_lessons.map(
                                    (lesson, idx) =>
                                      lesson.media_lesson &&
                                      lesson.media_lesson.length > 0 && (
                                        <div
                                          className="owned_lesson_list"
                                          key={idx}
                                        >
                                          <p>{lesson.study_package_name}</p>
                                          <div className="lesson_list_container">
                                            {lesson.media_lesson.map(
                                              (item, ind) => (
                                                <div className="list" key={ind}>
                                                  <span>{ind + 1}.</span>
                                                  <div className="media_lesson_name">
                                                    {item.name}
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </div>
                                      )
                                  )}
                                </div>
                              )}
                            {study_package.study_modules &&
                              study_package.study_modules.length > 0 && (
                                <div className="sub_media_lesson_list">
                                  {study_package.study_modules.map(
                                    (lesson, idx) =>
                                      lesson.media_lessons &&
                                      lesson.media_lessons.length > 0 && (
                                        <div
                                          className="owned_lesson_list"
                                          key={idx}
                                        >
                                          <p>{lesson.name}</p>
                                          <div className="lesson_list_container">
                                            {lesson.media_lessons &&
                                              lesson.media_lessons.length &&
                                              lesson.media_lessons.map(
                                                (item, ind) => (
                                                  <div
                                                    className="list"
                                                    key={ind}
                                                  >
                                                    <span>{ind + 1}.</span>
                                                    <div className="media_lesson_name">
                                                      {item.name}
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      )
                                  )}
                                </div>
                              )}
                          </li>
                        )
                    )}
                  </ol>
                </div>
              }
            </div>
          </div>
        </div>
      </ModalMask>
    </InnerModal>
  );
};

export default DiscountCourseList;
